import { PluginFunction } from 'vue'

import DashboardInjected from './dashboard-view/DashboardInjected.vue'
import LeaveManagementInjected from './leave-management/LeaveManagementInjected.vue'
import LeavePoliciesForm from './leave-management/setup/leave-policies/LeavePoliciesForm.vue'
import LeavePolicyItemsForm from './leave-management/setup/leave-policy-items/LeavePolicyItemsForm.vue'
import LeaveRequestsInjected from './leave-management/LeaveRequestsInjected.vue'
import NoAccessDashboardView from './dashboard-view/NoAccessDashboardView.vue'
import BackOfficeReportsView from './back-office-reports/views/BackOfficeReportsView.vue'
import HumanCapitalManagementEmployeeInjected from './leave-management/human-capital-management/HumanCapitalManagementEmployeeInjected.vue'
import ScimFormInjected from './scim-form-config/ScimFormInjected.vue'
import SmsLogsCounter from './sms-logs/SmsLogsCounter.vue'
import PhoneCallLogsCounter from './phone-call-logs/PhoneCallLogsCounter.vue'
import WidgetPage from './WidgetPage.vue'
import WidgetPageStandAlone from './WidgetPageStandAlone.vue'
import { CalendarExceptionsPlugin } from './calendar-exceptions'
import { EntityLabelsPlugin } from './entity-labels'
import { MobileRunsheetsPlugin } from './mobile-runsheets'
import { MobileRunsheetGroupsPlugin } from './mobile-runsheet-groups'
import { MobileScheduleGroupsPlugin } from './mobile-schedule-groups'
import { MobileSchedulesPlugin } from './mobile-schedules'
import { ReportTemplatesPlugin } from './report-templates'
import { ReportsPlugin } from './reports'
import { SchedulingGroupsPlugin } from './scheduling-groups'
import { SiteTasksPlugin } from './site-tasks'
import { SystemSettingsPlugin } from './system-settings'
import { BackOfficeReportsPlugin } from './back-office-reports'
import { RunsheetReportViewPlugin } from './runsheet-report-view'
import { SplitInvoicePlugin } from './split-invoice'
import { VendorsPlugin } from '@/tt-widget-views/vendors'
import { DispatchActionSettingsPlugin } from '@/tt-widget-views/dispatch'
import { MobileRunsheetOccurrencesPlugin } from '@/tt-widget-views/mobile-runsheet-occurrences'
import { ManageTeamsViewPlugin } from '@/tt-widget-views/manage-teams'
import RolesManagedPermissionPoliciesInjectedView from '@/tt-widget-views/roles-and-permissions/RolesManagedPermissionPoliciesInjectedView.vue'

export const TtWidgetViewsPlugin: PluginFunction<void> = (Vue) => {
  Vue.use(CalendarExceptionsPlugin)
  Vue.use(BackOfficeReportsPlugin)
  Vue.use(RunsheetReportViewPlugin)
  Vue.use(EntityLabelsPlugin)
  Vue.use(MobileRunsheetsPlugin)
  Vue.use(MobileRunsheetGroupsPlugin)
  Vue.use(MobileScheduleGroupsPlugin)
  Vue.use(MobileSchedulesPlugin)
  Vue.use(ReportTemplatesPlugin)
  Vue.use(ReportsPlugin)
  Vue.use(SchedulingGroupsPlugin)
  Vue.use(SiteTasksPlugin)
  Vue.use(SystemSettingsPlugin)
  Vue.use(SplitInvoicePlugin)
  Vue.use(VendorsPlugin)
  Vue.use(DispatchActionSettingsPlugin)
  Vue.use(MobileRunsheetOccurrencesPlugin)
  Vue.use(ManageTeamsViewPlugin)

  Vue.component('DashboardInjected', DashboardInjected)
  Vue.component('LeaveManagementInjected', LeaveManagementInjected)
  Vue.component('LeavePoliciesForm', LeavePoliciesForm)
  Vue.component('LeavePolicyItemsForm', LeavePolicyItemsForm)
  Vue.component('LeaveRequestsInjected', LeaveRequestsInjected)
  Vue.component('NoAccessDashboardView', NoAccessDashboardView)
  Vue.component('BackOfficeReportsView', BackOfficeReportsView)
  Vue.component(
    'HumanCapitalManagementEmployeeInjected',
    HumanCapitalManagementEmployeeInjected,
  )
  Vue.component('ScimFormView', ScimFormInjected)
  Vue.component('SmsLogsCounter', SmsLogsCounter)
  Vue.component('PhoneCallLogsCounter', PhoneCallLogsCounter)
  Vue.component('WidgetPage', WidgetPage)
  Vue.component('WidgetPageStandAlone', WidgetPageStandAlone)
  Vue.component('RolesManagedPermissionPoliciesInjectedView', RolesManagedPermissionPoliciesInjectedView)
}
