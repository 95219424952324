import {
  debounceInputEvent,
  DefinitionOption,
  DefinitionOptionMap,
  vuetifyDefaults,
} from '@tracktik/tt-json-schema-form'
import { UserPreferenceInterface } from '@tracktik/tt-authentication'

import { AppContext } from '@/tt-app-context'
import { convertPrefToMoment } from '@/helpers/formats/dates/utils'

import {
  convertPhpLocale,
  createDate,
  createDateTime,
  createTime,
  createViewWithProps,
  definitionNames,
  DrawCanvas,
  FileImage,
  Relation,
  ReportFlag,
  SiteLocation,
} from './EntityViewDefinitions.utils'

// @todo: separate report-specific definitions from open-api definitions
export const createEntityViewDefinitions = (
  //@todo: pass the appContext to inject the authModule dependency (for file upload, img url path, etc)
  userPreferences: UserPreferenceInterface,
): { [k in (typeof definitionNames)[number]]?: DefinitionOption } => ({
  Relation,
  RelationList: {
    view: {
      is: 'RelationField',
      props: { ...vuetifyDefaults, multiple: true },
    },
  },
  TextArea: {
    view: {
      is: 'TextAreaField',
    },
  },
  TextAreaNullable: {
    view: {
      is: 'TextAreaField',
    },
  },
  Currency: {
    view: {
      is: 'CurrencyField',
      props: {
        ...vuetifyDefaults,
        currency: userPreferences.currency,
        languageCode: convertPhpLocale(userPreferences.locale),
      },
      on: {
        input: debounceInputEvent,
      },
    },
  },
  CurrencyRate: {
    view: {
      is: 'CurrencyField',
      props: {
        ...vuetifyDefaults,
        currency: userPreferences.currency,
        languageCode: convertPhpLocale(userPreferences.locale),
      },
      on: {
        input: debounceInputEvent,
      },
    },
  },
  Date: createDate({
    locale: convertPhpLocale(userPreferences.locale),
    userDateFormat: convertPrefToMoment(userPreferences.dateFormat),
  }),
  DateNullable: createDate({
    locale: convertPhpLocale(userPreferences.locale),
    userDateFormat: convertPrefToMoment(userPreferences.dateFormat),
  }),
  DateTime: createDateTime({ withTimeZone: false }),
  TimeStampNumber: createDateTime({ withTimeZone: true }),
  TimeStampDate: createDateTime({ withTimeZone: true }),
  Time: createTime({
    userTimeFormat: convertPrefToMoment(userPreferences.timeFormat),
  }),
  TimeNullable: createTime({
    userTimeFormat: convertPrefToMoment(userPreferences.timeFormat),
  }),
  // RegionsId: {
  //   view: {
  //     is: 'RegionSelectorField',
  //     props: {
  //       ...vuetifyDefaults
  //     }
  //   }
  // },
  Email: createViewWithProps({ prependInnerIcon: 'email' }),
  PhoneNumber: createViewWithProps({
    prependInnerIcon: 'phone',
  }),
  Percentage: createViewWithProps({
    prependInnerIcon: 'mdi-percent-outline',
  }),
  Password: createViewWithProps({
    type: 'password',
    autocomplete: 'new-password',
    prependInnerIcon: 'vpn_key',
  }),
  Uri: createViewWithProps({ prependInnerIcon: 'link' }),
  TimeZone: createViewWithProps({ prependInnerIcon: 'public' }),
  SortIndex: createViewWithProps({
    prependInnerIcon: 'format_list_numbered_rtl',
  }),
  // @todo: JSON (type object) should be handled as an on-the-fly sub-form
  FileImage,
  Signature: DrawCanvas,
  DrawCanvas,
  ReportFlag,
  DateTimeNullable: createDateTime({ withTimeZone: false }),
  DrawCanvasNullable: DrawCanvas,
  FileImageNullable: FileImage,
  RelationNullable: Relation,
  ReportFlagNullable: ReportFlag,
  SignatureNullable: DrawCanvas,
  SectionDivider: {
    view: {
      is: 'SectionDivider',
    },
  },
  SiteLocation,
  SiteLocationNullable: SiteLocation,
})

export const getResourceDefinitions = ({
  authModule,
}: AppContext): DefinitionOptionMap =>
  createEntityViewDefinitions(authModule.getUserPreferences())
