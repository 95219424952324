import { FormOptions, JSONSchema7 } from '@tracktik/tt-json-schema-form'

export enum ReportTemplateActions {
  AssignLibrary = 'assign-library',
  SortFields = 'sort-fields',
  UnassignLibrary = 'unassign-library',
  Archive = 'archive',
  AddTranslation = 'add-translation',
  Extend = 'extend',
}

export enum ReportTemplateType {
  DISPATCHABLE_TASK = 'DISPATCHABLE_TASK',
}

export type ReportTemplate = {
  name: string
}

export type ReportTemplatesJsonFormSchemaExtensionResponse = {
  formOptions?: FormOptions
  schema: JSONSchema7
  value?: Record<string, unknown>
}

export enum AvailableToAllRegionsType {
  PARENT_GLOBAL_ID = 'PARENT_GLOBAL_ID',
  PARENT_ID_NONE = 'PARENT_ID_NONE',
}

export enum ReportTemplateFeatures {
  ASSET_HUB = 'ASSET-HUB',
  CHECKPOINT_TOUR = 'CHECKPOINT-TOUR',
  JOB_TYPE = 'JOB-TYPE',
  RUNSHEET = 'RUNSHEET',
  SITE_TASK = 'SITE-TASK',
  STANDALONE_REPORTS = 'STANDALONE-REPORTS',
}

export type AccountFeatureAssociationResponse = {
  reportTemplateFeatures: {
    features: Record<ReportTemplateFeatures, string>
  }
}

export type ReportTemplateFeatureAssociation = {
  id: number
  feature: ReportTemplateFeatures
  reportTemplate: number
}

export type ReportTemplateFeatureAssociationResponse = {
  associatedFeatures: ReportTemplateFeatureAssociation[]
}

export enum ReportTemplateArchiveChildExtensionAction {
  ARCHIVE_CHILDREN = 'ARCHIVE_CHILDREN',
  RELINK_CHILDREN = 'RELINK_CHILDREN',
}

export interface ReportTemplateLabels {
  code: string
  id: number
  label: string
  status: string
}
