<template>
  <ProvideItemHook v-if="itemHook" :provide="itemHook">
    <tt-attr name="account" />
  </ProvideItemHook>
</template>

<script lang="ts">
import Vue from 'vue'

import { EntityItemHook } from '@/tt-widget-entity-flow/EntityItemHook'
import { Resources } from '@/tt-entity-design/src/types'

export default Vue.extend({
  name: 'UserByodAccount',
  props: {
    positionId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      itemHook: new EntityItemHook(this.$appContext, {
        entityId: this.positionId,
        resourceName: Resources.POSITIONS,
      }),
    }
  },
  beforeDestroy() {
    this.itemHook.beforeDestroy()
  },
})
</script>
