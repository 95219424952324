<template>
  <div>
    <json-field name="dataSet.filters.scopes" />
    <v-divider />
    <json-field
      name="dataSet.filters.includeInactive"
      as="IncludeInactiveInput"
    />
    <v-divider />
    <json-field
      v-if="showSort"
      as="DataSetSortObjectInput"
      name="dataSet.sort"
    />
    <v-divider v-if="showSort" />
    <json-field name="dataSet.filters.filters" as="AttributeFilterInput" />
    <v-divider />
    <json-field name="dataSet.filters.customFilters" as="CustomFilterInput" />
    <v-divider />
    <json-field name="dataSet.filters.whereQL" />
    <v-divider />
    <json-field name="dataSet.filters.havingQL" as="HavingQLWidgetFormInput" />
    <v-divider />
    <json-field name="dataSet.contextFilters" />
    <json-field
      name="dataSet.cacheMaxAge"
      type="number"
      :label="$t('widgets.cache_info.cache-max-age')"
      :placeholder="$t('widgets.cache_info.time-in-seconds')"
      outlined
      dense
      class="pt-3"
    />
  </div>
</template>

<script lang="ts">
import Vue from 'vue'

export default Vue.extend({
  props: {
    showSort: {
      type: Boolean,
      default: false,
    },
  },
})
</script>
