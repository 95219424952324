<template>
  <v-text-field
    v-model="search"
    hide-details
    class="search-field primary--text"
    outlined
    dense
    :placeholder="$t('widget_search.type_to_search')"
    :type="type"
    :class="$vuetify.theme.dark && 'dark--mode'"
  >
    <template #prepend-inner>
      <v-icon small v-text="'search'" />
    </template>

    <template #append>
      <v-btn v-if="value" icon small @click="debounceEmit(null)">
        <v-icon small v-text="'close'" />
      </v-btn>
    </template>
  </v-text-field>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue'
import debounce from 'lodash/debounce'
import { Search } from '@/plugins/o11n'
import { INPUT_DEBOUNCE_TIME } from '@/helpers/constants'

export default Vue.extend({
  name: 'TSearchInput',
  props: {
    value: {
      type: [String, Number],
      default: null,
    },
    trackAnalytics: {
      type: Boolean,
      default: false,
    },
    debounceTime: {
      type: Number,
      default: INPUT_DEBOUNCE_TIME,
    },
    type: {
      type: String as PropType<'text' | 'number'>,
      default: 'text',
    },
  },
  computed: {
    debounceEmit(): any {
      return debounce((value) => {
        if (this.trackAnalytics) this.$analytics.track(Search.create())

        this.$emit('input', value)
      }, this.debounceTime)
    },
    search: {
      get(): string | number {
        return this.value
      },
      set(value: string | number) {
        this.debounceEmit(value)
      },
    },
  },
})
</script>

<style lang="scss">
@import 'vuetify/src/styles/settings/_variables.scss';

$search-field-border-color: white;

.search-field.dark--mode {
  background-color: black;
}

.search-field {
  background-color: white;
  font-size: 0.8em;
  border-radius: 1.5em;
  align-items: center;
  height: 32px;
  border-color: level1 !important;
  // remove the default min height
  &.v-text-field--outlined.v-input--dense > .v-input__control > .v-input__slot {
    min-height: auto;
    height: 100%;
  }

  // remove the margin-top of the search button
  &.v-text-field--enclosed.v-input--dense:not(
      .v-text-field--solo
    ).v-text-field--outlined
    .v-input__append-inner {
    margin-top: 2px;
  }

  // decrease the min-height of the input element
  &.v-input input {
    min-height: 28px;
  }

  // change the outline color
  &.v-text-field--outlined {
    fieldset {
      border-color: transparent !important;
    }
    &.v-input--is-focused {
      fieldset {
        border: 1px solid;
      }
    }
  }
}
</style>
