import { ColumnInputDefinition } from '@/tt-widget-components/widgets/DataTable/DataTableWidgetHook'
import { LeaveRequestStatusColors } from '@/tt-widget-views/leave-management/types'

export const CompletedRequestsColumns: ColumnInputDefinition[] = [
  {
    attributeName: 'createdOn',
    headerText: 'res.leave-requests.attr.createdOn.label',
    format: 'asDate',
  },
  { attributeName: 'employee' },
  { attributeName: 'leaveType.name' },
  {
    attributeName: 'startDateTime',
    format: 'asDate',
    component: {
      is: 'LeaveRequestsDateTimeIconColumn',
      props: {
        dateName: 'startDateTime',
        tooltipText:
          'tt-entity-design.leave-management.leave-requests.start-time',
      },
    },
  },
  { attributeName: 'endDateTime', format: 'asDate' },
  {
    attributeName: 'requestedDays',
    component: {
      is: 'LeaveRequestsRequestedDays',
    },
  },
  {
    headerText: 'res.leave-requests.extensions.affectedShifts.labels.label',
    attributeName: 'extensions.affectedShifts.total',
    component: {
      is: 'LeaveRequestsAffectedShiftsCounter',
    },
  },
  {
    headerText: 'res.leave-requests.attr.status.label',
    attributeName: 'status',
    component: {
      is: 'StatusChip',
      props: { statusColorOptions: LeaveRequestStatusColors },
    },
  },
]

export const CompletedRequestsDownloadableColumns: ColumnInputDefinition[] = [
  {
    attributeName: 'createdOn',
    headerText: 'res.leave-requests.attr.createdOn.label',
  },
  { attributeName: 'employee.name' },
  { attributeName: 'leaveType.name' },
  {
    attributeName: 'startDateTime',
  },
  { attributeName: 'endDateTime' },
  {
    attributeName: 'requestedDays',
  },
  {
    headerText: 'res.leave-requests.extensions.affectedShifts.labels.label',
    attributeName: 'extensions.affectedShifts.total',
  },
  {
    headerText: 'res.leave-requests.attr.status.label',
    attributeName: 'status',
  },
]
