<template>
  <TtAttrRelationList
    :value="operatorsCount"
    :name="attributeName"
    :resource="resource.OPERATION_CENTERS"
  />
</template>

<script lang="ts">
import Vue, { VueConstructor } from 'vue'
import { ItemHookProvider } from '@/tt-widget-entity-flow/types'
import { Resources } from '../../types'
import { Attribute } from '@/tt-entity-design/src/schema-types'

const getAttr = (attr: Attribute<typeof Resources.OPERATION_CENTERS>) => attr

export default (Vue as VueConstructor<Vue & ItemHookProvider>).extend({
  name: 'OperationCentersOperatorsAttr',
  inject: ['getItemHook'],
  computed: {
    operators(): any[] {
      return this.getItemHook().getRawValue('operators') || []
    },
    attributeName(): string {
      return getAttr('operators')
    },
    resource(): typeof Resources {
      return Resources
    },
    operatorsCount(): number {
      return this.operators.length
    },
  },
  created() {
    this.getItemHook().addAttribute('operators')
  },
})
</script>
