import { AppContext } from '@/tt-app-context'
import { ContextAccountPersister } from '../extensions/app-account-context/ContextAccountPersister'
import { ContextAccountInfo } from '../types'
/**
 * Retrieves the context account information from the application context.
 * The first account in the list (position zero) is the one that was selected last by the user.
 *
 * @param appContext - The application context containing account information.
 * @returns An object containing the account information and various account type assertions.
 */
export const getContextAccountInfo = ({
  authModule,
}: AppContext): ContextAccountInfo => {
  // The position zero will always contain the last selected account.
  // This is due to a new implementation where we store the 10 last selected accounts in the local storage.
  const accountInfo = ContextAccountPersister(authModule).get()[0]

  return {
    accountInfo,
    // account types asserts
    isSite: accountInfo?.type === 'CLIENT',
    isRegion: accountInfo?.type === 'REGION',
    isZone: accountInfo?.type === 'ZONE',
    // account sub types assserts
    isMultiSite:
      accountInfo?.type === 'CLIENT' && accountInfo?.subType === 'MULTI',
  }
}
