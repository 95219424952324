import { AppContext } from '@/tt-app-context'
import {
    DocumentInput,
    DocumentProviderInterface,
    DocumentProviders,
    DocumentServiceMethods,
} from '@/tt-entity-documents/types'

export interface TTCloudApiInput extends DocumentInput {
    provider: DocumentProviders.TTCLOUD_API
    data: {
        url?: string
        path?: string
        payload?: Record<string, string>
    }
}

export default class TTCloudApiProvider implements DocumentProviderInterface {
    private appContext: AppContext

    constructor(appContext: AppContext) {
        this.appContext = appContext
    }

    getMethod(): DocumentServiceMethods {
        return DocumentServiceMethods.POST
    }

    getHeaders(): Record<string, string> {
        return {
            Authorization: `Bearer ${this.appContext.authModule.getUserToken()}`,
            'Content-Type': 'application/json',
        }
    }

    async getDocumentOptionsSchema(): Promise<null> {
        return null
    }

    async getDocumentUrl(input: TTCloudApiInput): Promise<string> {
        const domain = new URL(
            this.appContext.authModule.getHttp().defaults.baseURL,
        )
        if (input.data.url) {
            return input.data.url
        }

        return `${domain.protocol}//${domain.host}${input.data.path}`
    }

    getProviderTag(): DocumentProviders {
        return DocumentProviders.TTCLOUD_API
    }

    getPayload(input: TTCloudApiInput): Record<string, string> {
        return input.data.payload
    }
}
