<template>
  <div>
    <json-field name="language" />
    <template v-if="loading">
      <v-skeleton-loader type="list-item" />
    </template>
    <template v-else>
      <json-field name="label" />
    </template>
  </div>
</template>

<script lang="ts">
import Vue, { VueConstructor } from 'vue'

import { EntityItemHook } from '@/tt-widget-entity-flow/EntityItemHook'
import { CollectionQuery, FormHookProvider } from '@/tt-widget-components/types'
import { Resources } from '../../types'
import { FilterOperatorType } from 'tracktik-sdk/lib/common/entity-filters'
import { ModelTranslation } from '../model-translations/types'

const FIELD_NAME = 'label'

type VueWithInjections = VueConstructor<Vue & FormHookProvider>

export default (Vue as VueWithInjections).extend({
  name: 'EntityLabelsAddTranslation',
  inject: ['formHook'],
  data() {
    return {
      loading: false,
    }
  },
  computed: {
    entityId(): number {
      return this.itemHook.entityReference.entityId
    },
    itemHook(): EntityItemHook {
      return this.formHook().getUserContextValue('itemHook')
    },
    translationResource(): string {
      return Resources.ENTITY_LABEL_TRANSLATIONS
    },
    language(): string | null {
      return this.formHook().getPathValue('language') ?? null
    },
  },
  watch: {
    language: {
      immediate: true,
      handler(language: string) {
        this.fetchTranslations(language)
      },
    },
  },
  methods: {
    async fetchTranslations(language: string) {
      if (!language) {
        this.setFieldValue(FIELD_NAME, '')

        return
      }
      this.loading = true
      const query: CollectionQuery = {
        resource: this.translationResource,
        filters: [
          {
            attribute: 'modelId',
            operator: FilterOperatorType.EQUAL,
            value: this.entityId,
          },
          {
            attribute: 'language',
            operator: FilterOperatorType.EQUAL,
            value: this.language,
          },
        ],
      }
      try {
        const response =
          await this.$appContext.widgetServices.resourceDataManager.getCollection(
            query,
          )
        const label = response.items.find(
          (item: ModelTranslation) => item.fieldName === FIELD_NAME,
        ) as ModelTranslation
        this.setFieldValue(FIELD_NAME, label?.translation || '')
      } catch (err) {
        this.$crash.captureException(err)
      } finally {
        this.loading = false
      }
    },
    setFieldValue(field: string, value: string) {
      this.formHook().setObjectValue(field, value)
    },
  },
})
</script>
