<template>
  <div>
    <v-tooltip :disabled="hasAccess" bottom>
      <template #activator="{ on: tooltip }">
        <div class="d-flex" v-on="tooltip">
          <v-icon v-if="!hasAccess" color="orange" small v-text="`error`" />

          <CollapsibleHeader
            class="w-100"
            :opened="opened"
            :disabled="!hasAccess"
          >
            <template #title>
              <v-tooltip :disabled="!tooltipLabel" top>
                <template #activator="{ on }">
                  <span
                    class="pl-4"
                    :class="{ 'has-value': hasValue }"
                    :data-analytics="`core:entity-filter:button|resource:${resource}|attribute:${attributeName}`"
                    v-on="on"
                    v-text="$t(filterButtonLabel)"
                  >
                    <v-badge v-if="hasValue" color="green" dot inline />
                  </span>
                </template>
                <span v-text="tooltipLabel" />
              </v-tooltip>
            </template>
            <template #right>
              <slot name="right">
                <v-btn v-if="hasValue" icon x-small @click.stop="clear">
                  <v-icon small> close </v-icon>
                </v-btn>
              </slot>
            </template>
            <v-card
              flat
              min-height="90"
              min-width="250"
              class="level0 pa-0 ma-0"
              :data-analytics="`core:entity-filter:controls|resource:${resource}|attribute:${attributeName}`"
            >
              <component
                :is="component"
                v-if="hasAccess"
                v-model="model"
                :attribute-meta="attributeMeta"
                :attribute-name="attributeName"
                :on-update="onUpdate"
                class="mb-2"
                @clear="clear"
                @apply="apply"
              />
              <EntityFilterError
                v-else
                :text-error="'entity_toolbar_filter_button.filter.not_resource_access'"
              />
            </v-card>
          </CollapsibleHeader>
        </div>
      </template>
      <span
        v-text="$t(`entity_toolbar_filter_button.filter.not_resource_access`)"
      />
    </v-tooltip>
    <v-divider />
  </div>
</template>

<script lang="ts">
import BaseFilterButton from '@/tt-entity-filter/components/BaseFilterButton'
import ResourcePermissionAuditor, {
  getResourcePermissionAuditorServices,
} from '@/tt-widget-factory/services/resource-meta/ResourcePermissionAuditor'
import { FieldTypes } from '@/tt-widget-factory/services/resource-meta/types'

import EntityFilterError from './EntityFilterError.vue'
import { getFilterName, getFilterLabel } from '../util'
import { Filter } from '../../tt-widget-components'
import FilterValueParser from '../FilterValueParser'
import { isTemporalFieldType } from '../temporal-filters/field-types-validator'

export default BaseFilterButton.extend({
  name: 'EntityToolbarFilterCollapsibleSection',
  components: {
    EntityFilterError,
  },
  props: {
    attributeName: {
      type: String,
      default: '',
    },
    resource: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: null,
    },
    opened: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    filterButtonLabel(): string {
      const translateFunction = (key: string): string => this.$tc(key)

      return this.label
        ? getFilterLabel(translateFunction(this.label), this.parsedValue)
        : getFilterName(
            this.filterValue,
            this.parsedValue,
            this.$appContext.widgetServices.resourceMetaManager,
            translateFunction,
            this.attributeName,
            this.resource,
          )
    },
    // We want to display date time and time zone on tooltip for FilterDateTime component
    tooltipLabel(): string | null {
      const type = this.attributeMeta.type
      const userPreferences = this.$appContext.authModule.getUserPreferences()

      return isTemporalFieldType(type)
        ? FilterValueParser.getFilterValue(
            this.attributeMeta,
            this.value,
            userPreferences,
          )
        : null
    },
    hasAccess(): boolean {
      const canViewResource = () =>
        ResourcePermissionAuditor.canViewResource(
          getResourcePermissionAuditorServices(this.$appContext),
          this.attributeMeta.relation.resource,
        )

      return this.attributeMeta.type === FieldTypes.Relation
        ? canViewResource()
        : true
    },
  },
  methods: {
    onUpdate(filter: Filter) {
      this.model = filter
    },
  },
})
</script>
<style scoped>
.has-value {
  color: var(--v-ttPrimary-base);
  font-weight: bold;
}
</style>
