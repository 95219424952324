<template>
  <div>
    <v-text-field v-model.number="model" v-bind="fieldProps" type="number" />
    <div v-if="showSuggestion" class="mb-3 ml-3">
      {{ suggestedText }}
    </div>
  </div>
</template>
<script lang="ts">
import Vue, { VueConstructor } from 'vue'

import { FormHookProvider } from '@/tt-widget-components'
import { vuetifyDefaults } from '@tracktik/tt-json-schema-form'

import {
  calcProratedQuantity,
  WeekDayServiceCycleType,
} from '@/helpers/prorated'
import {
  ActionProperty,
  ActionPropertyEnum,
} from '@/tt-entity-design/src/schema-types'

const path: ActionProperty<'mobile-schedule-groups', 'add-periodic'> =
  'proRatedQuantity'

export default (Vue as VueConstructor<Vue & FormHookProvider>).extend({
  name: 'SiteTaskSchedulesProratedInput',
  inject: ['formHook'],
  props: {
    beginServiceDate: {
      type: String,
      required: true,
    },
    showSuggestion: {
      type: Boolean,
      default: false,
    },
    weekDayStartSetting: {
      type: String,
      default: null,
    },
  },
  computed: {
    fieldProps(): Record<string, unknown> {
      return { ...vuetifyDefaults, ...this.$attrs }
    },
    model: {
      get(): number {
        return this.formHook().getPathValue(path)
      },
      set(newValue: number) {
        this.setValue(newValue)
      },
    },

    quantity(): number {
      return this.formHook().getPathValue('quantity')
    },
    hasProRatedQuantity(): boolean {
      return !!this.type && !!this.quantity
    },
    type(): ActionPropertyEnum<
      'mobile-schedule-groups',
      'add-periodic',
      'type'
    > {
      return this.formHook().getPathValue('type')
    },
    suggestedProRatedQuantity(): number {
      return calcProratedQuantity(
        this.type,
        this.beginServiceDate,
        this.quantity,
        this.weekDayStartSetting as WeekDayServiceCycleType,
      )
    },
    suggestedProRatedQuantityText(): string {
      if (!this.hasProRatedQuantity) {
        return this.$t('mobile_schedule_group_create_wizard.days_setup.NA')
      }

      return this.suggestedProRatedQuantity.toString()
    },
    suggestedLabel(): string {
      return this.$t(
        'mobile_schedule_group_create_wizard.days_setup.suggestedProRated',
      )
    },
    suggestedText(): string {
      return `${this.suggestedLabel}: ${this.suggestedProRatedQuantityText}`
    },
  },
  watch: {
    suggestedProRatedQuantity: {
      immediate: true,
      handler: function () {
        if (this.hasProRatedQuantity && !this.model) {
          this.model = this.suggestedProRatedQuantity
        }
      },
    },
  },
  methods: {
    setValue(newValue: number) {
      this.$emit('blur')

      this.formHook().setObjectValue(path, newValue, {
        debounce: true,
      })
    },
  },
})
</script>
