<template>
  <v-select
    v-model="selectedMinutes"
    filled
    hide-details
    dense
    background-color="level1"
    outlined
    :label="label"
    :placeholder="placeholder"
    :items="minutesList"
    :menu-props="{ offsetY: true }"
  >
    <template #selection="{ item }">
      <span class="text-smaller" v-text="item.text" />
    </template>
  </v-select>
</template>
<script lang="ts">
import Vue, { VueConstructor } from 'vue'
import times from 'lodash/times'

import { FormHookProvider } from '@/tt-widget-components'

type VueWithInjections = VueConstructor<Vue & FormHookProvider>

const DEFAULT_MAX_MINUTES = 60

export default (Vue as VueWithInjections).extend({
  name: 'TaskSiteInstructionsFormLoneWorkerFrequencyField',
  inject: ['formHook'],
  props: {
    value: {
      type: Number,
      default: null,
    },
    label: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    maxMinutes: {
      type: Number,
      default: DEFAULT_MAX_MINUTES,
    },
  },
  computed: {
    selectedMinutes: {
      get(): number | null {
        return this.value
      },
      set(newMinute: number) {
        this.$emit('input', newMinute)
      },
    },

    minutesList(): { text: string; value: number }[] {
      return times(this.maxMinutes / 5, (index) => ({
        text: this.$tc('common.minutes', (index + 1) * 5),
        value: (index + 1) * 5,
      }))
    },
  },
})
</script>
