// ******** This file is autogenerated DO NOT MODIFY **********

import { modularManager, PresetTypes, PresetLevels } from '@/tt-app-modular'
import { Resources } from '../../types'
import custom from './custom'
import PrintedRunsheetOccurrencesTile from './PrintedRunsheetOccurrencesTile.vue'
import PrintedRunsheetOccurrencesColumns from './PrintedRunsheetOccurrencesColumns'
import PrintedRunsheetOccurrencesDetail from './PrintedRunsheetOccurrencesDetail.vue'
import PrintedRunsheetOccurrencesMetaCard from './PrintedRunsheetOccurrencesMetaCard.vue'
import PrintedRunsheetOccurrencesReference from './PrintedRunsheetOccurrencesReference.vue'

export default {
  install(Vue) {
    Vue.use(custom)
    Vue.component(
      'PrintedRunsheetOccurrencesTile',
      PrintedRunsheetOccurrencesTile,
    )
    Vue.component(
      'PrintedRunsheetOccurrencesDetail',
      PrintedRunsheetOccurrencesDetail,
    )
    Vue.component(
      'PrintedRunsheetOccurrencesMetaCard',
      PrintedRunsheetOccurrencesMetaCard,
    )
    Vue.component(
      'PrintedRunsheetOccurrencesReference',
      PrintedRunsheetOccurrencesReference,
    )

    modularManager.addResourcePreset(
      Resources.PRINTED_RUNSHEET_OCCURRENCES,
      PresetTypes.LIST_ITEM,
      {
        title: 'Default',
        data: { is: 'PrintedRunsheetOccurrencesTile' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.PRINTED_RUNSHEET_OCCURRENCES,
      PresetTypes.COLUMNS,
      {
        title: 'Default',
        data: PrintedRunsheetOccurrencesColumns,
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.PRINTED_RUNSHEET_OCCURRENCES,
      PresetTypes.DETAILS_CARD,
      {
        title: 'Default',
        data: { is: 'PrintedRunsheetOccurrencesDetail' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.PRINTED_RUNSHEET_OCCURRENCES,
      PresetTypes.META_CARD,
      {
        title: 'Default',
        data: { is: 'PrintedRunsheetOccurrencesMetaCard' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.PRINTED_RUNSHEET_OCCURRENCES,
      PresetTypes.RELATION_REFERENCE,
      {
        title: 'Default',
        data: { is: 'PrintedRunsheetOccurrencesReference' },
        level: PresetLevels.DEFAULTS,
      },
    )
  },
}
