<template>
  <div class="flex--column justify-content-center">
    <v-btn
      small
      class="w-100 mb-1"
      :class="[isTileMap ? 'assign--user-button' : 'assign--button']"
      depressed
      @click="selectUser"
    >
      <span v-text="selectUserLabel" />
    </v-btn>
    <div
      v-if="isTileMap"
      class="d-flex justify-space-between"
      style="min-width: 100px"
    >
      <v-tooltip top>
        <template #activator="{ on }">
          <v-btn
            class="assign--button px-2"
            color="ttPrimary"
            icon
            x-small
            v-on="on"
            @click="selectPosition"
          >
            <v-icon class="mx-2" v-text="'mdi-office-building-plus'" />
          </v-btn>
        </template>
        <span v-text="selectPositionLabel" />
      </v-tooltip>
      <v-tooltip top>
        <template #activator="{ on }">
          <v-btn
            class="assign--button px-2"
            color="ttPrimary"
            x-small
            icon
            v-on="on"
            @click="selectPositionAccount"
          >
            <v-icon v-text="'mdi-shield-plus-outline'" />
          </v-btn>
        </template>
        <span v-text="siteZoneButtonLabel" />
      </v-tooltip>
      <v-tooltip top>
        <template #activator="{ on }">
          <v-btn
            class="assign--button px-2"
            color="ttPrimary"
            icon
            x-small
            v-on="on"
            @click="preview"
          >
            <v-icon v-text="'mdi-dots-horizontal'" />
          </v-btn>
        </template>
        <span v-text="$t('common.details')" />
      </v-tooltip>
    </div>
    <v-menu v-else bottom offset-y>
      <template #activator="{ on }">
        <v-btn
          color="ttPrimary"
          small
          text
          depressed
          class="w-100"
          style="text-transform: none !important"
          v-on="on"
        >
          <span v-text="moreActionsLabel" />
        </v-btn>
      </template>

      <div class="level0 d-flex flex-column pa-3">
        <v-btn
          color="ttPrimary"
          text
          small
          class="assign--button mb-2"
          @click="selectPosition"
        >
          <span v-text="selectPositionLabel" />
        </v-btn>
        <v-btn
          color="ttPrimary"
          text
          small
          class="assign--button mb-2"
          @click="selectPositionAccount"
        >
          <span v-text="siteZoneButtonLabel" />
        </v-btn>
        <v-btn
          color="ttPrimary"
          text
          small
          style="text-transform: none !important"
          @click="preview"
        >
          <span v-text="$t('common.details')" />
        </v-btn>
      </div>
    </v-menu>
  </div>
</template>

<script lang="ts">
import Vue, { VueConstructor, PropType } from 'vue'
import { ItemHookProvider } from '@/tt-widget-entity-flow/types'

import { ActiveWindowProvider } from '@/tt-app-layout/types'
import { updateDOM } from '@tracktik/tt-helpers/lib/browser/updateDOM'
import { OnSelectCallback } from '../types'
import { ResourceSchema } from '@/tt-entity-design/src/schema-types'
import { TranslateResult } from 'vue-i18n/types'

type AccountType =
  keyof ResourceSchema<'accounts'>['attributes']['type']['labels']['list']

type PositionAccountType = Extract<
  AccountType,
  'CLIENT' | 'ZONE' | 'DEPARTMENT'
>

export default (
  Vue as VueConstructor<Vue & ItemHookProvider & ActiveWindowProvider>
).extend({
  name: 'MobileDevicesTileActions',
  inject: ['getItemHook', 'activeWindow'],
  props: {
    onSelect: {
      type: Function as PropType<OnSelectCallback>,
      required: true,
    },
    isTileMap: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    accountType(): PositionAccountType {
      return this.getItemHook().getRawValue(
        'workStatus.userPosition.account.type',
      )
    },
    siteZoneButtonLabel(): TranslateResult {
      return this.accountType === 'CLIENT'
        ? this.$t('tt-entity-design.dispatch-tasks.assign-action.assign-site')
        : this.$t('tt-entity-design.dispatch-tasks.assign-action.assign-zone')
    },
    selectPositionLabel(): TranslateResult {
      return this.$t(
        'tt-entity-design.dispatch-tasks.assign-action.assign-position',
      )
    },
    selectUserLabel(): TranslateResult {
      return this.$t(
        'tt-entity-design.dispatch-tasks.assign-action.assign-user',
      )
    },
    moreActionsLabel(): TranslateResult {
      return this.$t(
        'tt-entity-design.dispatch-tasks.assign-action.more-actions',
      )
    },
  },
  methods: {
    selectUser() {
      const id = this.getItemHook().getRawValue('user.id')
      this.onSelect({ resource: 'users', id })
    },
    selectPosition() {
      const id = this.getItemHook().getRawValue('workStatus.userPosition.id')
      this.onSelect({ resource: 'positions', id })
    },
    selectPositionAccount() {
      const id = this.getItemHook().getRawValue(
        'workStatus.userPosition.account.id',
      )

      if (this.accountType === 'CLIENT') {
        this.onSelect({ resource: 'clients', id })
      } else {
        this.onSelect({ resource: 'zones', id })
      }
    },
    async preview() {
      await updateDOM()
      const { activeWindow } = this
      this.getItemHook().dispatchPreview({ activeWindow }, false)
    },
  },
})
</script>

<style scoped>
.assign--button {
  color: var(--v-ttPrimary-base);
  background-color: var(--v-ttPrimary-lighten5) !important;
  text-transform: none !important;
  border-radius: 5px;
  min-width: 30px;
  height: 30px;
}

.assign--user-button {
  color: white;
  background-color: var(--v-ttPrimary-base) !important;
  text-transform: none !important;
}

.mobile-device--tile >>> .v-list-item__action {
  margin: 0 !important;
}
</style>
