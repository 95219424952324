import { unitOfTime } from 'moment-timezone'
import { SchedulerTimelineView } from '../SchedulerTimeline/types'
/**
 * Check if the view is a supported Scheduler view (Day, Week, Month)
 */
export const isViewValid = (view: string): view is SchedulerTimelineView =>
  (Object.values(SchedulerTimelineView) as string[]).includes(view)

export const schedulerTimelineViewMapping: {
  [key in SchedulerTimelineView]: unitOfTime.StartOf
} = {
  [SchedulerTimelineView.TIMELINE_DAY]: 'day',
  [SchedulerTimelineView.TIMELINE_WEEK]: 'week',
  [SchedulerTimelineView.TIMELINE_MONTH]: 'month',
}

export const getNestedProperty = (obj: any, path: string): any => {
  return path.split('.').reduce((acc, key) => acc && acc[key], obj)
}
