<template>
  <v-app id="roles-and-permission-view">
    <v-main>
      <ProvideLayoutManager :layout-manager="manager">
        <BoundingBox v-slot="{ top, left }">
          <AppWindow :closable="false">
            <EntityItem
              :resource-name="resourceName"
              :entity-id="roleId"
              class="h-100"
            >
              <RoleManagedPermissionPoliciesRelationList />
            </EntityItem>
          </AppWindow>
          <AppLayoutUtils :top="49.5" :left="100" />
        </BoundingBox>
      </ProvideLayoutManager>
    </v-main>
  </v-app>
</template>

<script lang="ts">
import Vue from 'vue'

import { Resources } from '@/tt-entity-design/src/types'
import { AllowEntityOperationsProvider } from '@/types'

import ProvideLayoutManager from '@/tt-app-layout/components/ProvideLayoutManager.vue'
import BoundingBox from '@/tt-widget-views/components/BoundingBox.vue'
import AppWindow from '@/tt-app-layout/components/AppWindow.vue'
import AppLayoutUtils from '@/tt-app-layout/components/AppLayoutUtils.vue'
import EntityItem from '@/tt-widget-entity-flow/components/EntityItem.vue'
import RoleManagedPermissionPoliciesRelationList from '@/tt-entity-design/src/components/roles/RoleManagedPermissionPoliciesRelationList.vue'
import LayoutManager from '@/tt-app-layout/LayoutManager'

export default Vue.extend({
  name: 'RolesManagedPermissionPoliciesInjectedView',
  components: {
    ProvideLayoutManager,
    BoundingBox,
    AppWindow,
    AppLayoutUtils,
    EntityItem,
    RoleManagedPermissionPoliciesRelationList,
  },
  provide(): Partial<AllowEntityOperationsProvider> {
    return {
      allowEntityActions: [
        Resources.ROLES,
        Resources.MANAGED_PERMISSION_POLICIES,
      ],
    }
  },
  props: {
    roleId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      manager: new LayoutManager(this.$eventManager),
    }
  },
  computed: {
    resourceName(): string {
      return Resources.ROLES
    },
  },
})
</script>
