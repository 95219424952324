import { Resources } from '@/tt-entity-design/src/types'
import {
  Action,
  Attribute,
  CustomFilter,
} from '@/tt-entity-design/src/schema-types'

const OPERATION_CENTERS = Resources.OPERATION_CENTERS
const OPERATOR_RESOURCE = Resources.EMPLOYEES
const OPERATION_CENTER_REGIONS = Resources.OPERATION_CENTER_REGIONS
export { OPERATION_CENTERS, OPERATOR_RESOURCE, OPERATION_CENTER_REGIONS }

export const OPERATORS: Attribute<typeof OPERATION_CENTERS> = 'operators'
export const ADD_OPERATOR: Action<typeof OPERATION_CENTERS> = 'add-operator'
export const REMOVE_OPERATOR: Action<typeof OPERATION_CENTERS> =
  'remove-operator'

export const EMPLOYEE_NOT_IN_OP_CENTER_FILTER: CustomFilter<'employees'> =
  'excludeAlreadyAssignedToOperationCenter'

export const REPLACE_REGIONS: Action<typeof OPERATION_CENTERS> =
  'replace-regions'

export const ARCHIVE: Action<typeof OPERATION_CENTERS> = 'archive'
