import i18n from '@/plugins/i18n'
import { reactive } from 'vue'
import { FormLabelTypes } from '@tracktik/tt-json-schema-form'

import { FieldTypes } from '@/tt-widget-factory/services/resource-meta/types'
import { isWhitelistedResourceInvalidField } from '@/tt-widget-entity-flow/helper'
import { modularManager, PresetTypes } from '@/tt-app-modular'
import { ResourceTranslator } from '@/tt-widget-entity-flow/ResourceTranslator'

import CollectionWidgetHook from '../../base/CollectionWidgetHook'
import {
  ColumnDefinition,
  DefaultToolbar,
  DataTableWidgetModel,
  FullColumnDefinition,
  Pagination,
  CollectionQuery,
} from '../../schemas-types'
import { EntityId, RowModel } from './types'
import { getColumnAttributeName } from '@/tt-widget-components/helpers'
import { FetchOptions } from '@/tt-widget-components'
import { DevConsole } from '@/plugins/DevConsole'
import { FilterOperatorType } from 'tracktik-sdk/lib/common/entity-filters'
import { Filter } from '@/tt-widget-components'
import { FetchingState, CANCELLED_PROMISE } from '@/tt-widget-components/types'
import { AuthModule } from '@tracktik/tt-authentication'
import {
  getCachedPinnedRowIds,
  persistPinnedRowIdCache,
  removePinnedRowIdCache,
} from '@/tt-widget-components/helpers/local-storage-helper'
export type ColumnInputDefinition = ColumnDefinition

const getUniqueIds = (ids: EntityId[]): EntityId[] => [...new Set(ids)]

export default class DataTableWidgetHook extends CollectionWidgetHook<DataTableWidgetModel> {
  enableInfiniteScrolling = false // TODO Implement infinite scrolling with this follow up ticket FE-1121

  private datatableState = reactive({
    /**
     * The entity IDs of the expanded rows
     */
    expandedRowIds: [] as EntityId[],

    /**
     * The entity IDs of the selected rows
     */
    selectedRowIds: [] as EntityId[],

    /**
     * The entity IDs of the disabled rows. They can't be selected / unselected.
     */
    disabledRowIds: [] as EntityId[],

    /**
     * Set of pinned entities fetched
     */
    pinnedEntities: [] as EntityId[],

    /**
     * Promise that resolves when the fetching is done. This is helpful to not fetch multiple times.
     * The promise is reset to `null` when the fetching is done.
     * The request can also be cancelled.
     */
    fetchingPinnedData: {
      promise: null,
      cancel: null,
    } as FetchingState,

    /**
     * The entity IDs of the pinned rows
     */
    pinnedRowIds: [] as EntityId[],

    /**
     * Flag to keep track if the pinned rows change
     */
    pinnedRowsChanged: false,
  })

  /**
   * ----------------
   * Expanded rows
   * ----------------
   */

  /**
   * Given an entity ID, check if a row is expanded.
   */
  isRowExpanded(id: EntityId): boolean {
    return this.datatableState.expandedRowIds.includes(id)
  }

  /**
   * Given an entity ID, collapse the corresponding row if it is expanded.
   */
  collapseRow(id: EntityId) {
    this.datatableState.expandedRowIds =
      this.datatableState.expandedRowIds.filter(
        (expandedId) => expandedId !== id,
      )
  }

  /**
   * Collapse all rows.
   */
  collapseAllRows() {
    this.datatableState.expandedRowIds = []
  }

  /**
   * Given an entity ID, expand the corresponding row if it is collapsed.
   */
  expandRow(id: EntityId) {
    this.datatableState.expandedRowIds = [
      ...this.datatableState.expandedRowIds,
      id,
    ]
  }

  /**
   * ----------------
   * Row selection
   * ----------------
   */

  /**
   * Sync selected entities IDs state,
   * regardless of the disabled entities.
   */
  syncSelectedRows(ids: EntityId[]) {
    this.datatableState.selectedRowIds = [...ids]
  }

  /**
   * Select an entity
   */
  selectRow(id: EntityId) {
    if (this.isRowDisabled(id)) return

    this.selectMultipleRows([id])
  }

  /**
   * Deselect an entity
   */
  deselectRow(id: EntityId) {
    if (this.isRowDisabled(id)) return

    this.deselectMultipleRows([id])
  }

  /**
   * Check if an entity is selected
   */
  isRowSelected(id: EntityId): boolean {
    return this.getSelectedRowIds().includes(id)
  }

  /**
   * Toggle the selection of an entity
   */
  toggleRowSelection(id: EntityId) {
    if (this.isRowDisabled(id)) return

    if (this.isRowSelected(id)) {
      this.deselectRow(id)
    } else {
      this.selectRow(id)
    }
  }

  /**
   * Get all selected entity IDs
   */
  getSelectedRowIds(): EntityId[] {
    return [...this.datatableState.selectedRowIds]
  }

  /**
   * Get all entities IDs in the current view that are not disabled (meaning they are selectable).
   */
  getNonDisabledCurrentRows(): EntityId[] {
    const currentViewIds = this.state.entities.map((entity) => entity.id)

    return this.filterOutDisabledRows(currentViewIds)
  }

  /**
   * Deselect all entities in the current view
   */
  deselectCurrentViewRows() {
    const currentViewIds = this.getNonDisabledCurrentRows()

    this.deselectMultipleRows(currentViewIds)
  }

  /**
   * Select multiple entities
   */
  selectMultipleRows(ids: EntityId[]) {
    const newIds = this.filterOutDisabledRows(ids)

    this.datatableState.selectedRowIds = getUniqueIds([
      ...this.getSelectedRowIds(),
      ...newIds,
    ])
  }

  /**
   * Deselect multiple entities
   */
  deselectMultipleRows(ids: EntityId[]) {
    const idsToRemove = this.filterOutDisabledRows(ids)

    this.datatableState.selectedRowIds = this.getSelectedRowIds().filter(
      (id) => !idsToRemove.includes(id),
    )
  }

  /**
   * Select all entities in the current view (that are not disabled)
   */
  selectCurrentViewRows() {
    const currentViewIds = this.getNonDisabledCurrentRows()

    this.selectMultipleRows(currentViewIds)
  }

  /**
   * ----------------
   * Disabled row entities
   * ----------------
   */

  /**
   * Check if an entity is disabled (non selectable)
   */
  isRowDisabled(id: EntityId) {
    return this.datatableState.disabledRowIds.includes(id)
  }

  /**
   * Sync the disabled entities state with the given IDs
   */
  syncDisabledRows(ids: EntityId[]) {
    this.datatableState.disabledRowIds = [...ids]
  }

  /**
   * Given a list of entity IDs, returns a new list without the disabled IDs
   */
  filterOutDisabledRows(ids: EntityId[]) {
    return ids.filter((newId) => !this.isRowDisabled(newId))
  }

  get component() {
    return this.state.widget.component
  }

  /**
   * Save the hook fetching promise in the state.
   * Useful to know if a request is currently in progress.
   */
  private saveCurrentPinnedFetchingPromise({
    promise,
    cancel,
  }: FetchingState): void {
    DevConsole.log('Saving CollectionWidget promise...')
    this.datatableState.fetchingPinnedData = { promise, cancel }
  }

  /**
   * If the hook was currently fetching, cancel the promise.
   */
  async cancelCurrentPinnedDataFetching(): Promise<void> {
    if (this.datatableState.fetchingPinnedData.cancel) {
      DevConsole.log('Cancelling CollectionWidget promise...')
      this.datatableState.fetchingPinnedData.cancel(CANCELLED_PROMISE)
      // making sure the promise is cancelled before continuing
      await this.datatableState.fetchingPinnedData.promise
    }

    this.clearCurrentPinnedDataFetching()
  }

  /**
   * Clear the fetching promise
   */
  private clearCurrentPinnedDataFetching(): void {
    this.datatableState.fetchingPinnedData = { promise: null, cancel: null }
  }
  /**
   * Get the effective query
   */
  protected get effectiveQuery(): CollectionQuery {
    // get the return count and the query from the parent logic
    const parentQuery = super.effectiveQuery
    let cloneQuery = { ...parentQuery }

    // Checking the pinned row ids as we update the state on each pin/unpin action
    const pinnedRowIds = this.datatableState.pinnedRowIds ?? []
    if (pinnedRowIds.length > 0) {
      const filters = this.queryManager.query.filters ?? []
      cloneQuery = {
        ...this.queryManager.query,
        filters: [
          ...filters,
          {
            attribute: 'id',
            // - We are ignoring as we need API-2770 to include NOTIN operator
            // @ts-ignore
            operator: FilterOperatorType.NOTIN,
            value: pinnedRowIds,
          },
        ] as Filter[],
      }
    }

    return { ...cloneQuery, returnCount: parentQuery.returnCount }
  }

  /**
   * Set the required fields
   * @param attributes
   */
  set requiredFields(attributes: string[]) {
    const uniqueAttributes = [
      ...new Set(
        this.hasExpandableRow
          ? [...attributes, this.expandableAttribute, 'id']
          : [...attributes, 'id'],
      ),
    ]

    this.attributes = [...uniqueAttributes]
    this.isReadyToFetch = true
    this.update()
  }

  createQueryForPinnedRows = (
    initialQuery: CollectionQuery,
    originalQuery: CollectionQuery,
    pinnedRowIds: EntityId[],
  ): CollectionQuery => {
    // we need a custom query for the pinned items
    // we are going to mimic the normal query except some filters, scope and keep the
    // pagination the same  offset -> 0 - limit -> 5 as it is the max
    // keep only the default filters set at the widget creation
    const { filters = [], scope = [], search = '' } = initialQuery

    return {
      ...originalQuery,
      search,
      filters: [
        ...filters,
        {
          attribute: 'id',
          operator: FilterOperatorType.IN,
          value: pinnedRowIds,
        },
      ],
      scope,
      offset: 0,
      limit: 5,
    }
  }

  async update(fetchOptions?: FetchOptions): Promise<void> {
    if (!this.isReadyToFetch) return

    this.loading = true

    if (!this.datatableState.pinnedRowsChanged) {
      this.resetPaginationOnQueryChange({ ...this.effectiveQuery })
    }

    await this.cancelCurrentDataFetching()
    await this.cancelCurrentPinnedDataFetching()

    const promise = this.createExecutablePromise(
      { ...this.effectiveQuery },
      fetchOptions,
    )

    if (
      this.datatableState.pinnedRowIds &&
      this.datatableState.pinnedRowIds.length > 0 &&
      this.rowPinner
    ) {
      const pinnedRowsQuery = this.createQueryForPinnedRows(
        this.queryManager.initialQuery,
        { ...this.effectiveQuery },
        this.datatableState.pinnedRowIds,
      )

      const { run: runPinned } = this.prepareFetching(
        pinnedRowsQuery,
        fetchOptions,
        true,
      )
      const promisePinned = runPinned().catch((err) => DevConsole.warn(err))

      this.saveCurrentPinnedFetchingPromise({
        promise: promisePinned,
        cancel: null,
      })

      await Promise.all([promisePinned, promise])

      // concat both item states for the collections
      this.state.entities = [
        //saved on the datatable state
        ...this.state.pinnedEntities,
        //saved on the collection hook state
        ...this.state.entities,
      ]
    } else {
      await promise
    }

    this.clearCurrentDataFetching()
    this.clearCurrentPinnedDataFetching()
    this.initPinnedItems()
    this.datatableState.pinnedRowsChanged = false
    this.loading = false
  }

  get rootActions() {
    return []
  }

  get batchActions() {
    return []
  }

  get expandableAttribute(): string | null {
    if (!this.widget.expandableAttribute) {
      return null
    }

    const attribute = this.services.resourceMetaManager.getAttribute(
      this.resource,
      this.widget.expandableAttribute,
    )

    if (attribute.type !== FieldTypes.RelationList) {
      console.warn(
        `Expandable attribute '${this.widget.expandableAttribute}' is not a relation list attribute.`,
      )

      return null
    }

    return this.widget.expandableAttribute
  }

  get hasDataSource(): boolean {
    return !!this.entities?.length
  }

  get hasColumnHeaderFilters(): boolean {
    return !!this.widget.component?.columnHeaderFilters
  }

  get hasExpandableRow(): boolean {
    return !!this.widget.expandableAttribute
  }

  set hasInfiniteScrolling(enableInfiniteScrolling: boolean) {
    this.enableInfiniteScrolling = enableInfiniteScrolling
  }

  get hasInfiniteScrolling() {
    return this.enableInfiniteScrolling
  }

  setup() {
    // We need to wait for the UI to tell us what fields to add
    this.isReadyToFetch = false
    // Apply
    this.applyToolbar()
    this.queryManager.limit = this.paginationOptions.pageSize
  }

  private applyToolbar() {
    // Override default toolbar
    this.state.widget.toolbar =
      this.state.widget.toolbar ??
      ({
        show: true,
        displayCounts: true,
        filterOptions: {
          allowSearch: true,
          allowScopes: true,
          filters: [],
        },
      } as DefaultToolbar)
  }

  /**
   * Get the columns
   */
  public get columns(): ColumnInputDefinition[] {
    const filterWhitelistedInvalidFields = (
      columns: ColumnInputDefinition[],
    ): ColumnInputDefinition[] =>
      columns.filter((column) => {
        const attributeName = getColumnAttributeName(column)

        return (
          !attributeName ||
          !isWhitelistedResourceInvalidField({
            attributeName,
            resourceName: this.resource,
            resourceMetaManager: this.services.resourceMetaManager,
          })
        )
      })

    const columns = this.state?.widget?.component?.columns ?? []

    if (Array.isArray(columns) && columns.length > 0) {
      return filterWhitelistedInvalidFields(columns)
    }

    const resourcePreset = modularManager.getResourcePreset(
      this.resource,
      PresetTypes.COLUMNS,
    )?.data

    const resourcePresetDefaults = ['id']

    return (
      filterWhitelistedInvalidFields(resourcePreset) || resourcePresetDefaults
    )
  }

  createExtensionColumn(column: FullColumnDefinition): FullColumnDefinition {
    column.allowSorting = false
    column.headerText = ResourceTranslator.translateAttribute(
      this.resource,
      column.attributeName,
      FormLabelTypes.LABEL,
    )
    column.allowFilters = false

    return column
  }

  prepareColumn(columnDefinition: ColumnInputDefinition): FullColumnDefinition {
    const column =
      typeof columnDefinition === 'string'
        ? { attributeName: columnDefinition }
        : columnDefinition

    // Not an attribute column
    if (!column.attributeName) {
      // Return the raw column
      return column
    }

    return column.attributeName.startsWith('extensions.')
      ? this.createExtensionColumn(column)
      : this.prepareAttributeColumn(column)
  }

  get paginationOptions(): Pagination {
    return {
      pageSize: 20,
      pageSizeOptions: [10, 20, 50, 100],
    }
  }

  getFullDefinitionColumns(): FullColumnDefinition[] {
    return this.columns.map((col) => this.prepareColumn(col))
  }

  getRows(): RowModel[] {
    // if a row is expanded, add another row below with the sub-datatable
    return this.state.entities.reduce((acc: RowModel[], entity) => {
      acc.push({ entityId: entity.id })

      if (this.isRowExpanded(entity.id))
        acc.push({ entityId: entity.id, isExpandedRow: true })

      return acc
    }, [] as RowModel[])
  }

  private prepareAttributeColumn(
    col: FullColumnDefinition,
  ): FullColumnDefinition {
    const attribute = this.services.resourceMetaManager.getAttribute(
      this.resource,
      col.attributeName,
    )

    const disableFiltering =
      !attribute || [FieldTypes.Image, FieldTypes.JSON].includes(attribute.type)

    const disableSorting =
      !attribute || [FieldTypes.Image, FieldTypes.JSON].includes(attribute.type)

    const textAlign = col.style?.textAlign ?? 'Left'

    const getTranslatedAttr = () => {
      const i18nKeys =
        this.services.resourceMetaManager.getAttributePathLabelKeys(
          this.resource,
          col.attributeName,
        )

      return i18nKeys.map((key) => i18n.tc(key)).join(' - ')
    }

    const label = col.headerText ?? getTranslatedAttr()

    return {
      ...col,
      allowFilters: col.allowFilters ?? !disableFiltering,
      allowSorting: col.allowSorting ?? !disableSorting,
      headerText: label,
      style: {
        ...col.style,
        textAlign,
      },
    }
  }

  /**
   * Get the row pinner to see the datatable has Pin feature active or not
   */
  get rowPinner(): boolean | null {
    if (!this.widget.rowPinner) {
      return null
    } else {
      return this.widget.rowPinner !== ''
    }
  }

  /**
   * Set the pinned rows
   * @param ids
   */
  setPinnedRowIdsState(ids: EntityId[]) {
    this.datatableState.pinnedRowIds = [...ids]
  }

  /**
   * Add a pinned row
   */
  private addToPinState(id: EntityId) {
    if (!this.datatableState.pinnedRowIds.includes(id)) {
      this.datatableState.pinnedRowIds = [
        ...this.datatableState.pinnedRowIds,
        id,
      ]
    }
  }

  // avoid fetching pinned on page change, no need
  // refetch only normal data on pin / unpin

  /**
   * Remove a pinned row
   */
  private removeFromPinState(toBeRemovedId: EntityId) {
    this.datatableState.pinnedRowIds = this.datatableState.pinnedRowIds.filter(
      (id: EntityId) => id !== toBeRemovedId,
    )
  }

  /**
   * Get the pinned row ids
   */
  getStatePinnedRowIds(): EntityId[] {
    return this.datatableState.pinnedRowIds ?? []
  }

  /**
   * Keep state updated with local storage
   */
  keepStateUpdatedWithLocalStorage(): void {
    const localStorageData = getCachedPinnedRowIds(
      this.services.authModule,
      this.widget.rowPinner,
    )
    this.setPinnedRowIdsState(localStorageData)
  }

  /**
   * Add new pinned row id to state and local storage
   */
  async addPinnedRowId(id: EntityId) {
    persistPinnedRowIdCache(this.services.authModule, this.widget.rowPinner, id)

    this.addToPinState(id)

    //setting to true as we do not want the page back to 1
    this.datatableState.pinnedRowsChanged = true

    await this.update()
  }

  /**
   * Remove pinned row id from state and local storage
   */
  async removePinnedRowId(id: EntityId) {
    removePinnedRowIdCache(this.services.authModule, this.widget.rowPinner, id)

    this.removeFromPinState(id)

    //setting to true as we do not want the page back to 1
    this.datatableState.pinnedRowsChanged = true

    await this.update()
  }

  /**
   * Remove pinned row id from state and local storage without update
   */
  async removeFromCacheAndStatePinnedRowId(id: EntityId) {
    removePinnedRowIdCache(this.services.authModule, this.widget.rowPinner, id)

    this.removeFromPinState(id)
  }

  private initPinnedItems() {
    const pinItemsIdsFromLocal = this.getStatePinnedRowIds()
    const pinItemsIdsFetched = this.state.pinnedEntities.map((item) => item.id)
    const closedItemsIds = pinItemsIdsFromLocal.filter(
      (item) => !pinItemsIdsFetched.includes(item),
    )

    if (closedItemsIds.length > 0) {
      closedItemsIds.forEach((id) => {
        this.removeFromCacheAndStatePinnedRowId(id)
      })
    }
  }
}
