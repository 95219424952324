<template>
  <div v-if="actions && actions.length">
    <v-divider />

    <slot name="beforeList" />

    <v-list style="border-radius: 10px" dense class="py-0" two-line>
      <template v-for="(action, index) in actions">
        <v-list-item
          :key="action.actionName"
          :ripple="false"
          :data-analytics="`core:open-action-form-button|resource:${resource}|action:${action.actionName}`"
          @click="openAction(action, index)"
        >
          <TLoadingBar v-if="index === loading" />

          <v-list-item-content>
            <v-list-item-title>{{ action.label }}</v-list-item-title>

            <v-list-item-subtitle>
              {{ action.description }}
            </v-list-item-subtitle>
          </v-list-item-content>

          <v-list-item-action>
            <v-icon v-if="loading === null" v-text="`keyboard_arrow_right`" />
          </v-list-item-action>
        </v-list-item>

        <v-divider v-if="index < actions.length - 1" :key="index" />
      </template>
    </v-list>

    <v-divider />
  </div>
</template>

<script lang="ts">
import Vue, { PropType, VueConstructor } from 'vue'
import { FormOptions } from '@tracktik/tt-json-schema-form'

import { updateDOM } from '@/helpers/dom'
import { Action } from '@/tt-widget-factory/services/resource-action/types'
import {
  ActiveWindow,
  LayoutComponents,
  LayoutWindowEvent,
} from '@/tt-app-layout'

import { getResourceFormOptions } from '../helper'
import { ItemHookProvider } from '../types'

type VueWithInjections = VueConstructor<
  Vue &
    ItemHookProvider & {
      activeWindow: ActiveWindow
      formOptions: FormOptions
    }
>

export default (Vue as VueWithInjections).extend({
  name: 'EntityActionsAsList',
  inject: {
    activeWindow: {},
    getItemHook: {},
    formOptions: { default: undefined },
  },
  inheritAttrs: false,
  props: {
    actions: {
      type: Array as PropType<Action[]>,
      required: true,
    },
  },
  data: () => ({
    // index of action that is loading
    loading: null as null | number,
  }),
  computed: {
    resource(): string | null {
      return this.getItemHook().getResourceName()
    },
  },
  methods: {
    async openAction(action: Action, index: number) {
      this.loading = index

      await updateDOM()

      const payload = await this.getItemHook().getActionFormAsPage(
        action.actionName,
        {
          formOptions: this.formOptions,
        },
      )

      const { openIn } = getResourceFormOptions(
        this.getItemHook().getResourceName(),
        action.actionName,
      )

      if (openIn === LayoutComponents.dialog) {
        this.$appContext.eventManager.dispatchEvent(
          LayoutWindowEvent.DIALOG,
          payload,
        )
      } else {
        this.activeWindow.next(payload)
      }

      this.loading = null
    },
  },
})
</script>
