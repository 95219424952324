<template>
  <div class="w-100">
    <slot name="filters">
      <RealTimeOverflowBox :key="attributeFilters.length">
        <EntityToolbarFilterButton
          v-for="(filterSetting, i) in attributeFilters"
          :key="i + filterSetting.attributeName"
          :value="model[filterSetting.attributeName]"
          :attributeName="filterSetting.attributeName"
          :resource="resource"
          :attribute-meta="filterSetting.attributeMeta"
          :options="filterSetting.options"
          :label="filterSetting.label"
          @input="setFilter(filterSetting.attributeName, $event)"
          @apply="apply"
        />
        <FilterHandlerButton
          v-for="(handler, i) in filterHandlers"
          :key="i"
          :handler="handler"
          :resource="resource"
          :value="model[handler.attribute]"
          @input="setFilterAndApply(handler.attribute, $event)"
        />
        <EntityToolbarCustomFilterButton
          v-for="(filterName, i) in customFilterNames"
          :key="i + filterName"
          :value="model[filterName] && model[filterName].value"
          :resource="resource"
          :filter-name="filterName"
          @apply="setCustomFiltersAndApply(filterName, $event)"
        />
        <EntityToolbarScopeFilterButton
          v-for="(scopeName, i) in scopeFilterNames"
          :key="i + scopeName"
          :resource="resource"
          :scope-name="scopeName"
          :current-scope="currentScope"
          @apply="apply"
          @addscope="addScopeFilter"
          @removescope="removeScopeFilter"
        />
        <v-btn
          v-if="hasFilters"
          class="clear-filter-btn mr-2"
          :height="30"
          :max-width="200"
          outlined
          small
          text
          @click="resetAll"
        >
          <span v-text="$t('entity_toolbar_filter_button.filter.reset_all')" />
        </v-btn>

        <template #overflow>
          <EntityFilterDialog
            :resource="resource"
            :filters="attributeFilters"
            :custom-filter-names="customFilterNames"
            :scope-filter-names="scopeFilterNames"
            :current-scope="currentScope"
            :filter-handlers="filterHandlers"
            :value="model"
            @apply="apply"
            @addscope="addScopeFilter"
            @input="setFilters($event)"
            @removescope="removeScopeFilter"
            @removeSearch="removeSearchFilter"
          >
            <template #activator="{ on, dirtyFilterCount }">
              <v-btn small icon text v-on="on">
                <v-icon small> mdi-filter-variant </v-icon>
                <v-badge v-if="!!dirtyFilterCount" color="green" dot overlap />
              </v-btn>
            </template>
          </EntityFilterDialog>
        </template>
      </RealTimeOverflowBox>
    </slot>
  </div>
</template>

<script lang="ts">
import Vue, { PropType, VueConstructor } from 'vue'

import BaseQueryManager from '@/tt-widget-components/base/BaseQueryManager'
import {
  Filter,
  FiltersMap,
  FilterHandler,
  FilterOptions,
} from '@/tt-widget-components'

import EntityFilterDialog from './EntityFilterDialog.vue'
import EntityToolbarCustomFilterButton from './EntityToolbarCustomFilterButton.vue'
import EntityToolbarFilterButton from './EntityToolbarFilterButton.vue'
import EntityToolbarScopeFilterButton from './EntityToolbarScopeFilterButton.vue'
import FilterHandlerButton from './FilterHandlerButton.vue'
import { FilterSetting } from '../types'
import {
  getCustomFilterName,
  mergeManagedFilters,
  pickManagedFilters,
} from '../helpers/managed-filters'
type VueWithInjections = VueConstructor<
  Vue & { isInjectedDashboardTab: boolean }
>

export default (Vue as VueWithInjections).extend({
  name: 'EntityToolbarFilter',
  components: {
    EntityFilterDialog,
    EntityToolbarFilterButton,
    EntityToolbarCustomFilterButton,
    EntityToolbarScopeFilterButton,
    FilterHandlerButton,
  },
  inject: {
    isInjectedDashboardTab: {
      from: 'isInjectedDashboardTab',
      default: false,
    },
  },
  props: {
    filterHandlers: {
      type: Array as PropType<FilterHandler[]>,
      default: () => [] as FilterHandler[],
    },
    filterOptions: {
      type: Object as PropType<FilterOptions>,
      default: (): FilterOptions => ({}),
    },
    queryManager: {
      type: Object as PropType<BaseQueryManager>,
      required: true,
    },
  },
  computed: {
    model(): FiltersMap {
      return pickManagedFilters(
        this.queryManager.customFilters,
        this.filterOptions,
        this.filterHandlers,
      )
    },
    resource(): string | null {
      return this.queryManager.initialQuery.resource
    },
    customFilterNames(): string[] {
      return this.filterOptions.customFilters?.map(getCustomFilterName) || []
    },
    scopeFilterNames(): string[] {
      return this.filterOptions.scope || []
    },
    attributeFilters(): FilterSetting[] {
      if (!this.filterOptions.filters) {
        return []
      }

      return this.filterOptions.filters.map((filter) => {
        const { attributeName, options, label } = filter

        return {
          attributeName,
          attributeMeta:
            this.$appContext.widgetServices.resourceMetaManager.getAttribute(
              this.resource,
              attributeName,
            ),
          filter: this.model[attributeName],
          options,
          label,
        }
      })
    },
    currentScope(): string[] {
      return this.queryManager?.scope ?? []
    },
    hasFilters(): boolean {
      return !!(
        this.attributeFilters?.length ||
        this.customFilterNames?.length ||
        this.scopeFilterNames.length
      )
    },
  },
  methods: {
    apply() {
      this.$emit('update', this.model)
    },
    resetAll() {
      this.queryManager.resetAllFilters()
      this.apply()
    },
    setCustomFilter(filterName: string, value: Filter['value']) {
      const newFilter: Filter = {
        attribute: filterName,
        operator: '',
        value,
      }

      this.setFilter(filterName, newFilter)
    },
    setCustomFiltersAndApply(filterName: string, value: Filter['value']) {
      this.setCustomFilter(filterName, value)
      this.apply()
    },
    addScopeFilter(scopeToAdd: string | string[]): void {
      this.queryManager.addScope(scopeToAdd)
    },
    removeScopeFilter(scopeToRemove: string | string[]): void {
      this.queryManager.removeScope(scopeToRemove)
    },
    removeSearchFilter(): void {
      this.queryManager.removeSearch()
    },
    setFilterAndApply(attribute: string, filter: Filter | null): void {
      this.setFilter(attribute, filter)
      this.apply()
    },
    setFilter(attribute: string, filter: Filter | null): void {
      this.setFilters({ ...this.model, [attribute]: filter })
    },
    setFilters(filters: FiltersMap): void {
      const newFilters = mergeManagedFilters(
        this.queryManager.customFilters,
        filters,
        this.filterOptions,
        this.filterHandlers,
      )
      const newFiltersList = Object.values(newFilters)
      this.queryManager.setCustomFilters(newFiltersList)
    },
  },
})
</script>

<style scoped>
.clear-filter-btn {
  background-color: var(--v-level0-base);
  font-size: 0.75em;
  font-weight: 400;
  text-transform: capitalize;
}
</style>
