<template>
  <div>
    <json-object :name="dayName">
      <v-row :class="{ disabledRow: !enableRow }" class="mt-3">
        <v-col
          cols="3"
          lg="2"
          class="d-flex"
          :class="{
            'v-col-disabled': !shouldAllowEnterValueSection,
          }"
        >
          <v-checkbox
            v-if="!alwaysEnabled"
            v-model="enableRow"
            :label="effectiveLabel"
            class="mt-0"
          >
            <template #prepend>
              <v-btn
                v-if="hasChildren"
                icon
                small
                @click="expanded = !expanded"
              >
                <v-icon>
                  {{ expanded ? 'mdi-menu-down' : 'mdi-menu-right' }}
                </v-icon>
              </v-btn>
            </template>
          </v-checkbox>
          <div v-else class="mt-5">
            {{ effectiveLabel }}
          </div>
        </v-col>
        <v-col
          cols="9"
          lg="10"
          :class="{
            'v-col-disabled': !shouldAllowEnterValueSection,
          }"
          @click="enableRow = true"
        >
          <v-row>
            <v-col cols="12" lg="4" class="d-flex justify-lg-end py-0 py-lg-3">
              <!--Fake button to show when the row is disabled -->
              <div v-if="showPerformDropdown && !enableRow">
                <SchedulingGroupDoNotPerformButton
                  :value="parentDoNotPerform"
                  :enable-perform-on-exception-day="enablePerformOnExceptionDay"
                  disabled
                />
              </div>
              <json-field
                v-else-if="showPerformDropdown && enableRow"
                :name="doNotPerformKey"
                :enable-perform-on-exception-day="enablePerformOnExceptionDay"
                as="SchedulingGroupDoNotPerformButton"
              />
            </v-col>
            <v-col cols="12" lg="8">
              <div v-if="!enableRow" inert>
                <!-- Show the parent value if it's available, otherwise just show the disabled inputs for the current object-->
                <slot name="parentValue">
                  <v-row>
                    <v-col
                      v-for="property in properties"
                      :key="`${dayName}-${property}`"
                      cols="6"
                    >
                      <json-field :name="property" />
                    </v-col>
                  </v-row>
                </slot>
              </div>
              <div v-else>
                <v-row>
                  <v-col
                    v-for="property in properties"
                    :key="`${dayName}-${property}`"
                    cols="6"
                    :class="{
                      'v-col-disabled': !shouldAllowEnterValue,
                    }"
                  >
                    <template v-if="!shouldAllowEnterValue">
                      <div class="field-error">
                        <span>
                          {{ validationMessage }}
                        </span>
                      </div>
                      <json-field :name="property" :disabled="true" />
                    </template>
                    <template v-else>
                      <json-field :name="property" />
                    </template>
                  </v-col>
                </v-row>
              </div>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </json-object>
    <div v-if="hasChildren" v-show="expanded">
      <v-row style="margin-top: -40px">
        <v-col cols="11" offset="1">
          <SchedulingGroupDaySetupDayRow
            v-for="(day, index) in children"
            :key="`${dayName}-${day}`"
            :day-name="day"
            :parent-do-not-perform="perform"
            :show-divider="index !== children.length - 1"
            :enable-perform-on-exception-day="enablePerformOnExceptionDay"
            :parent-name="parentName"
            :should-allow-enter-value="getShouldAllowEnterValueForChild(day)"
            :should-allow-enter-value-section="shouldAllowEnterValueSection"
            :validation-message="validationMessage"
            :is-flag-day="isFlagDay"
          >
            <template #parentValue>
              <div>
                <v-row class="parentField">
                  <v-col
                    v-for="property in properties"
                    :key="`${dayName}-${property}`"
                    cols="6"
                  >
                    <json-field
                      :name="`${parentName}.${dayName}.${property}`"
                    />
                  </v-col>
                </v-row>
              </div>
            </template>
          </SchedulingGroupDaySetupDayRow>
        </v-col>
      </v-row>
    </div>
    <v-divider v-if="showDivider" />
  </div>
</template>
<script lang="ts">
import Vue, { PropType, VueConstructor } from 'vue'

import { FormHookProvider, NamespaceProvider } from '@/tt-widget-components'
import { rangeTimeKeys } from '@/tt-entity-design/src/components/mobile-runsheet-groups/types'

import { weekDays, weekEnds, PerformType } from '../types'
import { getFieldAbsoluteName } from '@tracktik/tt-json-schema-form'

type VueWithInjections = Vue & FormHookProvider & NamespaceProvider

export default (Vue as VueConstructor<VueWithInjections>).extend({
  name: 'SchedulingGroupDaySetupDayRow',
  inject: ['formHook', 'namespace'],
  props: {
    isFlagDay: { type: Boolean, default: false },
    alwaysEnabled: { type: Boolean, default: false },
    children: { type: Array as PropType<string[]>, default: null },
    dayName: { type: String, required: true },
    disabled: { type: Boolean, default: true },
    parentDoNotPerform: { type: String, default: PerformType.PERFORM_TASK },
    showDivider: { type: Boolean, default: true },
    enablePerformOnExceptionDay: { type: Boolean, default: false },
    parentName: { type: String, default: 'days' },
    shouldAllowEnterValue: { type: Boolean, default: true },
    shouldAllowEnterValueSection: { type: Boolean, default: true },
    validationMessage: { type: String, default: '' },
  },
  data() {
    return {
      enableRow: !this.disabled || this.alwaysEnabled,
      expanded: false,
    }
  },
  computed: {
    absoluteName(): string {
      return getFieldAbsoluteName(this)
    },
    childrenValues(): Record<string, Record<string, unknown>> | null {
      if (!this.children) return null

      const entries = this.children.map((child) => [
        child,
        this.formHook().getPathValue(this.getFieldPath(child)),
      ])

      return Object.fromEntries(entries)
    },
    childrenHaveValues(): boolean {
      return Object.values(this.childrenValues ?? {}).some(
        (value) => value != null,
      )
    },
    doNotPerformKey(): string {
      return 'performType'
    },
    effectiveLabel(): string {
      return this.label || this.translateDayName(this.dayName)
    },
    name(): string {
      return this.dayName
    },
    fieldPath(): string {
      return this.getFieldPath(this.dayName)
    },
    hasChildren(): boolean {
      return this.children?.length > 0
    },
    label(): string {
      return this.viewComponentProps.label
    },
    isDay(): boolean {
      return this.parentName === 'days'
    },
    showPerformDropdown(): boolean {
      return !this.isDay && !this.isFlagDay
    },
    perform(): string {
      return this.formHook().getPathValue(
        `${this.parentName}.${this.dayName}.${this.doNotPerformKey}`,
      )
    },
    properties(): string[] {
      if (
        ['holidays', 'closed'].includes(this.parentName) &&
        this.perform === PerformType.DONT_PERFORM_TASK
      ) {
        return []
      }

      return [rangeTimeKeys.START_TIME_KEY, rangeTimeKeys.END_TIME_KEY]
    },
    value(): Record<string, unknown> {
      return this.formHook().getPathValue(this.fieldPath)
    },
    viewComponentProps(): any {
      return this.formHook().getViewComponentProps(this.absoluteName)
    },
  },
  watch: {
    children: {
      immediate: true,
      handler(children: string[] | null) {
        // Automatically expand row if children have values
        if (children && this.childrenHaveValues && !this.expanded) {
          this.expanded = true
        }
      },
    },
    enableRow(isRowEnabled: boolean) {
      if (isRowEnabled && this.value == null) {
        if (!this.isDay) {
          this.formHook().setObjectValue(this.fieldPath, {})
          let performType = PerformType.PERFORM_TASK
          if (this.isFlagDay) {
            performType = PerformType.PERFORM_ON_EXCEPTION_DAY
          }
          this.formHook().setObjectValue(
            `${this.parentName}.${this.dayName}.${this.doNotPerformKey}`,
            performType,
          )
        } else {
          this.formHook().setObjectValue(this.fieldPath, {
            exceptionsOnly: false,
          })
        }
      } else if (!isRowEnabled && this.value) {
        this.formHook().setObjectValue(this.fieldPath, null)
      }
    },
    value: {
      immediate: true,
      handler(value: Record<string, unknown> | null) {
        // Automatically enables row if it has value
        if (value != null && !this.enableRow) {
          this.enableRow = true
        }
      },
    },
  },
  methods: {
    getFieldPath(dayName: string): string {
      return `${this.parentName}.${dayName}`
    },
    getShouldAllowEnterValueForChild(dayName: string): boolean {
      let currentComponent: any = this.$parent

      while (currentComponent) {
        if (currentComponent.$options.name === 'SchedulingGroupDaySetupForm') {
          return (currentComponent as any).shouldAllowEnterValue(dayName)
        }

        currentComponent = currentComponent.$parent

        if (!currentComponent) break
      }

      return this.shouldAllowEnterValue
    },
    translateDayName(dayName: string): string {
      const key = ([...weekDays, ...weekEnds] as string[]).includes(dayName)
        ? `res.mobile-runsheets.attr.dayOfWeek.list.${dayName}.label`
        : `scheduling_group_create_wizard.days_setup.${dayName.toLowerCase()}`

      return this.$t(key) as string
    },
  },
})
</script>
<style scoped>
.disabledRow >>> fieldset {
  color: rgba(0, 0, 0, 0.38) !important;
}
.disabledRow >>> .v-input label {
  color: rgba(0, 0, 0, 0.38);
}
[inert] >>> fieldset,
[inert] >>> .v-input input {
  color: rgba(0, 0, 0, 0.38);
}
/* Hide clear button on duplicated fields */
[inert] >>> .v-input__append-inner {
  display: none;
}
.parentField >>> .v-input .error--text {
  color: rgba(0, 0, 0, 0.38) !important;
}

.parentField >>> .v-text-field__details {
  display: none;
}
.v-col-disabled {
  pointer-events: none;
  opacity: 0.5;
}
.field-error {
  color: red;
  font-size: 12px;
  margin-top: -17px;
}
</style>
