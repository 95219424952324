import flatten from 'flat'

import { EntityItemHook } from '@/tt-widget-entity-flow/EntityItemHook'

import { SchedulerEventHandler } from './scheduler-event-handler'
import { SchedulerView } from '@/tt-widget-components/widgets/Scheduler/types'
import get from 'lodash/get'
import { UserPreferenceInterface } from '@tracktik/tt-authentication'
import { unitOfTime } from 'moment-timezone'

type EventData = ReturnType<SchedulerEventHandler['transform']>

export const processSchedulerEventTemplate = (
  eventHandler: SchedulerEventHandler,
  itemHook: EntityItemHook,
  entities: Record<string, any>[],
  userPreferences: UserPreferenceInterface,
): string => {
  const { template, transform } = eventHandler
  const data = (
    transform
      ? transform({ itemHook, entities, userPreferences })
      : itemHook.data
  ) as EventData

  const flattenData: EventData = flatten(data)

  let stringTemplate = ''
  if (typeof template === 'string') {
    stringTemplate = template
  } else if (typeof template === 'function') {
    stringTemplate = template({})
  }

  // Replaces placeholders in the template with corresponding values from the flattened data object.
  return Object.entries(flattenData).reduce((acc, [key, value]) => {
    const regex = new RegExp(`\\\${${key}}`, 'g')

    return acc.replace(regex, value)
  }, stringTemplate)
}

/**
 * Check if the view is a supported Scheduler view (Day, Week, Month)
 */
export const isViewValid = (view: string): view is SchedulerView =>
  (Object.values(SchedulerView) as string[]).includes(view)

/**
 * Create a stateful object that manages the colors of a list of events.
 * The color of an event is based on the value of the `colorAttribute`.
 */
export const createColorManager = (colorAttribute, colorOptions) => {
  const valuesToColorDictionary: Record<string, string> = {}
  const getNextColor = (): string =>
    colorOptions[
      Object.keys(valuesToColorDictionary).length % colorOptions.length
    ]

  /**
   * Returns the color of the event based on the value of the `colorAttribute`.
   * All same values will have the same color.
   * If the `colorAttribute` is not set, all events will have a different color.
   */
  const getEventColor = (entity: Record<string, string | number>) => {
    const value = colorAttribute ? get(entity, colorAttribute) : entity.id

    // if value does not have a color, assign one
    valuesToColorDictionary[value] ??= getNextColor()

    return valuesToColorDictionary[value]
  }

  return { getEventColor }
}

export const schedulerViewMapping: {
  [key in SchedulerView]: unitOfTime.StartOf
} = {
  [SchedulerView.DAY]: 'day',
  [SchedulerView.WEEK]: 'week',
  [SchedulerView.MONTH]: 'month',
}
