<template>
  <TChip :color="color">
    <TtAttrRelationList
      :value="presentOperatorsCount"
      :name="attributeName"
      :resource="resource"
    />
  </TChip>
</template>

<script lang="ts">
import Vue, { VueConstructor } from 'vue'
import { ItemHookProvider } from '@/tt-widget-entity-flow/types'
import { Resources } from '../../types'
import { Attribute } from '@/tt-entity-design/src/schema-types'
import { PresenceEnum } from './types'
import { getPresenceColor } from './utils'

const getAttr = (attr: Attribute<typeof Resources.OPERATION_CENTERS>) => attr

export default (Vue as VueConstructor<Vue & ItemHookProvider>).extend({
  name: 'OperationCentersPresentOperatorAttr',
  inject: ['getItemHook'],
  computed: {
    presentOperatorsCount(): number {
      const presentOperators =
        this.getItemHook().getRawValue('presentOperators') || []

      return presentOperators.length
    },
    operatorsCount(): number {
      const operators = this.getItemHook().getRawValue('operators') || []

      return operators.length
    },
    attributeName(): string {
      return getAttr('presentOperators')
    },
    resource(): string {
      return Resources.OPERATION_CENTERS
    },
    color(): string {
      if (this.operatorsCount > 0) {
        if (this.presentOperatorsCount === 0) {
          return getPresenceColor(PresenceEnum.ABSENT)
        } else {
          return getPresenceColor(PresenceEnum.PRESENT)
        }
      }

      // If there are no operators, we should not show any color
      return getPresenceColor()
    },
  },
  created() {
    this.getItemHook().addAttribute('presentOperators')
    this.getItemHook().addAttribute('operators')
    // adding the status to itemHook here as it is not part of the required columns
    this.getItemHook().addAttribute('status')
  },
})
</script>
