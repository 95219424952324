<template>
  <TChip :color="statusAttribute.color">
    {{ statusAttribute.text }}
  </TChip>
</template>
<script lang="ts">
import Vue, { VueConstructor } from 'vue'
import { ItemHookProvider } from '@/tt-widget-entity-flow/types'
import { getStatusColor } from './utils'
export default (Vue as VueConstructor<Vue & ItemHookProvider>).extend({
  name: 'OperationCentersStatusAttr',
  inject: ['getItemHook'],
  computed: {
    statusAttribute(): { color: string; text: string } {
      const status = this.getItemHook().getRawValue('status')
      const color = getStatusColor(status)

      return {
        color: color ?? '',
        text: status ?? '',
      }
    },
  },
  created() {
    this.getItemHook().addAttribute('status')
  },
})
</script>
