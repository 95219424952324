import colorPalettes from '@/tt-widget-components/lib/color-palettes'
import {
  AggregationQueryResponse,
  WidgetHookInterface,
} from '@/tt-widget-factory'
import DataSetWidgetHookPercent from '@/tt-widget-components/base/DataSetWidgetHookPercent'
import { PieChartWidgetModel } from '@/tt-widget-components'

export default class PieChartWidgetHook<WidgetModel extends PieChartWidgetModel>
  extends DataSetWidgetHookPercent<WidgetModel>
  implements WidgetHookInterface
{
  private static DEFAULT_PALETTE = 'INDIGO'

  SERIES_INDEX = 0
  DIMENSION_INDEX = 0
  MEASURE_INDEX = 0

  /**
   * The label of the serie
   */
  get seriesName() {
    return this.seriesManagers[this.SERIES_INDEX].firstDimension.name
  }

  /**
  This chart type does not support displaying negative values
*/

  async update() {
    const filterNegative = (
      response: AggregationQueryResponse,
    ): AggregationQueryResponse => {
      const items = response.items.filter(
        (record) => record.data[this.measureKey] > 0,
      )

      return { ...response, items }
    }

    await this._update(filterNegative)
  }

  get measureKey() {
    return this.getMeasureByIndex(this.SERIES_INDEX, this.MEASURE_INDEX).key
  }

  /**
   * Data source
   */
  get dataSource() {
    if (!this.series?.length) {
      return []
    }
    const dimension = this.getDimensionByIndex(
      this.SERIES_INDEX,
      this.DIMENSION_INDEX,
    )
    const measure = this.getMeasureByIndex(
      this.SERIES_INDEX,
      this.MEASURE_INDEX,
    )

    const dataSource: any[] = this.series[this.SERIES_INDEX].asSortedSeries(
      measure.key,
    )

    return this.formatDataSource(
      dataSource,
      this.SERIES_INDEX,
      dimension,
      measure,
      this.state.widget.dataSet?.measure?.format,
    )
  }

  /**
   * Get the colors
   */
  get colorPaletteColors(): string[] {
    return colorPalettes.get(this.colorPalette)!.colors
  }

  /**
   * Get the palette name. If it does not exists it reverts
   */
  get colorPalette(): string {
    if (
      this.widget.options &&
      this.widget.options.colorPalette &&
      colorPalettes.has(this.widget.options.colorPalette)
    ) {
      return this.widget.options.colorPalette
    }

    return PieChartWidgetHook.DEFAULT_PALETTE
  }
}
