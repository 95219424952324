<template>
  <div>
    <div v-if="isLoading">
      <v-skeleton-loader type="list-item" />
    </div>
    <div v-else>
      <json-field name="id" />
      <json-field name="taskType" />
      <json-field name="site" />
      <json-field name="jobInstructions" />
      <json-field name="generalInstructions" />
      <json-field name="checkPointTour" />
      <json-field name="priceTier" />
      <json-field name="dispatchSla" />
      <json-field name="generalDispatchSla" />
      <div v-if="hasAssetTrackingFeature">
        <json-field v-if="isDispatchable" name="dispatchableAssets" />
        <json-field v-if="schedulableRunsheet" name="schedulableAssets" />
        <json-field v-if="schedulableSiteTask" name="siteTaskAssets" />
      </div>
      <div v-if="showVendorsInput">
        <span class="text-body-1 grey--text text--darken-3">
          {{ translate('section.vendor.title') }}
        </span>
        <v-divider class="mt-2 mb-4" />
        <json-field
          :disabled="!selectedTaskTypeId || !isDispatchable"
          name="vendor"
        />
      </div>
      <span
        v-if="!isDispatchable"
        class="mt-2 text-body-2 grey--text text--darken-1"
      >
        {{ translate('section.vendor.dispatchable-warning') }}
      </span>
      <template v-if="loneWorkerFrequencyEnabled">
        <div v-if="isLoadingTaskType">
          <v-skeleton-loader type="list-item" />
        </div>
        <div v-else-if="allowLoneWorkerOverrideDispatch">
          <span class="text-body-1 grey--text text--darken-3">
            {{ translate('section.lone-worker.title') }}
          </span>
          <v-divider class="mt-2 mb-4" />

          <json-field
            name="checkInFrequency"
            :label="translate('check-in-frequency.label')"
            :placeholder="translate('check-in-frequency.placeholder')"
            as="TaskSiteInstructionsFormLoneWorkerFrequencyField"
          />
        </div>
      </template>
    </div>
  </div>
</template>

<script lang="ts">
import Vue, { VueConstructor } from 'vue'

import { createUserRegionFeatureManager } from '@/tt-feature-manager/UserRegionFeatureManager'
import { EntityFeatureManager } from '@/tt-feature-manager/types'
import { EntityItemHook } from '@/tt-widget-entity-flow/EntityItemHook'
import { FormHookProvider } from '@/tt-widget-components'
import { LayoutWindowEvent } from '@/tt-app-layout'
import { Resources } from '@/tt-entity-design/src/types'

import { getTaskType } from './helpers/task-site-instructions-api-helper'
import { TaskType } from './types'

const VENDOR_DISPATCH_FEATURE = 'VendorDispatchFeature'
const ASSET_HUB_FEATURE = 'AssetHubFeature'

export default (Vue as VueConstructor<Vue & FormHookProvider>).extend({
  name: 'TaskSiteInstructionsForm',
  inject: ['formHook'],
  data() {
    return {
      selectedTaskType: null as TaskType,
      isLoading: false,
      isLoadingTaskType: false,
      hasAssetTrackingFeature: false,
      featureManager: null as EntityFeatureManager,
    }
  },
  computed: {
    itemHook(): EntityItemHook | null {
      return this.formHook().getUserContextValue('itemHook') ?? null
    },
    selectedTaskTypeId(): number | null {
      return this.formHook().getPathValue('taskType') || null
    },
    allowLoneWorkerOverrideDispatch(): boolean {
      return this.selectedTaskType?.allowLoneWorkerOverrideDispatch ?? false
    },
    isDispatchable(): boolean {
      return this.selectedTaskType?.dispatchable ?? false
    },
    schedulableRunsheet(): boolean {
      return this.selectedTaskType?.schedulableRunsheet ?? false
    },
    schedulableSiteTask(): boolean {
      return this.selectedTaskType?.schedulableSiteTask ?? false
    },
    clientId(): number | null {
      return this.formHook().getPathValue('site') || null
    },
    showVendorsInput(): boolean {
      return this.featureManager.hasFeature(VENDOR_DISPATCH_FEATURE)
    },
    loneWorkerFrequencyEnabled(): boolean {
      return this.itemHook?.hasAttribute('checkInFrequency') ?? false
    },
    translationKeyPrefix(): string {
      return 'tt-entity-design.task-site-instructions.form'
    },
  },
  watch: {
    selectedTaskTypeId: {
      immediate: true,
      async handler(taskTypeId, oldValue) {
        this.selectedTaskType = taskTypeId
          ? await this.fetchTaskType(taskTypeId)
          : null

        // oldValue is only `undefined` on initial load
        if (oldValue !== undefined && oldValue !== taskTypeId) {
          this.formHook().setObjectValue('vendor', null)

          if (this.loneWorkerFrequencyEnabled) {
            this.formHook().setObjectValue('checkInFrequency', null)
          }
        }
      },
    },
    clientId: {
      immediate: true,
      async handler(clientId: number) {
        this.hasAssetTrackingFeature = clientId
          ? await this.fetchAssetTrackerFeatureState()
          : false
      },
    },
  },
  created() {
    this.featureManager = createUserRegionFeatureManager(
      this.$appContext.authModule,
    )
  },
  methods: {
    async fetchAssetTrackerFeatureState(): Promise<boolean> {
      this.isLoading = true
      const id = `${this.clientId}/features/${ASSET_HUB_FEATURE}`

      const res: { active?: boolean } = await this.$auth
        .getApi()
        .get(Resources.CLIENTS, id, {
          fields: [{ attribute: 'active' }],
        })
        .catch((error) => {
          if (error.response?.status !== 404) this.handleError(error)

          return { active: false }
        })

      this.isLoading = false

      return res.active
    },
    async fetchTaskType(taskTypeId: number): Promise<TaskType> {
      this.isLoadingTaskType = true

      const fields = [
        'dispatchable',
        'schedulableRunsheet',
        'schedulableSiteTask',
        ...(this.loneWorkerFrequencyEnabled
          ? ['allowLoneWorkerOverrideDispatch']
          : []),
      ]

      const taskType = await getTaskType(this.$appContext, taskTypeId, fields)

      this.isLoadingTaskType = false

      return taskType
    },
    handleError(error: unknown) {
      this.$crash.captureException(error)
      this.$eventManager.dispatchEvent(LayoutWindowEvent.SNACK_ERROR, {
        message: this.$t('common.error_message'),
      })
    },
    translate(key: string): string {
      return this.$t(`${this.translationKeyPrefix}.${key}`) as string
    },
  },
})
</script>
