<template>
  <div v-ripple class="role--tile pa-3 align-center" @click="handleClick">
    <!-- LEFT -->
    <div class="flex--column w-100 justify-center">
      <tt-attr
        prepend-icon="mdi-shield-account"
        prepend-icon-color="purple"
        name="managedPermissionPolicy.name"
      />

      <div class="flex--row py-2">
        <TChip color="ttPrimary">
          <tt-attr name="managedPermissionPolicy.portal" />
        </TChip>

        <tt-if-attr
          name="managedPermissionPolicy.status"
          class="pl-3"
          value="ARCHIVED"
        >
          <TChip color="muted">
            <tt-attr name="managedPermissionPolicy.status" />
          </TChip>
        </tt-if-attr>
      </div>
    </div>

    <!-- RIGHT -->
    <div class="flex--column align-center">
      <v-btn
        small
        class="ttPrimary ttPrimary--text lighten-5"
        :loading="isFetchingPermissions"
        elevation="0"
        @click.stop="viewPermissions"
      >
        <v-icon small class="mr-1" v-text="'mdi-eye'" />
        <span v-text="permissionLabel" />
      </v-btn>

      <!-- to make the tooltip work on disabled button -->
      <v-fab-transition>
        <v-btn
          v-show="showDeleteButton()"
          small
          class="error error--text lighten-5 mt-3"
          :loading="isDeleting"
          :disabled="!canDelete()"
          elevation="0"
          @click.stop="runDelete"
        >
          <v-icon small class="mr-1" v-text="'mdi-delete'" />
          <span v-text="$t('common.remove.btn')" />
        </v-btn>
      </v-fab-transition>
    </div>
  </div>
</template>

<script lang="ts">
import { ItemHookProvider } from '@/tt-widget-entity-flow/types'
import Vue, { VueConstructor, PropType } from 'vue'
import { Resources } from '@/tt-entity-design/src/types'
import { Attribute } from '@/tt-entity-design/src/schema-types'
import ApiPermissionsViewer from '@/tt-entity-design/src/components/managed-permission-policies/permissions/ApiPermissionsViewer.vue'
import { LayoutWindowEvent } from '@/tt-app-layout'
import { createI18nResourceKey } from '@/tt-widget-entity-flow/ResourceTranslator'

type Callback = (id: number) => void

export default (Vue as VueConstructor<Vue & ItemHookProvider>).extend({
  name: 'RoleManagedPermissionPoliciesRelationListTile',
  inject: ['getItemHook'],
  props: {
    showDeleteButton: {
      type: Function as PropType<() => boolean>,
      default: () => false,
    },
    canDelete: {
      type: Function as PropType<() => boolean>,
      default: () => false,
    },
    onDelete: {
      type: Function as PropType<Callback>,
      default: () =>
        console.warn('No "delete" callback provided.') as unknown as Callback,
    },
    onClick: {
      type: Function as PropType<Callback>,
      default: () =>
        console.log('No "click" callback provided.') as unknown as Callback,
    },
  },
  data: () => ({
    isDeleting: false,
    isFetchingPermissions: false,
  }),
  computed: {
    entityId(): number {
      return this.getItemHook().getRawValue('managedPermissionPolicy.id')
    },
    permissionLabel(): string {
      return this.$t(createI18nResourceKey('roles', 'permissions'))
    },
  },
  created() {
    this.getItemHook().addAttribute('managedPermissionPolicy.id')
  },
  methods: {
    async runDelete() {
      this.isDeleting = true
      await this.onDelete(this.entityId)
      this.isDeleting = false
    },
    handleClick() {
      this.onClick(this.entityId)
    },
    async viewPermissions() {
      this.isFetchingPermissions = true
      const policyAttribute: Attribute<
        typeof Resources.ROLE_MANAGED_PERMISSION_POLICIES
      > = 'managedPermissionPolicy'
      const policyPermissionAttr: Attribute<
        typeof Resources.MANAGED_PERMISSION_POLICIES
      > = 'permissions'

      const permissions = await this.$auth
        .getApi()
        .get(
          Resources.MANAGED_PERMISSION_POLICIES,
          this.getItemHook().getRawValue(`${policyAttribute}.id`),
          { fields: [{ attribute: policyPermissionAttr }] },
        )
        .then((entity) => entity[policyPermissionAttr])
        .catch((error) => {
          console.error('Error fetching permissions', error)

          return []
        })

      this.$eventManager.dispatchEvent(LayoutWindowEvent.BOTTOM_SHEET, {
        is: ApiPermissionsViewer,
        props: {
          permissions,
          showOnlyActivePermissionResources: true,
        },
      })

      this.isFetchingPermissions = false
    },
  },
})
</script>

<style scoped>
.role--tile {
  position: relative;
  display: flex;
  height: 100%;
  cursor: pointer;
}

.role--tile:hover {
  background-color: var(--v-muted-base);
}
</style>
