<template>
  <div class="operator--tile">
    <component :is="listItemComponent" v-bind="$attrs" />

    <div class="delete--button w-100 h-100">
      <div class="flex--column align-end justify-center pr-6">
        <v-tooltip left :disabled="canDelete()">
          <template #activator="{ on }">
            <!-- to make the tooltip work on disabled button -->
            <div v-on="on">
              <v-fab-transition>
                <v-btn
                  v-show="showDeleteButton()"
                  color="error"
                  icon
                  :loading="isLoading"
                  :disabled="!canDelete()"
                  @click.stop="onClickDelete"
                >
                  <v-icon v-text="'mdi-delete-circle'" />
                </v-btn>
              </v-fab-transition>
            </div>
          </template>

          <span
            v-text="
              $t('tt-entity-design.operation-centers.cannot-remove-operators')
            "
          />
        </v-tooltip>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { ItemHookProvider } from '@/tt-widget-entity-flow/types'
import Vue, { VueConstructor, PropType, Component } from 'vue'

type DeleteCallback = (id: number) => Promise<void>

export default (Vue as VueConstructor<Vue & ItemHookProvider>).extend({
  name: 'EntityRelationListEditorTile',
  inject: ['getItemHook'],
  props: {
    showDeleteButton: {
      type: Function as PropType<() => boolean>,
      default() {
        return () => false
      },
    },
    canDelete: {
      type: Function as PropType<() => boolean>,
      default() {
        return () => false
      },
    },
    runDelete: {
      type: Function as PropType<DeleteCallback>,
      default() {
        return () =>
          console.warn(
            'No "delete" callback provided.',
          ) as unknown as DeleteCallback
      },
    },
    listItemComponent: {
      type: [Object, Function, String] as PropType<Component>,
      required: true,
    },
  },
  data: () => ({
    isLoading: false,
  }),
  methods: {
    async onClickDelete() {
      this.isLoading = true
      const entityId = this.getItemHook().getEntityId()
      await this.runDelete(entityId)
      this.isLoading = false
    },
  },
})
</script>

<style scoped>
.delete--button {
  position: absolute;
  top: 0;
  left: 0;
}

.operator--tile {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
}
</style>
