<template>
  <widget-container class="d-flex flex-column">
    <v-skeleton-loader v-if="!isReady" type="table" />
    <WidgetFactory v-if="isReady" :widget="leaveHistoryWidgetModel" />
  </widget-container>
</template>

<script lang="ts">
import Vue from 'vue'
import {
  AttributeFilter,
  DataTableWidgetModel,
  Filter,
  Sort,
} from '@/tt-widget-components'
import LeaveHistoryCustomColumns from './LeaveHistoryCustomColumns'
import { Resources } from '@/tt-entity-design/src/types'
import { UnsubscribeFunction } from '@tracktik/tt-event-manager'
import { EntityIntentTypes } from '@/tt-widget-entity-flow'

export default Vue.extend({
  name: 'LeaveHistory',
  props: {
    employeeId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      unsubscribeFn: null as UnsubscribeFunction,
    }
  },
  computed: {
    isReady(): boolean {
      return this.$appContext.isReady()
    },
    leaveHistoryWidgetModel(): DataTableWidgetModel {
      const employeeFilter: Filter = {
        attribute: 'employee',
        operator: 'EQUAL',
        value: this.employeeId,
      }
      const sortByMostRecent: Sort = {
        attribute: 'createdOn',
        direction: 'DESC',
      }
      const startedOnFilter: AttributeFilter = {
        attributeName: 'startDateTime',
      }
      return {
        is: 'DataTableWidget',
        title: '',
        query: {
          resource: Resources.LEAVE_REQUESTS,
          filters: [employeeFilter],
          sort: [sortByMostRecent],
        },
        toolbar: {
          displayCounts: true,
          filterOptions: {
            filters: [startedOnFilter],
            allowSearch: true,
          },
        },
        component: {
          columns: LeaveHistoryCustomColumns,
        },
      }
    },
  },
  created() {
    // to refresh the list once a new leave request is posted for the employee
    this.unsubscribeFn = this.$appContext.eventManager.subscribeEvent(
      EntityIntentTypes.RESOURCE_UPDATED,
      ({ resource }) => {
        if (resource === Resources.LEAVE_REQUESTS) {
          this.updatePolicyBalances()
        }
      },
    )
  },
  beforeDestroy() {
    if (this.unsubscribeFn) this.unsubscribeFn()
  },
  methods: {
    updatePolicyBalances(): void {
      this.$appContext.eventManager.dispatchEvent(
        EntityIntentTypes.RESOURCE_UPDATED,
        { resource: Resources.LEAVE_REQUEST_LOGS },
      )
    },
  },
})
</script>
