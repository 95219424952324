import { modularManager } from '@/tt-app-modular'
import MobileRunsheetOccurrencesActionBar from './MobileRunsheetOccurrencesActionBar.vue'
import MobileRunsheetOccurrenceDoc, {
  DocumentRunsheetScheduleOccurrences,
} from './MobileRunsheetOccurrencesDoc'
import { Resources } from '../../types'
import { addResourceDocument } from '@/tt-widget-entity-flow/helper'
import {
  createI18nResourceKey,
  createResourceKey,
} from '@/tt-widget-entity-flow/ResourceTranslator'
import { MOBILE_RUNSHEET_OCCURRENCES } from './constants'
import { addPreviewTab } from '@/tt-widget-entity-flow/helper'
import { MOBILE_SCHEDULE_OCCURRENCES } from '@/apps/app.tracktik.runsheet/resources'
import MobileRunsheetOccurrencesScheduleOccurrences from './MobileRunsheetOccurrencesScheduleOccurrences.vue'
import { MOBILE_RUNSHEET_SCHEDULE_OCCURRENCES } from './constants'
import MobileRunsheetOccurrencesAuditLog from './MobileRunsheetOccurrencesAuditLog.vue'
import MobileRunsheetOccurrencesAuditLogItem from './MobileRunsheetOccurrencesAuditLogItem.vue'

export default {
  install(Vue) {
    Vue.component(
      'MobileRunsheetOccurrencesAuditLogItem',
      MobileRunsheetOccurrencesAuditLogItem,
    )

    addResourceDocument(
      Resources.MOBILE_RUNSHEET_OCCURRENCES,
      MobileRunsheetOccurrenceDoc,
    )
    addResourceDocument(
      MOBILE_RUNSHEET_SCHEDULE_OCCURRENCES,
      DocumentRunsheetScheduleOccurrences,
    )

    modularManager.registerActionBar(MOBILE_RUNSHEET_OCCURRENCES, {
      is: MobileRunsheetOccurrencesActionBar,
    })

    /**
     * Needs the API to add the relationList attribute
     */
    addPreviewTab(MOBILE_RUNSHEET_OCCURRENCES, {
      title: createResourceKey(MOBILE_SCHEDULE_OCCURRENCES),
      is: MobileRunsheetOccurrencesScheduleOccurrences,
    })
    addPreviewTab(MOBILE_RUNSHEET_OCCURRENCES, {
      title: createI18nResourceKey(
        MOBILE_RUNSHEET_OCCURRENCES,
        'printedRunsheetOccurrences',
      ),
      is: MobileRunsheetOccurrencesAuditLog,
      dataAnalytics: 'mobile-runsheet-occurrences:audit-log',
    })
  },
}
