export type SiteTaskFeatureFlags = {
  siteTaskFeatureFlags: {
    /**
     * Show the new SiteTask form + the "Instances" preview tab
     */
    enableSiteTaskEnhancement: boolean

    /**
     * Enable the calendar Exceptions UI
     */
    enableCalendarExceptions: boolean

    /**
     * Enable Periodic assignment UI
     */
    periodicAssignmentEnabled: boolean

    /**
     * Week day start day
     */
    weekDayStartSetting?: string
  }
}

export const siteTaskFeatureFlags: Record<
  'default',
  SiteTaskFeatureFlags['siteTaskFeatureFlags']
> = {
  default: {
    enableSiteTaskEnhancement: false,
    enableCalendarExceptions: false,
    periodicAssignmentEnabled: false,
  },
}

export type PeriodicSchedule = {
  id: number
  type: string
  quantity: number
  proRatedQuantity: number
  multipleVisits: boolean
  optionalService: boolean
}
