<template>
  <div class="flex--column">
    <ResourceAllowedOperations
      v-slot="{ allowsActions: appAllowsActions }"
      :resource-name="mainResource"
      class="flex--column"
    >
      <div v-if="appAllowsActions" class="flex--row align-center pa-3">
        <v-expand-transition>
          <div v-if="isEditMode && !canAdd">
            <v-icon small class="red--text mr-2" v-text="'mdi-alert-circle'" />
            <span
              class="caption red--text"
              v-text="$t('tt-entity-design.roles.cannot-add-roles')"
            />
          </div>
          <span
            v-else-if="isEditMode"
            class="caption green--text"
            v-text="$t('tt-entity-design.operation-centers.changes-saved')"
          />
        </v-expand-transition>
      </div>

      <v-expand-transition>
        <div v-if="isEditMode" class="d-flex flex-column level0">
          <div class="flex--row align-center pa-3">
            <EntitySelectorField
              v-model="entityIdToAdd"
              :resource="selectorResource"
              class="w-100"
              :query-options="addQueryOptions"
              :disabled="!canAdd"
              rounded
              :label="browsePermissionPoliciesLabel"
              hide-details
            />

            <v-btn
              large
              icon
              color="success"
              class="add--button mx-1"
              elevation="0"
              :disabled="!entityIdToAdd || !canAdd"
              :loading="isLoading"
              @click="runAdd"
            >
              <v-icon> mdi-shield-plus </v-icon>
            </v-btn>
          </div>
        </div>
      </v-expand-transition>

      <v-divider />

      <WidgetFactory
        class="h-100"
        :widget="widget"
        skip-validation
        @update:hook="hook = $event"
      />
    </ResourceAllowedOperations>
  </div>
</template>

<script lang="ts">
import Vue, { VueConstructor } from 'vue'
import { CollectionQuery, ListWidgetModel } from '@/tt-widget-components/types'
import { ItemHookProvider } from '@/tt-widget-entity-flow/types'
import {
  ActionProperty,
  Action,
  Attribute,
  CustomFilter,
} from '@/tt-entity-design/src/schema-types'

import { Resources } from '@/tt-entity-design/src/types'
import { updateDOM } from '@/helpers/dom/updateDOM'
import ListWidgetHook from '@/tt-widget-components/widgets/List/ListWidgetHook'
import { LayoutWindowEvent } from '@/tt-app-layout'
import RoleManagedPermissionPoliciesRelationListTile from './RoleManagedPermissionPoliciesRelationListTile.vue'
import { EntityIntentTypes } from '@/tt-widget-entity-flow'

const MAIN_RESOURCE = Resources.ROLES
type MainResource = typeof MAIN_RESOURCE

const RELATION_RESOURCE = Resources.MANAGED_PERMISSION_POLICIES
type RelationResource = typeof RELATION_RESOURCE

const RELATION_LIST_ATTR: Attribute<MainResource> =
  'managedPermissionPoliciesAssignments'
const ADD_ACTION: Action<MainResource> = 'add-managed-permission-policy'
const REMOVE_ACTION: Action<MainResource> = 'remove-managed-permission-policy'

type AddActionProperty = ActionProperty<MainResource, typeof ADD_ACTION>

type DeleteProperty = ActionProperty<MainResource, typeof REMOVE_ACTION>

const ROLE_PORTAL_ATTR: Attribute<MainResource> = 'portal'

export default (Vue as VueConstructor<Vue & ItemHookProvider>).extend({
  name: 'RoleManagedPermissionPoliciesRelationList',
  inject: ['getItemHook'],
  props: {
    isEditMode: {
      type: Boolean,
      default: true,
    },
  },
  data: () => ({
    hook: null as ListWidgetHook | null,
    isLoading: false,
    entityIdToAdd: null,
  }),
  computed: {
    mainResource(): string {
      return MAIN_RESOURCE
    },
    selectorResource(): string {
      return RELATION_RESOURCE
    },
    browsePermissionPoliciesLabel(): string {
      return this.canAdd
        ? this.$t('tt-entity-design.roles.browse-managed-permission-policies')
        : this.$t(
            'tt-entity-design.roles.no-permission-to-add-permission-policies',
          )
    },
    canAdd(): boolean {
      return this.getItemHook().isActionAvailable(ADD_ACTION)
    },
    canRemove(): boolean {
      return this.getItemHook().isActionAvailable(REMOVE_ACTION)
    },
    addQueryOptions(): Partial<CollectionQuery> {
      const policyPortal: Attribute<RelationResource> = 'portal'
      const excludeAlreadyAssigned: CustomFilter<RelationResource> =
        'excludeAlreadyAssignedToRole'

      return {
        returnCount: false,
        filters: [
          {
            attribute: policyPortal,
            operator: 'EQUAL',
            value: this.getItemHook().getRawValue(ROLE_PORTAL_ATTR),
          },
        ],
        customFilters: [
          {
            filterName: excludeAlreadyAssigned,
            value: this.mainEntityId,
          },
        ],
      }
    },
    mainEntityId(): number {
      return this.getItemHook().getEntityId()
    },
    widget(): ListWidgetModel {
      return {
        is: 'ListWidget',
        title: '',
        allowActions: true,
        disableResourcePreviewOnClick: true,
        listItem: {
          // @ts-ignore
          is: RoleManagedPermissionPoliciesRelationListTile,
          props: {
            // @ts-ignore
            showDeleteButton: () => this.isEditMode,
            // @ts-ignore
            onDelete: (id) => this.runDelete(id),
            // @ts-ignore
            canDelete: () => this.canRemove,
            // @ts-ignore
            onClick: (id) => {
              this.$eventManager.dispatchEvent(EntityIntentTypes.PREVIEW, {
                resourceName: RELATION_RESOURCE,
                entityId: id,
                hasToAddPages: true,
              })
            },
          },
        },
        query: {
          resource: MAIN_RESOURCE,
          relationListResource: {
            resource: MAIN_RESOURCE,
            id: this.mainEntityId,
            attribute: RELATION_LIST_ATTR,
          },
          sort: [
            {
              attribute: 'updatedOn',
              direction: 'DESC',
            },
          ],
        },
        toolbar: {
          show: false,
        },
      }
    },
  },
  created() {
    this.getItemHook().addAttribute(ROLE_PORTAL_ATTR)
    this.getItemHook().needActions()
  },
  methods: {
    async runAdd() {
      this.isLoading = true
      await updateDOM()

      const payload: Record<AddActionProperty, number> = {
        managedPermissionPolicy: this.entityIdToAdd,
      }

      await this.$auth
        .getApi()
        .doAction(MAIN_RESOURCE, this.mainEntityId, ADD_ACTION, payload)
        .then(async () => {
          await this.afterUpdate()

          this.entityIdToAdd = null
        })
        .catch((error) => {
          this.$eventManager.dispatchEvent(LayoutWindowEvent.SNACK_ERROR, {
            message: error.message,
          })

          this.isLoading = false
        })
    },
    async runDelete(entityIdToRemove: number) {
      await updateDOM()

      const payload: Record<DeleteProperty, number> = {
        managedPermissionPolicy: entityIdToRemove,
      }

      await this.$auth
        .getApi()
        .doAction(MAIN_RESOURCE, this.mainEntityId, REMOVE_ACTION, payload)
        .then(async () => {
          await this.afterUpdate()
        })
        .catch((error) => {
          this.$eventManager.dispatchEvent(LayoutWindowEvent.SNACK_ERROR, {
            message: error.message,
          })
        })
    },
    async afterUpdate() {
      this.$eventManager.dispatchEvent(EntityIntentTypes.RESOURCE_UPDATED, {
        resource: MAIN_RESOURCE,
      })

      await this.hook?.update({ disableCache: true })
      this.isLoading = false
    },
  },
})
</script>
