import { StatusColorType } from '@/tt-ui/types'
import { UiChipColor } from '@/tt-widget-views/leave-management/types'

export enum LeaveRequestsStatus {
  PENDING = 'PENDING',
  APPROVED = 'APPROVED',
  DECLINED = 'DECLINED',
  CANCELLED = 'CANCELLED',
  PENDING_CANCELLATION = 'PENDING_CANCELLATION',
}

export type SupportedActions =
  | 'approve'
  | 'approve-cancellation'
  | 'cancel'
  | 'decline'
  | 'decline-cancellation'
  | 'request-cancellation'

export type Shift = {
  id: string
  startDateTime: Date
  endDateTime: Date
  employee?: {
    id: number
    avatar: string
    name: string
  }
  position?: {
    name: string
    account: { name: string }
  }
  onShiftBoard: boolean
  vacant: boolean
}

export type LeaveRequest = {
  id: string
  startDateTime: Date
  endDateTime: Date
}

export type CalendarPills = Record<string, string>

export type ConflictInfo = {
  amount: number
  resource: string
  color: StatusColorType
  text: string
  displayItem: boolean
  conflicts: Shift[] | LeaveRequest[]
}

export type LeavePolicy = {
  id: number
  endPeriodDate: Date
  startPeriodDate: Date
}

export const LeaveRequestsStatusColorOptions: Record<
  LeaveRequestsStatus,
  UiChipColor
> = {
  [LeaveRequestsStatus.APPROVED]: {
    background: 'bg-green1',
    text: 'bg-text-green1',
  },
  [LeaveRequestsStatus.PENDING]: {
    background: 'bg-indigo1',
    text: 'bg-text-indigo1',
  },
  [LeaveRequestsStatus.PENDING_CANCELLATION]: {
    background: 'bg-orange1',
    text: 'bg-text-orange1',
  },
  [LeaveRequestsStatus.CANCELLED]: {
    background: 'bg-orange1',
    text: 'bg-text-orange1',
  },
  [LeaveRequestsStatus.DECLINED]: {
    background: 'bg-red1',
    text: 'bg-text-red1',
  },
}

export const actionFormTitleOptions: Record<SupportedActions, string> = {
  approve:
    'tt-entity-design.leave-management.leave-requests.approve-form-title',
  'approve-cancellation':
    'tt-entity-design.leave-management.leave-requests.approve-cancellation-form-title',
  cancel: 'tt-entity-design.leave-management.leave-requests.cancel-form-title',
  decline:
    'tt-entity-design.leave-management.leave-requests.decline-form-title',
  'decline-cancellation':
    'tt-entity-design.leave-management.leave-requests.decline-cancellation-form-title',
  'request-cancellation':
    'tt-entity-design.leave-management.leave-requests.request-cancellation-form-title',
}
