/**
 * These routes are rendered within the <AppLayoutRouter /> component.
 */
export enum Routes {
  HOME = 'home',
  DASHBOARD = 'dashboard',
  ASSETS = 'assets',
  ASSET_SCAN = 'asset-scan',
  ASSET_TYPES = 'asset-types',
  ASSET_SERIAL_SEARCH = 'asset-serial-search',
  CATEGORIES = 'categories',
  MANUFACTURERS = 'manufacturers',
  SUPPLIERS = 'suppliers',
  REASONS = 'reasons',
  PERSONS = 'person',
  CUSTOM_ATTRIBUTES = 'custom-attributes',
  FIELDSET = 'fieldsets',
  BATCH_IMPORT = 'batch-import',
  NOTIFICATIONS = 'notifications',
  LATE_ASSETS = 'late-assets',
  ASSET_INVENTORY_REPORT = 'asset-inventory-report',
  ASSET_RESERVATIONS = 'asset-reservations',
  IMPORT_WIZARD = 'import-wizard',
}

/**
 * Custom routes are not rendered within the <AppLayoutRouter /> component.
 */
export enum CustomRoutes {
  CONTEXT_ACCOUNT_SELECTION = '/account-context',
}
