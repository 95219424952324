// ******** This file is autogenerated DO NOT MODIFY **********

import { modularManager, PresetTypes, PresetLevels } from '@/tt-app-modular'
import { Resources } from '../../types'
import custom from './custom'
import ManagedPermissionPoliciesTile from './ManagedPermissionPoliciesTile.vue'
import ManagedPermissionPoliciesColumns from './ManagedPermissionPoliciesColumns'
import ManagedPermissionPoliciesDetail from './ManagedPermissionPoliciesDetail.vue'
import ManagedPermissionPoliciesMetaCard from './ManagedPermissionPoliciesMetaCard.vue'
import ManagedPermissionPoliciesReference from './ManagedPermissionPoliciesReference.vue'

export default {
  install(Vue) {
    Vue.use(custom)
    Vue.component(
      'ManagedPermissionPoliciesTile',
      ManagedPermissionPoliciesTile,
    )
    Vue.component(
      'ManagedPermissionPoliciesDetail',
      ManagedPermissionPoliciesDetail,
    )
    Vue.component(
      'ManagedPermissionPoliciesMetaCard',
      ManagedPermissionPoliciesMetaCard,
    )
    Vue.component(
      'ManagedPermissionPoliciesReference',
      ManagedPermissionPoliciesReference,
    )

    modularManager.addResourcePreset(
      Resources.MANAGED_PERMISSION_POLICIES,
      PresetTypes.LIST_ITEM,
      {
        title: 'Default',
        data: { is: 'ManagedPermissionPoliciesTile' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.MANAGED_PERMISSION_POLICIES,
      PresetTypes.COLUMNS,
      {
        title: 'Default',
        data: ManagedPermissionPoliciesColumns,
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.MANAGED_PERMISSION_POLICIES,
      PresetTypes.DETAILS_CARD,
      {
        title: 'Default',
        data: { is: 'ManagedPermissionPoliciesDetail' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.MANAGED_PERMISSION_POLICIES,
      PresetTypes.META_CARD,
      {
        title: 'Default',
        data: { is: 'ManagedPermissionPoliciesMetaCard' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.MANAGED_PERMISSION_POLICIES,
      PresetTypes.RELATION_REFERENCE,
      {
        title: 'Default',
        data: { is: 'ManagedPermissionPoliciesReference' },
        level: PresetLevels.DEFAULTS,
      },
    )
  },
}
