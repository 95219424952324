<template>
  <div>
    <v-alert
      v-if="hasLeaveForRequestedShift"
      type="warning"
      color="orange darken-1"
    >
      {{ createLeaveRequestMessage }}
    </v-alert>
    <json-field name="note" />
    <json-field name="acknowledge" />
    <json-field name="notify" />
  </div>
</template>
<script lang="ts">
import Vue from 'vue'
import { LeaveRequestsStatus } from '@/tt-widget-views/leave-management/types'
import { Resources } from '../../types'
import { FilterOperatorType } from '@/tt-widget-factory/definitions'

export default Vue.extend({
  name: 'OpenShiftRequestsApproveForm',
  props: {
    employeeId: {
      type: Number,
      required: true,
    },
    employeeName: {
      type: String,
      required: true,
    },
    shiftStartDate: {
      type: String,
      required: true,
    },
    shiftEndDate: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      hasLeaveForRequestedShift: false,
    }
  },
  computed: {
    createLeaveRequestMessage(): string {
      return this.$t(
        'tt-entity-design.open-shift-requests.leave-request-warning',
        { name: this.employeeName },
      )
    },
  },
  created() {
    this.fetchShiftsLeaveRequests()
  },
  methods: {
    async fetchShiftsLeaveRequests(): Promise<void> {
      const filters = [
        {
          attribute: 'startDateTime',
          operator: FilterOperatorType.BEFORE,
          value: this.shiftEndDate,
        },
        {
          attribute: 'endDateTime',
          operator: FilterOperatorType.AFTER,
          value: this.shiftStartDate,
        },
        {
          attribute: 'employee',
          operator: FilterOperatorType.EQUAL,
          value: this.employeeId,
        },
        {
          attribute: 'status',
          operator: FilterOperatorType.EQUAL,
          value: LeaveRequestsStatus.PENDING,
        },
      ]

      await this.$appContext.widgetServices.resourceDataManager
        .getCollection({
          resource: Resources.LEAVE_REQUESTS,
          filters,
        })
        .then(({ total }) => {
          this.hasLeaveForRequestedShift = !!total
        })
    },
  },
})
</script>
