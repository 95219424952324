import { LeaveRequestsDatesManager } from '@/tt-entity-design/src/components/leave-requests/LeaveRequestsDatesManager'
import { CustomFilter } from '@/tt-widget-factory'
import { LeaveRequestInjectedOrigin } from '../types'

export const createLeaveRequestFilterByPeriod = (
  startDateTime: string,
  endDateTime: string,
): CustomFilter => {
  const formattedStartDate =
    LeaveRequestsDatesManager.formatStartDate(startDateTime)
  const formattedEndDate = LeaveRequestsDatesManager.formatEndDate(endDateTime)

  return {
    filterName: 'byPeriod',
    value: `${formattedStartDate}|${formattedEndDate}`,
  }
}

export const isInjectedInPayroll = (origin: string): boolean => {
  return origin === LeaveRequestInjectedOrigin.PAYROLL
}
