<template>
  <v-menu v-if="otherWorkspaces.length" bottom :nudge-bottom="dropdownNudge">
    <template #activator="{ on: onMenu }">
      <div data-analytics="core:workspace-launcher:button" class="h-100 w-100">
        <TopBarButton
          icon="mdi-apps"
          text="extensions.workspace.shortLabel"
          v-on="onMenu"
        >
          <slot />
        </TopBarButton>
      </div>
    </template>

    <v-card width="400">
      <v-row no-gutters>
        <v-col>
          <v-subheader>
            <span v-text="$t(`extensions.workspace.label`)" />
          </v-subheader>
        </v-col>
        <v-col v-if="currentWorkspace" class="text-right mt-3 mr-3">
          <span class="caption mr-2">
            <span v-text="$t(`extensions.workspace.current`)" />
          </span>
          <v-chip> <span v-text="currentWorkspace.name" /> </v-chip>
        </v-col>
      </v-row>

      <v-list>
        <template v-for="item in items">
          <div :key="item.id">
            <v-divider />
            <WorkspaceLauncherTile :item="item" />
          </div>
        </template>
      </v-list>
    </v-card>
  </v-menu>
</template>

<script lang="ts">
import Vue from 'vue'

import { WorkSpace } from '@/tt-app-extensions/workspace/type'
import { DROPDOWN_APP_BAR_NUDGE } from '@/helpers/constants'

import WorkspaceLauncherTile from './WorkspaceLauncherTile.vue'
import TopBarButton from '@/tt-app-layout/components/TopBarButton.vue'

export default Vue.extend({
  name: 'WorkspaceLauncherExtension',
  components: {
    WorkspaceLauncherTile,
    TopBarButton,
  },
  computed: {
    current(): string {
      return this.$store.state.appConfig.id
    },
    otherWorkspaces(): WorkSpace[] {
      return this.$auth
        .getSession()
        .profile.scopes.workspaces.filter((item) => item.id !== this.current)
    },
    currentWorkspace(): WorkSpace {
      return this.$auth
        .getSession()
        .profile.scopes.workspaces.find((item) => item.id === this.current)
    },
    items(): WorkSpace[] {
      return this.$auth
        .getSession()
        .profile.scopes.workspaces.filter((item) =>
          item.id === this.current ? false : true,
        )
    },
    dropdownNudge(): number {
      return DROPDOWN_APP_BAR_NUDGE
    },
  },
})
</script>
<style>
.workspace-launcher-tile-current {
  border: 1px solid #ccc;
}
</style>
