<template>
  <div class="flex--column h-100">
    <div class="d-flex flex-column">
      <div class="flex--row align-start pa-3">
        <v-tooltip :open-delay="0" top>
          <template #activator="{ on }">
            <v-btn
              icon
              class="mr-2"
              :color="addAsBillingNote ? 'warning' : 'ttPrimary'"
              :class="{ 'toggle--active': addAsBillingNote }"
              depressed
              v-on="on"
              @click="addAsBillingNote = !addAsBillingNote"
            >
              <v-scroll-x-transition hide-on-leave>
                <v-icon v-if="addAsBillingNote" v-text="`mdi-currency-usd`" />
              </v-scroll-x-transition>
              <v-scroll-x-reverse-transition hide-on-leave>
                <v-icon v-if="!addAsBillingNote" v-text="`mdi-comment`" />
              </v-scroll-x-reverse-transition>
            </v-btn>
          </template>
          <span v-text="tooltipLabel" />
        </v-tooltip>

        <v-textarea
          v-model="newNoteText"
          filled
          rounded
          rows="1"
          auto-grow
          :placeholder="$t('tt-entity-design.dispatch-tasks.add-note')"
          hide-details
        />
        <v-btn
          icon
          color="ttPrimary"
          :disabled="!newNoteText"
          :loading="isSubmitting"
          @click="sendNewNote"
        >
          <v-icon>mdi-send</v-icon>
        </v-btn>
      </div>

      <v-expand-transition>
        <div v-show="newNoteText" class="flex--row align-center">
          <v-switch
            v-model="addAsBillingNote"
            color="warning"
            hide-details
            class="pt-0 mt-0 pl-3 overline"
          />
          <span
            class="overline opacity-blended"
            style="font-size: 1em"
            v-text="$t('tt-entity-design.dispatch-tasks.add-as-billing-note')"
          />

          <div v-if="!addAsBillingNote" class="sentToAllDiv">
            <v-tooltip top>
              <template #activator="{ on }">
                <div v-on="on">
                  <v-switch
                    v-model="sendToAll"
                    color="blue"
                    hide-details
                    class="pt-0 mt-0 pl-3 overline"
                  />
                </div>
              </template>
              <span
                v-text="$t('tt-entity-design.dispatch-tasks.send-to-all')"
              />
            </v-tooltip>

            <span
              class="overline opacity-blended"
              style="font-size: 1em"
              v-text="$t('tt-entity-design.dispatch-tasks.send-to-all')"
            />
          </div>
        </div>
      </v-expand-transition>
    </div>

    <v-divider />

    <WidgetFactory
      class="dispatch-tasks--notes h-100"
      :widget="widget"
      skip-validation
    />
  </div>
</template>

<script lang="ts">
import Vue, { VueConstructor } from 'vue'
import { updateDOM } from '@tracktik/tt-helpers/lib/browser/updateDOM'
import { ListWidgetModel } from '@/tt-widget-components'
import { ItemHookProvider } from '@/tt-widget-entity-flow/types'
import { createI18nResourceKey } from '@/tt-widget-entity-flow/ResourceTranslator'
import { AddNoteProperty, SentFromEnum } from './types'
import {
  DISPATCH_TASKS,
  COMMENTS,
  NOTES,
  ADD_NOTE,
  CREATED_ON,
} from './constants'
import CommentsNotesTile from '../../comments/CommentsNotesTile.vue'

export default (Vue as VueConstructor<Vue & ItemHookProvider>).extend({
  name: 'DispatchTaskNotesListWidget',
  inject: ['getItemHook'],
  data: () => ({
    newNoteText: '',
    addAsBillingNote: false,
    isSubmitting: false,
    sendToAll: true,
  }),
  computed: {
    addNotePayload(): Record<AddNoteProperty, unknown> {
      return {
        note: this.newNoteText,
        showOnInvoiceAndBillingReport: this.addAsBillingNote,
        sendToAll: !this.addAsBillingNote && this.sendToAll,
        sentFrom: SentFromEnum.OPERATOR,
      }
    },
    dispatchTaskId(): number {
      return this.getItemHook().getEntityId()
    },
    tooltipLabel(): string {
      return this.addAsBillingNote
        ? this.$t('tt-entity-design.dispatch-tasks.billing-note')
        : this.$t('tt-entity-design.dispatch-tasks.comment')
    },
    widget(): ListWidgetModel {
      return {
        is: 'ListWidget',
        title: createI18nResourceKey(DISPATCH_TASKS, NOTES),
        disableResourcePreviewOnClick: true,
        listItem: {
          // @ts-ignore
          is: CommentsNotesTile,
          props: {
            showSentFrom: true,
          },
        },
        query: {
          resource: COMMENTS,
          relationListResource: {
            resource: DISPATCH_TASKS,
            id: this.dispatchTaskId,
            attribute: NOTES,
          },
          sort: [
            {
              attribute: CREATED_ON,
              direction: 'DESC',
            },
          ],
        },
        toolbar: {
          show: false,
        },
      }
    },
  },
  methods: {
    async sendNewNote() {
      this.isSubmitting = true
      await updateDOM()

      await this.$appContext.entityServices.persister.executeEntityAction(
        DISPATCH_TASKS,
        ADD_NOTE,
        this.dispatchTaskId,
        this.addNotePayload,
      )

      this.isSubmitting = false
    },
  },
})
</script>

<style scoped>
/* removes border added by List Widget */
.dispatch-tasks--notes >>> .list-item-div {
  border: 0 !important;
}

/* removes the separator */
.dispatch-tasks--notes >>> .list-item-div > hr {
  display: none !important;
}

/* hide widget title at the top */
.dispatch-tasks--notes >>> .widget-title {
  display: none !important;
}

.dispatch-tasks--notes {
  padding-top: 1em;
}

.v-textarea >>> .v-input__slot {
  min-height: 0 !important;
}

.v-textarea >>> textarea {
  margin-top: 4px !important;
}

.sentToAllDiv {
  display: flex;
  align-items: center;
}
</style>
