import { reactive } from 'vue'
import i18n from '@/plugins/i18n'

import { Resources } from '../../../types'
import {
  EntityActionIntent,
  EntityActionIntentInterface,
} from '@/tt-widget-entity-flow'
import { LayoutWindowEvent, DialogFormInterface } from '@/tt-app-layout'
import { AppContext } from '@/tt-app-context'
import { Action } from '@/tt-widget-factory/services/resource-action/types'

export type OverrideFrenquencyServiceType = {
  isAvailableAction: (id: number) => Promise<boolean>
  openOverrideFrenquencyForm: (
    employeeId: number,
    dispatchTaskId?: number,
  ) => void
  getIsLoading: () => boolean
}

type OverrideFrenquencyServiceState = {
  isLoading: boolean
}

const SET_LONE_WORKER_FREQUENCY = 'set-loneworker-frequency'

export const overrideFrenquencyService = (
  appContext: AppContext,
): OverrideFrenquencyServiceType => {
  const state = reactive<OverrideFrenquencyServiceState>({
    isLoading: false,
  })

  const getIsLoading = (): boolean => state.isLoading

  const getActionFormState = async (
    employeeId: number,
    dispatchTaskId?: number,
  ): Promise<DialogFormInterface> => {
    state.isLoading = true

    const formConfig: EntityActionIntentInterface = {
      resourceName: Resources.EMPLOYEES,
      entityId: employeeId,
      actionName: SET_LONE_WORKER_FREQUENCY,
      title: i18n.t(
        'tt-entity-design.employees.lone-worker-fields.action-name',
      ),

      initialModel: dispatchTaskId
        ? {
            dispatchTaskId: dispatchTaskId,
          }
        : null,
    }
    const builder = await new EntityActionIntent(
      appContext,
    ).getDialogFormBuilder(formConfig)

    state.isLoading = false

    return builder.getState()
  }

  const openOverrideFrenquencyForm = async (
    employeeId: number,
    dispatchTaskId?: number,
  ): Promise<void> => {
    const payload = await getActionFormState(employeeId, dispatchTaskId)

    appContext.eventManager.dispatchEvent(
      LayoutWindowEvent.SIDE_SHEET_ADD_PAGE,
      {
        is: 'GenericFormPage',
        props: { payload },
      },
    )
  }

  const fetchAvailableActions = async (id: number): Promise<string[]> => {
    if (!id) {
      return []
    }

    state.isLoading = true

    return appContext.widgetServices.resourceActionManager
      .getEntityActions(Resources.EMPLOYEES, id)
      .then((actions: Action[]) => {
        return actions.map((a) => a.actionName)
      })
      .catch((err) => {
        console.error('Could not fetch entity actions:', err)

        return []
      })
      .finally(() => {
        state.isLoading = false
      })
  }

  const isAvailableAction = async (employeeId: number): Promise<boolean> => {
    const availableActions = await fetchAvailableActions(employeeId)

    return availableActions.includes(SET_LONE_WORKER_FREQUENCY)
  }

  return {
    isAvailableAction,
    openOverrideFrenquencyForm,
    getIsLoading,
  }
}
