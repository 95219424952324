import Vue from 'vue'
import { RouteConfig } from 'vue-router'

import { AuthModuleFactoryParams } from '@tracktik/tt-authentication'
import { ObservabilityVue } from '@tracktik/tt-observability'

import { ActionMenuBuilder } from '@/tt-app-layout/ActionMenuBuilder'
import {
  AllowEntityActionsOption,
  AllowEntityOption,
  defaultInstallOptions,
  InstallOptions,
  InstallSetupCallback,
} from '@/types'
import { LayoutConfiguration } from '@/tt-app-layout'
import { Resources } from '@/tt-entity-design/src/types'
import { WidgetModels } from '@/tt-widget-components'

import { AppConfig } from './types'
import {
  CollectionQueryPartial,
  createListRoute,
  createWidgetRoute,
} from './helpers'

/**
 * App builder
 */
export class AppConfigBuilder {
  // Routes that are in the application layout
  private routes: RouteConfig[] = []
  private customRoutes: RouteConfig[] = []
  public navigation: ActionMenuBuilder
  public layout: LayoutConfiguration
  private readonly appName: string
  private readonly appId: string
  private readonly o11n: ObservabilityVue.ObservabilityVueConfig // o11n stand for Observability
  private readonly auth: AuthModuleFactoryParams
  public installOptions: InstallOptions
  private onSetupCallback: InstallSetupCallback
  private dataAnalytics: string
  /**
   * App name
   *
   * @param appName
   */
  constructor(options: {
    appName: string
    appId: string
    o11n?: ObservabilityVue.ObservabilityVueConfig
    auth?: AuthModuleFactoryParams
    allowEntityEdit?: AllowEntityOption
    allowEntityCreation?: AllowEntityOption
    allowEntityActions?: AllowEntityActionsOption
    layout?: LayoutConfiguration
    dataAnalytics: string
  }) {
    this.appName = options.appName
    this.appId = options.appId
    this.navigation = new ActionMenuBuilder()
    this.installOptions = defaultInstallOptions
    this.dataAnalytics = options.dataAnalytics

    const allowEntityEdit = options.allowEntityEdit
    const allowEntityCreation = options.allowEntityCreation
    const allowEntityActions = options.allowEntityActions

    this.installOptions = {
      ...defaultInstallOptions,
      allowEntityEdit,
      allowEntityCreation,
      allowEntityActions,
    }
    this.o11n = options.o11n
    this.auth = options.auth

    const navigation = options.layout?.navigation || []
    const topBar = options.layout?.topBar || { hidden: false }
    const sideMenu = options.layout?.sideMenu || {
      hidden: false,
    }

    this.layout = Vue.observable({
      ...options.layout,
      navigation,
      topBar,
      sideMenu,
    })
  }

  registerResourceListRoute(
    routeName: string,
    resource: Resources,
    query: CollectionQueryPartial = {},
    props: any = {},
  ) {
    this.registerRoute(createListRoute(routeName, resource, query, props))
  }

  registerWidgetViewRoute(
    routeName: string,
    widget: WidgetModels,
    props: any = {},
  ) {
    this.registerRoute(createWidgetRoute(routeName, widget, props))
  }

  /**
   * Regular routes are SERVED in the application layout
   * @param routes
   */
  registerRoutes(routes: RouteConfig[]) {
    this.routes = [...this.routes, ...routes]
  }

  /**
   * Custom routes are NOT in the application layout
   * @param routes
   */
  registerCustomRoutes(routes: RouteConfig[]) {
    this.customRoutes = [...this.customRoutes, ...routes]
  }

  /**
   * Route component
   *
   * @param routeComponent
   */
  registerRoute(routeComponent: RouteConfig) {
    this.routes.push(routeComponent)
  }

  toApp(): AppConfig {
    return {
      id: this.appId,
      name: this.appName,
      routes: this.routes,
      onSetup: this.onSetupCallback,
      customRoutes: this.customRoutes,
      installOptions: this.installOptions,
      o11n: this.o11n,
      auth: this.auth,
      layout: {
        ...this.layout,
        navigation: this.navigation.toNavigation(),
      },
      dataAnalytics: this.dataAnalytics,
    }
  }

  onSetup(setup: InstallSetupCallback) {
    this.onSetupCallback = setup
  }
}
