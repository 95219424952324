<template>
  <v-list>
    <v-list-group v-for="(types, group) in groupedTypes" :key="group" multiple>
      <template #activator>
        <v-list-item-content>
          <v-list-item-title>
            <v-icon class="pr-2" v-text="getGroupView(group).icon" />
            <v-badge
              :value="getSelectedInGroupCount(group)"
              color="ttPrimary"
              :content="getSelectedInGroupCount(group)"
              inline
              class="mt-0"
            >
              {{ $t(getGroupView(group).i18nKey) }}
            </v-badge>
          </v-list-item-title>
        </v-list-item-content>
      </template>

      <v-list-item
        v-for="typeName in types"
        :key="typeName"
        @click="toggle(typeName)"
      >
        <v-list-item-action>
          <v-simple-checkbox
            :value="isSelected(typeName)"
            color="ttPrimary"
            @click="toggle(typeName)"
          />
        </v-list-item-action>

        <v-list-item-content>
          <TicketTypesChip
            :ticket-type="typeName"
            :resource="resource"
            attributes-name="type"
          />
        </v-list-item-content>
      </v-list-item>
    </v-list-group>
  </v-list>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue'
import { FilterOperatorType } from 'tracktik-sdk/lib/common/entity-filters'

import type { EnumAttributeFilterOptions } from '@/tt-widget-components/schemas-types'

import {
  groupCtaTypes,
  TYPE_GROUP_MAP,
  CTAType,
  GroupedCtaTypes,
  getGroupTypeView,
  CtaGroupsEnum,
  GroupTypeView,
} from './index'

import { SYSTEM_EXCEPTION_TICKETS } from '../constants'
import { Filter } from '@/tt-widget-components/schemas-types'
import TicketTypesChip from './TicketTypesChip.vue'

export default Vue.extend({
  name: 'CustomCtaTypeFilter',
  components: {
    TicketTypesChip,
  },
  props: {
    value: {
      type: Object as PropType<Filter>,
      required: true,
    },
    options: {
      type: Object as PropType<EnumAttributeFilterOptions>,
      default: () => ({} as EnumAttributeFilterOptions),
    },
  },
  computed: {
    selected(): CTAType[] {
      const rawValue = this.value.value

      if (!rawValue) {
        return []
      }

      return (
        typeof rawValue === 'string' ? rawValue.split(',') : rawValue
      ) as CTAType[]
    },
    groupedTypes(): GroupedCtaTypes {
      const isNotExcluded = (value: string) =>
        !this.options.exclude?.includes(value)

      const types = Object.keys(TYPE_GROUP_MAP).filter(
        isNotExcluded,
      ) as CTAType[]

      return groupCtaTypes(types)
    },
    resource(): string {
      return SYSTEM_EXCEPTION_TICKETS
    },
  },
  methods: {
    toggle(type: CTAType) {
      const selectionWithoutType = this.selected.filter(
        (selected) => selected !== type,
      )

      const newSelected = this.selected.includes(type)
        ? selectionWithoutType
        : [...selectionWithoutType, type]

      const newFilter: Filter = {
        attribute: this.value.attribute,
        operator: FilterOperatorType.IN,
        value: newSelected,
      }

      this.$emit('input', newFilter)
    },
    isSelected(item: CTAType) {
      return this.selected.includes(item)
    },
    getGroupView(group: CtaGroupsEnum): GroupTypeView {
      return getGroupTypeView(group)
    },
    getSelectedInGroupCount(group: CtaGroupsEnum) {
      return this.selected.filter((type) => TYPE_GROUP_MAP[type] === group)
        .length
    },
  },
})
</script>

<style scoped>
.v-list >>> .v-list-group__items {
  border-left: 2px solid var(--v-ttPrimary-base);
}
</style>
