<template>
  <div class="flex--column">
    <v-row>
      <v-col>
        <TimeField v-model="startTime" :rules="rules" :label="startLabel" />
      </v-col>

      <v-col>
        <TimeField v-model="endTime" :rules="rules" :label="endLabel" />
      </v-col>
    </v-row>

    <v-row>
      <v-spacer />
      <v-btn
        color="success"
        class="pa-3"
        :disabled="disableSaveButton"
        raised
        :loading="loading"
        @click="saveTimings"
      >
        {{ $t('common.save.btn') }}
      </v-btn>
    </v-row>
  </div>
</template>

<script lang="ts">
import Vue from 'vue'
import TimeField from '@/tt-entity-forms/components/TimeField.vue'
import { createI18nResourceKey } from '@/tt-widget-entity-flow/ResourceTranslator'
import { Resources } from '@/tt-entity-design/src/types'
import { createSiteTaskScheduleTimingsHelper } from '@/tt-widget-views/site-tasks/helpers/site-task-schedule-timings'
import { isEmpty } from '@/helpers/isEmpty'

import {
  displayErrorMessages,
  parseErrorMessages,
} from '@/tt-widget-entity-flow/intents/helpers'

export default Vue.extend({
  name: 'SiteTaskScheduleTimingsNonRecurrent',
  components: {
    TimeField,
  },
  props: {
    groupId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      startTime: '',
      endTime: '',
      loading: false,
      apiHelper: createSiteTaskScheduleTimingsHelper(this.$auth),
    }
  },
  computed: {
    disableSaveButton(): boolean {
      return isEmpty(this.startTime)
    },
    startLabel(): string {
      return this.$t(
        createI18nResourceKey(
          Resources.SITE_TASK_SCHEDULE_TIMINGS,
          'rangeStartTime',
        ),
      ) as string
    },
    endLabel(): string {
      return this.$t(
        createI18nResourceKey(
          Resources.SITE_TASK_SCHEDULE_TIMINGS,
          'rangeEndTime',
        ),
      ) as string
    },
    rules(): ((value: string) => string | boolean)[] {
      return [
        (value: string) => !isEmpty(value) || this.$t('common.required_field'),
      ]
    },
  },
  async created() {
    this.loading = true
    const timings = await this.fetchTimings()
    this.startTime = timings[0]?.['rangeStartTime'] || ''
    this.endTime = timings[0]?.['rangeEndTime'] || ''
    this.loading = false
  },
  methods: {
    async fetchTimings(): Promise<any[]> {
      const timings = await this.apiHelper.fetchSiteTaskScheduleTimings(
        this.groupId,
      )

      if (timings.length > 1)
        console.warn(
          'More than one timing found for a non recurrent site-task',
          this.groupId,
          timings,
        )

      return timings
    },
    async saveTimings() {
      this.loading = true

      try {
        await this.apiHelper.saveSingleSiteTaskScheduleTiming(this.groupId, {
          rangeStartTime: this.startTime,
          rangeEndTime: this.endTime,
        })
        this.$emit('submit:success')
      } catch (error) {
        this.$crash.captureException(error)
        const messages = parseErrorMessages({
          error,
          resourceName: Resources.SITE_TASK_SCHEDULE_TIMINGS,
        })
        displayErrorMessages(messages, this.$eventManager)
        this.$emit('submit:error')
      } finally {
        this.loading = false
      }
    },
  },
})
</script>
