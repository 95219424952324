import { cloneDeep } from 'lodash'

import { FilterOperatorType } from 'tracktik-sdk/lib/common/entity-filters'

import { FilterSetting } from '@/tt-entity-filter/types'
import { FormLabelTypes } from '@tracktik/tt-json-schema-form'

import i18n from '@/plugins/i18n'
import { AttributeName } from '@/tt-widget-components/lib/names'
import { Filter, FilterWithValue } from '@/tt-widget-components'
import {
  FieldTypes,
  RelationFilter,
  RelationTypes,
  ResourceMetaProviderInterface,
} from '@/tt-widget-factory/services/resource-meta/types'
import { getIconByAttributeName } from '@/tt-widget-components/lib/icons'
import { isEmpty } from '@/helpers/isEmpty'

const { DATE_RANGE_ATTRIBUTE, REGION_ATTRIBUTE, ACCOUNT_ATTRIBUTE } =
  AttributeName

export const BETWEEN_SEPARATOR_NUMBER = ', '

type NullOperator =
  | typeof FilterOperatorType.ISNOTNULL
  | typeof FilterOperatorType.ISNULL

export const isNullOperatorType = (operator): operator is NullOperator => {
  return [FilterOperatorType.ISNULL, FilterOperatorType.ISNOTNULL].includes(
    operator,
  )
}

export const isFilterValid = (filter: Filter): boolean =>
  isNullOperatorType(filter.operator) || !isEmpty(filter.value)

export const getFilterLabel = (label: string, parsedValue: string): string =>
  `${label}${parsedValue ? ` ${parsedValue}` : ''}`

export const getFilterName = (
  filterValue: string,
  filterParsedValue: string,
  resourceMetaManager: ResourceMetaProviderInterface,
  translateFunction: (key: string) => string,
  attributeName: string,
  resource?: string,
) => {
  if (!attributeName || !resource) return filterValue
  const text = resourceMetaManager
    .getAttributePathLabelKeys(resource, attributeName)
    .map((key: string) => {
      return translateFunction(key)
    })
    .join(' · ')

  return getFilterLabel(text, filterParsedValue)
}

export const getFilterSetting = (
  resourceMetaManager,
  resource: string,
  filter: Filter,
): FilterSetting => {
  const attributeName = filter.attribute
  // Get the attribute meta
  const attributeMeta = cloneDeep(
    resourceMetaManager.getAttribute(resource, attributeName),
  )
  // Set the translated label
  attributeMeta.labels.label = i18n.t(
    resourceMetaManager.getAttributeLabelKey(
      resource,
      attributeName,
      FormLabelTypes.LABEL,
    ),
  )

  return {
    attributeName,
    attributeMeta,
    filter: filter,
  }
}

/**
 * Create one of the three context FilterSetting object (Region, Account, DateRange)
 */
export const createContextFilterSetting = (
  name: AttributeName,
  relationFilter?: RelationFilter,
): FilterSetting => {
  const operations = {
    patch: false,
    post: false,
    put: false,
  }

  if (name === DATE_RANGE_ATTRIBUTE) {
    return {
      attributeName: name,
      component: null,
      icon: getIconByAttributeName(name).icon,
      attributeMeta: {
        labels: {
          label: i18n.tc('components.widget_context_toolbar.filter.date_range'),
        },
        rawType: 'string',
        operations,
        type: FieldTypes.TimeStampDate,
        name,
      },
      filter: {
        attribute: DATE_RANGE_ATTRIBUTE,
        operator: 'EQUAL',
      },
      // @todo: remove assertion
    } as FilterSetting
  }

  if (name === REGION_ATTRIBUTE) {
    return {
      icon: getIconByAttributeName(name).icon,
      attributeName: name,
      component: null,
      attributeMeta: {
        labels: {
          label: i18n.tc('components.widget_context_toolbar.filter.region'),
        },
        rawType: 'integer',
        operations,
        type: FieldTypes.Relation,
        relation: {
          resource: 'regions',
          type: RelationTypes.BelongsTo,
          embed: false,
          ...(relationFilter ? { relationFilter } : {}),
        },
        name,
      },
      filter: {
        attribute: name,
        operator: 'EQUAL',
      },
      // @todo: remove assertion
    } as FilterSetting
  }

  if (name === ACCOUNT_ATTRIBUTE) {
    return {
      icon: getIconByAttributeName(name).icon,
      attributeName: name,
      component: null,
      attributeMeta: {
        labels: {
          label: i18n.tc('components.widget_context_toolbar.filter.account'),
        },
        rawType: 'integer',
        operations,
        type: FieldTypes.Relation,
        relation: {
          resource: 'accounts',
          type: RelationTypes.BelongsTo,
          embed: false,
          ...(relationFilter ? { relationFilter } : {}),
        },
        name,
      },
      filter: {
        attribute: name,
        operator: 'EQUAL',
      },
      // @todo: remove assertion
    } as FilterSetting
  }
}

export const createFilter = (
  attribute: string,
  operator: Filter['operator'],
  value?: FilterWithValue['value'],
): Filter =>
  operator !== 'ISNULL' && operator !== 'ISNOTNULL'
    ? {
        attribute,
        operator,
        value: value,
      }
    : { attribute, operator }
