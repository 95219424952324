<template>
  <v-autocomplete
    v-model="selectedTaskType"
    multiple
    dense
    outlined
    item-text="name"
    item-value="name"
    :value="value"
    :label="label"
    :items="structuredTypes"
    :disabled="disabled"
  >
    <template #selection="{ item }">
      <div class="ma-1">
        <TicketTypesChip
          :ticket-type="item.name"
          :resource="resource"
          attributes-name="type"
          close
          @click:close="remove(item)"
        />
      </div>
    </template>
    <template #item="{ item, on }">
      <v-list-item link :disabled="item.disabled" @change="on.click">
        <v-list-item-action>
          <v-simple-checkbox
            :value="isSelected(item.name)"
            color="ttPrimary"
            :disabled="item.disabled"
            @click="on.click"
          />
        </v-list-item-action>
        <v-list-item-content>
          <div class="d-flex">
            <TicketTypesChip
              :ticket-type="item.name"
              :resource="resource"
              attributes-name="type"
            />
          </div>
        </v-list-item-content>
      </v-list-item>
    </template>
  </v-autocomplete>
</template>

<script lang="ts">
import Vue, { PropType, VueConstructor } from 'vue'

import { FormHook } from '@tracktik/tt-json-schema-form'
import { Resources } from '@/tt-entity-design/src/types'
import { createI18nEnumKey } from '@/tt-widget-entity-flow/ResourceTranslator'
import {
  groupCtaTypes,
  CTAType,
  getGroupTypeView,
  CtaGroupsEnum,
  GroupTypeView,
} from '@/tt-entity-design/src/components/system-exception-tickets/cta-types/index'
import TicketTypesChip from '@/tt-entity-design/src/components/system-exception-tickets/cta-types/TicketTypesChip.vue'

export default (
  Vue as VueConstructor<Vue & { formHook: () => FormHook }>
).extend({
  name: 'TicketTypesField',
  components: {
    TicketTypesChip,
  },
  inject: ['formHook'],
  props: {
    name: { type: String, required: true },
    label: { type: String, required: true },
    value: { type: Array as PropType<string[]>, default: () => [] },
    disabledValues: { type: Array as PropType<string[]>, default: () => [] },
    disabled: { type: Boolean, default: false },
  },
  computed: {
    selectedTaskType: {
      get(): string[] | null {
        return this.value
      },
      set(newTaskType: string[]) {
        if (!newTaskType.length) {
          this.$emit('input', null)
        } else {
          this.$emit('input', newTaskType)
        }
      },
    },
    itemField(): { is: string; props: Record<string, any> } {
      return this.formHook().getViewComponentProps(this.name).itemField
    },
    items(): CTAType[] {
      return this.itemField.props.items.map((item) => item.value)
    },
    resource(): string {
      return Resources.SYSTEM_EXCEPTION_TICKETS
    },
    structuredTypes(): Array<{
      header?: string
      name?: string
      group?: string
      disabled?: boolean
      divider?: boolean
    }> {
      const groupedTypes = groupCtaTypes(this.items)

      return Object.entries(groupedTypes).reduce(
        (acc, [group, types], index, array) =>
          acc.concat(
            [
              {
                header: this.$t(
                  getGroupTypeView(group as CtaGroupsEnum).i18nKey,
                ) as string,
              },
            ],
            types.map((type) => ({
              name: type,
              group,
              disabled: this.disabledValues.includes(type),
            })),
            index < array.length - 1 ? [{ divider: true }] : [],
          ),
        [] as Array<{
          header?: string
          name?: string
          group?: string
          disabled?: boolean
          divider?: boolean
        }>,
      )
    },
  },
  methods: {
    isSelected(item: CTAType) {
      return this.value?.includes(item)
    },
    getGroupView(group: CtaGroupsEnum): GroupTypeView {
      return getGroupTypeView(group)
    },
    localizeType(type: CTAType) {
      return this.$t(
        createI18nEnumKey(Resources.SYSTEM_EXCEPTION_TICKETS, 'type', type),
      )
    },
    remove(item: { name: string }) {
      const value = [...this.value]
      const types = value.filter((type) => type !== item.name)
      if (!types.length) {
        this.$emit('input', null)
      } else {
        this.$emit('input', types)
      }
    },
  },
})
</script>
