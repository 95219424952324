import { vuetifyDefaults } from '@tracktik/tt-json-schema-form'

export const definitionNames = [
  'Boolean',
  // 'CSV',
  'Currency',
  'CurrencyRate',
  'Date',
  'DateTime',
  'Decimal',
  'DrawCanvas',
  'Email',
  'Enum',
  'FileImage',
  // 'GeoPosition',
  // 'GeoRegion',
  // 'GeoCountry',
  // 'GeoPostalCode',
  'Hours',
  // 'Image',
  'Integer',
  // 'JSON',
  'Label',
  'Minutes',
  'Number',
  'Password',
  'Percentage',
  'PhoneNumber',
  'Relation',
  'RelationList',
  'ReportFlag',
  'SiteLocation',
  'Signature',
  'SortIndex',
  'TextArea',
  'TextAreaNullable',
  'Time',
  'TimeStampNumber',
  'TimeStampDate',
  'TimeZone',
  'Uri',
  // 'Barcode'
  // 'BarcodeNullable',
  // 'BooleanNullable',
  'DateNullable',
  'DateTimeNullable',
  'DrawCanvasNullable',
  'FileImageNullable',
  // 'LabelNullable',
  'RelationNullable',
  'ReportFlagNullable',
  'SiteLocationNullable',
  'SectionDivider',
  'SignatureNullable',
  'TimeNullable',
] as const

export const convertPhpLocale = (locale: string) => locale.replace(/_/g, '-')

export const createViewWithProps = (props: Record<string, any>) => ({
  view: {
    props,
  },
})
export const createDate = ({
  locale,
  userDateFormat,
}: {
  locale: string
  userDateFormat: string
}) => ({
  view: {
    is: 'DateField',
    props: {
      ...vuetifyDefaults,
      locale,
      userDateFormat,
    },
  },
})

export const createDateTime = ({
  withTimeZone,
}: {
  withTimeZone: boolean
}) => ({
  view: {
    is: 'DateTimeFieldWrapper',
    props: {
      ...vuetifyDefaults,
      withTimeZone,
    },
  },
})

export const createTime = ({ userTimeFormat }: { userTimeFormat: string }) => ({
  view: {
    is: 'TimeField',
    props: {
      ...vuetifyDefaults,
      userTimeFormat,
    },
  },
})

export const DrawCanvas = {
  view: {
    is: 'DrawCanvasField',
  },
}

export const FileImage = {
  view: {
    is: 'ImageField',
  },
}

export const Relation = {
  view: {
    is: 'RelationField',
    props: { ...vuetifyDefaults },
  },
}

export const ReportFlag = {
  view: {
    is: 'ReportFlagForm',
  },
}
export const SiteLocation = {
  view: {
    is: 'SiteLocationForm',
  },
}
