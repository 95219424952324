import { modularManager } from '@/tt-app-modular'
import { OPERATION_CENTERS } from '@/tt-entity-design/src/components/operation-centers/constants'
import { addPreviewTab } from '@/tt-widget-entity-flow/helper'
import { createI18nResourceKey } from '@/tt-widget-entity-flow/ResourceTranslator'
import OperationCentersEmployees from './OperationCentersEmployees.vue'
import OperationCentersRegions from './OperationCentersRegions.vue'
import OperationCentersActionBar from '@/tt-entity-design/src/components/operation-centers/OperationCentersActionBar.vue'
import OperationCentersEscalationPoliciesAttr from './OperationCentersEscalationPoliciesAttr.vue'
import OperationCentersOperatorsAttr from './OperationCentersOperatorsAttr.vue'
import OperationCentersRegionsAttr from './OperationCentersRegionsAttr.vue'
import OperationCentersPresentOperatorAttr from './OperationCentersPresentOperatorAttr.vue'
import PoliciesRelationListPreviewWrapper from './PoliciesRelationListPreviewWrapper.vue'
import { getAttr } from './utils'

export default {
  install(Vue) {
    Vue.component(
      'OperationCentersEscalationPoliciesAttr',
      OperationCentersEscalationPoliciesAttr,
    )
    Vue.component(
      'OperationCentersOperatorsAttr',
      OperationCentersOperatorsAttr,
    )
    Vue.component('OperationCentersRegionsAttr', OperationCentersRegionsAttr)
    Vue.component(
      'OperationCentersPresentOperatorAttr',
      OperationCentersPresentOperatorAttr,
    )

    addPreviewTab(OPERATION_CENTERS, {
      title: createI18nResourceKey(OPERATION_CENTERS, getAttr('operators')),
      is: OperationCentersEmployees,
    })

    addPreviewTab(OPERATION_CENTERS, {
      title: createI18nResourceKey(OPERATION_CENTERS, getAttr('regions')),
      is: OperationCentersRegions,
    })

    addPreviewTab(OPERATION_CENTERS, {
      title: createI18nResourceKey(OPERATION_CENTERS, getAttr('policies')),
      is: PoliciesRelationListPreviewWrapper,
    })

    /**
     * To hide the default list of actions.
     * @TODO: find better way
     */
    modularManager.registerActionBar(OPERATION_CENTERS, {
      is: OperationCentersActionBar,
    })
  },
}
