<template>
  <v-card flat class="dispatch-location-form--container">
    <v-divider />
    <DispatchTasksFormLocationFields
      class="px-4 py-6 dispatch-location-form--fields-wrapper"
      @input:new-client="newClientInformation = $event"
      @input:set-position="setPosition"
    />
    <v-divider />
    <v-toolbar flat text>
      <v-spacer />
      <v-btn
        color="grey"
        class="mr-3"
        outlined
        raised
        @click="cancelCustomLocation"
      >
        <span v-text="$t('common.cancel.btn')" />
      </v-btn>
      <v-btn
        class="mr-3"
        color="ttPrimary"
        outlined
        :disabled="disabledButton"
        @click="setCustomLocation"
      >
        <v-icon small v-text="'mdi-account-outline'" />
        <span
          class="px-2"
          v-text="
            $t('tt-entity-design.dispatch-tasks.form-button.set-location')
          "
        />
      </v-btn>
    </v-toolbar>
  </v-card>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue'

import DispatchTasksFormLocationFields from './DispatchTasksFormLocationFields.vue'
import { DispatchTaskFormServiceType } from './types'
import { FormHook } from '@tracktik/tt-json-schema-form'
import { filterErrors } from '@/helpers/form-errors-manager'
import { ErrorObject } from '@tracktik/tt-json-schema-form'
import { LocationType, NewClientInterface } from '../types'
import { Geocoding } from '@tracktik/tt-geo-proxy'

type DispatchTasksFormProvider = {
  dispatchFormService: DispatchTaskFormServiceType
  formHook: () => FormHook
  namespace: string
}

export default Vue.extend({
  name: 'DispatchTasksFormLocationPage',
  components: {
    DispatchTasksFormLocationFields,
  },
  provide(): DispatchTasksFormProvider {
    return {
      dispatchFormService: this.dispatchFormService,
      formHook: this.formHook,
      namespace: this.namespace,
    }
  },
  props: {
    formHook: {
      type: Function as PropType<() => FormHook>,
      required: true,
    },
    dispatchFormService: {
      type: Object as PropType<DispatchTaskFormServiceType>,
      required: true,
    },
    namespace: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      newClientInformation: {} as NewClientInterface,
    }
  },
  computed: {
    taskId(): number | null {
      return this.formHook().getPathValue('taskType')
    },
    disabledButton(): boolean {
      const mainFormErrors: Partial<Record<string, ErrorObject[]>> =
        this.formHook().errors ?? {}
      const filteredErrors: Partial<Record<string, ErrorObject[]>> =
        filterErrors(
          this.dispatchFormService.getErrorsListWhiteListed(),
          mainFormErrors,
        ) ?? {}
      const hasLocationError = (error) => error.includes('location')

      return Object.keys(filteredErrors).some(hasLocationError)
    },
    newClient(): NewClientInterface {
      const address = {
        addressLine1: this.formHook().getPathValue('location.addressLine1'),
        addressLine2: this.formHook().getPathValue('location.addressLine2'),
        city: this.formHook().getPathValue('location.city'),
        state: this.formHook().getPathValue('location.state'),
        postalCode: this.formHook().getPathValue('location.postalCode'),
        country: this.formHook().getPathValue('location.country'),
      }

      return {
        address,
        company: this.formHook().getPathValue('location.name'),
        region: this.formHook().getPathValue('location.region'),
        type: 'NEW_ACCOUNT',
        ...this.newClientInformation,
      }
    },
    isCustomLocation(): boolean {
      return this.dispatchFormService.getIsCustomLocation()
    },
    isEditForm(): boolean {
      return this.dispatchFormService.getIsEditForm()
    },
    hasAssignedUser(): boolean {
      return !!this.formHook().getPathValue('assignedUser')
    },
    hasAssignedGroup(): boolean {
      return !!this.formHook().getPathValue('assignedGroup')
    },
  },
  created() {
    this.dispatchFormService.setIsSavedLocation(false)

    if (!this.isEditForm) {
      this.dispatchFormService.setIsCustomLocation(false)
      this.formHook().setObjectValue('location', null)
    }
  },
  beforeDestroy() {
    if (!this.isCustomLocation) {
      this.formHook().setObjectValue(
        'locationType',
        LocationType.ACCOUNT_ADDRESS,
      )
      this.formHook().setObjectValue('location', null)
      this.formHook().setCustomError('location.region', null)
    }
  },
  methods: {
    clearAssignment() {
      this.dispatchFormService.setAssignment(null)
      if (this.hasAssignedUser) {
        this.formHook().setObjectValue('assignedUser', null)
      }
      if (this.hasAssignedGroup) {
        this.formHook().setObjectValue('assignedGroup', null)
      }
    },
    cancelCustomLocation(): void {
      // If we are not in edit mode, we want to reset the location when we cancel
      if (!this.isEditForm) {
        this.dispatchFormService.setIsCustomLocation(false)
      }
      this.dispatchFormService.setIsSavedLocation(false)
      this.$emit('back')
    },
    setCustomLocation(): void {
      this.dispatchFormService.setNewClient(this.newClient)
      this.dispatchFormService.setIsCustomLocation(true)
      // We want to reset assignment when we change the location
      this.clearAssignment()

      this.$emit('back')
    },
    setPosition(geocode: Geocoding): void {
      const { address, coordinates } = geocode

      this.formHook().setObjectValue('location', {
        ...this.formHook().getPathValue('location'),
        ...address,
        ...coordinates,
        dispatchTask: this.taskId,
      })
    },
  },
})
</script>
<style scoped>
.dispatch-location-form--container {
  overflow: hidden;
  height: 100%;
}
.dispatch-location-form--fields-wrapper {
  overflow: auto;
  height: calc(100% - 64px);
}
</style>
