<template>
  <tt-attr-row-card>
    <tt-attr-row name="status">
      <SystemExceptionTicketsStatusAttr />
    </tt-attr-row>
    <SystemExceptionTicketsInstructionSnapshotAttr />
    <tt-attr-row name="comments" />
  </tt-attr-row-card>
</template>

<script lang="ts">
import Vue from 'vue'

import SystemExceptionTicketsInstructionSnapshotAttr from './SystemExceptionTicketsInstructionSnapshotAttr.vue'
import SystemExceptionTicketsStatusAttr from './SystemExceptionTicketsStatusAttr.vue'

export default Vue.extend({
  name: 'SystemExceptionTicketsDetail',
  components: {
    SystemExceptionTicketsInstructionSnapshotAttr,
    SystemExceptionTicketsStatusAttr,
  },
})
</script>
