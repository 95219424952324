import Vue from 'vue'
import { ExtensionInterface, LayoutExtensionSlots } from '@/tt-app-modular'

const DarkLightExtension = () => import('./components/DarkLightExtension.vue')

Vue.component('DarkLightExtension', DarkLightExtension)

export const DARK_LIGHT_EXTENSION = 'extension.dark-light.tracktik.com'

export default {
  name: DARK_LIGHT_EXTENSION,
  slots: [LayoutExtensionSlots.TOOLBAR_RIGHT],
  component: {
    is: 'DarkLightExtension',
  },
} as ExtensionInterface
