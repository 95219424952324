<template>
  <ActionBarPreset :actions="actionList" />
</template>

<script lang="ts">
import Vue, { PropType, VueConstructor } from 'vue'

import {
  ActiveWindowProvider,
  LayoutWindowEvent,
  PageInterface,
} from '@/tt-app-layout'
import {
  EntityItemHook,
  EntityItemViewInterface,
} from '@/tt-widget-entity-flow/EntityItemHook'
import { Resources } from '../../types'
import ActionBarPreset from '@/tt-widget-entity-flow/components/action-bar/ActionBarPreset.vue'
import { ViewAction } from '@/tt-widget-entity-flow/components/action-bar/ActionBarPreset'
import { Action } from '@/tt-widget-factory/services/resource-action/types'
import { createViewAction } from '../../tools/actions-generator'
import { getEditFormState } from '../../tools/helpers/form-state-generator'
import { TaskStatusEnum } from './types'
import { FieldErrorRule } from '@tracktik/tt-json-schema-form'
import { EntityEditIntentInterface } from '@/tt-widget-entity-flow'
// allow apps to override the action bar see FE-1626
import {
  BROWSER_CHANNEL_MAP,
  PREVIEW_ON_MAP,
} from '@/apps/app.tracktik.dispatch/browser-channel/constants'
import {
  DashboardToMapPayload,
  FocusOnMapData,
} from '@/apps/app.tracktik.dispatch/browser-channel/types'
import { createBrowserChannel } from '@tracktik/tt-browser-channel'
import { Routes } from '@/apps/app.tracktik.dispatch/enumRoutes'
import DispatchTasksAssignForm from './assign/DispatchTasksAssignForm.vue'
import { ASSIGN } from './constants'
import {
  OverrideFrenquencyServiceType,
  overrideFrenquencyService,
} from './lone-worker/override-lone-worker-frequency-service'

type VueWithInjections = VueConstructor<
  Vue & { getItemHook: () => EntityItemHook } & ActiveWindowProvider
>

export default (Vue as VueWithInjections).extend({
  name: 'DispatchTasksActionBar',
  components: {
    ActionBarPreset,
  },
  inject: ['activeWindow', 'getItemHook'],
  props: {
    actions: {
      type: Array as PropType<Action[]>,
      required: true,
    },
  },
  data() {
    return {
      isOverrideFrequencyActionAvailable: false,
    }
  },
  computed: {
    status(): string {
      return this.getItemHook().getRawValue('status')
    },
    reportId(): number {
      return this.getItemHook().getRawValue('report.id')
    },
    userId(): number {
      return this.getItemHook().getRawValue('assignedUser.id')
    },
    entityReference(): EntityItemViewInterface {
      return this.getItemHook().entityReference
    },
    overrideFrequencyService(): OverrideFrenquencyServiceType {
      return overrideFrenquencyService(this.$appContext)
    },
    actionsDictionnary(): Record<string, ViewAction> {
      const openFormAction = async (actionName: string) => {
        const payload = await this.getItemHook().getActionFormAsPage(actionName)
        if (actionName === ASSIGN) {
          const assignPayload: PageInterface = payload
          this.activeWindow.next({
            ...assignPayload,
            props: { ...assignPayload.props.payload },
            is: DispatchTasksAssignForm,
          })
        } else {
          this.activeWindow.next(payload)
        }
      }

      return {
        ['open']: createViewAction(
          'mdi-arrow-right-drop-circle',
          'open',
          Resources.DISPATCH_TASKS,
          openFormAction,
        ),
        ['close']: createViewAction(
          'mdi-close-thick',
          'close',
          Resources.DISPATCH_TASKS,
          openFormAction,
        ),
        ['assign']: createViewAction(
          'mdi-account-check',
          'assign',
          Resources.DISPATCH_TASKS,
          openFormAction,
        ),
      }
    },
    isShowOnMapActionDisabled(): boolean {
      return (
        !this.getItemHook().getRawValue('location.latitude') ||
        !this.getItemHook().getRawValue('location.longitude')
      )
    },

    actionList(): ViewAction[] {
      const availableActions: ViewAction[] = this.actions
        .map(({ actionName }) => {
          return this.actionsDictionnary[actionName] || (null as ViewAction)
        })
        .filter(Boolean)

      const editAction: ViewAction = {
        icon: 'mdi-pencil-outline',
        title: this.$t('common.edit.btn'),
        onClick: async () => this.openTasksForm(),
      }

      const viewReport: ViewAction = {
        icon: 'mdi-file-eye-outline',
        title: this.$t('tt-entity-design.view-report'),
        onClick: async () => this.openReport(),
      }

      const overrideFrequencyAction: ViewAction = this
        .isOverrideFrequencyActionAvailable
        ? {
            icon: 'mdi-clock-time-eight-outline',
            title: this.$t(
              'tt-entity-design.employees.lone-worker-fields.action-name',
            ),
            onClick: async () =>
              await this.overrideFrequencyService.openOverrideFrenquencyForm(
                this.userId,
                this.getItemHook().getEntityId(),
              ),
          }
        : null

      /**
       * Move the action bar out of the core, to the Command Center, and register from there.
       */
      const openOnMap: ViewAction = {
        icon: 'mdi-map-marker',
        title: this.$t('tt-entity-design.common.show-on-map'),
        disabled: this.isShowOnMapActionDisabled,
        onClick: async () => {
          const browserChannel = createBrowserChannel<DashboardToMapPayload>({
            channelName: BROWSER_CHANNEL_MAP,
          })

          const data: FocusOnMapData = {
            resourceName: Resources.DISPATCH_TASKS,
            uid: Resources.DISPATCH_TASKS,
            entityId: this.getItemHook().getEntityId(),
            location: {
              lat: this.getItemHook().getRawValue('location.latitude'),
              lng: this.getItemHook().getRawValue('location.longitude'),
            },
          }
          await browserChannel.send(PREVIEW_ON_MAP, data).catch((err) => {
            console.warn(
              "Failed to send message. Most probably you don't have the Command Center map opened in another tab.",
              err,
            )
            // if no map is opened, we open it in a new tab and pass data throw query params
            const encodedData = encodeURIComponent(JSON.stringify(data))
            window.open(`#${Routes.LIVE_MAP}?focusOnMapData=${encodedData}`)
          })

          browserChannel.destroy()
        },
      }

      return this.status === TaskStatusEnum.OPEN
        ? [
            editAction,
            ...availableActions,
            overrideFrequencyAction,
            viewReport,
            openOnMap,
          ].filter(Boolean)
        : availableActions
    },
  },
  watch: {
    async userId() {
      await this.checkOverrideFrequencyActionAvailability()
    },
  },
  async created() {
    this.getItemHook().addAttribute('status')
    this.getItemHook().addAttribute('report.id')
    this.getItemHook().addAttribute('location.latitude')
    this.getItemHook().addAttribute('location.longitude')
    this.getItemHook().addAttribute('assignedUser.id')
  },
  methods: {
    async checkOverrideFrequencyActionAvailability(): Promise<void> {
      this.isOverrideFrequencyActionAvailable =
        await this.overrideFrequencyService.isAvailableAction(this.userId)
    },
    async openTasksForm(): Promise<void> {
      const formConfig: EntityEditIntentInterface = {
        resourceName: Resources.DISPATCH_TASKS,
        title: '', //We want to hide default title
        entityId: this.getItemHook().getEntityId(),
        itemHook: this.getItemHook(),
        formOptions: {
          fieldErrorRule: FieldErrorRule.ALWAYS,
          requiredFieldLabelTransformer: null,
        },
      }

      const payload = await getEditFormState(this.$appContext, formConfig)

      this.$eventManager.dispatchEvent(LayoutWindowEvent.SIDE_SHEET_ADD_PAGE, {
        is: 'DispatchTasksForm',
        title: 'tt-entity-design.dispatch-tasks.editing-dispatch',
        props: { ...payload },
      })
    },
    async openReport() {
      this.$eventManager.dispatchEvent(LayoutWindowEvent.SIDE_SHEET_ADD_PAGE, {
        is: 'ViewReportsFields',
        title: 'tt-entity-design.report',
        props: {
          reportId: this.reportId,
          entityReference: this.entityReference,
        },
      })
    },
  },
})
</script>
