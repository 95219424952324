import groupBy from 'lodash/groupBy'
import isEmpty from 'lodash/isEmpty'
import isEqual from 'lodash/isEqual'
import pick from 'lodash/pick'
import sortBy from 'lodash/sortBy'

import {
  BatchFile,
  BatchFileActions,
  BatchFileOnFailureOptions,
  BatchFileOperation,
  BatchFileResponse,
} from '@/types'
import { EntityPersistRunner } from '@/tt-widget-entity-flow/EntityPersistRunner'
import {
  Field,
  FilterOperatorType,
  ResourceDataManagerInterface,
} from '@/tt-widget-factory'
import { Resources } from '@/tt-entity-design/src/types'
import { rootExceptionTypeId } from '@/tt-entity-design/src/components/exception-types/exception-types-parent'

import SchemaHelper from './schema-helper'
import {
  SchedulingEntityResource,
  SchedulingEntity,
  SchedulingExceptionTypeEntity,
  rangeTimeKeys,
  ExceptionData,
  SchedulingGroupEntity,
  ExceptionType,
  SchedulingGroupBatch,
  SchedulingEntityKeys,
  SchedulingExceptionType,
  SchedulingGroupType,
  PerformType,
  DayData,
  SchedulingData,
  REAL_DAYS,
} from '../types'
import { createSiteTaskScheduleTimingsHelper } from '@/tt-widget-views/site-tasks/helpers/site-task-schedule-timings'
import { TTC_API_MAX_LIMIT } from '@/tt-widget-components/constants'
import i18n from '@/plugins/i18n'
import Api from 'tracktik-sdk'
import { Filter } from '@/tt-widget-components'
import { filter, findKey, flatMap, some } from 'lodash'

const daysListArray = [
  'MONDAY',
  'TUESDAY',
  'WEDNESDAY',
  'THURSDAY',
  'FRIDAY',
  'SATURDAY',
  'SUNDAY',
]

function parseExceptionDays(exceptionDays: {
  items: ExceptionType[]
}): ExceptionType[] {
  const grouped = groupBy(exceptionDays.items, 'parent')

  const otherTypesChildrenIds = [
    rootExceptionTypeId.WeekdayBeforeHoliday,
    rootExceptionTypeId.WeekdayBeforeEve,
    rootExceptionTypeId.HolidayBeforeWeekDay,
    rootExceptionTypeId.FlagDay,
  ]
  const rootTypes = grouped['null'] ?? []

  //Get all the root types for the holidays with their sub types
  return sortBy(
    rootTypes
      .filter((type) => !otherTypesChildrenIds.includes(type.id))
      .map((exceptionRootType) => ({
        ...exceptionRootType,
        children: grouped[exceptionRootType.id],
      }))
      .filter((exceptionRootType) => !isEmpty(exceptionRootType.children)),
    [
      (exception) => {
        //Order the exception types so that Holiday and Holiday eves are together
        switch (exception.id) {
          case rootExceptionTypeId.Closed:
            return 1
          case rootExceptionTypeId.Holiday:
            return 2
          case rootExceptionTypeId.HolidayEve:
            return 3
          default:
            return 4
        }
      },
    ],
  )
}

function parseOthersExceptionDays(exceptionDays: { items: ExceptionType[] }): {
  grouped: Record<string, ExceptionType[]>
  mappedOthersExceptions: ExceptionType[]
} {
  const grouped = groupBy(exceptionDays.items, 'parent')
  const rootTypes = grouped['null'] ?? []
  // Dynamically filter othersExceptions using rootExceptionTypeId keys and values
  const idsToFilter = [
    rootExceptionTypeId.WeekdayBeforeHoliday,
    rootExceptionTypeId.WeekdayBeforeEve,
    rootExceptionTypeId.HolidayBeforeWeekDay,
  ]
  const othersExceptions = rootTypes.filter((item) =>
    idsToFilter.includes(item.id),
  )
  const mappedOthersExceptions = othersExceptions.map((exception) => {
    return { ...exception, parent: 0 }
  })

  return { grouped, mappedOthersExceptions }
}

function parseFlagDaysException(exceptionDays: {
  items: ExceptionType[]
}): ExceptionType[] {
  const grouped = groupBy(exceptionDays.items, 'parent')
  const rootTypes = grouped['null'] ?? []

  // Filter only Flag Day root type
  return rootTypes
    .filter((type) => type.id === rootExceptionTypeId.FlagDay)
    .map((flagDayRoot) => ({
      ...flagDayRoot,
      children: grouped[flagDayRoot.id] || [],
    }))
    .filter((flagDay) => !isEmpty(flagDay.children))
}
/**
 * Helper class to interact with "scheduling" resources.
 *
 * eg: Mobile Schedules, Mobile Runsheets, Site Task Schedules
 * @TODO: SEU-2090: Finish Site Task Schedules
 */
export default class SchedulingGroupApiHelper {
  private resource: SchedulingEntityResource
  private schemaHelper: SchemaHelper

  constructor(resource: SchedulingEntityResource) {
    this.resource = resource
    this.schemaHelper = new SchemaHelper(resource)
  }

  async fetchAccountExceptions(
    accountId: number,
    {
      resourceDataManager,
    }: { resourceDataManager: ResourceDataManagerInterface },
  ): Promise<ExceptionType[]> {
    const filterMap: Record<SchedulingEntityResource, string> = {
      [Resources.MOBILE_SCHEDULES]: 'forScheduleByAccountId',
      [Resources.MOBILE_RUNSHEETS]: 'forRunsheetByAccountId',
      [Resources.SITE_TASK_SCHEDULE_TIMINGS]: 'forSiteTaskByAccountId',
    }
    const filters = [
      {
        attribute: filterMap[this.resource],
        operator: FilterOperatorType.EQUAL,
        value: accountId,
      },
      //We no longer support closed days in the region level, see SEU-3525
      {
        attribute: 'excludeTreeById',
        operator: FilterOperatorType.EQUAL,
        value: rootExceptionTypeId.Closed,
      },
    ]
    const response = await resourceDataManager.getCollection(
      {
        resource: Resources.EXCEPTION_TYPES,
        filters,
        limit: TTC_API_MAX_LIMIT,
      },
      { disableCache: true },
    )

    return parseExceptionDays(response)
  }

  async fetchOthersExceptions(
    accountId: number,
    {
      resourceDataManager,
    }: { resourceDataManager: ResourceDataManagerInterface },
  ): Promise<{
    exceptionsList: ExceptionType[]
    grouped: Record<string, ExceptionType[]>
  }> {
    const filterMap: Record<SchedulingEntityResource, string> = {
      [Resources.MOBILE_SCHEDULES]: 'forScheduleByAccountId',
      [Resources.MOBILE_RUNSHEETS]: 'forRunsheetByAccountId',
      [Resources.SITE_TASK_SCHEDULE_TIMINGS]: 'forSiteTaskByAccountId',
    }
    const filters = [
      {
        attribute: filterMap[this.resource],
        operator: FilterOperatorType.EQUAL,
        value: accountId,
      },
      ...(this.resource === Resources.MOBILE_RUNSHEETS
        ? [
            {
              attribute: 'excludeTreeById',
              operator: FilterOperatorType.EQUAL,
              value: rootExceptionTypeId.Closed,
            },
          ]
        : []),
    ]
    const response = await resourceDataManager.getCollection(
      {
        resource: Resources.EXCEPTION_TYPES,
        filters,
        limit: TTC_API_MAX_LIMIT,
      },
      { disableCache: true },
    )
    // Using parseOthersExceptionDays to get both grouped data and mapped others exceptions
    const { grouped, mappedOthersExceptions } =
      parseOthersExceptionDays(response)

    const exceptionsList = [
      {
        label: i18n.t('scheduling_group.others.label'),
        parent: null,
        id: 0,
        children: mappedOthersExceptions,
      },
    ]

    return { exceptionsList, grouped }
  }

  async fetchFlagDaysExceptions(
    accountId: number,
    {
      resourceDataManager,
    }: { resourceDataManager: ResourceDataManagerInterface },
  ): Promise<ExceptionType[]> {
    const filterMap: Record<SchedulingEntityResource, string> = {
      [Resources.MOBILE_SCHEDULES]: 'forScheduleByAccountId',
      [Resources.MOBILE_RUNSHEETS]: 'forRunsheetByAccountId',
      [Resources.SITE_TASK_SCHEDULE_TIMINGS]: 'forSiteTaskByAccountId',
    }

    const filters = [
      {
        attribute: filterMap[this.resource],
        operator: FilterOperatorType.EQUAL,
        value: accountId,
      },
      //Only add the filter for the Closed days if the resource is Runsheets
      ...(this.resource === Resources.MOBILE_RUNSHEETS
        ? [
            {
              attribute: 'excludeTreeById',
              operator: FilterOperatorType.EQUAL,
              value: rootExceptionTypeId.Closed,
            },
          ]
        : []),
    ]

    const response = await resourceDataManager.getCollection(
      {
        resource: Resources.EXCEPTION_TYPES,
        filters,
        limit: TTC_API_MAX_LIMIT,
      },
      { disableCache: true },
    )

    return parseFlagDaysException(response)
  }

  fetchSchedulingExceptions = async (
    groupId: number,
    {
      resourceDataManager,
    }: { resourceDataManager: ResourceDataManagerInterface },
  ): Promise<SchedulingExceptionTypeEntity[]> => {
    // Exception type isn't included in the response by default
    const fieldsNames: (keyof SchedulingExceptionTypeEntity)[] = [
      'id',
      'exceptionType',
      'perform',
      'timeFrom',
      'timeTo',
    ]

    if (
      this.resource === Resources.MOBILE_SCHEDULES ||
      this.resource === Resources.MOBILE_RUNSHEETS ||
      this.resource === Resources.SITE_TASK_SCHEDULE_TIMINGS
    ) {
      fieldsNames.push(
        ...[
          'timeFromOverwrite',
          'timeToOverwrite',
          'performOverwrite',
          'exceptionTypeOverwrite',
        ],
      )
    }

    const fields = fieldsNames.map((attribute) => ({ attribute }))
    const filterMap: Record<SchedulingEntityResource, string> = {
      [Resources.MOBILE_RUNSHEETS]: 'runsheet.runsheetGroup',
      [Resources.MOBILE_SCHEDULES]: 'mobileSchedule.scheduleGroup',
      [Resources.SITE_TASK_SCHEDULE_TIMINGS]: 'schedule',
    }
    const filters = [
      {
        attribute: filterMap[this.resource],
        operator: FilterOperatorType.EQUAL,
        value: groupId,
      },
    ]
    const response = await resourceDataManager.getCollection(
      {
        resource: SchedulingExceptionType[this.resource],
        fields,
        filters,
        limit: TTC_API_MAX_LIMIT,
      },
      { disableCache: true },
    )

    return response.items as SchedulingEntity[]
  }

  fetchSchedulingExceptionsGrouped = async (
    groupId: number,
    {
      resourceDataManager,
    }: { resourceDataManager: ResourceDataManagerInterface },
  ): Promise<SchedulingExceptionTypeEntity[]> => {
    const items = await this.fetchSchedulingExceptions(groupId, {
      resourceDataManager,
    })
    /**
     * Group exceptions by type to get rid of "duplicates" of the same type
     */
    const exceptionsByType = groupBy(
      items as SchedulingExceptionTypeEntity[],
      'exceptionType',
    )
    const exception = Object.values(exceptionsByType).map((exceptions) => ({
      ...exceptions[0],
      performType: exceptions[0].perform,
    }))

    return exception
  }

  async fetchSchedulingEntityByGroup(
    groupId: number,
    {
      resourceDataManager,
    }: { resourceDataManager: ResourceDataManagerInterface },
    getAll = false,
  ): Promise<SchedulingEntity[]> {
    const filters: Filter[] = [
      {
        attribute: this.getGroupAttribute(),
        operator: FilterOperatorType.EQUAL,
        value: groupId,
      },
    ]

    if (
      !getAll &&
      (this.resource === Resources.MOBILE_SCHEDULES ||
        this.resource === Resources.MOBILE_RUNSHEETS)
    ) {
      filters.push({
        attribute: 'exceptionsOnly',
        operator: FilterOperatorType.EQUAL,
        value: false,
      })
    }

    const response = await resourceDataManager.getCollection(
      { resource: this.resource, filters, limit: TTC_API_MAX_LIMIT },
      { disableCache: true },
    )

    return response.items as SchedulingEntity[]
  }

  private getGroupEntityID(data: SchedulingGroupBatch): number {
    const keyMap: Record<SchedulingEntityResource, string> = {
      [Resources.MOBILE_SCHEDULES]: 'scheduleGroup',
      [Resources.MOBILE_RUNSHEETS]: 'runsheetGroup',
      [Resources.SITE_TASK_SCHEDULE_TIMINGS]: 'schedule', // to review
    }

    const groupAttribute = keyMap[this.resource]

    return data[groupAttribute] as number
  }

  async saveSchedulingExceptionsSchedule(
    {
      data,
      accountId,
    }: {
      data: SchedulingGroupBatch
      accountId: number
    },
    {
      persister,
      resourceDataManager,
    }: {
      persister: EntityPersistRunner
      resourceDataManager: ResourceDataManagerInterface
    },
  ): Promise<BatchFileResponse> {
    const [allHolidays, oldExceptions] = await Promise.all([
      this.fetchAccountExceptions(accountId, {
        resourceDataManager,
      }),
      this.fetchSchedulingExceptions(this.getGroupEntityID(data), {
        resourceDataManager,
      }),
    ])

    const newExceptionsData = data.holidays ?? {}
    const newExceptionsDataEntries = Object.entries(newExceptionsData)
    const shouldRemoveException = (exception: SchedulingExceptionTypeEntity) =>
      newExceptionsDataEntries.every(([exceptionType, data]) => {
        return (
          exception.exceptionType !==
            this.schemaHelper.parseExceptionKey(exceptionType) || isEmpty(data)
        )
      })
    const exceptionsToRemove = oldExceptions.filter(shouldRemoveException)

    if (this.resource === Resources.SITE_TASK_SCHEDULE_TIMINGS) {
      const closed = data.closed?.closed

      return createSiteTaskScheduleTimingsHelper(
        persister.authModule,
      ).saveSiteTaskScheduleExceptions(
        this.getGroupEntityID(data),
        newExceptionsData,
        exceptionsToRemove.map(({ id }) => id),
        closed,
      )
    } else {
      const exceptionsPayload = this.buildExceptionTypes({
        data,
        allHolidays,
        holidayData: newExceptionsData,
        removedHolidays: exceptionsToRemove,
        groupId: this.getGroupEntityID(data),
        resourceDataManager,
      })

      return persister.executeBatchFileRequest(exceptionsPayload)
    }
  }

  async saveOthersExceptionsDataSchedule(
    {
      data,
      accountId,
    }: {
      data: SchedulingGroupBatch
      accountId: number
    },
    {
      persister,
      resourceDataManager,
    }: {
      persister: EntityPersistRunner
      resourceDataManager: ResourceDataManagerInterface
    },
    schedulingItemIds: number[],
    initialOtherExceptionData: Record<string, ExceptionData>,
  ): Promise<BatchFileResponse> {
    const allOthersExceptions = await this.fetchOthersExceptions(accountId, {
      resourceDataManager,
    })
    const currentOtherExceptionData = data.others ?? {}

    // Check for removed "HolidayBeforeWeekDay" key
    const holidayBeforeWeekdayKey = `others-${rootExceptionTypeId.HolidayBeforeWeekDay}`
    const isHolidayBeforeWeekdayRemoved =
      holidayBeforeWeekdayKey in initialOtherExceptionData &&
      !(holidayBeforeWeekdayKey in currentOtherExceptionData)

    if (isHolidayBeforeWeekdayRemoved) {
      const groupId = this.getGroupEntityID(data)

      await this.removeHolidayBeforeWeekdayExceptions(groupId, { persister })
    }

    const missingKeys = Object.keys(initialOtherExceptionData).filter((key) => {
      const isKeyMissing = !(key in currentOtherExceptionData)
      const isExcludedKey = key === holidayBeforeWeekdayKey

      return isKeyMissing && !isExcludedKey
    })

    const exceptionsToRemoveIds = missingKeys
      .map((key) => {
        const parentId = key.split('-')[1]

        const exceptionsGroup = allOthersExceptions.grouped[parentId]
        if (exceptionsGroup) {
          return exceptionsGroup.map((exception) => exception.id)
        }

        return []
      })
      .flat()
    if (
      this.resource === Resources.MOBILE_SCHEDULES ||
      this.resource === Resources.MOBILE_RUNSHEETS ||
      Resources.SITE_TASK_SCHEDULE_TIMINGS
    ) {
      const exceptionsPayload = this.buildOthersExceptionsTypes({
        allOthersExceptions: allOthersExceptions.exceptionsList,
        othersData: currentOtherExceptionData,
        groupId: this.getGroupEntityID(data),
        removedOthersIds: exceptionsToRemoveIds,
        schedulingItemIds,
      })

      return persister.executeBatchFileRequest(exceptionsPayload)
    }
  }

  async saveSchedulingFlagDaysSchedule(
    {
      data,
      accountId,
    }: {
      data: SchedulingGroupBatch
      accountId: number
    },
    {
      persister,
      resourceDataManager,
    }: {
      persister: EntityPersistRunner
      resourceDataManager: ResourceDataManagerInterface
    },
  ): Promise<BatchFileResponse> {
    const groupId = this.getGroupEntityID(data)

    const [allFlagDays, existingExceptions] = await Promise.all([
      this.fetchFlagDaysExceptions(accountId, { resourceDataManager }),
      this.fetchSchedulingExceptions(groupId, { resourceDataManager }),
    ])

    // Determine existing flag day exceptions
    const childrenFlagDays = flatMap(allFlagDays, (flagDay) => flagDay.children)

    const existingFlagDayExceptions = filter(existingExceptions, (exception) =>
      some(childrenFlagDays, (child) => child.id === exception.exceptionType),
    )

    const payload = this.buildFlagDaysPayload({
      data,
      childrenFlagDays,
      groupId,
      existingFlagDayExceptions,
    })

    return persister.executeBatchFileRequest(payload)
  }

  async removeHolidayBeforeWeekdayExceptions(
    groupId: number,
    {
      persister,
    }: {
      persister: EntityPersistRunner
    },
  ): Promise<void> {
    const actionName = 'remove-holiday-before-weekday-exception-types'

    await persister.executeEntityAction(
      SchedulingGroupType[this.resource],
      actionName,
      groupId,
      {},
    )
  }

  private getDayOfWeekAttribute(): string {
    return SchedulingEntityKeys[this.resource].dayOfWeekStart
  }

  private getGroupAttribute(): string {
    return SchedulingEntityKeys[this.resource].groupName
  }

  /**
   * This function is used to add load relation list items to the scheduling group since
   * they are not returned by the API when saving or when fetching the group while editing
   * by the entity form it will return the original group with the properties ids in an array
   * e.g when requesting assets:
   * { id: 3, name: 'my group', assets: [1,2,3] }
   *
   * This is used to cascade down the relation list values to the schedules (assets, post orders)
   */
  async addExtraPropsToSchedulingGroup(
    group: SchedulingGroupEntity,
    propNames: string[],
    api: Api,
  ): Promise<SchedulingGroupEntity> {
    const propsToAdd = {}
    if (propNames?.length > 0) {
      const groupFields = await api.get(
        SchedulingGroupType[this.resource],
        group.id,
        {
          fields: propNames.map<Field>((name) => ({
            attribute: name,
          })),
        },
      )
      propNames.forEach((prop) => {
        if (groupFields[prop]) {
          propsToAdd[prop] = groupFields[prop].map(
            (value: { id: number }) => value.id,
          )
        }
      })
    }

    return { ...group, ...propsToAdd }
  }

  async saveItemsSchedules(
    data: SchedulingGroupBatch,
    {
      resourceDataManager,
      persister,
    }: {
      persister: EntityPersistRunner
      resourceDataManager: ResourceDataManagerInterface
    },
  ): Promise<BatchFileResponse> {
    /**
     * review and strongly type SchedulingGroupBatch
     */
    const groupId = data[this.getGroupAttribute()] as number

    /**
     * Site Task Schedule Timings does not have an "archive" action but a delete operation.
     * We need to handle this case separately.
     * @TODO: to review
     */
    if (this.resource === Resources.SITE_TASK_SCHEDULE_TIMINGS) {
      const payload = this.schemaHelper.getDaysData(data.days || {})

      return createSiteTaskScheduleTimingsHelper(
        persister.authModule,
      ).saveSiteTaskScheduleTimings(groupId, payload)
    } else {
      const oldItems = await this.fetchSchedulingEntityByGroup(groupId, {
        resourceDataManager,
      })

      const newItemDataEntries = Object.entries(data.days || {})
      const shouldArchiveItem = (entity: SchedulingEntity) =>
        newItemDataEntries.every(
          ([dayOfWeek, data]) =>
            entity[this.getDayOfWeekAttribute()] !== dayOfWeek || isEmpty(data),
        )

      const itemsToArchiveIds = oldItems
        .filter(shouldArchiveItem)
        .map(({ id }) => id)

      const batchOperation = this.schemaHelper.createBatchRequest(
        data,
        itemsToArchiveIds,
      )

      return persister.executeBatchFileRequest(batchOperation)
    }
  }

  private buildExceptionData(data: ExceptionData, holiday: ExceptionType) {
    if (data.performType === PerformType.DONT_PERFORM_TASK) {
      return {
        exceptionTypes: [holiday.id],
        performType: data.performType,
      }
    }

    return {
      ...data,
      exceptionTypes: [holiday.id],
    }
  }

  private buildExceptionTypes({
    data,
    allHolidays,
    groupId,
    holidayData,
    removedHolidays = [],
  }: {
    data: SchedulingGroupBatch
    allHolidays: ExceptionType[]
    groupId: number
    holidayData: Record<string, ExceptionData>
    resourceDataManager: ResourceDataManagerInterface
    removedHolidays?: SchedulingExceptionTypeEntity[]
  }): BatchFile {
    const children = allHolidays.map((holiday) => holiday.children).flat()

    const addOperations: BatchFileOperation[] = children.reduce(
      (holidayOperations, holiday) => {
        const data =
          holidayData[this.schemaHelper.getHolidayKey(holiday.id)] ||
          holidayData[this.schemaHelper.getHolidayKey(holiday.parent)]
        //Skip if the parent holiday is not set, and the holiday is not selected
        if (!data) {
          return holidayOperations
        }

        return [
          ...holidayOperations,
          {
            action: BatchFileActions.EXECUTE,
            actionName: 'add-exception-types',
            resource: SchedulingGroupType[this.resource],
            lookup: groupId,
            data: this.buildExceptionData(data, holiday),
          },
        ]
      },
      [],
    )

    const removeOperations = []
    if (removedHolidays.length > 0) {
      for (const { id } of removedHolidays) {
        removeOperations.push({
          resource: SchedulingExceptionType[this.resource],
          data: {},
          action: BatchFileActions.EXECUTE,
          actionName: 'remove',
          lookup: id,
        })
      }
    }

    let performOnException = []

    const { days: scheduleDays, holidays: _, ...scheduleData } = data

    const days = this.schemaHelper.getDaysData(
      (scheduleDays as Record<string, SchedulingData>) || {},
    )

    const hasPerformOnExceptionDay = children.some((holiday) => {
      const exceptionData =
        holidayData[this.schemaHelper.getHolidayKey(holiday.id)] ||
        holidayData[this.schemaHelper.getHolidayKey(holiday.parent)]

      if (!exceptionData) return false

      return exceptionData.performType === PerformType.PERFORM_ON_EXCEPTION_DAY
    })

    const hasOthersWithPerformOnExceptionDay =
      data.others &&
      Object.values(data.others).some(
        (other) => other.performType === PerformType.PERFORM_ON_EXCEPTION_DAY,
      )

    if (hasPerformOnExceptionDay || hasOthersWithPerformOnExceptionDay) {
      performOnException = this.buildPerformOnExceptionDay(days, scheduleData)
    }

    return {
      onFailure: BatchFileOnFailureOptions.ROLLBACK,
      operations: [
        ...removeOperations,
        ...performOnException,
        ...addOperations,
      ],
    }
  }

  private buildOthersExceptionsTypes({
    allOthersExceptions,
    groupId,
    othersData,
    removedOthersIds = [],
    schedulingItemIds = [],
  }: {
    groupId: number
    allOthersExceptions: ExceptionType[]
    othersData?: Record<string, ExceptionData>
    removedOthersIds?: number[]
    schedulingItemIds?: number[]
  }): BatchFile {
    const children = allOthersExceptions.map((other) => other.children).flat()
    const addOperations: BatchFileOperation[] = children.reduce(
      (othersOperations, child) => {
        const othersDataEntry =
          othersData[this.schemaHelper.getOthersKey(child.id)] ||
          othersData[this.schemaHelper.getOthersKey(child.parent)]

        if (!othersDataEntry) {
          return othersOperations
        }

        return [
          ...othersOperations,
          {
            action: BatchFileActions.EXECUTE,
            actionName: 'add-exception-types',
            resource: SchedulingGroupType[this.resource],
            lookup: groupId,
            data: {
              performType: othersDataEntry['performType'],
              exceptionTypes: [child.id],
              ...(!othersDataEntry[rangeTimeKeys.DO_NOT_PERFORM]
                ? {
                    [rangeTimeKeys.START_TIME_KEY]:
                      othersDataEntry[rangeTimeKeys.START_TIME_KEY],
                    [rangeTimeKeys.END_TIME_KEY]:
                      othersDataEntry[rangeTimeKeys.END_TIME_KEY],
                  }
                : {}),
            },
          },
        ]
      },
      [],
    )

    const lookupKeys: Record<SchedulingEntityResource, string> = {
      [Resources.MOBILE_RUNSHEETS]: 'runsheet',
      [Resources.MOBILE_SCHEDULES]: 'mobileSchedule',
      [Resources.SITE_TASK_SCHEDULE_TIMINGS]: 'schedule',
    }

    const removeOperations: BatchFileOperation[] = removedOthersIds
      .map((exceptionTypeId) =>
        schedulingItemIds.map((schedulingItemId) => ({
          resource: SchedulingExceptionType[this.resource],
          data: {},
          action: BatchFileActions.EXECUTE,
          actionName: 'remove',
          lookup: {
            [lookupKeys[this.resource]]: schedulingItemId,
            exceptionType: exceptionTypeId,
          },
        })),
      )
      .flat()

    return {
      onFailure: BatchFileOnFailureOptions.ROLLBACK,
      operations: [...addOperations, ...removeOperations],
    }
  }

  private buildFlagDaysAddOperationsPayload(
    childrenFlagDays,
    modelFlagDays,
    groupId,
  ): BatchFileOperation[] {
    return childrenFlagDays.reduce((flagDayOperations, flagDay) => {
      const data =
        modelFlagDays[this.schemaHelper.getFlagDaysKey(flagDay.id)] ||
        modelFlagDays[this.schemaHelper.getFlagDaysKey(flagDay.parent)]

      if (!data) {
        return flagDayOperations
      }

      return [
        ...flagDayOperations,
        {
          action: 'EXECUTE',
          actionName: 'add-flag-day-exception-types',
          resource: SchedulingGroupType[this.resource],
          lookup: groupId.toString(),
          data: {
            exceptionTypes: [flagDay.id],
            rangeStartTime: data[rangeTimeKeys.START_TIME_KEY],
            rangeEndTime: data[rangeTimeKeys.END_TIME_KEY],
          },
        },
      ]
    }, [])
  }

  private buildFlagDaysScheduleTemplate(
    data: SchedulingGroupBatch,
    groupId: number,
  ): BatchFileOperation[] {
    const baseData: Record<string, unknown> = {
      ...pick(data, [
        'client',
        'name',
        'durationMinutes',
        'taskType',
        'beginServiceDate',
        'endServiceDate',
        'beginServiceOn',
      ]),
      [this.getGroupAttribute()]: groupId,
      beginServiceOn: data.beginServiceOn,
      rangeStartTime: '00:00:00',
      rangeEndTime: '00:00:00',
      exceptionsOnly: true,
    }

    // day operations
    return daysListArray.map((day) => ({
      resource: this.resource,
      action: BatchFileActions.REPLACE,
      data: { ...baseData, dayOfWeekStart: day },
      lookup: { dayOfWeekStart: day, [this.getGroupAttribute()]: groupId },
    }))
  }

  private buildFlagDaysPayload({
    data,
    childrenFlagDays,
    groupId,
    existingFlagDayExceptions = [],
  }: {
    data: SchedulingGroupBatch
    childrenFlagDays: ExceptionType[]
    groupId: number
    existingFlagDayExceptions?: SchedulingExceptionTypeEntity[]
  }): BatchFile {
    const modelFlagDays = data.flagDays ?? {}

    const removeOperations: BatchFileOperation[] = []

    const addOperations = this.buildFlagDaysAddOperationsPayload(
      childrenFlagDays,
      modelFlagDays,
      groupId,
    )

    // only generates the days template if there is some flag day to add
    const dayOperations =
      addOperations.length > 0
        ? this.buildFlagDaysScheduleTemplate(data, groupId)
        : []

    const flagDayKeysInModel = Object.keys(modelFlagDays)
    // remove operation
    existingFlagDayExceptions.forEach((existingFlagDay) => {
      const isFlagDayInModel = flagDayKeysInModel.some(
        (key) =>
          this.schemaHelper.parseExceptionKey(key) ===
          existingFlagDay.exceptionType,
      )

      if (!isFlagDayInModel) {
        removeOperations.push({
          resource: SchedulingExceptionType[this.resource],
          data: {},
          action: BatchFileActions.EXECUTE,
          actionName: 'remove',
          lookup: existingFlagDay.id.toString(),
        })
      }
    })

    return {
      onFailure: BatchFileOnFailureOptions.ROLLBACK,
      operations: [...dayOperations, ...removeOperations, ...addOperations],
    }
  }

  buildPerformOnExceptionDay(
    days: DayData[],
    scheduleData: Record<string, unknown>,
  ) {
    const generalData: Record<string, unknown> = {
      ...scheduleData,
      rangeStartTime: '00:00:00',
      rangeEndTime: '00:00:00',
      exceptionsOnly: true,
    }

    const daysOfWeekAttribute = this.getDayOfWeekAttribute()
    const daysList = daysListArray.reduce((acc, day) => {
      return {
        ...acc,
        [day]: {
          resource: this.resource,
          action: BatchFileActions.REPLACE,
          data: {
            ...generalData,
            [daysOfWeekAttribute]: day,
          },
          lookup: {
            [daysOfWeekAttribute]: day,
            [this.getGroupAttribute()]: generalData[this.getGroupAttribute()],
          },
        },
      }
    }, {})

    days.forEach((day) => {
      delete daysList[day[daysOfWeekAttribute]]
    })

    return Object.values(daysList)
  }

  async resyncSchedulingItemFromGroupData(
    group: SchedulingGroupEntity,
    {
      persister,
      resourceDataManager,
    }: {
      persister: EntityPersistRunner
      resourceDataManager: ResourceDataManagerInterface
    },
  ): Promise<BatchFileResponse | null> {
    const items = await this.fetchSchedulingEntityByGroup(group.id, {
      resourceDataManager,
    })

    const attributesToUpdate: Record<
      SchedulingEntityResource,
      (keyof SchedulingGroupEntity)[]
    > = {
      [Resources.MOBILE_RUNSHEETS]: ['name', 'position'],
      [Resources.MOBILE_SCHEDULES]: ['customId'],
      [Resources.SITE_TASK_SCHEDULE_TIMINGS]: ['id'], // TODO: confirm with SEU-2060
    }
    const dataToUpdate = pick(group, attributesToUpdate[this.resource])

    const itemsToUpdate = items.filter((schedulingItem) => {
      const itemData = pick(schedulingItem, attributesToUpdate[this.resource])

      return !isEqual(itemData, dataToUpdate)
    })

    // Return if there are no items to update
    if (itemsToUpdate.length === 0) {
      return null
    }

    const operations: BatchFileOperation[] = itemsToUpdate.map(
      (schedulingItem) => ({
        action: BatchFileActions.UPDATE,
        resource: this.resource,
        data: { ...schedulingItem, ...dataToUpdate },
        lookup: schedulingItem.id.toString(),
      }),
    )

    if (operations.length === 0) {
      return null
    }

    const batchOperation = {
      onFailure: BatchFileOnFailureOptions.ABORT,
      operations,
    }

    return persister.executeBatchFileRequest(batchOperation)
  }

  getAccountKey() {
    switch (this.resource) {
      case Resources.MOBILE_RUNSHEETS:
        return 'zone'
      case Resources.MOBILE_SCHEDULES:
      case Resources.SITE_TASK_SCHEDULE_TIMINGS:
      default:
        return 'client'
    }
  }

  prepareGroupModel(group: SchedulingGroupEntity): Record<string, unknown> {
    const { id: schedulingGroup, ...groupData } = group

    return {
      ...groupData,
      [this.getGroupAttribute()]: schedulingGroup,
      ...(this.resource === Resources.MOBILE_SCHEDULES
        ? {
            beginServiceOn: new Date(groupData.beginServiceDate),
          }
        : {}),
    }
  }

  createEverydayGroupBatch(group: SchedulingGroupEntity): SchedulingGroupBatch {
    const model = this.prepareGroupModel(group)

    return model as SchedulingGroupBatch // to review
  }

  createCustomGroupBatch(
    group: SchedulingGroupEntity,
    schedulingItems: SchedulingEntity[],
    schedulingExceptions: SchedulingExceptionTypeEntity[],
    groupedExceptions: Record<string, ExceptionType[]>,
  ): SchedulingGroupBatch {
    const model = this.prepareGroupModel(group)

    const dayOfWeekAttr = this.getDayOfWeekAttribute()
    const days: SchedulingGroupBatch['days'] = schedulingItems.reduce(
      (acc, schedulingItem) => {
        const key = schedulingItem[dayOfWeekAttr] as string

        return {
          ...acc,
          [key]: schedulingItem,
        }
      },
      {},
    )

    const holidays: SchedulingGroupBatch['holidays'] =
      schedulingExceptions.reduce(
        (acc, { exceptionType, timeFrom, timeTo, performType }) => {
          return {
            ...acc,
            [this.schemaHelper.getHolidayKey(exceptionType)]: {
              performType,
              [rangeTimeKeys.START_TIME_KEY]: timeFrom,
              [rangeTimeKeys.END_TIME_KEY]: timeTo,
            },
          }
        },
        {},
      )
    const flagDays: SchedulingGroupBatch['flagDays'] =
      schedulingExceptions.reduce(
        (acc, { exceptionType, timeFrom, timeTo, performType }) => {
          const parentType = findKey(groupedExceptions, (items) =>
            items.some((item) => item.id === exceptionType),
          )

          if (
            parentType &&
            Number(parentType) === rootExceptionTypeId.FlagDay
          ) {
            const key = this.schemaHelper.getFlagDaysKey(exceptionType)
            if (timeFrom && timeTo) {
              return {
                ...acc,
                [key]: {
                  performType,
                  [rangeTimeKeys.START_TIME_KEY]: timeFrom,
                  [rangeTimeKeys.END_TIME_KEY]: timeTo,
                },
              }
            }
          }

          return acc
        },
        {},
      )

    const closedData = schedulingExceptions.find(
      (exception) => exception.exceptionType === rootExceptionTypeId.Closed,
    )
    const closed: SchedulingGroupBatch['closed'] = {
      ...(closedData
        ? {
            closed: {
              performType: closedData.perform,
              [rangeTimeKeys.START_TIME_KEY]: closedData.timeFrom,
              [rangeTimeKeys.END_TIME_KEY]: closedData.timeTo,
            },
          }
        : {}),
    }

    const allowedRootTypes = [
      rootExceptionTypeId.WeekdayBeforeEve,
      rootExceptionTypeId.WeekdayBeforeHoliday,
    ]

    const others: SchedulingGroupBatch['others'] = schedulingExceptions.reduce(
      (
        acc,
        {
          exceptionType,
          perform,
          timeFrom,
          timeTo,
          timeFromOverwrite,
          timeToOverwrite,
          performOverwrite,
        },
      ) => {
        // Find the parent root type for the current exceptionType
        const parentType = Object.keys(groupedExceptions).find((key) =>
          groupedExceptions[key].some((item) => item.id === exceptionType),
        )

        // Special handling for Holidays (parentType: 12)
        if (Number(parentType) === rootExceptionTypeId.Holiday) {
          const key = `others-${rootExceptionTypeId.HolidayBeforeWeekDay}`

          // Add conditions for performOverwrite with timeFromOverwrite and timeToOverwrite
          if (timeFromOverwrite && timeToOverwrite) {
            return {
              ...acc,
              [key]: {
                performType: performOverwrite ? performOverwrite : perform,
                [rangeTimeKeys.START_TIME_KEY]: timeFromOverwrite,
                [rangeTimeKeys.END_TIME_KEY]: timeToOverwrite,
              },
            }
          }

          return acc
        }

        if (parentType && allowedRootTypes.includes(Number(parentType))) {
          const key = `others-${parentType}`

          return {
            ...acc,
            [key]: {
              performType: perform,
              [rangeTimeKeys.START_TIME_KEY]: timeFrom,
              [rangeTimeKeys.END_TIME_KEY]: timeTo,
            },
          }
        }

        return acc
      },
      {},
    )

    return {
      ...model,
      ...(!isEmpty(days) ? { days } : {}),
      ...(!isEmpty(holidays) ? { holidays } : {}),
      ...(!isEmpty(others) ? { others } : {}),
      ...(!isEmpty(flagDays) ? { flagDays } : {}),
      ...(!isEmpty(closed) ? { closed } : {}),
    } as SchedulingGroupBatch // to review
  }
}
