<template>
  <v-card flat class="timeline-container">
    <v-card-title class="d-flex align-center justify-space-between">
      <v-text-field
        v-model="searchQuery"
        :label="
          $t('tt-entity-design.dispatch-tasks.filter-and-sort.filterLabel')
        "
        outlined
        dense
        hide-details
        prepend-inner-icon="mdi-magnify"
        class="search-input"
      />
      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn v-bind="attrs" v-on="on" outlined>
            <v-icon left>mdi-sort</v-icon>
            {{
              $t('tt-entity-design.dispatch-tasks.filter-and-sort.sortLabel')
            }}
          </v-btn>
        </template>
        <v-list>
          <v-list-item @click="sortByDate('desc')">
            <v-list-item-title>
              {{
                $t(
                  'tt-entity-design.dispatch-tasks.filter-and-sort.sortOptions.newest',
                )
              }}
            </v-list-item-title>
          </v-list-item>
          <v-list-item @click="sortByDate('asc')">
            <v-list-item-title>
              {{
                $t(
                  'tt-entity-design.dispatch-tasks.filter-and-sort.sortOptions.oldest',
                )
              }}
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-card-title>
    <v-timeline class="w-100 pr-2 pt-0" dense>
      <v-timeline-item
        fill-dot
        class="w-100"
        v-for="(item, index) in filteredActivities"
        :key="index"
        :color="getResourceInfo(item.resource).color"
        :icon="getResourceInfo(item.resource).icon"
        medium
      >
        <template v-slot:icon>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon v-bind="attrs" v-on="on" color="level0">
                {{ getResourceInfo(item.resource).icon }}
              </v-icon>
            </template>
            <span>{{ getResourceInfo(item.resource).text }}</span>
          </v-tooltip>
        </template>
        <v-card>
          <v-card-title class="d-flex align-center">
            <v-avatar size="24" class="mr-2">
              <template v-if="getUserInfo(item).avatar">
                <v-img :src="getUserInfo(item).avatar" contain></v-img>
              </template>
              <template v-else>
                <v-icon color="grey darken-1">mdi-account</v-icon>
              </template>
            </v-avatar>
            <span>{{ getUserInfo(item).name }}</span>
          </v-card-title>

          <v-card-subtitle>{{ item.content }}</v-card-subtitle>
          <v-card-actions>
            <v-spacer></v-spacer>
            <span class="time-diff">{{
              getTimeDifference(item.createdOn.date)
            }}</span>
          </v-card-actions>
        </v-card>
      </v-timeline-item>
    </v-timeline>
  </v-card>
</template>

<script lang="ts">
import Vue, { VueConstructor } from 'vue'
import { ItemHookProvider } from '@/tt-widget-entity-flow/types'
import { Resource } from '@/tt-entity-design/src/schema-types'
import { getDiffInMinutsFromNow } from '@/helpers/dates/getDiffInMinuts'
const DISPATCH_TASKS: Resource = 'dispatch-tasks'
import _ from 'lodash'

type ActivitiesResource = {
  icon: string
  text: string
  color: string
}

type SortDirection = 'asc' | 'desc'

interface ActivityItem {
  resource: string
  content: string
  createdOn: { date: string }
  relationObject?: {
    user?: { name: string; avatar: string }
    createdBy?: { name: string; avatar: string }
  }
}

interface ApiResponse {
  allActivities?: { data: ActivityItem[] }
}

export default (Vue as VueConstructor<Vue & ItemHookProvider>).extend({
  name: 'AllActivitiesListWidget',
  inject: ['getItemHook'],
  data() {
    return {
      allActivitiesData: [] as ActivityItem[],
      searchQuery: '',
      sortOrder: 'desc' as SortDirection,
      loading: true,
    }
  },
  computed: {
    dispatchTaskId(): number {
      return this.getItemHook().getEntityId()
    },
    unknownActivity(): ActivitiesResource {
      return {
        icon: 'mdi-help-circle',
        text: this.$t('tt-entity-design.dispatch-tasks.unknownResource'),
        color: 'grey',
      }
    },
    filteredActivities(): ActivityItem[] {
      const filtered = this.allActivitiesData.filter((item) =>
        item.content.toLowerCase().includes(this.searchQuery.toLowerCase()),
      )

      return _.orderBy(
        filtered,
        (item: ActivityItem) => new Date(item.createdOn.date).getTime(),
        this.sortOrder as 'asc' | 'desc',
      )
    },
  },
  async created() {
    try {
      const response = (await this.$auth
        .getApi()
        .get(DISPATCH_TASKS, this.dispatchTaskId, {
          extension: ['allActivities'],
          fields: [{ attribute: 'id' }],
        })) as ApiResponse
      this.allActivitiesData = response?.allActivities?.data ?? []
    } catch (error) {
      console.error('Failed to fetch widget data:', error)
    }
  },
  methods: {
    getResourceInfo(resourceType: string): ActivitiesResource {
      const resourceMap: Record<string, ActivitiesResource> = {
        'workflow-instance-logs': {
          icon: 'mdi-check-circle',
          text: this.$t(
            'tt-entity-design.dispatch-tasks.resourceInfos.workflowLogEntry',
          ),
          color: 'success',
        },
        comments: {
          icon: 'mdi-message',
          text: this.$t(
            'tt-entity-design.dispatch-tasks.resourceInfos.userComment',
          ),
          color: 'ttPrimary',
        },
        'system-exception-tickets': {
          icon: 'mdi-alert',
          text: this.$t(
            'tt-entity-design.dispatch-tasks.resourceInfos.systemException',
          ),
          color: 'error',
        },
      }

      return resourceMap[resourceType] || this.unknownActivity
    },
    getTimeDifference(date: string): string {
      const minutes = getDiffInMinutsFromNow(date)
      const months = Math.floor(minutes / (60 * 24 * 30))

      return months === 0
        ? this.$t('tt-entity-design.dispatch-tasks.time.thisMonth')
        : this.$tc('tt-entity-design.dispatch-tasks.time.monthsAgo', months, {
            count: months,
          })
    },
    getUserInfo(item: ActivityItem): { name: string; avatar: string } {
      const user = item.relationObject?.user || item.relationObject?.createdBy

      return {
        name:
          user?.name || this.$t('tt-entity-design.dispatch-tasks.unknownUser'),
        avatar: user?.avatar || '',
      }
    },
    sortByDate(order: SortDirection) {
      this.sortOrder = order
    },
  },
})
</script>

<style scoped>
.timeline-container {
  padding: 16px;
  overflow-y: auto;
}
.time-diff {
  font-size: 12px;
  color: gray;
}
.search-input {
  max-width: 250px;
}
</style>
