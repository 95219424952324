import moment from 'moment'
import { WeekDayServiceCycleType } from './weekDayServiceCycleType'
import { Period } from '@/tt-widget-views/mobile-schedule-groups/types'

// Get the next cycle date based in the selected period and the begin service date
export const getNextCycleDate = (
  period: Period,
  beginServiceDate: string,
  weekDayStartSetting: WeekDayServiceCycleType,
) => {
  const date = moment(beginServiceDate)
  const map = {
    WEEKLY: 'week',
    MONTHLY: 'month',
    HALF_YEAR: 'year',
    QUARTERLY: 'quarter',
    YEARLY: 'year',
    TWO_YEAR: 'year',
  }

  const weekdaySettingMap = {
    SUNDAY: 0,
    MONDAY: 1,
    TUESDAY: 2,
    WEDNESDAY: 3,
    THURSDAY: 4,
    FRIDAY: 5,
    SATURDAY: 6,
  }

  if (period == 'HALF_YEAR') {
    const mounth = date.get('month')
    date.set('date', 1)
    if (mounth < 6) {
      date.set('month', 6) // if the date is before july set the next cycle to july first
    } else {
      date.add(1, 'year').set('month', 0) // if the date is in july or after set the next cycle to january next year
    }
  } else if (period == 'TWO_YEAR') {
    date
      .add(2, map[period] as moment.DurationInputArg2)
      .startOf(map[period] as moment.unitOfTime.StartOf)
  } else if (period == 'WEEKLY') {
    const beginServiceDateDayOfWeek = date.day()
    date.day(weekdaySettingMap[weekDayStartSetting])
    if (beginServiceDateDayOfWeek >= date.day()) {
      date.add(1, 'week')
    }
  } else {
    date
      .add(1, map[period] as moment.DurationInputArg2)
      .startOf(map[period] as moment.unitOfTime.StartOf)
  }

  return date
}
