<template>
  <component :is="tag" style="position: relative" @mousemove="onMove">
    <v-toolbar v-show="!hide" dense short class="toolbar3" flat>
      <slot name="before" />

      <EntityToolbarSearch
        v-if="allowSearch"
        :query-manager="queryManager"
        class="hide-in-print-view"
        @update="search"
      />

      <div style="display: flex; min-width: 0">
        <EntityToolbarFilter
          v-if="showFilters"
          :filter-options="filterOptions"
          :query-manager="queryManager"
          :filter-handlers="filterHandlers"
          class="hide-in-print-view"
          @update="updateFilters"
        />
        <slot name="after-filters" />
      </div>

      <v-spacer />

      <div v-if="showCounts" style="width: auto">
        <v-chip color="level0" small style="font-size: 0.7em">
          {{ entitiesCountText }}
        </v-chip>
      </div>
      <v-btn
        v-if="showCreateButton"
        depressed
        color="indigo accent-2"
        dark
        class="ml-2 hide-in-print-view"
        small
        :data-analytics="`core:create-entity-button|resource:${resource}`"
        @click="dispatchCreationForm"
      >
        <v-icon left>
          {{ 'mdi-plus' }}
        </v-icon>
        {{ $t('common.create.btn') }}
      </v-btn>

      <v-toolbar-items v-if="showControls" class="hide-in-print-view">
        <v-btn icon text small>
          <v-icon small>
            {{ 'mdi-dots-vertical' }}
          </v-icon>
        </v-btn>
      </v-toolbar-items>

      <v-btn
        v-if="showReloadButton"
        class="mx-2"
        color="primary"
        small
        icon
        @click="reload"
      >
        <v-icon small>
          {{ 'mdi-reload' }}
        </v-icon>
      </v-btn>

      <TLiveUpdateIcon
        v-if="allowLiveUpdate"
        :is-active="isLiveUpdateActive"
        :last-update="lastUpdateDatetime"
        class="ml-2"
      />
      <slot name="end-actions" />
    </v-toolbar>

    <slot />
  </component>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue'
import debounce from 'lodash/debounce'

import BaseQueryManager from '@/tt-widget-components/base/BaseQueryManager'
import EntityToolbarFilter from '@/tt-entity-filter/components/EntityToolbarFilter.vue'
import EntityToolbarSearch from '@/tt-entity-filter/components/EntityToolbarSearch.vue'
import {
  CollectionWidgetHook,
  DataSetWidgetHook,
  FilterOptions,
  FiltersMap,
  FilterHandler,
} from '@/tt-widget-components'
import { numberWithCommas } from '@/helpers/formats'

import { EntityIntentTypes } from '../intents/types'
import { EntityToolbarManager } from '../EntityToolbarManager'
import TLiveUpdateIcon from '@/tt-ui/components/TLiveUpdateIcon.vue'
import { PusherSdk } from '@tracktik/tt-pusher'

export default Vue.extend({
  name: 'EntityToolbar',
  components: {
    EntityToolbarFilter,
    EntityToolbarSearch,
    TLiveUpdateIcon,
  },
  props: {
    filterHandlers: {
      type: Array as PropType<FilterHandler[]>,
      default: () => [] as FilterHandler[],
    },
    hook: {
      type: Object as
        | PropType<CollectionWidgetHook<any>>
        | PropType<DataSetWidgetHook<any>>,
      required: true,
    },
    tag: {
      type: String,
      default: 'div',
    },
  },
  data() {
    return {
      hide: false,
    }
  },
  computed: {
    resource(): string {
      return this.hook.resource
    },
    toolbarManager(): EntityToolbarManager {
      return this.hook.toolbarManager
    },
    totalEntities(): number | null {
      return this.hook.totalEntities ?? null
    },
    allowCreation(): boolean {
      return !!this.hook.widget.allowCreation
    },
    loading(): boolean {
      return !!this.hook.loading
    },
    allowLiveUpdate(): boolean {
      return this.hook.widget.allowLiveUpdate
    },
    isLiveUpdateActive(): boolean {
      if (!this.allowLiveUpdate) return false

      const pusher = this.$appContext.pusherSdk as PusherSdk

      return pusher.getResourceSubscriptions(this.hook.resource).length > 0
    },
    lastUpdateDatetime(): number | null {
      if (!this.allowLiveUpdate) return null

      const pusher = this.$appContext.pusherSdk as PusherSdk

      const datetime = pusher.getResourceNewEvents(this.hook.resource)[0]
        ?.datetime

      return datetime || null
    },
    allowSearch(): boolean {
      return this.show && !!this.toolbarManager.model.filterOptions?.allowSearch
    },
    autoHide(): boolean {
      return this.toolbarManager.model.autoHide
    },
    entitiesCountText(): string {
      const { totalEntities } = this
      if (totalEntities === null) {
        return '...'
      }

      if (this.loading) {
        return `${this.$t('common.loading')}...`
      }

      return totalEntities === 0
        ? (this.$t('components.entity-toolbar.no-records') as string)
        : (this.$t('components.entity-toolbar.total-entities', {
            totalEntities: `${numberWithCommas(this.totalEntities)}`,
          }) as string)
    },
    filterOptions(): FilterOptions {
      return this.toolbarManager.model.filterOptions ?? {}
    },
    isResourceAvailable(): boolean {
      return !!this.toolbarManager.queryManager.initialQuery.resource
    },
    queryManager(): BaseQueryManager {
      return this.toolbarManager.queryManager
    },
    show(): boolean {
      return this.toolbarManager.model?.show ?? true
    },
    showControls(): boolean {
      return !!(this.show && this.toolbarManager.model.controls)
    },
    showCounts(): boolean {
      return this.show && this.toolbarManager.model.displayCounts
    },
    showFilters(): boolean {
      return this.show
    },
    showCreateButton(): boolean {
      return this.allowCreation && this.isResourceAvailable
    },
    showReloadButton(): boolean {
      return this.show && this.toolbarManager.model.displayReload
    },
  },
  created() {
    if (this.autoHide) {
      this.hide = true
    }
  },
  methods: {
    onMove() {
      if (this.autoHide) {
        this.hide = false
        this.hideDebounce()
      }
    },
    hideDebounce: debounce(function () {
      // @ts-ignore
      this.hide = true
    }, 4000),
    dispatchCreationForm() {
      this.$appContext.eventManager.dispatchEvent(EntityIntentTypes.CREATE, {
        resourceName: this.toolbarManager.queryManager.initialQuery.resource,
      })
    },
    reload() {
      this.hook.update({ disableCache: true })
    },
    search() {
      this.hook.update()
    },
    updateFilters(filters: FiltersMap) {
      this.hook.update()
      this.$emit('update:filters', filters)
    },
  },
})
</script>

<style scoped>
@media print {
  .hide-in-print-view {
    display: none;
  }
}
</style>
