<template>
  <div class="flex--column">
    <!-- Hidden component to collect the required fields -->
    <EntityViewAttributeCollector
      v-if="!hook.isReadyToFetch"
      v-show="false"
      v-slot="{ itemHook }"
      :resource-name="hook.resource"
      @input="setFields"
      @action="loadActions"
    >
      <component
        :is="listItem.is"
        v-bind="listItem.props"
        :key="itemHook.entityReference.entityId"
      />
    </EntityViewAttributeCollector>

    <!-- @todo: test PDF print -->
    <div
      v-if="hook.resource && hook.isInitialized && listItem.is"
      class="list-widget flex--column"
      @rendered="onWidgetRendered"
    >
      <WidgetTitleToolbar
        :container="container"
        :hook="hook"
        @update:filters="$emit('update:filters', $event)"
      />

      <div v-if="hook.loading" style="position: relative">
        <TLoadingBar />
      </div>

      <!-- @todo: see if not needed how to remove -->
      <VerticalStackCard
        v-slot="{ clientWidth, clientHeight }"
        class="flex--column"
      >
        <div
          v-if="hook.entities.length"
          class="flex--column w-100"
          :class="clientWidth && clientHeight ? 'd-flex' : 'd-none'"
        >
          <Flipper :flip-key="flipKey" spring="noWobble" class="h-100">
            <EntityLooper
              ref="overflow-container"
              v-slot="{ itemHook, index }"
              :entities="hook.entities"
              :resource-name="hook.resource"
              :actions="hook.actions"
              :allow-reorder="allowReorder"
              style="overflow-y: scroll"
              class="hide-scroll-in-print-view fill-height"
            >
              <Flipped
                :key="itemHook.getEntityId()"
                :flip-id="`${itemHook.getEntityId()}`"
              >
                <div
                  class="list-item-div w-100"
                  :class="{
                    selected: isRecordSelected(itemHook.getEntityId()),
                    'cursor-pointer': !isPreviewDisabled,
                  }"
                  @click="click(itemHook)"
                >
                  <component
                    :is="listItem.is"
                    v-bind="{ index: formattedIndex(index), ...listItem.props }"
                  />
                  <v-divider class="mx-6" />
                </div>
              </Flipped>
            </EntityLooper>
          </Flipper>
        </div>

        <!-- only at startup -->
        <template v-if="hook.loading">
          <div v-if="!hook.entities.length" class="flex--column">
            <v-skeleton-loader
              v-for="index in skeletonCount"
              :key="index"
              type="list-item-avatar-three-line"
              :style="{ opacity: getSkeletonOpacity(index) }"
            />
          </div>
        </template>

        <WidgetErrorDetails
          v-else-if="hook.hasErrors"
          :resource="hook.resource"
          :errors="hook.errors"
        />

        <TNoData v-else-if="hook.hasDataSource === false" class="h-100" />

        <div class="list-footer d-flex flex-column">
          <v-divider class="px-3" />

          <TPagination
            :width="clientWidth"
            :offset="hook.effectiveQuery.offset"
            :limit="hook.effectiveQuery.limit"
            :total="hook.totalEntities"
            :current-page-count="hook.entities.length"
            class="hide-in-print-view w-100"
            @update:pagination="updatePagination"
          />
        </div>
      </VerticalStackCard>
    </div>
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue'
import xor from 'lodash/xor'

import BaseWidget from '@/tt-widget-components/components/BaseWidget'
import { ActiveWindow } from '@/tt-app-layout'
import {
  CollectionWidgetEventNames,
  PaginationType,
} from '@/tt-widget-components/types'
import { EntityItemHook } from '@/tt-widget-entity-flow/EntityItemHook'
import {
  WidgetContainerInterface,
  WidgetContainerType,
} from '@/tt-widget-factory'
import { Flipper, Flipped } from 'vue-flip-toolkit'

import ListWidgetHook from './ListWidgetHook'
import { ListWidgetEventNames } from './types'
import TPagination from '@/tt-ui/components/TPagination.vue'
import EntityLooper from '@/tt-widget-entity-flow/components/EntityLooper.vue'

type WidgetInstance = {
  activeWindow?: ActiveWindow
  container: WidgetContainerInterface
}

export type ListWidgetOnSelectCallback = (
  hook: ListWidgetHook,
  itemHook: EntityItemHook,
  widgetInstance: WidgetInstance,
) => void

const canPreviewInSameContainer = ({ container }: WidgetInstance) => {
  const { DASHBOARD_CELL, STANDALONE, DASHBOARD_TAB } = WidgetContainerType

  return (
    !container ||
    ![DASHBOARD_CELL, STANDALONE, DASHBOARD_TAB].includes(container.type)
  )
}

// @TODO: To review, EntityItemHook should not handle the dispatch of the preview
const previewItem: ListWidgetOnSelectCallback = (
  hook,
  itemHook,
  widgetInstance,
) => {
  if (hook.widget.disableResourcePreviewOnClick) return

  if (canPreviewInSameContainer(widgetInstance)) {
    itemHook.dispatchPreview(
      {
        activeWindow: widgetInstance.activeWindow ?? null,
      },
      false,
    )
  } else {
    itemHook.dispatchPreview({}, false)
  }
}

import TNoData from '@/tt-ui/components/TNoData.vue'

export default BaseWidget.extend({
  name: 'ListWidget',
  data() {
    return {
      /**
       * Used to force the flip animation when the list is updated
       */
      flipKey: 0,
      skeletonCount: 6,
    }
  },
  inject: {
    activeWindow: { default: null },
  },
  components: {
    Flipper,
    Flipped,
    TNoData,
    TPagination,
    EntityLooper,
  },
  props: {
    hook: {
      type: Object as PropType<ListWidgetHook>,
      required: true,
    },
    onSelect: {
      type: Function as PropType<ListWidgetOnSelectCallback>,
      default: previewItem,
    },
    selectedRecords: {
      type: Array as PropType<number[]>,
      default: () => [],
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    allowReorder: {
      type: Boolean,
      default: false,
    },
  },
  /**
   * List widget supports customization of the component that handles each
   * item through the `listItem.is` prop in the widget's configuration.
   * The entities may be injected in such components.
   */
  provide(): { entities: Record<string, any>[] } {
    return {
      entities: this.hook.entities,
    }
  },
  methods: {
    moveToTop() {
      (this.$refs['overflow-container'] as Vue)?.$el.scrollTo({
        top: 0,
        behavior: 'smooth',
      })
    },
    click(itemHook: EntityItemHook) {
      if (!this.multiple && this.isRecordSelected(itemHook.getEntityId()))
        return
      const valueToEmit = this.multiple
        ? xor(this.selectedRecords, [itemHook.getEntityId()])
        : [itemHook.getEntityId()]

      this.$emit(CollectionWidgetEventNames.SELECTION_LIST_UPDATED, valueToEmit)
      this.onSelect && this.onSelect(this.hook, itemHook, this)
    },
    isRecordSelected(entityId: number): boolean {
      return this.selectedRecords.includes(entityId)
    },
    setFields(fields: string[]) {
      if (fields && fields.length) {
        this.$emit('set-required-fields', fields)
        this.hook.requiredFields = fields
      }
    },
    loadActions() {
      this.hook.loadEntitiesActions = true
    },
    updatePagination({ offset, limit }: PaginationType) {
      this.hook.queryManager.setOffset(offset)
      this.hook.queryManager.setLimit(limit)
      this.hook.update()
      this.moveToTop()
    },
    onWidgetRendered() {
      this.$emit('rendered')
    },
    /**
     * Formatted index allows you not to lose the index count when you reach the pagination limit
     */
    formattedIndex(index: number): number | null {
      if (index == null) return null

      const offset = this.hook.queryManager.query.offset ?? 0

      return index + offset
    },
    /**
     * Returns the opacity of the skeleton loader based on its position to create a fade-out effect.
     * The higher the list index, the lower the opacity.
     */
    getSkeletonOpacity(index: number): number {
      const positionOpacity = 1 - index / this.skeletonCount

      return positionOpacity * 0.8
    },
  },
  computed: {
    isPreviewDisabled(): boolean {
      return !!this.hook.widget.disableResourcePreviewOnClick
    },
    total(): number {
      return Math.ceil(this.hook?.state?.totalEntities)
    },
    listItem(): any {
      return this.hook.listItem ?? {}
    },
    pagination(): {
      total: any
      limit: any
      offset: any
    } {
      return {
        total: this.hook.state.totalEntities,
        limit: this.hook.queryManager.query.limit,
        offset: this.hook.queryManager.query.offset,
      }
    },
  },
  watch: {
    'hook.entities'(entities) {
      this.$emit(ListWidgetEventNames.RECORDS_LOADED, entities)
      this.onWidgetRendered()
      this.flipKey = this.flipKey + 1
    },
  },
})
</script>

<style scoped>
.list-widget >>> .list-item-div.selected {
  border-color: var(--v-info-darken2);
}

@media print {
  .hide-scroll-in-print-view {
    overflow: hidden !important;
  }

  .hide-in-print-view {
    display: none;
  }
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-pointer:hover {
  background-color: rgba(234, 238, 255, 0.2) !important;
}

/**
Remove row background hover effect when hovering on a clickable element (ie: relations)
 */
.cursor-pointer:has(.text-clickable:hover) {
  background-color: transparent !important;
}
</style>
