<template>
  <div
    :class="['fill-height', 'd-flex', smAndDown ? 'flex-column' : 'flex-row']"
  >
    <div
      class="pt-6 pl-md-6 pl-sm-2 pr-2"
      :class="{ 'employee-card-md': !smAndDown }"
    >
      <EmployeeEmploymentProfile :employee-info="employeeInfo">
        <template #policy>
          <EntitySelectorField
            v-if="!hasPolicyAssigned"
            v-model="newLeavePolicy"
            :hide-details="!!pendingRequestsCount"
            :resource="leavePolicyResource"
            :query-options="leavePolicySort"
            :placeholder="$t(policySelectorLabel)"
            :label="$t(policySelectorLabel)"
          />
        </template>
        <template #actions>
          <v-btn
            :disabled="isValid"
            depressed
            color="#4E69E9"
            dark
            class="ml-2 mt-5 text-body-1"
            style="text-transform: none"
            :loading="isFetching || isSubmitting"
            @click="hasPolicyAssigned ? switchForm() : submitPolicyChange()"
          >
            <v-icon left>
              {{ btnIcon }}
            </v-icon>
            {{ $t(btnLabel) }}
          </v-btn>
          <div v-if="pendingRequestsCount" class="d-flex align-start pt-2">
            <v-icon
              small
              color="orange darken-2"
              class="pr-2"
              style="padding-top: 2px"
            >
              mdi-alert
            </v-icon>
            <p
              class="orange--text text--darken-1 text-left pt-0 mb-0"
              style="font-size: 14px"
            >
              {{ pendingRequestsWarning }}
            </p>
          </div>
          <v-skeleton-loader v-if="!isReady" type="paragraph, button" />
        </template>
      </EmployeeEmploymentProfile>
    </div>
    <div class="d-flex flex-column flex-grow-1 pt-6 pl-2 pr-md-6 pr-sm-2">
      <WidgetLoader v-if="!isReady" :widget="currentPolicyItemsWidget">
        <v-skeleton-loader type="table" style="height: 100%" />
      </WidgetLoader>
      <NoPolicyWarning v-else-if="!hasPolicyAssigned" />
      <WidgetFactory v-else :widget="currentPolicyItemsWidget" />
    </div>
  </div>
</template>
<script lang="ts">
import { Resources } from '@/tt-entity-design/src/types'
import Vue, { PropType } from 'vue'
import {
  DataTableWidgetModel,
  Filter,
  RelationListResource,
} from '@/tt-widget-components/types'
import { FilterOperatorType } from '@/tt-widget-factory'
import { SettingsTabColumns } from './SettingsTabColumns'
import { EmployeesSettingsProvider } from './types'
import { UnsubscribeFunction } from '@tracktik/tt-event-manager'
import {
  EntityCreateIntentInterface,
  EntityIntentTypes,
} from '@/tt-widget-entity-flow'
import { LeaveRequestsStatus } from '../../types'
import { Filter as SDKFilter } from 'tracktik-sdk/lib/common/entity-filters'
import EmployeeEmploymentProfile from '../employment-profile/EmployeeEmploymentProfile.vue'
import NoPolicyWarning from '../NoPolicyWarning.vue'
import { LeavePolicyEmployeeInfo } from '@/tt-entity-design/src/components/leave-policies/types'
import { DefinitionOptionMap, FormOptions } from '@tracktik/tt-json-schema-form'
import {
  EntityCollectionRequestOptions,
  SortDirectionType,
} from 'tracktik-sdk/lib/common/entity-collection'
import { LayoutWindowEvent } from '@/tt-app-layout'

const switchActionName = 'switch-employees'

export default Vue.extend({
  name: 'SettingsTab',
  components: {
    EmployeeEmploymentProfile,
    NoPolicyWarning,
  },
  provide(): EmployeesSettingsProvider {
    return {
      leaveManagementEmployeeInfo: this.leaveManagementEmployeeInfo,
    }
  },
  props: {
    employeeInfo: {
      type: Object as PropType<LeavePolicyEmployeeInfo>,
      required: true,
    },
  },
  data() {
    return {
      leavePolicyResource: Resources.LEAVE_POLICIES,
      leaveManagementEmployeeInfo: Vue.observable({
        employee: this.employeeInfo.id,
        leavePolicy: this.employeeInfo?.currentLeavePolicy?.id,
      }),
      newLeavePolicy: null,
      isFetching: true,
      isSubmitting: false,
      unsubscribeFns: [] as UnsubscribeFunction[],
      pendingRequestsCount: null as number,
    }
  },
  computed: {
    currentPolicyItemsWidget(): DataTableWidgetModel {
      const currentEmployeeFilter: Filter = {
        attribute: 'employee',
        operator: FilterOperatorType.EQUAL,
        value: this.employeeInfo.id,
      }

      return {
        is: 'DataTableWidget',
        title: '',
        query: {
          resource: Resources.LEAVE_POLICY_ITEMS,
          relationListResource: this.relationListResource,
          filters: [currentEmployeeFilter],
        },
        component: { columns: SettingsTabColumns },
      }
    },
    currentLeavePolicy(): number {
      return this.employeeInfo?.currentLeavePolicy?.id
    },
    policySelectorLabel(): string {
      return `${this.translationBase}.policy-selector.label`
    },
    isEmployeeInfoLoaded(): boolean {
      return (
        typeof this.employeeInfo?.id === 'number' &&
        !Number.isNaN(this.employeeInfo?.id)
      )
    },
    hasPolicyAssigned(): boolean {
      return (
        typeof this.currentLeavePolicy === 'number' &&
        !Number.isNaN(this.currentLeavePolicy)
      )
    },
    isReady(): boolean {
      return this.$appContext.isReady() && this.isEmployeeInfoLoaded
    },
    isValid(): boolean {
      if (this.hasPolicyAssigned) {
        return !!this.pendingRequestsCount
      }

      return !this.newLeavePolicy
    },
    leavePolicySort(): EntityCollectionRequestOptions {
      return {
        sort: [
          {
            attribute: 'name',
            direction: SortDirectionType.ASC,
          },
        ],
      }
    },
    pendingRequestsWarning(): string {
      return this.$tc(
        `${this.translationBase}.pending-requests-warning`,
        this.pendingRequestsCount,
      )
    },
    relationListResource(): RelationListResource {
      return {
        resource: Resources.LEAVE_POLICIES,
        id: this.currentLeavePolicy,
        attribute: 'leavePolicyItems',
      }
    },
    smAndDown(): boolean {
      return this.$vuetify.breakpoint.smAndDown
    },
    translationBase(): string {
      return 'tt-entity-design.leave-management.employee.settings'
    },
    switchPoliciesLabel(): string {
      return `${this.translationBase}.btn-switch-policies`
    },
    assignPolicyLabel(): string {
      return `${this.translationBase}.btn-assign-policy`
    },
    btnLabel(): string {
      return this.hasPolicyAssigned
        ? this.switchPoliciesLabel
        : this.assignPolicyLabel
    },
    btnIcon(): string {
      return this.hasPolicyAssigned
        ? 'mdi-swap-horizontal'
        : 'mdi-playlist-plus'
    },
  },
  async created() {
    this.setCurrentLeavePolicy(this.currentLeavePolicy)
    await this.getEmployeeLeaveRequests()
    this.subscribeToLeaveRequests()
    this.subscribeToLeavePolicies()
  },
  beforeDestroy() {
    this.unsubscribeFns.forEach((unsubscribe) => unsubscribe())
  },
  methods: {
    setCurrentLeavePolicy(leavePolicyID: number | null): void {
      this.leaveManagementEmployeeInfo.leavePolicy = leavePolicyID
      this.newLeavePolicy = leavePolicyID
    },
    showPolicyChangeErrorMsg(): void {
      this.$eventManager.dispatchEvent(LayoutWindowEvent.SNACK_ERROR, {
        message: this.$t(`${this.translationBase}.policy-change.error`),
      })
    },
    showPolicyChangeSuccessMsg(): void {
      this.$eventManager.dispatchEvent(LayoutWindowEvent.SNACK_SUCCESS, {
        message: this.$t(`${this.translationBase}.policy-change.success`),
      })
    },
    subscribeToLeaveRequests(): void {
      // to update the available days once a new leave request is actioned upon
      // AND update the leave requests warning message
      this.unsubscribeFns.push(
        this.$appContext.eventManager.subscribeEvent(
          EntityIntentTypes.RESOURCE_UPDATED,
          async ({ resource }) => {
            if (resource === Resources.LEAVE_REQUESTS) {
              await this.getEmployeeLeaveRequests()
            }
          },
        ),
      )
    },
    subscribeToLeavePolicies(): void {
      // to update the leave policy & leave policy items once a leave policy is switched
      this.unsubscribeFns.push(
        this.$appContext.eventManager.subscribeEvent(
          EntityIntentTypes.RESOURCE_UPDATED,
          ({ resource }) => {
            if (resource === Resources.LEAVE_POLICIES) {
              this.updatePolicyItems()
              this.$emit('policy-update')
            }
          },
        ),
      )
    },
    updatePolicyItems(): void {
      this.$appContext.eventManager.dispatchEvent(
        EntityIntentTypes.RESOURCE_UPDATED,
        { resource: Resources.LEAVE_POLICY_ITEMS },
      )
    },
    async getEmployeeLeaveRequests(): Promise<number | void> {
      const statusFilter: SDKFilter = {
        attribute: 'status',
        operator: FilterOperatorType.EQUAL,
        value: LeaveRequestsStatus.PENDING,
      }
      const employeeFilter: SDKFilter = {
        attribute: 'employee',
        operator: FilterOperatorType.EQUAL,
        value: this.leaveManagementEmployeeInfo.employee,
      }
      try {
        const employeeRequests =
          await this.$appContext.entityServices.persister.api.getAll(
            Resources.LEAVE_REQUESTS,
            {
              filters: [statusFilter, employeeFilter],
            },
          )
        this.pendingRequestsCount = employeeRequests.itemCount
      } catch (err) {
        this.$crash.captureException(err)
      } finally {
        this.isFetching = false
      }
    },
    switchForm(): void {
      const customFormDefinitionOptions: DefinitionOptionMap = {
        LeavePoliciesSwitchEmployees: {
          view: {
            is: 'SingleEmployeeSwitchPoliciesForm',
            props: {
              additionalQueryOptions: this.leavePolicySort,
            },
          },
        },
      }

      const initialModel: EntityCreateIntentInterface['initialModel'] = {
        employees: { [this.employeeInfo.name]: this.employeeInfo.id },
      }

      const formOptions: FormOptions = {
        definitions: customFormDefinitionOptions,
      }

      this.$eventManager.dispatchEvent(EntityIntentTypes.RUN_ACTION, {
        actionName: switchActionName,
        resourceName: this.leavePolicyResource,
        entityId: this.currentLeavePolicy,
        formOptions,
        initialModel,
        title: this.switchPoliciesLabel,
      })
    },
    async assignToLeavePolicy() {
      return await this.$appContext.entityServices.persister.executeEntityAction(
        this.leavePolicyResource,
        'assign-employees',
        this.newLeavePolicy,
        {
          employeesIds: [this.employeeInfo.id],
        },
      )
    },
    async submitPolicyChange() {
      this.isSubmitting = true
      try {
        await this.assignToLeavePolicy()
        this.showPolicyChangeSuccessMsg()
      } catch (err) {
        this.$crash.captureException(err)
        this.showPolicyChangeErrorMsg()
      } finally {
        this.isSubmitting = false
      }
    },
  },
})
</script>
<style scoped>
.employee-card-md {
  width: 23.4285rem;
}

.theme--dark.v-btn.v-btn--disabled {
  background-color: var(--v-bg-grey1-darken3) !important;
}
</style>
