import { DefinitionOptionMap, FormOptions } from '@tracktik/tt-json-schema-form'

import { ActiveWindow } from '@/tt-app-layout'
import { CollectionQuery, Measure } from '@/tt-widget-components'

import { EntityItemHook } from '../EntityItemHook'
import { RegionFilterPayload } from '../intents/RegionFilterIntent'
import { ContextDictionary, SecurityRuleOperation } from '@/tt-widget-factory'
import { EntityPreviewEditHandler } from '@/tt-widget-entity-flow/types'

// Base interface
interface BaseEntityItemIntentInterface {
  resourceName: string
  entityId: number
  entity?: any
  context?: BaseEntityInterfaceContext
  itemHook?: EntityItemHook
}

interface BaseEntityInterfaceContext {}

export interface EntityPreviewAbsoluteIntentInterface
  extends BaseEntityItemIntentInterface {
  position: {
    x: number
    y: number
  }
}

/**
 * Batch action
 */
export interface EntityBatchActionIntentInterface {
  actionName: string
  entities: any[]
  formOptions?: FormOptions
  label?: string
  resourceName: string
  width?: number
  itemHook?: EntityItemHook | null
}

/**
 * Single entity action
 */
export interface EntityActionIntentInterface
  extends BaseEntityItemIntentInterface {
  actionName: string
  formOptions?: FormOptions
  onSuccess?: (updatedEntity: { id: number }) => void
  title?: string
  initialModel?: { [key: string]: unknown }
}

/**
 * Preview entity
 */
export interface EntityPreviewIntentInterface
  extends BaseEntityItemIntentInterface {
  activeWindow?: ActiveWindow
  title?: string
  hasToAddPages?: boolean
  is?: string
  onEdit?: EntityPreviewEditHandler
}

export interface EntityPreviewCloseIntentInterface {
  resourceName: string
  entityId: number
}

export type EntityIntentBuildDefinitionsFn = (
  definitions: DefinitionOptionMap,
  formName: string,
) => DefinitionOptionMap

/**
 * Edit profile
 */
export interface EntityEditIntentInterface
  extends BaseEntityItemIntentInterface {
  buildDefinitions?: EntityIntentBuildDefinitionsFn
  onSuccess?: (updatedEntity: { id: number }) => void
  formOptions?: FormOptions
  title?: string
}

export interface EntityCreateIntentInterface {
  resourceName: string
  title?: string
  initialModel?: { [key: string]: unknown }
  formOptions?: FormOptions
  buildDefinitions?: EntityIntentBuildDefinitionsFn
  onSuccess?: (newEntity: { id: number }) => void
  itemHook?: EntityItemHook | null
}

/**
 * List interface
 */
export interface EntityListIntentInterface {
  query: CollectionQuery
  context: any
}

export interface EntityAggregationIntentInterface {
  query: CollectionQuery
  aggregationContext?: AggregationContext
}

export interface AggregationContext {
  value: string | number
  measure: Measure
}

export interface ResourceUpdatedInterface {
  resource: string
  entityId?: string | number
  actionName?: string
  entity?: Record<string, any>
  operation?: Omit<SecurityRuleOperation, 'view'>
}

export interface ResourceCreatedInterface {
  resourceName: string
  entityId?: string | number
  actionName?: string
}

export interface EntityErrorInterface {
  error?: string | Error
}

export enum EntityIntentTypes {
  LIST = 'entity.list',
  PREVIEW = 'entity.preview',
  PREVIEW_CLOSE = 'entity.preview.close',
  PREVIEW_ABSOLUTE = 'entity.preview.absolute',
  PREVIEW_ABSOLUTE_CLOSE = 'entity.preview.absolute.close',
  EDIT = 'entity.edit',
  CREATE = 'entity.create',
  RUN_ACTION = 'entity.action',
  RUN_BATCH_ACTION = 'entity.action.batch',
  RESOURCE_UPDATED = 'resource-updated',
  AGGREGATION_DETAILS = 'entity.aggregation.details',
  CONTEXT_CHANGE = 'context-change',
  ERROR = 'entity.error',
}

export type EntityIntentEventPayload = {
  [EntityIntentTypes.LIST]: EntityListIntentInterface
  [EntityIntentTypes.PREVIEW]: EntityPreviewIntentInterface
  [EntityIntentTypes.PREVIEW_CLOSE]: EntityPreviewCloseIntentInterface
  [EntityIntentTypes.PREVIEW_ABSOLUTE]: EntityPreviewAbsoluteIntentInterface
  [EntityIntentTypes.PREVIEW_ABSOLUTE_CLOSE]: undefined
  [EntityIntentTypes.EDIT]: EntityEditIntentInterface
  [EntityIntentTypes.CREATE]: EntityCreateIntentInterface
  [EntityIntentTypes.RUN_ACTION]: EntityActionIntentInterface
  [EntityIntentTypes.RUN_BATCH_ACTION]: EntityBatchActionIntentInterface
  [EntityIntentTypes.RESOURCE_UPDATED]: ResourceUpdatedInterface
  [EntityIntentTypes.AGGREGATION_DETAILS]: EntityAggregationIntentInterface
  [EntityIntentTypes.CONTEXT_CHANGE]: ContextDictionary
  [EntityIntentTypes.ERROR]: EntityErrorInterface
}

export enum WidgetComponentEvents {
  REGION_FILTER = 'component.region.filter',
}

export type WidgetComponentEventPayload = {
  [WidgetComponentEvents.REGION_FILTER]: RegionFilterPayload
}
