<template>
  <v-container fluid>
    <v-row>
      <v-col class="pa-0">
        <tt-tile class="tile">
          <tt-attr-img slot="avatar" name="employee.avatar" />
          <tt-attr slot="title" name="employee.name" class="font-weight-bold" />
          <template #line3>
            <div class="font-weight-medium d-flex">
              <tt-attr name="leaveType.name" translate-output />
              <span class="pr-1">:</span>
              <div class="d-flex">
                <tt-attr name="startDateTime" format="asDate" />
                <span class="px-1">-</span>
                <tt-attr name="endDateTime" format="asDate" />
                <v-tooltip
                  v-if="showPastDateWarning"
                  bottom
                  :open-delay="tooltipOpenDelay"
                >
                  <template #activator="{ on }">
                    <v-icon
                      small
                      class="warning-color pl-2 align-self-baseline"
                      v-on="on"
                    >
                      mdi-alert
                    </v-icon>
                  </template>
                  <span v-text="$t(tooltipText)" />
                </v-tooltip>
              </div>
            </div>
          </template>
          <template #line4>
            <div class="font-weight-medium d-flex">
              <span class="pr-1">{{ startTimeText }}:</span>
              <tt-attr name="startDateTime" format="asTime" />
              <span class="px-1">-</span>
              <span class="pr-1">{{ endTimeText }}:</span>
              <tt-attr name="endDateTime" format="asTime" />
            </div>
          </template>
          <template #line5>
            <div>
              <span class="pr-1" v-text="$t(affectedShiftsText)" />
              <LeaveRequestsAffectedShiftsCounter in-details />
            </div>
            <div>
              <span class="pr-1" v-text="$t(SLItext)" />
              <ServiceLevelImpactPercentage in-details />
            </div>
          </template>
        </tt-tile>
      </v-col>
    </v-row>
  </v-container>
</template>

<script lang="ts">
import Vue, { VueConstructor } from 'vue'
import { ItemHookProvider } from '@/tt-widget-entity-flow/types'
import { TOOLTIP_OPEN_DELAY } from '@/helpers/constants'
import { isDateInPast } from '@/helpers/dates/isDateInPast'
import { LeaveRequestsStatus } from '@/tt-widget-views/leave-management/types'

export default (Vue as VueConstructor<Vue & ItemHookProvider>).extend({
  name: 'LeaveRequestsTileListView',
  inject: ['getItemHook'],
  computed: {
    startTimeText(): string {
      return this.$t('res.shifts.actions.log-time.attr.startTime.label')
    },
    endTimeText(): string {
      return this.$t('res.shifts.actions.log-time.attr.endTime.label')
    },
    affectedShiftsText(): string {
      return 'res.leave-requests.extensions.affectedShifts.labels.label'
    },
    SLItext(): string {
      return 'tt-entity-design.leave-management.leave-requests.service-level-impact'
    },
    tooltipOpenDelay(): number {
      return TOOLTIP_OPEN_DELAY
    },
    requestStatus(): LeaveRequestsStatus {
      return this.getItemHook().getRawValue('status')
    },
    requestStartDate(): string {
      return this.getItemHook().getRawValue('startDateTime')
    },
    statusIsPending(): boolean {
      return (
        this.requestStatus === LeaveRequestsStatus.PENDING ||
        this.requestStatus === LeaveRequestsStatus.PENDING_CANCELLATION
      )
    },
    showPastDateWarning(): boolean {
      return isDateInPast(this.requestStartDate) && this.statusIsPending
    },
    tooltipText(): string {
      return 'tt-entity-design.leave-management.leave-requests.past-date'
    },
  },
  created() {
    this.getItemHook().addAttribute('employee.jobTitle')
    this.getItemHook().addAttribute('status')
  },
})
</script>

<style scoped>
.warning-color {
  color: var(--v-ttWarning-base);
}
</style>
