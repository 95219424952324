<template>
  <MetadataLoadingGuard>
    <v-container fluid>
      <div class="d-flex">
        <div class="d-flex flex-column justify-center pa-2">
          <v-card class="pa-2" color="bg-grey3" elevation="0">
            <h6 class="caption d-flex justify-end text-right text-no-wrap">
              <span class="mr-2" v-text="$t(startLabel)" />
              <tt-attr
                name="startDateTime"
                format="asDateTime"
                value-css-class="font-weight-bold"
              />
            </h6>
            <h6 class="caption d-flex justify-end text-right text-no-wrap">
              <span class="mr-2" v-text="$t(endLabel)" />
              <tt-attr
                name="endDateTime"
                format="asDateTime"
                value-css-class="font-weight-bold"
              />
            </h6>
          </v-card>
        </div>
        <div>
          <tt-tile class="tile" :avatar-size="48">
            <tt-attr-img slot="avatar" name="employee.avatar" />
            <tt-attr
              slot="title"
              name="employee.name"
              class="font-weight-bold"
            />
            <tt-attr
              slot="line2"
              name="employee.jobTitle"
              prepend-icon="mdi-account-circle"
              prepend-icon-color="green"
            />
          </tt-tile>
        </div>
        <div
          v-if="id"
          class="d-flex align-items-center justify-center align-self-center"
        >
          <LeaveRequestsActionBar :actions="supportedActions" />
        </div>
      </div>
    </v-container>
  </MetadataLoadingGuard>
</template>
<script lang="ts">
import Vue, { VueConstructor } from 'vue'
import { Action } from '@/tt-widget-factory/services/resource-action/types'
import LeaveRequestsActionBar from '@/tt-entity-design/src/components/leave-requests/LeaveRequestsActionBar.vue'
import MetadataLoadingGuard from '../../../../components/MetadataLoadingGuard.vue'
import { ItemHookProvider } from '@/tt-widget-entity-flow/types'

export default (Vue as VueConstructor<Vue & ItemHookProvider>).extend({
  name: 'LeavePoliciesPendingLeaveRequestInfo',
  components: {
    LeaveRequestsActionBar,
    MetadataLoadingGuard,
  },
  inject: ['getItemHook'],
  computed: {
    id(): number {
      return this.getItemHook().getEntityId()
    },
    startLabel(): string {
      return 'res.leave-requests.attr.startDateTime.label'
    },
    endLabel(): string {
      return 'res.leave-requests.attr.endDateTime.label'
    },
    supportedActions(): Action[] {
      return [
        { actionName: 'approve', icon: '' },
        { actionName: 'decline', icon: '' },
      ]
    },
  },
  created() {
    this.getItemHook().addAttribute('employee.id')
    this.getItemHook().addAttribute('leaveType.id')
  },
})
</script>
