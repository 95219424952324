<template>
  <v-card flat>
    <EscalationRulesField />
    <TicketTypesSection
      :has-to-display-task-type-field="hasToDisplayTaskTypeField"
    />
  </v-card>
</template>
<script lang="ts">
import Vue from 'vue'

import EscalationRulesField from './EscalationRulesField.vue'
import TicketTypesSection from './TicketTypesSection.vue'

export default Vue.extend({
  name: 'OperationCenterEscalationPoliciesForm',
  components: {
    EscalationRulesField,
    TicketTypesSection,
  },
  computed: {
    hasToDisplayTaskTypeField(): boolean {
      //@ts-ignore
      return this.$attrs.value?.hasToDisplayTaskTypeField
    },
  },
})
</script>
