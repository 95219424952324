import { VueConstructor } from 'vue'

import AssignedEmployeesClickableColumn from './setup/leave-policies/AssignedEmployeesClickableColumn.vue'
import AssignedPolicyItemsClickableColumn from './setup/leave-policies/AssignedPolicyItemsClickableColumn.vue'
import BasicInformationClickableColumn from './setup/leave-policies/BasicInformationClickableColumn.vue'
import LeavePoliciesCarryoverColumn from './setup/leave-policies/carryover/LeavePoliciesCarryoverColumn.vue'
import LeavePoliciesCarryoverDialog from './setup/leave-policies/carryover/LeavePoliciesCarryoverDialog.vue'
import LeavePoliciesPendingLeaveRequestInfo from './setup/leave-policies/carryover/LeavePoliciesPendingLeaveRequestInfo.vue'

import ClickableNameColumn from './setup/leave-policy-items/ClickableNameColumn.vue'
import LeavePolicyItemsPartOfColumn from './setup/leave-policy-items/LeavePolicyItemsPartOfColumn.vue'
import LeavePolicyItemsQuantityColumn from './setup/leave-policy-items/LeavePolicyItemsQuantityColumn.vue'

import LeaveRequestsTileList from './employee/overview/LeaveRequestsTileList.vue'
import OverviewRequestsButtons from './employee/overview/OverviewRequestsButtons.vue'

import LeaveHistoryAmountColumn from './employee/leaveHistory/LeaveHistoryAmountColumn.vue'
import LeaveHistoryDatesColumn from './employee/leaveHistory/LeaveHistoryDatesColumn.vue'

import TransactionActivityDateColumn from './employee/transactionActivity/TransactionActivityDateColumn.vue'
import TransactionActivityTransactionTypeColumn from './employee/transactionActivity/TransactionActivityTransactionTypeColumn.vue'

import LeaveRequestsPastDateWarningColumn from './leave-requests/pending/LeaveRequestsPastDateWarningColumn.vue'
import AdjustBalanceDialog from './employee/settings/AdjustBalanceDialog.vue'
import AvailableDays from './employee/settings/AvailableDays.vue'
import StatusChip from './StatusChip.vue'
import SingleEmployeeSwitchPoliciesForm from './setup/leave-policies/SingleEmployeeSwitchPoliciesForm.vue'

export default {
  install(Vue: VueConstructor) {
    Vue.component(
      'BasicInformationClickableColumn',
      BasicInformationClickableColumn,
    )
    Vue.component(
      'AssignedPolicyItemsClickableColumn',
      AssignedPolicyItemsClickableColumn,
    )
    Vue.component(
      'AssignedEmployeesClickableColumn',
      AssignedEmployeesClickableColumn,
    )
    Vue.component('ClickableNameColumn', ClickableNameColumn)
    Vue.component('AdjustBalanceDialog', AdjustBalanceDialog)
    Vue.component('AvailableDays', AvailableDays)
    Vue.component('OverviewRequestsButtons', OverviewRequestsButtons)
    Vue.component('LeaveRequestsTileList', LeaveRequestsTileList)
    Vue.component('LeaveHistoryDatesColumn', LeaveHistoryDatesColumn)
    Vue.component('LeaveHistoryAmountColumn', LeaveHistoryAmountColumn)
    Vue.component(
      'TransactionActivityDateColumn',
      TransactionActivityDateColumn,
    )
    Vue.component(
      'TransactionActivityTransactionTypeColumn',
      TransactionActivityTransactionTypeColumn,
    )
    Vue.component('LeavePolicyItemsPartOfColumn', LeavePolicyItemsPartOfColumn)
    Vue.component(
      'LeavePolicyItemsQuantityColumn',
      LeavePolicyItemsQuantityColumn,
    )
    Vue.component(
      'LeaveRequestsPastDateWarningColumn',
      LeaveRequestsPastDateWarningColumn,
    )
    Vue.component('LeavePoliciesCarryoverColumn', LeavePoliciesCarryoverColumn)
    Vue.component('LeavePoliciesCarryoverDialog', LeavePoliciesCarryoverDialog)
    Vue.component(
      'LeavePoliciesPendingLeaveRequestInfo',
      LeavePoliciesPendingLeaveRequestInfo,
    )
    Vue.component('StatusChip', StatusChip)
    Vue.component(
      'SingleEmployeeSwitchPoliciesForm',
      SingleEmployeeSwitchPoliciesForm,
    )
  },
}
