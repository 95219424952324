import { modularManager, PresetLevels, PresetTypes } from '@/tt-app-modular'

import MobileDevicesMapMarker from './MobileDevicesMapMarker.vue'
import { Resources } from '../../types'

export default {
  install(Vue) {
    Vue.component('MobileDevicesMapMarker', MobileDevicesMapMarker)

    modularManager.addResourcePreset(
      Resources.MOBILE_DEVICES,
      PresetTypes.MAP_MARKER,
      {
        title: 'Default',
        data: { is: 'MobileDevicesMapMarker' },
        level: PresetLevels.DEFAULTS,
      },
    )
  },
}
