<template>
  <TChipLabel v-if="isFlagDay" v-bind="taskTypeChipProps" />
</template>

<script lang="ts">
import Vue from 'vue'

export default Vue.extend({
  name: 'TaskTypesChip',
  props: {
    isFlagDay: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  computed: {
    chipText(): string {
      return this.isFlagDay
        ? this.$t('calendar_exceptions_view.tabs.flag_day')
        : ''
    },
    taskTypeChipProps(): Record<string, unknown> {
      return {
        textKey: this.chipText,
        modifiers: {
          xSmall: true,
        },
        type: 'LABEL',
        isSmall: false,
      }
    },
  },
})
</script>
