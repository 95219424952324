<template>
  <EntityRelationListEditor
    :resource="resource"
    :relation-list-attribute="operatorsAttr"
    :can-add="canAddOperator"
    :can-delete="canRemoveOperator"
    :run-add="addOperator"
    :run-delete="deleteOperator"
    :add-query-options="addOperatorQueryOptions"
  />
</template>

<script lang="ts">
import Vue, { VueConstructor } from 'vue'
import { CollectionQuery } from '@/tt-widget-components/types'
import { ItemHookProvider } from '@/tt-widget-entity-flow/types'

import { updateDOM } from '@/helpers/dom/updateDOM'
import ListWidgetHook from '@/tt-widget-components/widgets/List/ListWidgetHook'
import { LayoutWindowEvent } from '@/tt-app-layout'
import { operationCenterResourceUpdated } from '@/tt-entity-design/src/components/operation-centers/utils'
import {
  OPERATORS,
  ADD_OPERATOR,
  REMOVE_OPERATOR,
  OPERATION_CENTERS,
  EMPLOYEE_NOT_IN_OP_CENTER_FILTER,
} from './constants'
import EntityRelationListEditor from '@/tt-widget-entity-flow/components/EntityRelationListEditor.vue'
import {
  AddActionProperty,
  DeleteProperty,
} from '@/tt-entity-design/src/components/operation-centers/types'

export default (Vue as VueConstructor<Vue & ItemHookProvider>).extend({
  name: 'OperationCentersEmployees',
  components: {
    EntityRelationListEditor,
  },
  inject: ['getItemHook'],
  data: () => ({
    hook: null as ListWidgetHook | null,
    isLoading: false,
  }),
  computed: {
    resource(): string {
      return OPERATION_CENTERS
    },
    operatorsAttr(): string {
      return OPERATORS
    },
    canAddOperator(): boolean {
      return this.getItemHook().isActionAvailable(ADD_OPERATOR)
    },
    canRemoveOperator(): boolean {
      return this.getItemHook().isActionAvailable(REMOVE_OPERATOR)
    },
    addOperatorQueryOptions(): Partial<CollectionQuery> {
      return {
        returnCount: false,
        customFilters: [
          {
            filterName: EMPLOYEE_NOT_IN_OP_CENTER_FILTER,
            value: this.operationCenterId,
          },
        ],
      }
    },
    operationCenterId(): number {
      return this.getItemHook().getEntityId()
    },
  },
  methods: {
    async addOperator(newEmployeeId: number) {
      this.isLoading = true
      await updateDOM()

      const payload: Record<AddActionProperty, number> = {
        operator: newEmployeeId,
      }

      await this.$auth
        .getApi()
        .doAction(
          OPERATION_CENTERS,
          this.operationCenterId,
          ADD_OPERATOR,
          payload,
        )
        .then(async () => {
          await this.afterUpdate()
        })
        .catch((error) => {
          this.showError(error)

          this.isLoading = false
        })
    },
    async deleteOperator(employeeId: number) {
      await updateDOM()

      const payload: Record<DeleteProperty, number> = {
        operator: employeeId,
      }

      await this.$auth
        .getApi()
        .doAction(
          OPERATION_CENTERS,
          this.operationCenterId,
          REMOVE_OPERATOR,
          payload,
        )
        .then(async () => {
          await this.afterUpdate()
        })
        .catch((error) => {
          this.showError(error)
        })
    },
    showError(error: Error) {
      this.$eventManager.dispatchEvent(LayoutWindowEvent.SNACK_ERROR, {
        message: error.message,
      })
    },
    async afterUpdate() {
      const updateOperatorList = () => this.hook?.update({ disableCache: true })
      operationCenterResourceUpdated(this.$eventManager)

      await Promise.all([updateOperatorList()])
      this.isLoading = false
    },
  },
})
</script>
