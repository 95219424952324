<template>
  <v-tooltip :disabled="hasAccess" bottom>
    <template #activator="{ on: tooltip }">
      <div v-on="tooltip">
        <component :is="menuType" v-model="menu" v-bind="menuProps">
          <template #activator="{ on: menu }">
            <div
              :data-analytics="`core:entity-filter:button|resource:${resource}|attribute:${attributeName}`"
            >
              <v-icon
                v-if="buttonIcon"
                small
                class="icon-button--activator"
                v-on="menu"
                v-text="buttonIcon"
              />

              <FilterButtonActivator
                v-else
                :has-value="hasValue"
                :has-access="hasAccess"
                :disabled="!hasAccess"
                :label="filterName"
                :tooltip-label="tooltipLabel"
                @clear="clear"
                v-on="menu"
              >
                <template #button-left>
                  <slot name="button-left" :selected="hasValue" />
                </template>
              </FilterButtonActivator>
            </div>
          </template>

          <v-card
            v-if="menu"
            min-height="90"
            class="level0 pa-0 ma-0 entity-toolbar-filter--content"
            :data-analytics="`core:entity-filter:controls|resource:${resource}|attribute:${attributeName}`"
          >
            <v-toolbar
              v-if="menuType === 'v-dialog'"
              dense
              short
              flat
              class="level1"
            >
              <v-icon class="mr-2"> mdi-filter-variant </v-icon>
              <span v-text="filterLabel" />
            </v-toolbar>

            <component
              :is="component"
              v-model="model"
              v-bind="{
                attributeMeta,
                attributeName,
                options,
                onUpdate,
              }"
              @cancel="close"
              @clear="clear"
              @apply="apply"
            />

            <v-toolbar dense short flat class="level1">
              <v-btn text color="grey" @click="clear">
                <span v-text="$t('common.clear.btn')" />
              </v-btn>
              <v-spacer />
              <v-btn color="green" text @click="apply">
                <span v-text="$t('common.apply.btn')" />
              </v-btn>
            </v-toolbar>
          </v-card>
        </component>
      </div>
    </template>
    <span
      v-text="$t(`entity_toolbar_filter_button.filter.not_resource_access`)"
    />
  </v-tooltip>
</template>

<script lang="ts">
import ResourcePermissionAuditor, {
  getResourcePermissionAuditorServices,
} from '@/tt-widget-factory/services/resource-meta/ResourcePermissionAuditor'
import { FieldTypes } from '@/tt-widget-factory/services/resource-meta/types'

import BaseFilterButton from './BaseFilterButton'
import FilterButtonActivator from './FilterButtonActivator.vue'
import { EntityFilterEvent } from '../types'
import { getFilterName, getFilterLabel } from '../util'
import { PropType } from 'vue'
import { AttributeFilterOptions, Filter } from '@/tt-widget-components'
import FilterValueParser from '../FilterValueParser'
import { isTemporalFieldType } from '../temporal-filters/field-types-validator'

export default BaseFilterButton.extend({
  name: 'EntityToolbarFilterButton',
  components: { FilterButtonActivator },
  inheritAttrs: false,
  data() {
    return {
      filterLabel: '',
    }
  },
  props: {
    attributeName: {
      type: String,
      default: '',
    },
    isContextFilter: {
      type: Boolean,
      default: false,
    },
    menuType: {
      type: String,
      default: 'v-menu',
    },
    prependIcon: {
      type: Boolean,
      default: false,
    },
    resource: {
      type: String,
      default: '',
    },
    buttonIcon: {
      type: String,
      default: null,
    },
    options: {
      type: Object as PropType<AttributeFilterOptions>,
      default: () => ({}),
    },
    label: {
      type: String,
      default: null,
    },
  },
  computed: {
    filterName(): string {
      const translateFunction = (key: string): string => this.$tc(key)

      const filterName = this.label
        ? getFilterLabel(translateFunction(this.label), this.parsedValue)
        : getFilterName(
            this.filterValue,
            this.parsedValue,
            this.$appContext.widgetServices.resourceMetaManager,
            translateFunction,
            this.attributeName,
            this.resource,
          )

      if (this.hasValue || this.parsedValue) {
        return this.isDateRangeAttribute ? this.parsedValue : filterName
      }

      return filterName
    },
    tooltipLabel(): string | null {
      const type = this.attributeMeta.type

      return isTemporalFieldType(type) && this.value
        ? FilterValueParser.getFilterValue(
            this.attributeMeta,
            this.value,
            this.userPreferences,
          )
        : null
    },
    menuProps(): Record<string, any> {
      if (this.$vuetify.breakpoint.mdAndUp) {
        return {
          nudgeBottom: 30,
          bottom: true,
          closeOnContentClick: false,
          left: true,
          attach: this.isContextFilter,
        }
      } else {
        return {
          closeOnContentClick: false,
        }
      }
    },
    filterText(): string {
      return this.$t('entity_toolbar_filter_button.filter.filter', {
        attributeLabel: this.attributeLabel,
      }) as string
    },
    hasAccess(): boolean {
      const canViewResource = () =>
        ResourcePermissionAuditor.canViewResource(
          getResourcePermissionAuditorServices(this.$appContext),
          this.attributeMeta.relation.resource,
        )

      return this.attributeMeta.type === FieldTypes.Relation
        ? canViewResource()
        : true
    },
  },
  methods: {
    onUpdate(filter: Filter) {
      this.model = filter

      this.apply()
    },
  },
  watch: {
    menu(isOpen: boolean) {
      this.$eventManager.dispatchEvent(
        EntityFilterEvent.TOOLBAR_MENU_ACTIVE,
        isOpen,
      )
    },
  },
})
</script>

<style scoped>
.entity-toolbar-filter--content {
  width: 320px;
}
</style>
