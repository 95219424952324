import { PluginFunction } from 'vue'

const ManageTeamsView = () => import('./views/ManageTeamsView.vue')
const ManageTeamsPage = () => import('./pages/ManageTeamsPage.vue')
const ManageTeamsWizard = () => import('./components/ManageTeamsWizard.vue')
const ManageTeamsTableWidget = () =>
  import('./components/ManageTeamsTableWidget.vue')
const ManageTeamsAssignment = () =>
  import('./components/ManageTeamsAssignment.vue')
const ManageTeamsActionsColumn = () =>
  import('./components/ManageTeamsActionsColumn.vue')

export {
  ManageTeamsView,
  ManageTeamsPage,
  ManageTeamsWizard,
  ManageTeamsTableWidget,
  ManageTeamsAssignment,
  ManageTeamsActionsColumn,
}

export const ManageTeamsViewPlugin: PluginFunction<void> = (Vue) => {
  // View (entry point)
  Vue.component('ManageTeamsView', ManageTeamsView)

  // Pages
  Vue.component('ManageTeamsPage', ManageTeamsPage)

  // Components
  Vue.component('ManageTeamsWizard', ManageTeamsWizard)
  Vue.component('ManageTeamsTableWidget', ManageTeamsTableWidget)
  Vue.component('ManageTeamsAssignment', ManageTeamsAssignment)
  Vue.component('ManageTeamsActionsColumn', ManageTeamsActionsColumn)
}
