<template>
  <tt-attr-row-card v-bind="$props">
    <tt-attr-row name="reportTemplate" />
    <tt-attr-row name="isDispatcherField" />
    <tt-attr-row name="type" />
    <tt-attr-row name="label" />
    <tt-attr-row v-if="isSignature" name="extra" />
    <tt-attr-row v-if="isList" name="listItems" />
    <tt-attr-row name="required" />
    <tt-attr-row v-if="isText" name="confidential" />
    <tt-attr-row name="containsPii" :label="containsPiiLabel" />
    <tt-attr-row name="fieldTag" />
  </tt-attr-row-card>
</template>

<script lang="ts">
import Vue, { VueConstructor } from 'vue'

import { ItemHookProvider } from '@/tt-widget-entity-flow/types'
import { ReportTemplateFieldType } from './types'

type VueWithInjections = VueConstructor<Vue & ItemHookProvider>

export default (Vue as VueWithInjections).extend({
  name: 'ReportTemplateFieldsDetail',
  inject: ['getItemHook'],
  computed: {
    isList(): boolean {
      return [
        ReportTemplateFieldType.List,
        ReportTemplateFieldType.ListMultiple,
      ].includes(this.type)
    },
    isSignature(): boolean {
      return this.type === ReportTemplateFieldType.Signature
    },
    isText(): boolean {
      return [
        ReportTemplateFieldType.Text,
        ReportTemplateFieldType.Textarea,
        ReportTemplateFieldType.TextNumber,
      ].includes(this.type)
    },
    type(): ReportTemplateFieldType {
      return this.getItemHook().getRawValue('type')
    },
    containsPiiLabel(): string {
      return this.$tc(
        'tt-entity-design.report-template-fields.form.containsPii.label',
      )
    },
  },
})
</script>
