import { Resources } from '@/tt-entity-design/src/types'

import { MobileScheduleOccurrencesStatus } from './types'

export const { MOBILE_SCHEDULE_OCCURRENCES } = Resources

/**
 * @todo: Remove `ts-ignore` below when SEU-3280 reaches the latest portal
 */
export const MOBILE_SCHEDULE_OCCURRENCES_STATUS: Record<
  string,
  MobileScheduleOccurrencesStatus
> = {
  // @ts-ignore
  SCHEDULED: 'SCHEDULED',
  // @ts-ignore
  ASSIGNED: 'ASSIGNED',
  // @ts-ignore
  ONSITE: 'ONSITE',
  // @ts-ignore
  COMPLETED: 'COMPLETED',
  // @ts-ignore
  EN_ROUTE: 'EN_ROUTE',
  // @ts-ignore
  TO_REASSIGN: 'TO_REASSIGN',
  // @ts-ignore
  MISSED: 'MISSED',
  // @ts-ignore
  CANCELLED: 'CANCELLED',
  // @ts-ignore
  PENDING_REASSIGN: 'PENDING_REASSIGN',
  // @ts-ignore
  REASSIGN_COMPLETED: 'REASSIGN_COMPLETED',
}
