<template>
  <v-bottom-sheet
    v-model="layoutManager.state.bottomSheet.state"
    :fullscreen="isMobile"
  >
    <v-card
      :style="`height: 600px; overflow-y: scroll; left: ${left}px; width: calc(100% - ${left}px);`"
    >
      <AppWindow
        v-model="layoutManager.state.bottomSheet.state"
        :landing-page="layoutManager.state.bottomSheet.page"
        closable
        :event="eventType.BOTTOM_SHEET"
      />
    </v-card>
  </v-bottom-sheet>
</template>

<script lang="ts">
import BaseAppLayoutComponent from '@/tt-app-layout/components/BaseAppLayoutComponent'

export default BaseAppLayoutComponent.extend({
  name: 'AppLayoutBottomSheet',
  props: {
    left: {
      type: Number,
      default: 0,
    },
  },
})
</script>
