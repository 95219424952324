<template>
  <v-card min-width="328" color="toolbar3" elevation="0">
    <div class="d-flex flex-md-column flex-sm-row align-center pa-6">
      <EmployeeBadge
        :avatar="employeeInfo.avatar"
        :job-title="employeeInfo.jobTitle"
        :name="employeeInfo.name"
        :hours-per-day="hoursPerDay"
      />
      <div
        class="d-flex flex-column flex-sm-grow-1 justify-md-center text-center align-self-stretch"
      >
        <EmployeeProfileInfoRow
          :title="leavePolicyLabel"
          :value="leavePolicyName"
          :divider="!isSmall"
          :has-policy-assigned="hasPolicyAssigned"
          :tooltip-icon="'mdi-calendar'"
          :tooltip-text="leavePolicyDates"
        >
          <template #value>
            <slot name="policy"></slot>
          </template>
          <EmployeeInfoChip
            v-if="hasLeavePolicyHoursPerDay"
            :info="$t(primaryLabel)"
            class="ml-4"
          />
        </EmployeeProfileInfoRow>

        <EmployeeProfileInfoRow
          :title="employeeClassLabel"
          :value="employeeClass"
        >
          <EmployeeInfoChip
            v-if="hasEmployeeClassHoursPerDay && !hasLeavePolicyHoursPerDay"
            :info="primaryLabel"
            class="ml-4"
          />
        </EmployeeProfileInfoRow>
        <slot name="actions"></slot>
      </div>
    </div>
  </v-card>
</template>
<script lang="ts">
import Vue from 'vue'
import EmployeeInfoChip from './EmployeeInfoChip.vue'
import EmployeeProfileInfoRow from './EmployeeProfileInfoRow.vue'
import EmployeeBadge from './EmployeeBadge.vue'
import { DEFAULT_HOURS_PER_DAY } from '../constants'
import { formatDateRange } from '@/helpers/formats/dates'

export default Vue.extend({
  name: 'EmployeeEmploymentProfile',
  components: {
    EmployeeInfoChip,
    EmployeeProfileInfoRow,
    EmployeeBadge,
  },
  props: {
    employeeInfo: {
      type: Object,
      required: true,
    },
  },
  computed: {
    hasEmployeeClassHoursPerDay(): boolean {
      return !!this.employeeInfo?.employmentProfile?.employeeClass
        ?.payHoursPerDay
    },
    hasLeavePolicyHoursPerDay(): boolean {
      return !!this.employeeInfo?.currentLeavePolicy?.payHoursPerDay
    },
    employeeClass(): string {
      return this.employeeInfo?.employmentProfile?.employeeClass?.name ?? '-'
    },
    employeeClassLabel(): string {
      return 'tt-entity-design.leave-management.employee.employmentProfile.employee-class-label'
    },
    hasPolicyAssigned(): boolean {
      return !!this.employeeInfo?.currentLeavePolicy
    },
    leavePolicyName(): string {
      return this.employeeInfo?.currentLeavePolicy?.name ?? '-'
    },
    leavePolicyLabel(): string {
      return 'tt-entity-design.leave-management.employee.employmentProfile.leave-policy-label'
    },
    leavePolicyDates(): string {
      const startDate = this.employeeInfo?.currentLeavePolicy?.startPeriodDate
      const endDate = this.employeeInfo?.currentLeavePolicy?.endPeriodDate

      return formatDateRange(
        { startDate, endDate },
        this.$appContext.authModule.getUserPreferences(),
      )
    },
    hoursPerDay(): string {
      const key =
        'tt-entity-design.leave-management.employee.employmentProfile.hours-per-day'

      const employeeClassHoursPerDay =
        this.employeeInfo?.employmentProfile?.employeeClass?.payHoursPerDay
      const leavePolicyHoursPerDay =
        this.employeeInfo?.currentLeavePolicy?.payHoursPerDay

      const value =
        leavePolicyHoursPerDay ??
        employeeClassHoursPerDay ??
        DEFAULT_HOURS_PER_DAY

      return this.$tc(key, value)
    },
    primaryLabel(): string {
      return 'tt-entity-design.leave-management.employee.employmentProfile.primary-label'
    },
    isSmall(): boolean {
      return this.$vuetify.breakpoint.smAndDown
    },
  },
})
</script>
