<template>
  <div>
    <GenericForm
      v-if="form && !loading"
      v-bind="form"
      :cancellable="true"
      :closable="false"
      cancel-btn-text="mobile_schedule_group_create_wizard.tab.skip"
      v-on="$listeners"
      @submit:error="onSubmitError($event.error)"
      @submit:success="$emit('submit:success')"
    />
    <div v-if="loading">
      <v-skeleton-loader type="card-heading" />
      <v-divider />
      <v-skeleton-loader class="field-loader" tile type="card-heading" />
      <v-skeleton-loader class="field-loader" tile type="card-heading" />
      <v-skeleton-loader class="field-loader" tile type="card-heading" />
      <v-divider class="mt-4" />
      <v-skeleton-loader type="actions" />
    </div>
  </div>
</template>
<script lang="ts">
import Vue, { PropType } from 'vue'

import { DialogFormInterface } from '@/tt-app-layout'
import { Resources } from '@/tt-entity-design/src/types'
import { EntityActionIntent } from '@/tt-widget-entity-flow'
import {
  EntityItemHook,
  EntityItemViewInterface,
} from '@/tt-widget-entity-flow/EntityItemHook'
import { FormOptions } from '@tracktik/tt-json-schema-form'
import { DialogFormBuilder } from '@/helpers/dialog-form-builder'
import {
  displayErrorMessages,
  parseErrorMessages,
} from '@/tt-widget-entity-flow/intents/helpers'
import { GenericFormError } from '@/tt-app-layout/components/GenericForm.vue'
import SiteTaskSchedulesProratedInput from './SiteTaskSchedulesProratedInput.vue'
import { getFormDefinitionName } from '@/tt-widget-entity-flow/intents/helpers'
import { Entity } from '@/tt-widget-components/base/EntityCollector/type'

const RESOURCE = Resources.SITE_TASK_SCHEDULES
const actionName = 'add-periodic'

type SiteTaskSchedulesPeriodicStepData = {
  loading: boolean
  entityActionIntent: EntityActionIntent
  formBuilder: DialogFormBuilder
  beginServiceDate: string
  initialModel: Entity
}

export default Vue.extend({
  name: 'SiteTaskSchedulesPeriodicStep',
  props: {
    entityId: { type: Number, default: undefined },
    weekDayStartSetting: {
      type: String,
      default: null,
    },
    isEditForm: {
      type: Boolean,
      default: false,
    },
  },
  data: (): SiteTaskSchedulesPeriodicStepData => ({
    loading: false,
    entityActionIntent: null,
    formBuilder: null,
    beginServiceDate: null,
    initialModel: null,
  }),
  computed: {
    form(): DialogFormInterface | null {
      return this.formBuilder?.getState() ?? null
    },
    entityReference(): EntityItemViewInterface {
      return {
        entity: { id: this.entityId },
        entityId: this.entityId,
        resourceName: RESOURCE,
      }
    },
  },
  async created() {
    await this.loadFormBuilder()
  },
  methods: {
    async loadFormBuilder() {
      this.entityActionIntent = new EntityActionIntent(this.$appContext)

      const itemHook = new EntityItemHook(
        this.$appContext,
        this.entityReference,
      )

      await this.loadSiteTask()

      const formOptions: FormOptions = {
        definitions: {
          [getFormDefinitionName(RESOURCE, actionName)]: {
            properties: {
              proRatedQuantity: {
                is: SiteTaskSchedulesProratedInput,
                props: {
                  showSuggestion: this.isEditForm,
                  beginServiceDate: this.beginServiceDate,
                  weekDayStartSetting: this.weekDayStartSetting,
                },
              },
            },
          },
        },
      }
      this.formBuilder = await this.entityActionIntent.getDialogFormBuilder({
        actionName: actionName,
        entityId: this.entityId,
        formOptions: formOptions,
        itemHook: itemHook,
        resourceName: RESOURCE,
        title: '',
        initialModel: this.initialModel ? this.initialModel : {},
      })
    },
    async loadSiteTask() {
      this.loading = true
      const api = this.$appContext.authModule.getApi()
      try {
        const STResponse = await api.get<number, Entity>(
          Resources.SITE_TASK_SCHEDULES,
          this.entityId,
        )

        this.beginServiceDate = STResponse.beginServiceDate as string
        if (STResponse.periodicSchedule) {
          this.initialModel = await api.get(
            Resources.PERIODIC_SCHEDULES,
            STResponse.periodicSchedule,
          )
        }
      } catch (err) {}

      this.loading = false
    },
    onSubmitError(error: GenericFormError) {
      const messages = parseErrorMessages({
        error,
        resourceName: RESOURCE,
      })
      displayErrorMessages(messages, this.$appContext.eventManager)
      this.$emit('submit:error', error)
    },
  },
})
</script>
