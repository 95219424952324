import { AuthModule } from '@tracktik/tt-authentication'
import { DevConsole } from '@/plugins/DevConsole'
import { AssetTrackingContextAccount } from '../../types'

const STORAGE_KEY = 'TT_ASSET-TRACKING_CONTEXT-ACCOUNTS'
const MAX_ACCOUNTS_STORED = 5

type ServerUrl = string
type UserId = string
type StateKey = `${ServerUrl}_${UserId}`
type TypeContextAccountPersister = {
  save: (accountInfo?: AssetTrackingContextAccount) => void
  get: () => AssetTrackingContextAccount[]
  remove: (accountId: number) => void
}

/**
 * Service to read / write the context account into the local storage,
 * using the server URL and user ID as the key.
 *
 * Don't confuse this with the `ContextAccountHandler` which is used to manage the context account
 * in the application level.
 */
export const ContextAccountPersister = (
  authModule: AuthModule,
): TypeContextAccountPersister => {
  const getUserKey = (): StateKey | null => {
    const serverUrl = authModule.getServerUrl()?.trim()
    const userId = authModule.getUserId()

    if (!serverUrl || !userId) {
      DevConsole.warn('Invalid server URL or user ID for context persistence')

      return null
    }

    return `${serverUrl}_${userId}`
  }

  const readFromStorage = (): Record<string, AssetTrackingContextAccount[]> => {
    try {
      const serializedData = localStorage.getItem(STORAGE_KEY)

      return serializedData ? JSON.parse(serializedData) : {}
    } catch (e) {
      DevConsole.warn(`Couldn't read persisted data from local storage`, e)

      return {}
    }
  }

  const writeToStorage = (
    data: Record<string, AssetTrackingContextAccount[]>,
  ) => {
    try {
      localStorage.setItem(STORAGE_KEY, JSON.stringify(data))
    } catch (e) {
      DevConsole.warn(`Couldn't write data to local storage`, e)
    }
  }

  return {
    save: (accountInfo?: AssetTrackingContextAccount) => {
      const key = getUserKey()
      if (!key) return

      const storageData = readFromStorage()
      let existingAccounts = storageData[key] || []

      /**
       * Remove old accounts to ensure backward compatibility
       * after removing "id" and "name" aliases.
       */
      existingAccounts = existingAccounts.filter(
        (account) => account.value !== accountInfo.id,
      )

      const updatedAccounts = accountInfo
        ? [
            accountInfo,
            ...existingAccounts.filter(
              (account) => account.id !== accountInfo.id,
            ),
          ].slice(0, MAX_ACCOUNTS_STORED)
        : existingAccounts

      writeToStorage({ ...storageData, [key]: updatedAccounts })
    },
    get: (): AssetTrackingContextAccount[] => {
      const key = getUserKey()
      if (!key) return []

      const storageData = readFromStorage()

      return storageData[key] ? [...storageData[key]] : []
    },
    remove: (accountId: number) => {
      const key = getUserKey()
      if (!key) return

      const storageData = readFromStorage()
      const updatedAccounts =
        storageData[key]?.filter((account) => account.id !== accountId) || []

      writeToStorage({ ...storageData, [key]: updatedAccounts })
    },
  }
}
