<template>
  <div>
    <json-field
      name="leavePolicy"
      resource="leave-policies"
      :block-creation="true"
      :additional-query-options="additionalQueryOptions"
      :attach="false"
    />
    <json-field name="transferBalance" />
  </div>
</template>
<script lang="ts">
import Vue, { PropType } from 'vue'
import { EntityCollectionRequestOptions } from 'tracktik-sdk/lib/common/entity-collection'

export default Vue.extend({
  name: 'SingleEmployeeSwitchPoliciesForm',
  props: {
    additionalQueryOptions: {
      type: Object as PropType<EntityCollectionRequestOptions>,
      required: true,
    },
  },
})
</script>
