<template>
  <div>
    <v-subheader>
      {{ $t('components.entity-preview.detail') }}
    </v-subheader>
    <tt-attr-row-card v-if="itemHook">
      <tt-attr-row name="type" />
      <tt-attr-row name="quantity" />
      <tt-attr-row name="proRatedQuantity" />
      <tt-attr-row name="multipleVisits" />
      <tt-attr-row name="optionalService" />
    </tt-attr-row-card>
    <v-row
      v-for="index in 6"
      v-else
      :key="index"
      class="mx-3 mt-2"
      style="height: 30px"
    >
      <v-col cols="4">
        <v-skeleton-loader type="text" />
      </v-col>
      <v-col cols="3">
        <v-skeleton-loader type="text" />
      </v-col>
    </v-row>
  </div>
</template>
<script lang="ts">
import Vue, { VueConstructor } from 'vue'

import { ItemHookProvider } from '@/tt-widget-entity-flow/types'
import { EntityItemHook } from '@/tt-widget-entity-flow/EntityItemHook'

import { Resources } from '../../types'
import { PeriodicSchedule } from './types'

const PeriodicScheduleKey = 'periodicSchedule'

export default (Vue as VueConstructor<Vue & ItemHookProvider>).extend({
  name: 'SiteTaskSchedulesPeriodicScheduleTab',
  inject: ['getItemHook'],
  provide(): ItemHookProvider {
    return {
      getItemHook: () => this.itemHook,
      relationPrefix: PeriodicScheduleKey,
    }
  },
  data() {
    return {
      periodicSchedule: null as { periodicSchedule: PeriodicSchedule } | null,
      itemHook: null as EntityItemHook | null,
    }
  },
  computed: {
    entityId(): number {
      return this.getItemHook().get('id')
    },
    resourceName(): string {
      return Resources.SITE_TASK_SCHEDULES
    },
  },
  async created() {
    this.periodicSchedule = await this.fetchPeriodicSchedule()
    this.itemHook = this.createItemHook()
  },
  methods: {
    createItemHook(): EntityItemHook {
      return new EntityItemHook(
        this.$appContext,
        {
          resourceName: this.resourceName,
          entityId: this.entityId,
          entity: this.periodicSchedule,
        },
        { fetchEnabled: false, hasFetched: true },
      )
    },
    fetchPeriodicSchedule(): Promise<{ periodicSchedule: PeriodicSchedule }> {
      return this.$auth.getApi().get(this.resourceName, this.entityId, {
        include: [PeriodicScheduleKey],
      })
    },
  },
})
</script>
