<template>
  <OperationCenterEscalationPoliciesTimeline />
</template>

<script lang="ts">
import Vue from 'vue'
import OperationCenterEscalationPoliciesTimeline from './OperationCenterEscalationPoliciesTimeline.vue'

export default Vue.extend({
  name: 'OperationCenterEscalationPoliciesDetail',
  components: { OperationCenterEscalationPoliciesTimeline },
})
</script>
