<template>
  <div>
    <ArrayField
      v-if="isArrayField || !isRelationField"
      v-bind="{
        ...$props,
        ...$attrs,
      }"
      v-on="$listeners"
    />
    <RelationField
      v-else
      v-bind="{
        resource: itemResource,
        disabled: isReadOnly,
        ...$props,
        ...$attrs,
        multiple: true,
      }"
      v-on="$listeners"
    />
  </div>
</template>

<script lang="ts">
import Vue, { VueConstructor, PropType } from 'vue'
import { EnumComponent } from '@tracktik/tt-json-schema-form'
import { FormHookProvider, NamespaceProvider } from '@/tt-widget-components'
import { TTC_RELATION_NAME } from '@/tt-entity-forms/components/utils/RelationField'

export default (
  Vue as VueConstructor<Vue & FormHookProvider & NamespaceProvider>
).extend({
  name: 'ArrayFieldWrapper',
  inject: ['namespace', 'formHook'],
  props: {
    name: String,
    itemProps: {
      type: Object as PropType<Record<string, unknown>>,
      default: null,
    },
  },
  computed: {
    isReadOnly(): boolean {
      return this.formHook().isReadOnly()
    },
    isArrayField(): boolean {
      return this.itemProps !== null
    },
    itemField(): EnumComponent {
      return this.formHook().getViewComponentProps(this.absoluteName).itemField
    },
    itemResource(): string {
      return this.itemField.props.$schema[TTC_RELATION_NAME]
    },
    isRelationField(): boolean {
      return this.itemField.props.definitionName === 'Relation'
    },
    absoluteName(): string {
      if (!this.name) {
        return ''
      }

      return this.name.includes('.')
        ? this.name
        : this.namespace
        ? `${this.namespace}.${this.name}`
        : this.name
    },
  },
})
</script>
