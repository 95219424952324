import uniq from 'lodash/uniq'

import { ResourceMetaManagerInterface } from '@/tt-widget-factory'

type GetRelationAttributeResourcesServices = {
  resourceMetaManager: ResourceMetaManagerInterface
}

export const getRelationAttributeResources = (
  resource: string,
  attributes: string[],
  { resourceMetaManager }: GetRelationAttributeResourcesServices,
): string[] => {
  const isNestedPath = (attr: string): boolean => attr.includes('.')

  const discardPathEnd = (attr: string): string =>
    attr.split('.').slice(0, -1).join('.')

  /**
   * @example
   * - 'name' => ['name']
   * - 'region.name' => ['region', 'region.name']
   * - 'region.address.city' => ['region', 'region.address', 'region.address.city']
   */
  const splitNestedPath = (attr: string): string[] =>
    isNestedPath(attr)
      ? [...splitNestedPath(discardPathEnd(attr)), attr]
      : [attr]

  const relationPaths = attributes.map(splitNestedPath).flat()

  const relationResources = uniq(relationPaths)
    .map((path) => resourceMetaManager.getRelationResourceName(resource, path))
    .filter(Boolean)

  return uniq(relationResources)
}
