<template>
  <EntityRelationListPreview
    class="list-container"
    :entity-id="entityId"
    :item-hook="itemHook"
    :resource-name="resourceName"
    :entity="attributeName"
    :hide-entity-preview-header="true"
    :disable-resource-preview="disableResourcePreview"
    :show-toolbar="showToolbar"
    :allow-actions="allowActions"
  />
</template>

<script lang="ts">
import Vue, { VueConstructor } from 'vue'

import { ItemHookProvider } from '../types'
import { EntityItemHook } from '../EntityItemHook'

export default (Vue as VueConstructor<Vue & ItemHookProvider>).extend({
  name: 'EntityRelationListCustomPreviewWrapper',
  inject: ['getItemHook'],
  props: {
    attributeName: {
      type: String,
      required: true,
    },
    showToolbar: {
      type: Boolean,
      default: true,
    },
    allowActions: {
      type: Boolean,
      default: false,
    },
    disableResourcePreview: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    itemHook(): EntityItemHook {
      return this.getItemHook()
    },
    resourceName(): string {
      return this.itemHook.getResourceName()
    },
    entityId(): number {
      return this.itemHook.getEntityId()
    },
  },
})
</script>

<style lang="scss" scoped>
.list-container {
  min-height: 300px;
}
</style>
