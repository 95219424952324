<template>
  <div>
    <v-toolbar class="toolbar3" dense flat short>
      <!-- toolbar info -->
      <div class="d-flex align-start tt-toolbar__info">
        <!-- back button -->
        <slot name="back-button" />
        <!-- / back button -->

        <!-- icon -->
        <slot v-if="showTitle" name="icon">
          <WidgetIcon class="my-3 mr-2" :widget-type="widgetType" />
        </slot>
        <!-- / icon -->

        <!-- title & subtitle -->
        <div v-if="showTitle" :class="toolbarTitleClass" @click="titleClick">
          <p
            class="my-0 py-0 tt-toolbar__title v-toolbar__title"
            v-text="title"
          />
          <p
            v-if="description"
            class="my-0 py-0 text-caption tt-toolbar__subtitle"
            v-text="description"
          />
        </div>
        <!-- / title & subtitle -->

        <!-- toggle button -->
        <slot name="toggle" />
        <!-- / toggle button -->
      </div>
      <!-- / toolbar info -->

      <!-- toolbar actions -->
      <div class="d-flex">
        <WidgetCachedResponseIcon
          v-if="cacheTimestamp"
          :cache-timestamp-seconds="cacheTimestamp"
        />

        <WidgetContextStateEditor
          v-if="editMode && hasFilter"
          :default-filter-values="defaultFilterValues"
          :monitored-attributes="monitoredAttributes"
          @update="$emit('update:default-filters', $event)"
        />
        <WidgetInfoButton
          v-if="showInfoButton"
          :hook="hook"
          :is-dashboard-wrapper="isDashboard"
          class="info-button pr-2"
        />
        <WidgetContextToolbarFilters
          :filters="filters"
          :page-name="widgetType"
          :value="contextFilterValues"
          @update="applyFilters($event)"
        />

        <slot />
      </div>
      <!-- / toolbar actions -->
    </v-toolbar>
    <slot name="extra-toolbar" />
  </div>
</template>

<script lang="ts">
import Vue, { PropType, VueConstructor } from 'vue'

import ResourcePermissionAuditor, {
  getResourcePermissionAuditorServices,
} from '@/tt-widget-factory/services/resource-meta/ResourcePermissionAuditor'
import {
  createContextFilterSetting,
  isNullOperatorType,
} from '@/tt-entity-filter/util'
import { FilterSetting } from '@/tt-entity-filter/types'
import { Resources } from '@/tt-entity-design/src/types'
import {
  Attribute,
  RelationFilter,
} from '@/tt-widget-factory/services/resource-meta/types'

import WidgetContextToolbarFilters from './WidgetContextToolbarFilters.vue'
import WidgetInfoButton from './WidgetInfoButton.vue'
import { AttributeName, WidgetName } from '../lib/names'
import { canDisplayWidgetDetails } from './WidgetDetails.utils'
import { Filter, FilterWithValue, FilterWithoutValue } from '../types'
import { WidgetHookInterface } from '@/tt-widget-factory/WidgetHookInterface'
import { ContextManagerInterface } from '@/tt-widget-factory/types'
import WidgetCachedResponseIcon from './WidgetCachedResponseIcon.vue'

const { DATE_RANGE_ATTRIBUTE, ACCOUNT_ATTRIBUTE, REGION_ATTRIBUTE } =
  AttributeName

type VueWithInjections = VueConstructor<
  Vue & { parentContext: ContextManagerInterface; getEditMode: () => boolean }
>

export default (Vue as VueWithInjections).extend({
  name: 'WidgetContextToolbar',
  components: {
    WidgetContextToolbarFilters,
    WidgetInfoButton,
    WidgetCachedResponseIcon,
  },
  inject: {
    parentContext: {
      from: 'contextManager',
      default: undefined,
    },
    getEditMode: {
      default() {
        return () => false
      },
    },
  },
  props: {
    hook: {
      type: Object as PropType<WidgetHookInterface>,
      default: null,
    },
    editMode: {
      type: Boolean,
      default: null,
    },
    showDateRange: {
      type: Boolean,
      default: true,
    },
    showRegion: {
      type: Boolean,
      default: true,
    },
    showAccount: {
      type: Boolean,
      default: true,
    },
    showTitle: {
      type: Boolean,
      default: true,
    },
    defaultFilterValues: {
      type: Array as PropType<Filter[]>,
      default: () => [],
    },
    relationFilters: {
      type: Object as PropType<{ [key: string]: RelationFilter }>,
      default: null,
    },
  },
  data() {
    return {
      contextManager:
        this.parentContext || this.$appContext.widgetServices.contextManager,
    }
  },
  computed: {
    description(): string {
      return this.hook?.widget?.description || ''
    },
    widgetType(): string {
      return this.hook?.widget?.is || ''
    },
    contextFilterValues(): Record<string, Filter> {
      const filters = this.contextManager?.getContextFilters() ?? []
      const entries = filters.map((filter): [string, Filter] => [
        filter.attribute,
        filter,
      ])

      return Object.fromEntries(entries)
    },
    title(): string {
      if (this.hook?.widget?.title) {
        return this.$t(this.hook?.widget?.title)
      }

      if (this.isDashboard) {
        return this.$tc('components.widget_context_toolbar.untitled_dashboard')
      }

      return this.$tc('components.widget_context_toolbar.untitled_widget')
    },
    isDashboard(): boolean {
      return this.widgetType === WidgetName.DASHBOARD_WIDGET
    },
    showInfoButton(): boolean {
      return (
        this.hook?.widget &&
        canDisplayWidgetDetails(this.hook.widget, this.$appContext) &&
        !this.getEditMode()
      )
    },
    /**
     * Return an array of Context Filters
     */
    filters(): FilterSetting[] {
      if (!this.contextManager.resources?.length) return []
      const auditorServices = getResourcePermissionAuditorServices(
        this.$appContext,
      )

      const filterTypes = this.contextManager.contextFilterTypes
      const hasRegion =
        this.showRegion &&
        filterTypes.includes(REGION_ATTRIBUTE) &&
        ResourcePermissionAuditor.canViewResource(
          auditorServices,
          Resources.REGIONS,
        )

      const hasDate =
        this.showDateRange && filterTypes.includes(DATE_RANGE_ATTRIBUTE)

      const hasAccount =
        this.showAccount &&
        filterTypes.includes(ACCOUNT_ATTRIBUTE) &&
        ResourcePermissionAuditor.canViewResource(
          auditorServices,
          Resources.ACCOUNTS,
        )

      const dateRangeFilter: FilterSetting =
        createContextFilterSetting(DATE_RANGE_ATTRIBUTE)

      const getAttribute = (resource, attr) =>
        this.$appContext.widgetServices.resourceMetaManager.getAttribute(
          resource,
          attr,
        )

      const dateRangeMeta: Attribute = {
        ...dateRangeFilter.attributeMeta,
        type: this.contextManager.getDateRangeBestType(getAttribute),
      }

      const dateFilter = {
        ...dateRangeFilter,
        attributeMeta: dateRangeMeta,
      }

      return [
        ...(hasDate ? [dateFilter] : []),
        ...(hasRegion ? [this.getContextFilterSetting(REGION_ATTRIBUTE)] : []),
        ...(hasAccount
          ? [this.getContextFilterSetting(ACCOUNT_ATTRIBUTE)]
          : []),
      ]
    },
    toolbarTitleClass(): (string | object)[] {
      return [
        'pa-2',
        'tt-toolbar__title',
        {
          'tt-toolbar__title--edit': this.editMode,
          'tt-toolbar__title--empty': !this.title && !this.description,
        },
      ]
    },
    hasFilter(): boolean {
      return !!this.filters.length
    },
    monitoredAttributes(): string[] {
      return ['regionAttribute', 'accountAttribute', 'dateRangeAttribute']
    },
    cacheTimestamp(): number {
      return this.hook?.getCacheTimestamp() ?? 0
    },
  },
  methods: {
    titleClick() {
      if (this.editMode) {
        this.$emit('titleClick')
      }
    },
    /**
     * Apply a filter on the context
     * @param filtersObject
     */
    applyFilters(filtersObject: { [attribute: string]: Filter }) {
      // Loop through the filters
      this.filters.forEach((filterSetting: FilterSetting) => {
        const { attributeName } = filterSetting
        const { operator, value } =
          filtersObject[filterSetting.attributeName] ?? {}
        this.changeModelContext(attributeName, operator, value)
      })
    },
    /**
     * Change the model context
     * @param attribute
     * @param operator
     * @param value
     */
    changeModelContext(
      attribute: string,
      operator: Filter['operator'],
      value: any,
    ) {
      if (isNullOperatorType(operator)) {
        const filter: FilterWithoutValue = { attribute, operator }
        this.contextManager.setContextFilter(filter)
      } else if (value && value.length > 0) {
        const filter: FilterWithValue = { attribute, operator, value }
        this.contextManager.setContextFilter(filter)
      } else {
        this.contextManager.removeContextFilter(attribute)
      }
    },
    getContextFilterSetting(attribute: AttributeName): FilterSetting | null {
      const filterSetting = createContextFilterSetting(
        attribute,
        this.getRelationFieldFilters(attribute),
      )

      return { ...filterSetting }
    },
    getRelationFieldFilters(attribute: string): RelationFilter | null {
      return this.relationFilters?.[attribute] ?? null
    },
  },
})
</script>

<style lang="scss" scoped>
@import '@/tt-widget-components/scss/WidgetContextToolbar.scss';
</style>
