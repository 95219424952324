import { registerResourceForm } from '@/tt-widget-entity-flow/helper'

import ReportTemplateFieldsSorter from './ReportTemplateFieldsSorter.vue'
import ReportTemplatesSortFields from './ReportTemplatesSortFields.vue'
import ReportTemplateFeatureAssociationField from './ReportTemplateFeatureAssociationField.vue'
import ReportTemplatesAddTranslation from './ReportTemplatesAddTranslation.vue'
import ReportTemplateFieldsTranslation from './ReportTemplateFieldsTranslation.vue'
import ReportTemplatesArchive from './ReportTemplatesArchive.vue'
import ReportTemplateStandardizedIdField from './ReportTemplateStandardizedIdField.vue'
import { Resources } from '../../types'
import { ReportTemplateActions } from './types'

export default {
  install(Vue) {
    Vue.component('ReportTemplateFieldsSorter', ReportTemplateFieldsSorter)
    Vue.component('ReportTemplatesSortFields', ReportTemplatesSortFields)
    Vue.component(
      'ReportTemplateFeatureAssociationField',
      ReportTemplateFeatureAssociationField,
    )
    Vue.component(
      'ReportTemplatesAddTranslation',
      ReportTemplatesAddTranslation,
    )
    Vue.component(
      'ReportTemplateFieldsTranslation',
      ReportTemplateFieldsTranslation,
    )
    Vue.component('ReportTemplatesArchive', ReportTemplatesArchive)
    Vue.component(
      'ReportTemplateStandardizedIdField',
      ReportTemplateStandardizedIdField,
    )

    registerResourceForm(
      Resources.REPORT_TEMPLATES,
      'ReportTemplatesSortFields',
      ReportTemplateActions.SortFields,
    )
    registerResourceForm(
      Resources.REPORT_TEMPLATES,
      'ReportTemplatesAddTranslation',
      ReportTemplateActions.AddTranslation,
    )
    registerResourceForm(
      Resources.REPORT_TEMPLATES,
      'ReportTemplatesArchive',
      ReportTemplateActions.Archive,
    )
  },
}
