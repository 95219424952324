<template>
  <div class="fill-height">
    <slot :context-manager="contextManager" />
  </div>
</template>

<script lang="ts">
import Vue, { PropType, VueConstructor } from 'vue'

import ContextManager from '@/tt-widget-factory/context/ContextManager'
import {
  ContextDictionary,
  ContextManagerInterface,
} from '@/tt-widget-factory/types'

type VueWithInjections = VueConstructor<
  Vue & { parentContext: ContextManagerInterface }
>

export default (Vue as VueWithInjections).extend({
  name: 'WidgetContainer',
  inject: {
    parentContext: { from: 'contextManager', default: null },
  },
  provide(): any {
    return {
      contextManager: this.contextManager,
      useCache: this.useCache,
    }
  },
  props: {
    context: {
      type: Object as PropType<ContextDictionary>,
      default: undefined,
    },
    useCache: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    const extendedContext =
      this.parentContext || this.$appContext.contextManager

    const { authModule, regionManager } = this.$appContext

    return {
      contextManager: new ContextManager(
        authModule,
        regionManager,
        extendedContext,
        {
          ...extendedContext.context,
          ...this.context,
        },
      ),
    }
  },
})
</script>
