<template>
  <v-btn
    class="filter-btn-activator mr-2"
    :class="{ 'has-value': hasValue }"
    :disabled="disabled"
    :height="30"
    :max-width="200"
    outlined
    small
    text
    v-on="$listeners"
  >
    <v-icon
      v-if="!hasAccess"
      class="filter-btn-activator--no-access-icon mr-2"
      small
      v-text="`error`"
    />

    <slot name="button-left" />

    <v-tooltip top :disabled="!showTooltip">
      {{ tooltipValue }}
      <template #activator="{ on: tooltipOn }">
        <span
          class="text-truncate"
          display="inline-block"
          style="max-width: 120px"
          v-on="tooltipOn"
        >
          <span ref="label" display="inline-block" class="text-truncate">
            {{ label }}
          </span>
        </span>
      </template>
    </v-tooltip>

    <v-icon
      v-if="hasValue"
      style="opacity: 0.5"
      class="ml-2"
      small
      @click.stop="$emit('clear')"
      v-text="`close`"
    />
    <v-icon
      v-else
      style="opacity: 0.5"
      class="ml-1"
      small
      v-text="`mdi-chevron-down`"
    />
  </v-btn>
</template>

<script lang="ts">
import Vue from 'vue'
import { isElementOverflowing } from '@/helpers/dom'

export default Vue.extend({
  name: 'FilterButtonActivator',
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    hasValue: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      required: true,
    },
    tooltipLabel: {
      type: String || null,
      default: null,
    },
    hasAccess: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      showTooltip: false,
    }
  },
  computed: {
    tooltipValue(): string {
      return this.tooltipLabel || this.label
    },
  },
  mounted() {
    this.checkLabelOverflow()
  },
  updated() {
    this.checkLabelOverflow()
  },
  methods: {
    async checkLabelOverflow() {
      await Vue.nextTick()
      const labelElement = this.$refs.label as Element
      // If we have the tootipLabel property, we display tooltip all the time
      this.showTooltip = this.tooltipLabel
        ? true
        : isElementOverflowing(labelElement)
    },
  },
})
</script>

<style scoped lang="scss">
.filter-btn-activator {
  background-color: var(--v-level0-base);
  font-size: 0.75em;
  font-weight: 400;
  text-transform: capitalize;

  &.has-value {
    color: var(--v-ttPrimary-base);
    font-weight: bold;
  }
}
.theme--light.v-btn.v-btn--disabled
  .v-icon.filter-btn-activator--no-access-icon {
  color: orange !important;
}
</style>
