import { PluginFunction } from 'vue'

const RunsheetResourceInjected = () =>
  import(
    './views/RunsheetResourceInjected.vue'
    )

export { RunsheetResourceInjected }

export const MobileRunsheetOccurrencesPlugin: PluginFunction<void> = (Vue) => {
  Vue.component('RunsheetResourceInjected', RunsheetResourceInjected)
}
