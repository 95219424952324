<template>
  <div>
    <v-menu
      v-model="menu"
      nudge-bottom="40"
      bottom
      :close-on-content-click="false"
    >
      <template #activator="{ on }">
        <v-text-field
          ref="input-field"
          :value="selectedText"
          :loading="loading && 'yellow darken-2'"
          v-bind="{ ...$props, ...$attrs }"
          clearable
          autocomplete="off"
          dense
          outlined
          loader-height="3"
          v-on="on"
          @focus="fetchItems"
          @click:clear="clearSelection(true)"
          @input="setSearchAndDebounce"
          @keydown.delete="clearSelection(false)"
        >
          <template #append>
            <v-icon>{{ menu ? 'mdi-menu-up' : 'mdi-menu-down' }}</v-icon>
          </template>
        </v-text-field>
      </template>

      <v-card class="card">
        <v-list-item
          v-if="items.length === 0 && !loading"
          v-html="$t(`common.no_results`)"
        />
        <v-treeview
          v-else
          id="tree"
          :items="items"
          dense
          activatable
          item-key="id"
          hoverable
          transition
          :active="getActiveItem"
          :load-children="fetchChildren"
          @update:active="handleSelection"
        >
          <template #append="{ item }">
            <div
              v-if="hasToDisplayObserver(item)"
              v-intersect.quiet="_debounceNextPage"
            >
              <v-skeleton-loader type="list-item" />
            </div>
          </template>
        </v-treeview>
      </v-card>
    </v-menu>
  </div>
</template>

<script lang="ts">
import Vue, { VueConstructor } from 'vue'
import { Resources } from '@/tt-entity-design/src/types'
import QueryManager from '@/tt-widget-components/base/QueryManager'
import { debounce, DebouncedFunc, uniqBy } from 'lodash'
import { SiteLocationsResponse } from '@/tt-entity-design/src/components/site-locations/types'
import { DEFAULT_QUERY_LIMIT } from '../../helpers/constants'
import { CollectionQuery, Filter } from '@/tt-widget-components/schemas-types'
import { FormHookProvider } from '@/tt-widget-components/types'
import { FilterOperatorType } from 'tracktik-sdk/lib/common/entity-filters'
import { Attribute, CustomFilter } from '@/tt-entity-design/src/schema-types'
import { EntityCollectionResponse } from 'tracktik-sdk/lib/common/entity-collection'

interface TreeItem {
  id: number
  name: string
  children?: TreeItem[]
}

interface SuccessFunction {
  items: SiteLocationsResponse[]
  total?: number
}

const PARENT_ATTRIBUTE: Attribute<typeof Resources.SITE_LOCATIONS> = 'parent'
const NAME_ATTRIBUTE: Attribute<typeof Resources.SITE_LOCATIONS> = 'name'
const ORDER_ATTRIBUTE: Attribute<typeof Resources.SITE_LOCATIONS> = 'order'
const FOR_ACCOUNT_ID_FILTER: CustomFilter<typeof Resources.SITE_LOCATIONS> =
  'forAccountId'

export default (Vue as VueConstructor<Vue & FormHookProvider>).extend({
  name: 'SiteLocationForm',
  inject: ['formHook'],
  props: {
    value: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      menu: false,
      selectedItem: null as SiteLocationsResponse | null,
      loading: false,
      items: [] as TreeItem[],
      itemCount: null as number,
      queryManager: null as QueryManager | null,
      search: '',
      selectedText: '',
      /**
       * Cancels the request that is currently fetching the selected items
       */
      cancelFetch: null as (() => void) | null,
    }
  },
  computed: {
    accountId(): number {
      return this.formHook().getPathValue('account')
    },
    getActiveItem(): number[] {
      return [this.selectedItem?.id]
    },
    resource(): Resources {
      return Resources.SITE_LOCATIONS
    },
    hasLoadedAllData(): boolean {
      return this.itemCount <= this.items.length
    },
    isParentFilter(): Filter[] {
      return [
        {
          attribute: PARENT_ATTRIBUTE,
          operator: FilterOperatorType.ISNULL,
        },
      ]
    },
    _debounceNextPage(): DebouncedFunc<() => void> {
      return debounce(() => {
        this.nextPage()
      }, 250)
    },
    _debounceSearch(): DebouncedFunc<() => void> {
      return debounce(() => {
        this.fetchItems()
        this.menu = true
      }, 250)
    },
  },
  watch: {
    value: {
      immediate: true,
      handler() {
        if (this.value) {
          this.fetchSingleItem(this.value)
        } else if (this.selectedItem) {
          this.clearSelection()
        }
      },
    },
  },
  methods: {
    blurInputField() {
      const inputField = this.$refs['input-field'] as HTMLElement
      inputField?.blur()
      this.menu = false
    },
    hasToDisplayObserver(item: SiteLocationsResponse): boolean {
      return (
        item.id === this.items[this.items.length - 1].id &&
        ![null, 0].includes(this.itemCount) &&
        !this.hasLoadedAllData
      )
    },
    addToAllItems(items: TreeItem[]) {
      this.items = uniqBy([...this.items, ...items], 'name')
    },
    mapItems(items: SiteLocationsResponse[]): TreeItem[] {
      return items.map(({ id, name, children }) => {
        /* If the site location has children we wanna give it an empty array so we can fetch them later,
           Otherwise assigning the children directly means we don't have the information that the children 
           have children of their own */
        return {
          id,
          name,
          children: children.length > 0 ? [] : undefined,
        } as TreeItem
      })
    },
    handleError(err) {
      this.$crash.captureException(err)
    },
    createCancellableRequest(query: CollectionQuery) {
      return this.$appContext.widgetServices.resourceDataManager.cancelableGetCollection(
        query,
      )
    },
    handleSelection(items: TreeItem[]) {
      if (items.length > 0) {
        const selectedItem = items[items.length - 1]
        this.$emit('input', selectedItem)
        this.menu = false
      }
    },
    clearSelection(blur?: boolean) {
      this.selectedItem = null
      this.selectedText = ''
      this.search = ''
      this.$emit('input', null)
      if (blur) this.blurInputField()
    },
    setSearchAndDebounce(search?: string) {
      const newSearch = search || ''
      if (newSearch === this.search) return this.fetchItems()
      this.search = newSearch
      this.cancelFetch?.()
      this.loading = true
      this._debounceSearch()
    },
    setQueryManager(limit: number): void {
      this.queryManager = new QueryManager(
        {
          resource: this.resource,
          include: ['children'],
          filters: [
            {
              attribute: FOR_ACCOUNT_ID_FILTER,
              operator: FilterOperatorType.EQUAL,
              value: this.accountId,
            },
            {
              attribute: NAME_ATTRIBUTE,
              operator: FilterOperatorType.CONTAINS,
              value: this.search,
            },
            ...(!this.search ? this.isParentFilter : []),
          ],
          sort: [
            {
              attribute: ORDER_ATTRIBUTE,
            },
          ],
        },
        this.$appContext.contextManager,
        {
          services: this.$appContext.widgetServices,
        },
      )
      this.queryManager.setLimit(limit)
    },
    async fetch(
      query: CollectionQuery,
      onSuccess: ({ items, total }: SuccessFunction) => void,
    ): Promise<void> {
      this.cancelFetch?.()
      this.loading = true

      const cleanAll = () => {
        this.loading = false
        this.cancelFetch = null
      }

      const request = this.createCancellableRequest(query)

      this.cancelFetch = () => request.cancel('Canceled by component')

      await request
        .run()
        .then(onSuccess)
        .catch(this.handleError)
        .finally(cleanAll)
    },
    async fetchItems(): Promise<void> {
      this.items = []
      this.setQueryManager(DEFAULT_QUERY_LIMIT)

      const onSuccess = ({ items, total }: SuccessFunction) => {
        const newItems = this.mapItems(items)
        this.addToAllItems(newItems)
        this.itemCount = total
      }

      await this.fetch(this.queryManager.query, onSuccess)
    },
    async fetchSingleItem(value: string | number): Promise<void> {
      this.selectedItem = null
      this.loading = true

      const onFinish = () => {
        this.loading = false
      }

      const onSuccess = (item: SiteLocationsResponse) => {
        this.selectedItem = item
        this.selectedText = item.name
        this.search = ''
      }

      this.$appContext.authModule
        .getApi()
        .get(this.resource, value)
        .then(onSuccess)
        .catch(this.handleError)
        .finally(onFinish)
    },
    async fetchChildren(item: TreeItem): Promise<void> {
      this.loading = true

      const onSuccess = ({
        items,
      }: EntityCollectionResponse<SiteLocationsResponse>) => {
        const newItems = this.mapItems(items)
        item.children = newItems
      }

      const onFinish = () => {
        this.loading = false
      }

      return this.$appContext.authModule
        .getApi()
        .getAll(this.resource, {
          include: ['children'],
          filters: [
            {
              attribute: PARENT_ATTRIBUTE,
              operator: FilterOperatorType.EQUAL,
              value: item.id,
            },
          ],
          sort: [
            {
              attribute: ORDER_ATTRIBUTE,
            },
          ],
        })
        .then(onSuccess)
        .catch(this.handleError)
        .finally(onFinish)
    },
    async nextPage(): Promise<void> {
      if (this.loading) return

      const currentOffset = this.queryManager.query.offset ?? 0
      const onSuccess = ({ items, total }: SuccessFunction) => {
        const newItems = this.mapItems(items)
        this.addToAllItems(newItems)
        this.itemCount = total
      }

      this.queryManager.setOffset(currentOffset + DEFAULT_QUERY_LIMIT)

      await this.fetch(this.queryManager.query, onSuccess)
    },
  },
})
</script>

<style scoped>
.card {
  max-height: 300px;
  overflow-y: auto;
  width: 100%;
  padding-bottom: 2px;
}

#tree >>> .v-treeview-node {
  cursor: pointer !important;
}

#tree >>> .v-treeview-node--active {
  background-color: #eee !important;
}
</style>
