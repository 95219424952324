import { JSONSchema7 } from '@tracktik/tt-json-schema-form'

import {
  DocumentInput,
  DocumentProviderInterface,
  DocumentProviders,
  DocumentServiceMethods,
} from '@/tt-entity-documents/types'

/**
 * A document manager
 */
export class DocumentManager {
  providers: Partial<Record<DocumentProviders, DocumentProviderInterface>> = {}

  /**
   * Register a provider
   * @param provider
   */
  public registerProvider(provider: DocumentProviderInterface) {
    this.providers[provider.getProviderTag()] = provider
  }

  /**
   * Get the provider
   * @param providerTag
   */
  private getProvider(
    providerTag: DocumentProviders,
  ): DocumentProviderInterface {
    return this.providers[providerTag]
  }

  async getDocumentOptionsSchema(
    input: DocumentInput,
  ): Promise<JSONSchema7 | null> {
    return this.getProvider(input.provider)?.getDocumentOptionsSchema(input)
  }

  /**
   * Get the document URL
   * @param input
   */
  async getDocumentUrl(
    input: DocumentInput,
    params?: Record<string, string>,
  ): Promise<string> {
    return this.getProvider(input.provider)?.getDocumentUrl(input, params)
  }

  /**
   * Get headers for custom call
   * @param input
   */
  getHeaders(input: DocumentInput): Record<string, string> {
    const provider = this.getProvider(input.provider)
    if (!provider || !provider.getHeaders) {
      return {}
    }

    return provider.getHeaders()
  }

  /**
   * Get payload for custom call
   * @param input
   */
  getPayload(input: DocumentInput): Record<string, string> {
    const provider = this.getProvider(input.provider)
    if (!provider || !provider.getPayload) {
      return {}
    }

    return provider.getPayload(input)
  }

  /**
   * Get the document URL
   * @param input
   */
  async getHTTPMethod(input: DocumentInput): Promise<DocumentServiceMethods> {
    return this.getProvider(input.provider)?.getMethod()
  }
}
