import cloneDeep from 'lodash/cloneDeep'

import TracktikApiError from 'tracktik-sdk/lib/errors'
import { AuthModule } from '@tracktik/tt-authentication'
import { EntityAggregationRequestOptions } from 'tracktik-sdk/lib/common/entity-aggregation'
import {
  EntityCollectionRequestOptions,
  ExportType,
} from 'tracktik-sdk/lib/common/entity-collection'

import QueryCache from '@/tt-widget-components/base/QueryCache'
import { cloneData } from '@/helpers/cloneData'
import {
  CollectionQuery,
  Filter,
  Measure,
  RelationListResource,
} from '@/tt-widget-components'
import { MeasureOperation } from '@/tt-widget-components/components/measure-types'

import {
  AggregationQuery,
  AggregationQueryResponse,
  CollectionQueryResponse,
} from '../../definitions'
import {
  CancelableResponse,
  CollectionQueryOptions,
  ResourceDataProviderInterface,
} from './types'
import { FieldTypes, ResourceMetaProviderInterface } from '../resource-meta'

const setQueryCache =
  (cacheKey: string, tags?: string[]) =>
  <T>(response: T): T => {
    QueryCache.set(cacheKey, response, 10, tags)

    return response
  }

const convertCustomFilterToFilter = ({ filterName, value }): Filter =>
  ({
    attribute: filterName,
    value,
  } as Filter)

export default class ResourceDataProvider
  implements ResourceDataProviderInterface
{
  auth: AuthModule
  resourceMetaManager: ResourceMetaProviderInterface

  constructor(
    auth: AuthModule,
    resourceMetaManager: ResourceMetaProviderInterface,
  ) {
    this.auth = auth
    this.resourceMetaManager = resourceMetaManager
  }

  getAggregation(
    query: AggregationQuery,
    disableCache?: boolean,
  ): Promise<AggregationQueryResponse> {
    const { resource, ...queryParams } = cloneDeep(query)

    const cacheKey = JSON.stringify(query)
    if (!disableCache && QueryCache.has(cacheKey)) {
      return QueryCache.get(cacheKey)
    }
    // Add the custom API filters as a filter as it is not supported by the SDK officially
    // @todo: add implementation in tracktik-sdk
    if (queryParams.customFilters) {
      const convertedCustomFilters = queryParams.customFilters.map(
        convertCustomFilterToFilter,
      )
      queryParams.filters = queryParams.filters ?? []
      queryParams.filters = [...queryParams.filters, ...convertedCustomFilters]
    }
    // Convert the expression to base64
    if (queryParams.measures) {
      queryParams.measures = queryParams.measures.map((measure: Measure) => {
        if (measure.operation === MeasureOperation.EXPRESSION) {
          measure.attribute = btoa(measure.attribute)
        }

        return measure
      })
    }

    queryParams.cacheMaxAge = disableCache ? 0 : queryParams?.cacheMaxAge

    const results = this.auth
      .getApi()
      .aggregateWithMetadata(
        resource,
        queryParams as EntityAggregationRequestOptions,
      )
      .then(setQueryCache(cacheKey, [resource]))
      .catch((e) => {
        throw e
      })

    return results
  }

  private getRelationListPath(
    relationListResource: RelationListResource,
  ): string | null {
    const { resource, id, attribute } = relationListResource
    const attributeType = this.resourceMetaManager.getAttribute(
      resource,
      attribute,
    ).type

    if (attributeType === FieldTypes.RelationList) {
      return [resource, id, attribute].join('/')
    }

    return null
  }

  private getResourceAndQueryParams(
    _query: CollectionQuery,
    options: CollectionQueryOptions,
  ): [string, EntityCollectionRequestOptions] {
    const aggregateRelationList = !options?.keepRelationList

    const query = cloneData(_query)

    // Sort, we support object and array but the SDK only supports array
    if (query.sort && !Array.isArray(query.sort)) {
      if (!query.sort.attribute) {
        query.sort = null
      } else {
        query.sort = [query.sort]
      }
    }

    const { resource, relationListResource, fields, ...queryParams } = query

    // Add the whereQL as a filter as it is not supported by the SDK officially
    if (queryParams.whereQL) {
      queryParams.filters = queryParams.filters ?? []
      queryParams.filters.push({
        attribute: 'whereQL',
        operator: 'EQUAL',
        value: queryParams.whereQL,
      })
    }

    // Add the custom API filters as a filter as it is not supported by the SDK officially
    if (queryParams.customFilters) {
      const convertedCustomFilters = queryParams.customFilters.map(
        convertCustomFilterToFilter,
      )
      queryParams.filters = queryParams.filters ?? []
      queryParams.filters = [...queryParams.filters, ...convertedCustomFilters]
    }

    const relationListPath =
      relationListResource && this.getRelationListPath(relationListResource)

    // Add modifier and alias on attribute field which are relation list type
    const getUpdatedFields = () =>
      this.resourceMetaManager.getUpdatedRelationListAttribute(resource, fields)

    queryParams.fields = aggregateRelationList ? getUpdatedFields() : fields

    queryParams.cacheMaxAge = options?.disableCache
      ? 0
      : queryParams?.cacheMaxAge

    return [
      relationListPath ?? resource,
      queryParams as EntityCollectionRequestOptions,
    ]
  }

  getCollection(
    query: CollectionQuery,
    options?: CollectionQueryOptions,
  ): Promise<CollectionQueryResponse> {
    const [resourcePath, queryParams] = this.getResourceAndQueryParams(
      query,
      options,
    )

    const disableCache = options?.disableCache
    const cacheKey = JSON.stringify(query)
    if (!disableCache && QueryCache.has(cacheKey)) {
      return QueryCache.get(cacheKey)
    }

    const results = this.auth
      .getApi()
      .getAll(resourcePath, queryParams)
      .catch((e: TracktikApiError) => {
        throw e
      })
      .then(setQueryCache(cacheKey, [resourcePath]))

    return results
  }

  cancelableGetCollection(
    query: CollectionQuery,
    options?: CollectionQueryOptions,
  ): CancelableResponse<CollectionQueryResponse> {
    const [resourcePath, queryParams] = this.getResourceAndQueryParams(
      query,
      options,
    )

    const cacheKey = JSON.stringify(query)

    const request = this.auth
      .getApi()
      .createCancellableGetAll(resourcePath, queryParams)

    const handledApiRequest = async () => {
      const useCache = !options?.disableCache && QueryCache.has(cacheKey)

      return useCache
        ? QueryCache.get(cacheKey)
        : request.run().then(setQueryCache(cacheKey, [resourcePath]))
    }

    return { cancel: request.cancel, run: handledApiRequest }
  }

  canQuery(query: AggregationQuery | CollectionQuery): boolean {
    const { resource } = query

    return true
  }
}
