import { Action } from '@/tt-entity-design/src/schema-types'
import { Resources } from '@/tt-entity-design/src/types'
import { rootExceptionTypeId } from '@/tt-entity-design/src/components/exception-types/exception-types-parent'
import { TTC_API_MAX_LIMIT } from '@/tt-widget-components/constants'
import { parseExceptionKey } from '@/tt-widget-views/scheduling-groups/helpers/schema-helper'
import {
  DayOfWeek,
  ExceptionData,
  PerformType,
} from '@/tt-widget-views/scheduling-groups/types'
import {
  BatchFileActions,
  BatchFileOperation,
  BatchFileResponse,
} from '@/types'
import { AuthModule } from '@tracktik/tt-authentication'

export type SiteTaskTimingData = {
  rangeStartTime: string
  rangeEndTime?: string
  dayOfWeekStart?: DayOfWeek
  dayOfWeekEnd?: DayOfWeek
  isCustom?: boolean
}

export type SiteTaskTimingEntity = SiteTaskTimingData & {
  id: number
  schedule: number
}

export const createSiteTaskScheduleTimingsHelper = (authModule: AuthModule) => {
  const fetchSiteTaskScheduleTimings = async (
    siteTaskScheduleId: number,
  ): Promise<SiteTaskTimingEntity[]> => {
    return authModule
      .getApi()
      .getAll<SiteTaskTimingEntity>(Resources.SITE_TASK_SCHEDULE_TIMINGS, {
        filters: [
          {
            attribute: 'schedule',
            operator: 'EQUAL',
            value: siteTaskScheduleId,
          },
        ],
        limit: TTC_API_MAX_LIMIT,
      })
      .then(({ items }) => items)
  }

  const deleteOldTimings = async (
    siteTaskScheduleId: number,
  ): Promise<void> => {
    const oldTimings = await fetchSiteTaskScheduleTimings(siteTaskScheduleId)

    const deletionPromises = oldTimings.map(({ id }) =>
      authModule.getApi().delete(Resources.SITE_TASK_SCHEDULE_TIMINGS, id),
    )

    await Promise.all(deletionPromises)
  }

  const saveSiteTaskScheduleTimings = async (
    siteTaskScheduleId: number,
    timings: SiteTaskTimingData[],
  ): Promise<BatchFileResponse> => {
    await deleteOldTimings(siteTaskScheduleId)
    const operations: BatchFileOperation[] = timings.map((timing) => ({
      resource: Resources.SITE_TASK_SCHEDULE_TIMINGS,
      action: 'CREATE',
      data: { ...timing, schedule: siteTaskScheduleId },
    }))

    return authModule.getApi().processBatchFile(operations, 'ROLLBACK')
  }

  const saveSingleSiteTaskScheduleTiming = async (
    siteTaskScheduleId: number,
    timing: SiteTaskTimingData,
  ): Promise<Record<string, string>> => {
    await deleteOldTimings(siteTaskScheduleId)

    return authModule.getApi().create(Resources.SITE_TASK_SCHEDULE_TIMINGS, {
      ...timing,
      schedule: siteTaskScheduleId,
    })
  }

  const buildExceptionData = (
    data: ExceptionData,
    exceptionTypes: number[],
  ) => {
    if (data.performType === PerformType.DONT_PERFORM_TASK) {
      return {
        exceptionTypes,
        performType: data.performType,
      }
    }

    return {
      ...data,
      exceptionTypes,
    }
  }

  const createAddExceptionOperations = (
    siteTaskScheduleID: number,
    newSiteTaskExceptions: Record<string, ExceptionData>,
  ): BatchFileOperation[] => {
    return Object.entries(newSiteTaskExceptions)
      .filter(([key, exception]) => exception)
      .map(
        ([key, exception]): BatchFileOperation => ({
          action: BatchFileActions.EXECUTE,
          actionName: 'add-exception-types',
          resource: Resources.SITE_TASK_SCHEDULES,
          lookup: String(siteTaskScheduleID),
          data: buildExceptionData(exception, [parseExceptionKey(key)]),
        }),
      )
  }

  const createRemoveExceptionOperations = (
    toRemoveSiteTaskExceptionIDs: number[],
  ): BatchFileOperation[] => {
    const resource = Resources.SITE_TASK_SCHEDULE_EXCEPTION_TYPES
    const actionName: Action<typeof resource> = 'remove'

    return toRemoveSiteTaskExceptionIDs.map(
      (siteTaskExceptionID): BatchFileOperation => ({
        resource,
        action: 'EXECUTE',
        actionName,
        data: {},
        lookup: String(siteTaskExceptionID),
      }),
    )
  }

  const buildClosedExceptionData = (
    siteTaskScheduleId: number,
    closedData?: ExceptionData,
  ): BatchFileOperation => {
    return closedData
      ? {
          action: BatchFileActions.EXECUTE,
          actionName: 'add-exception-types',
          resource: Resources.SITE_TASK_SCHEDULES,
          lookup: String(siteTaskScheduleId),
          data: buildExceptionData(closedData, [rootExceptionTypeId.Closed]),
        }
      : null
  }

  const saveSiteTaskScheduleExceptions = async (
    siteTaskScheduleID: number,
    newSiteTaskExceptions: Record<string, ExceptionData>, // review type
    toRemoveSiteTaskExceptionIDs: number[],
    closedData?: ExceptionData,
  ): Promise<BatchFileResponse> => {
    const closedExceptionData = buildClosedExceptionData(
      siteTaskScheduleID,
      closedData,
    )

    const operations: BatchFileOperation[] = [
      ...createRemoveExceptionOperations(toRemoveSiteTaskExceptionIDs),
      ...createAddExceptionOperations(
        siteTaskScheduleID,
        newSiteTaskExceptions,
      ),
      ...(closedExceptionData ? [closedExceptionData] : []),
    ]

    return authModule.getApi().processBatchFile(operations, 'ROLLBACK')
  }

  return {
    fetchSiteTaskScheduleTimings,
    saveSiteTaskScheduleTimings,
    saveSiteTaskScheduleExceptions,
    saveSingleSiteTaskScheduleTiming,
  }
}
