<template>
  <ProvideTQLResource>
    <template #default="{ resource }">
      <WidgetTabEditor
        :tabs="[
          { text: 'widget_edit.tab.data_source' },
          { text: 'widget_edit.tab.options' },
          { text: 'widget_edit.tab.toolbar' },
        ]"
        :resource-name="resource"
      >
        <template #default="{ tab }">
          <div v-show="tab === 0">
            <!-- Resource Selector -->
            <json-field name="dataSet.tql" />
            <json-field name="dataSet.ignoreValidation" />
            <InfoBox>
              <span v-text="$t('widget_edit.max_results.description')" />
            </InfoBox>
            <json-field
              name="dataSet.includeInactive"
              as="IncludeInactiveInput"
            />
            <json-field name="dataSet.contextFilters" />
            <json-field
              name="dataSet.cacheMaxAge"
              type="number"
              :label="$t('widgets.cache_info.cache-max-age')"
              :placeholder="$t('widgets.cache_info.time-in-seconds')"
              outlined
              dense
              class="pt-3"
            />
          </div>

          <div v-show="tab === 1">
            <json-field
              name="options.exportOptions"
              as="TQLQueryExportOptionsInput"
            />
          </div>
          <div v-show="tab === 2">
            <json-field name="toolbar" />
          </div>
        </template>
      </WidgetTabEditor>
    </template>
  </ProvideTQLResource>
</template>

<script lang="ts">
import IncludeInactiveInput from '@/tt-widget-components/components/IncludeInactiveInput.vue'
import Vue, { VueConstructor } from 'vue'
import { TQLValidator } from '@/tt-tql-inputs/src/lang/validator'
import { lang } from '@/tt-tql-inputs/src/lang/types'
import { FormHookProvider } from '@/tt-widget-components/types'

Vue.component('IncludeInactiveInput', IncludeInactiveInput)

export default (Vue as VueConstructor<Vue & FormHookProvider>).extend({
  inject: ['formHook'],
  data() {
    return {
      validator: new TQLValidator(
        this.$appContext.widgetServices.resourceMetaManager,
        lang,
      ),
    }
  },
  computed: {
    tql(): any {
      return this.formHook().getPathValue('dataSet.tql')
    },
    resource(): string {
      return this.validator.getResource(this.tql)
    },
  },
})
</script>
