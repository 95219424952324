import { addPreviewTab } from '@/tt-widget-entity-flow/helper'
import { createI18nPluralResourceKey } from '@/tt-widget-entity-flow/ResourceTranslator'
import { PreviewTabInterface } from '@/tt-widget-entity-flow/types'
import { WidgetModels } from '@/tt-widget-components'

import { Resources } from '../../types'

import RolesManagedPermissionPolicies from './RoleManagedPermissionPoliciesRelationList.vue'

const { ROLES } = Resources

export default {
  install(Vue) {
    const employeeRolesListWidget: WidgetModels = {
      is: 'ListWidget',
      title: '',
      query: {
        resource: Resources.EMPLOYEE_ROLES,
      },
    }

    const employeeRoles: PreviewTabInterface = {
      title: createI18nPluralResourceKey('employee-roles'),
      is: 'EntityItemWidget',
      props: {
        filterAttribute: 'role.id',
        widget: employeeRolesListWidget,
      },
    }

    const rolePermissionsListWidget: WidgetModels = {
      is: 'ListWidget',
      title: '',
      query: {
        resource: Resources.ROLE_PERMISSIONS,
      },
    }

    const rolePermissions: PreviewTabInterface = {
      title: createI18nPluralResourceKey('role-permissions'),
      is: 'EntityItemWidget',
      props: {
        filterAttribute: 'role.id',
        widget: rolePermissionsListWidget,
      },
    }

    const roleAccessPoliciesListWidget: WidgetModels = {
      is: 'ListWidget',
      title: '',
      query: {
        resource: Resources.ROLE_ACCESS_POLICIES,
      },
    }

    const roleAccessPolicies: PreviewTabInterface = {
      title: createI18nPluralResourceKey('role-access-policies'),
      is: 'EntityItemWidget',
      props: {
        filterAttribute: 'role.id',
        widget: roleAccessPoliciesListWidget,
      },
    }

    // Add the employees list
    addPreviewTab(ROLES, employeeRoles)

    // Add the role permissions list
    addPreviewTab(ROLES, rolePermissions)

    // Add the role access policies list
    addPreviewTab(ROLES, roleAccessPolicies)

    addPreviewTab(ROLES, {
      title: createI18nPluralResourceKey(Resources.MANAGED_PERMISSION_POLICIES),
      is: RolesManagedPermissionPolicies,
    })
  },
}
