// Enter your custom installation here
import { registerResourceForm } from '@/tt-widget-entity-flow/helper'
import EntityLabelsForm from './EntityLabelsForm.vue'
import EntityLabelsAvailabilitiesColumn from './EntityLabelsAvailabilitiesColumn.vue'
import EntityLabelsAvailabilitiesRow from './EntityLabelsAvailabilitiesRow.vue'
import EntityLabelsAddTranslation from './EntityLabelsAddTranslation.vue'
import { Resources } from '../../types'
import { ActionFormTypes } from '@/tt-app-modular'
import { EntityLabelsActions } from './types'

export default {
  install(Vue) {
    Vue.component('EntityLabelsForm', EntityLabelsForm)
    Vue.component(
      'EntityLabelsAvailabilitiesRow',
      EntityLabelsAvailabilitiesRow,
    )
    Vue.component(
      'EntityLabelsAvailabilitiesColumn',
      EntityLabelsAvailabilitiesColumn,
    )
    Vue.component('EntityLabelsAddTranslation', EntityLabelsAddTranslation)
    registerResourceForm(
      Resources.ENTITY_LABELS,
      'EntityLabelsForm',
      ActionFormTypes.FULL_ENTITY,
    )
    registerResourceForm(
      Resources.ENTITY_LABELS,
      'EntityLabelsAddTranslation',
      EntityLabelsActions.AddTranslation,
    )
  },
}
