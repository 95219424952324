<template>
  <div
    class="enum-list--container pt-3 v-card v-card--flat v-sheet theme--light"
  >
    <TLoadingBar v-if="isLoading" class="loader" />
    <v-row no-gutters class="ma-1">
      <v-col cols="4">
        <div class="attribute-row-label">
          {{ `${computedLabel}:` }}
        </div>
      </v-col>
      <v-col v-if="value.length > 0" cols="8">
        <div
          v-for="(item, index) in value"
          :key="index"
          class="attribute-row-value"
        >
          {{ localizeEnums(item) }}
        </div>
      </v-col>
      <div v-else class="attribute-row-value">
        {{ noValuePlaceholder }}
      </div>
    </v-row>
    <v-divider />
  </div>
</template>
<script lang="ts">
import Vue, { VueConstructor } from 'vue'
import { ItemHookProvider } from '@/tt-widget-entity-flow/types'
import {
  createI18nEnumKey,
  ResourceTranslator,
} from '@/tt-widget-entity-flow/ResourceTranslator'
import { FormLabelTypes } from '@tracktik/tt-json-schema-form'

export default (
  Vue as VueConstructor<Vue & ItemHookProvider & { relationPrefix: string }>
).extend({
  name: 'EnumListAttr',
  inject: ['getItemHook', 'relationPrefix'],
  props: {
    attrName: { type: String, required: true },
    label: { type: String, default: '' },
    noValuePlaceholder: { type: String, default: '' },
  },
  computed: {
    resource(): string | null {
      return this.getItemHook().getResourceName()
    },

    computedLabel(): string | null {
      if (this.label !== '') {
        return this.$t(this.label)
      }

      const newName = this.relationPrefix
        ? `${this.relationPrefix}.${this.attrName}`
        : this.attrName

      const label = ResourceTranslator.translateAttribute(
        this.resource,
        newName,
        FormLabelTypes.LABEL,
      )

      return `${label}`
    },

    isLoading(): boolean {
      return this.getItemHook().loading
    },

    value(): string[] {
      return this.getItemHook().getRawValue(this.attrName) ?? []
    },
  },
  created() {
    this.getItemHook().addAttribute(this.attrName)
  },
  methods: {
    localizeEnums(value: string): string {
      return this.$t(createI18nEnumKey(this.resource, this.attrName, value))
    },
  },
})
</script>
<style scoped>
.enum-list--container ::v-deep .attribute-row-label {
  color: #888 !important;
  font-size: 0.8em !important;
}
.enum-list--container ::v-deep .attribute-row-value {
  font-size: 0.8em !important;
}
</style>
