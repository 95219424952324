<template>
  <ActionBarPreset :actions="actionList" />
</template>

<script lang="ts">
import Vue, { VueConstructor } from 'vue'

import { ActiveWindowProvider, LayoutWindowEvent } from '@/tt-app-layout'
import { ResourceTranslator } from '@/tt-widget-entity-flow/ResourceTranslator'
import { Resources } from '../../types'
import ActionBarPreset from '@/tt-widget-entity-flow/components/action-bar/ActionBarPreset.vue'
import { ViewAction } from '@/tt-widget-entity-flow/components/action-bar/ActionBarPreset'
import { ItemHookProvider } from '@/tt-widget-entity-flow/types'
import { getResourceDocument } from '@/tt-widget-entity-flow/helper'
import i18n from '@/plugins/i18n'
import { MOBILE_RUNSHEET_OCCURRENCES } from './constants'
import { DocumentProviders } from '@/tt-entity-documents/types'

type VueWithInjections = VueConstructor<
  Vue & ItemHookProvider & ActiveWindowProvider
>

export default (Vue as VueWithInjections).extend({
  name: 'MobileRunsheetOccurrencesActionBar',
  components: {
    ActionBarPreset,
  },
  inject: ['activeWindow', 'getItemHook'],
  computed: {
    sessionId(): number {
      return this.getItemHook().getRawValue('session.id')
    },

    reportId(): number {
      const attribute = this.relationPrefix ? `${this.relationPrefix}.id` : 'id'

      return this.getItemHook().getRawValue(attribute)
    },

    disableReportAction(): boolean {
      return this.sessionId === null || this.sessionId === undefined
    },

    actions(): ViewAction[] {
      const openFormAction = async (actionName: string) => {
        const payload = await this.getItemHook().getActionFormAsPage(actionName)

        this.activeWindow.next(payload)
      }

      return [
        {
          icon: 'mdi-cancel',
          title: ResourceTranslator.translateActionLabel(
            Resources.MOBILE_RUNSHEET_OCCURRENCES,
            'cancel',
          ),
          onClick: () => openFormAction('cancel'),
        },
      ]
    },

    actionList(): ViewAction[] {
      const viewReport: ViewAction = {
        disabled: this.disableReportAction,
        icon: 'mdi-file-eye-outline',
        title: this.$t('tt-entity-design.view-report'),
        onClick: async () => this.view(),
      }

      const downloadAction: ViewAction = {
        icon: 'mdi-download-box-outline',
        title: this.$t('tt-entity-design.print-runsheet'),
        onClick: async () => this.download(),
      }

      return [...this.actions, viewReport, downloadAction]
    },
  },
  created() {
    this.getItemHook().addAttribute('session.id')
  },
  methods: {
    async view() {
      const translationKey =
        'tt-entity-design.mobile-runsheet-occurrences.runsheet-report'

      const documentPreset = getResourceDocument(
        Resources.MOBILE_RUNSHEET_OCCURRENCES,
        translationKey,
      )

      if (!documentPreset) {
        console.error(
          `No document preset registered for resource "${
            Resources.MOBILE_RUNSHEET_OCCURRENCES
          }" and document name ${this.$t(translationKey)}`,
        )

        return
      }

      const reportInput = await documentPreset.toDocument(
        this.$appContext,
        { ...this.getItemHook().entityReference, entityId: this.sessionId },
        i18n.locale,
      )
      this.$eventManager.dispatchEvent(
        LayoutWindowEvent.VIEW_DOCUMENT,
        reportInput,
      )
    },

    async download() {
      this.$eventManager.dispatchEvent(LayoutWindowEvent.DIALOG, {
        is: 'DocumentViewer',
        title: this.$t(
          'tt-entity-design.mobile-runsheet-occurrences.print-runsheet',
        ),
        width: Math.min(window.innerWidth - 20, 1500),
        height: window.innerHeight * 0.9,
        props: {
          documentInput: {
            provider: DocumentProviders.TTCLOUD_API,
            data: {
              path: `/rest/v1/${MOBILE_RUNSHEET_OCCURRENCES}/${this.reportId}/actions/print`,
              payload: {
                printedRunsheetOccurrenceIds: [this.reportId],
              },
            },
          },
        },
      })
    },
  },
})
</script>
