<template>
  <v-btn
    icon
    class="ml-auto"
    :color="btnColor"
    :loading="loading"
    :disabled="loading || disabled"
    @click.stop="$emit('on-click')"
  >
    <v-icon small> mdi-minus-circle-outline </v-icon>
  </v-btn>
</template>

<script lang="ts">
import Vue from 'vue'

/**
 * A simple button with a delete icon.
 * It emits the 'on-click' event when clicked, and prevents event bubbling.
 */
export default Vue.extend({
  name: 'TButtonDelete',
  props: {
    /**
     * Whether the button is in a loading state.
     * When true, the button will show a loading spinner and be disabled.
     */
    loading: {
      type: Boolean,
      default: false,
    },
    /**
     * Whether the button is disabled.
     * When true, the button will be grayed out, and not clickable.
     */
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    btnColor(): string {
      return this.disabled ? 'grey' : 'red'
    },
  },
})
</script>
