import { WeekDayServiceCycleType } from './weekDayServiceCycleType'
import { getNextCycleDate } from './getNextCycleDate'
import moment from 'moment'
import { Period } from './period'

export const PERIOD_TO_VALUE: { [key in Period]: number } = {
  // days inside the respective time period
  WEEKLY: 7,
  MONTHLY: 30.41,
  QUARTERLY: 91.23,
  HALF_YEAR: 182.5,
  YEARLY: 365,
  TWO_YEAR: 730,
}

// calc for prorated quantity is (quantity/deys inside period)*days to next cycle from begin service date
export const calcProratedQuantity = (
  period: Period,
  beginServiceDate: string,
  quantity: number,
  weekDayStartSetting?: WeekDayServiceCycleType,
) => {
  const nextCycleDate = getNextCycleDate(
    period,
    beginServiceDate,
    weekDayStartSetting,
  )
  const beginSDateMoment = moment(beginServiceDate)
  const daysRequired = nextCycleDate.diff(beginSDateMoment, 'days')

  return Math.round((quantity / PERIOD_TO_VALUE[period]) * daysRequired)
}
