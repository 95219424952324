<template>
  <div class="pb-3">
    <v-tabs v-model="tab" height="45" slider-color="orange">
      <v-tab v-for="item in tabs" :key="item.key">
        <span v-text="item.label" />
      </v-tab>
    </v-tabs>

    <!-- Preset range -->
    <div v-if="isPresetTabSelected" style="height: 200px; overflow-y: scroll">
      <v-list dense>
        <div v-for="preset in presets" :key="preset.value">
          <v-list-item
            :data-preset-date="preset.value"
            @click="setPreset(preset.value)"
          >
            <v-list-item-content>
              <v-list-item-title>
                {{ $t(preset.text) }}
              </v-list-item-title>
            </v-list-item-content>
            <v-list-item-action v-if="isPresetSelected(preset)">
              <v-icon color="green" v-text="'mdi-check'" />
            </v-list-item-action>
          </v-list-item>
          <v-divider />
        </div>
      </v-list>
    </div>

    <!-- Custom range -->
    <div v-else-if="isRangeTabSelected" class="d-flex flex-column align-center">
      <TimeZonePicker
        v-if="!hideTimezone"
        :time-zone="timezone"
        :default-time-zone="userPreferences.timeZone"
        class="level1 w-100 px-3 py-1"
        @input="setTimeZone"
      />

      <v-date-picker
        :value="dateRange"
        range
        no-title
        color="ttPrimary"
        full-width
        :locale="$i18n.locale"
        @input="$emit('new-date-range', $event)"
      />

      <div v-if="startDate" class="details--container w-100">
        <div class="dates--container level0 py-1 subtitle-2">
          <v-chip
            color="var(--v-ttPrimary-lighten5)"
            text-color="ttPrimary"
            v-text="formattedStartDate"
          />

          <v-chip
            v-if="endDate"
            color="var(--v-ttPrimary-lighten5)"
            text-color="ttPrimary"
            v-text="formattedEndDate"
          />
          <v-chip
            v-else
            color="var(--v-ttPrimary-lighten5)"
            text-color="ttPrimary"
            v-text="'---'"
          />
        </div>

        <div v-if="!hideTimePicker" class="w-100 level1">
          <div class="dates--container d-flex align-center w-100">
            <div
              v-if="canShowTimePicker"
              class="flex--row"
              style="height: 30px"
              @click="hasRequestedToSetTime = true"
            >
              <TimePickerDropdown
                :value="timeStart"
                @input="$emit('update:time-start', $event)"
              />
              <TimePickerDropdown
                :value="timeEnd"
                @input="$emit('update:time-end', $event)"
              />
            </div>

            <v-btn
              v-else
              text
              small
              color="grey"
              @click="hasRequestedToSetTime = true"
            >
              {{ $t('filter.time.set-time') }}
            </v-btn>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import Vue from 'vue'

import { UserPreferenceInterface } from '@tracktik/tt-authentication'
import TimeZonePicker from './TimeZonePicker.vue'

import TimePickerDropdown from './TimePickerDropdown.vue'
import {
  DATE_PRESETS,
  DatePresetValue,
  DatePreset,
} from '../temporal-filters/date-presets'
import { Timezone } from '@/helpers/dates/timezones'
import dateFormatter from '@/helpers/formats/dates'
import { FilterDateTimeTab, FilterDateTimeTabs } from '../types'
import {
  DEFAULT_START_TIME,
  DEFAULT_END_TIME,
} from '@/tt-entity-filter/temporal-filters/contants'

export default Vue.extend({
  name: 'FilterDateTime',
  components: { TimeZonePicker, TimePickerDropdown },
  props: {
    presetValue: {
      type: String,
      default: null,
    },
    hidePresets: {
      type: Boolean,
      default: false,
    },
    hideTimePicker: {
      type: Boolean,
      default: false,
    },
    hideTimezone: {
      type: Boolean,
      default: false,
    },
    startDate: {
      type: String,
      default: null,
    },
    endDate: {
      type: String,
      default: null,
    },
    timezone: {
      type: String,
      default: null,
    },
    isPreset: {
      type: Boolean,
      default: false,
    },
    timeStart: {
      type: String,
      default: null,
    },
    timeEnd: {
      type: String,
      default: null,
    },
    isEmpty: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      tab: 0,
      presets: DATE_PRESETS,
      availableTabs: [
        {
          key: FilterDateTimeTabs.PRESETS,
          label: this.$t('filter.date.preset'),
        },
        { key: FilterDateTimeTabs.RANGE, label: this.$t('filter.date.range') },
      ] as FilterDateTimeTab[],
      hasRequestedToSetTime: false,
    }
  },
  computed: {
    canShowTimePicker(): boolean {
      return (
        this.hasRequestedToSetTime ||
        this.timeStart !== DEFAULT_START_TIME ||
        this.timeEnd !== DEFAULT_END_TIME
      )
    },
    dateRange(): string[] {
      return [this.startDate, this.endDate].filter(Boolean)
    },
    tabs(): FilterDateTimeTab[] {
      return this.hidePresets ? this.availableTabs.slice(1) : this.availableTabs
    },
    userPreferences(): UserPreferenceInterface {
      return this.$appContext.authModule.getUserPreferences()
    },
    formattedStartDate(): string {
      return dateFormatter.asDate(
        this.startDate,
        this.userPreferences,
      ) as string
    },
    formattedEndDate(): string {
      return dateFormatter.asDate(this.endDate, this.userPreferences) as string
    },
    isPresetTabSelected(): boolean {
      return this.tabs[this.tab]?.key === FilterDateTimeTabs.PRESETS
    },
    isRangeTabSelected(): boolean {
      return this.tabs[this.tab]?.key === FilterDateTimeTabs.RANGE
    },
  },
  created() {
    this.tab = this.isPreset || this.isEmpty ? 0 : 1
  },
  methods: {
    setPreset(preset?: DatePresetValue) {
      this.$emit('new-preset', preset)
    },
    setTimeZone(timeZone: Timezone): void {
      this.$emit('update:timezone', timeZone)
    },
    isPresetSelected(preset: DatePreset): boolean {
      return this.presetValue === preset.value
    },
  },
})
</script>

<style scoped>
.dates--container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  color: var(--v-ttPrimary-base);
  font-weight: bold;
}

.dates--title {
  font-size: 0.7em !important;
}
</style>
