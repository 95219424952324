<template>
  <v-card class="text-center fill-height d-flex flex-column">
    <v-tabs v-if="isMobile" v-model="tab">
      <v-tab v-text="$t(`common.document`)" />
      <v-tab v-text="$t(`common.options`)" />
    </v-tabs>

    <v-row class="fill-height" no-gutters>
      <v-col
        v-show="showDocumentCol"
        style="position: relative"
        class="fill-height"
      >
        <v-overlay v-if="showOverlay" opacity="0.8" absolute>
          <template v-if="!loaded">
            <v-alert
              text
              type="info"
              v-text="$t(`common.documents.waiting_message`)"
            />
            <v-progress-circular indeterminate color="seconday" size="150" />
          </template>

          <template v-if="networkError">
            <v-alert text type="warning" v-text="$t(`common.error_message`)" />
            <v-btn v-if="networkError" large @click="init">
              <span v-text="$t(`common.retry.btn`)" />
            </v-btn>
          </template>

          <v-btn v-else-if="isDirty" large @click="updateDocumentURL">
            <span v-text="$t(`common.update.btn`)" />
          </v-btn>
        </v-overlay>

        <iframe
          v-if="iFrameUrl"
          v-show="loaded"
          :src="iFrameUrl"
          name="documentIframe"
          style="width: 100%; height: 100%"
          frameborder="0"
          @load="loaded = true"
        />
      </v-col>

      <v-col
        v-if="hasOptionsSchema && !networkError"
        v-show="showOptionsCol"
        :cols="isMobile ? null : 3"
      >
        <v-toolbar v-if="!isMobile" dense flat small>
          <v-toolbar-title class="heading" v-text="$t(`common.options`)" />
        </v-toolbar>

        <div class="pa-3">
          <json-form
            v-model="options"
            :form-options="formOptions"
            :schema="optionsSchema"
            show-state
            @debouncing="isFormDebouncing = $event"
            @hook:mounted="updateDocumentURL"
          />

          <a
            v-if="showDownload"
            :href="iFrameUrl"
            :download="documentTitle"
            style="text-decoration: none"
            target="_blank"
          >
            <v-btn color="success" width="100%">
              <span v-text="$t(`common.download.btn`)" />
              <v-icon v-text="`mdi-download`" />
            </v-btn>
          </a>

          <v-btn
            v-else
            :loading="isFormDebouncing || !loaded"
            width="100%"
            @click="updateDocumentURL"
          >
            <span v-text="$t(`common.update.btn`)" />
          </v-btn>
        </div>
      </v-col>
    </v-row>
  </v-card>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue'
import isEqual from 'lodash/isEqual'
import {
  EmptyValueRule,
  FormOptions,
  JSONSchema7,
} from '@tracktik/tt-json-schema-form'
import documentManager from '@/tt-entity-documents' // @todo: decouple from documentManager instance
import i18n from '@/plugins/i18n'
import {
  DocumentInput,
  DocumentServiceMethods,
} from '@/tt-entity-documents/types'
import { isEmpty } from '@/helpers/isEmpty'
import {
  EntityOptions,
  fetchDocumentAsBlob,
  optionsToParams,
} from './DocumentViewer.utils'

export default Vue.extend({
  name: 'DocumentViewer',
  props: {
    documentInput: {
      required: true,
      type: Object as PropType<DocumentInput>,
    },
  },
  data() {
    return {
      tab: 0,
      iFrameUrl: '',
      objectURL: '',
      loaded: false,
      options: {} as EntityOptions,
      lastSentOptions: {} as EntityOptions,
      optionsSchema: null as JSONSchema7 | null,
      isFormDebouncing: false,
      networkError: false,
    }
  },
  computed: {
    showOverlay(): boolean {
      return this.networkError || !this.loaded || this.isDirty
    },
    isDirty(): boolean {
      return !isEqual(this.options, this.lastSentOptions)
    },
    showDownload(): boolean {
      return (
        !!this.iFrameUrl &&
        this.loaded &&
        !this.isDirty &&
        !this.isFormDebouncing
      )
    },
    documentTitle(): string {
      const title = (this.options?.title as string) || 'document'
      return title.replaceAll('.', '_').trim() // if title contains a dot, browser will interpret it as an extension
    },
    showDocumentCol(): boolean {
      return (this.isMobile && this.tab === 0) || !this.isMobile
    },
    showOptionsCol(): boolean {
      return (
        (this.isMobile && this.tab === 1 && this.hasOptionsSchema) ||
        !this.isMobile
      )
    },
    isMobile(): boolean {
      return this.$vuetify.breakpoint.smAndDown
    },
    hasOptionsSchema(): boolean {
      return !isEmpty(this.optionsSchema?.properties)
    },
    formOptions(): FormOptions {
      return {
        emptyValues: EmptyValueRule.APPLY_DEFAULTS,
        locale: this.documentInput.locale ?? i18n.locale,
        definitions: {
          ContextFilters: { view: { is: '' } }, // `contextFilters` are passed but not displayed
        },
      }
    },
    height(): number {
      return window.innerHeight - 200
    },
  },
  created() {
    const initialOptions = { ...this.documentInput.options }

    this.options = { ...initialOptions }
    this.lastSentOptions = { ...initialOptions }

    this.init()
  },
  beforeDestroy() {
    this.revokeObjectUrl()
  },
  methods: {
    async init() {
      this.networkError = false
      this.loaded = false
      await this.loadDocumentOptionsSchema()
      // if there is a customization form, we wait for the first update to get the valid `options` payload
      // if not, we don't wait and load the document
      if (!this.hasOptionsSchema && !this.networkError)
        await this.updateDocumentURL()
    },
    updateDocumentURL() {
      return this.loadDocumentURL().catch((e) => this.handleError(e))
    },
    async loadDocumentURL() {
      this.networkError = false
      this.loaded = false
      this.revokeObjectUrl()
      this.lastSentOptions = { ...this.options }

      const httpMethod = await documentManager.getHTTPMethod(this.documentInput)
      const documentUrl = await documentManager.getDocumentUrl(
        this.documentInput,
        optionsToParams(this.options), // do we need to send params in query when POST ?
      )

      this.iFrameUrl =
        httpMethod === DocumentServiceMethods.POST
          ? await this.fetchDocumentObjectURL(documentUrl)
          : documentUrl
    },
    handleError(err: unknown) {
      this.networkError = true
      this.loaded = true
      console.warn(err)
      this.$crash.captureException(err)
      return null
    },
    async loadDocumentOptionsSchema(): Promise<void> {
      this.optionsSchema = await documentManager
        .getDocumentOptionsSchema(this.documentInput)
        .catch((e) => this.handleError(e))
    },
    async fetchDocumentObjectURL(documentUrl): Promise<string> {
      const createObjectURL = (blob: Blob) => URL.createObjectURL(blob)

      const headers = documentManager.getHeaders(this.documentInput)
      const payload = documentManager.getPayload(this.documentInput)
      this.objectURL = await fetchDocumentAsBlob(
        documentUrl,
        this.options,
        payload,
        headers,
      ).then(createObjectURL)

      return this.objectURL
    },
    revokeObjectUrl() {
      if (this.objectURL) {
        URL.revokeObjectURL(this.objectURL)
        this.objectURL = ''
      }
    },
  },
})
</script>
