<template>
  <ActionBarPreset :actions="actionList" />
</template>
<script lang="ts">
import Vue, { PropType } from 'vue'
import ActionBarPreset from '@/tt-widget-entity-flow/components/action-bar/ActionBarPreset.vue'
import {
  ViewAction,
  VueWithInjections,
} from '@/tt-widget-entity-flow/components/action-bar/ActionBarPreset'
import {
  EntityEditIntentInterface,
  EntityCreateIntentInterface,
  EntityIntentTypes,
  EntityActionIntentInterface,
} from '@/tt-widget-entity-flow'
import { Resources } from '@/tt-entity-design/src/types'
import { FieldErrorRule } from '@tracktik/tt-json-schema-form'
import {
  getEditFormState,
  getCreateFormState,
} from '@/tt-entity-design/src/tools/helpers/form-state-generator'
import { LayoutWindowEvent } from '@/tt-app-layout'
import { Action } from '@/tt-widget-factory/services/resource-action/types'
import { createViewAction } from '@/tt-entity-design/src/tools/actions-generator'
import { ARCHIVE } from './constants'
import OperationCentersArchiveForm from './form/OperationCentersArchiveForm.vue'
import { StatusEnum } from './types'

export default (Vue as VueWithInjections).extend({
  name: 'OperationCentersActionBar',
  components: {
    ActionBarPreset,
  },
  inject: ['activeWindow', 'getItemHook'],
  props: {
    actions: {
      type: Array as PropType<Action[]>,
      required: true,
    },
  },
  computed: {
    operationCenterStatus(): string {
      return this.getItemHook().getRawValue('status')
    },
    actionList(): ViewAction[] {
      const editAction: ViewAction = {
        disabled: this.operationCenterStatus === StatusEnum.ARCHIVED,
        icon: 'mdi-pencil-outline',
        title: this.$t('common.edit.btn'),
        onClick: async () => this.openEditForm(),
      }

      const createPolicyAction: ViewAction = {
        icon: 'add',
        title: this.$t(
          'tt-entity-design.operation-center-escalation-policies.button.add-policies',
        ),
        onClick: async () => this.openPoliciesForm(),
        dataAnalytics:
          'core:entity:operation-centers:escalation-policies:add-button',
      }

      const availableActions: ViewAction[] = this.actions
        .map(({ actionName }) => {
          return this.actionsDictionnary[actionName] || (null as ViewAction)
        })
        .filter(Boolean)

      return [editAction, createPolicyAction, ...availableActions]
    },
    operationCenterName(): string {
      return this.getItemHook().getRawValue('name')
    },
    actionsDictionnary(): Record<string, ViewAction> {
      const openFormAction = async (actionName: string) => {
        const options: Partial<EntityActionIntentInterface> = {
          formOptions: {
            requiredFieldLabelTransformer: null,
          },
          onSuccess:
            actionName === ARCHIVE
              ? () => {
                  this.activeWindow.back()
                }
              : undefined,
        }

        const pageInterface = await this.getItemHook().getActionFormAsPage(
          actionName,
          options,
        )

        const payload =
          actionName === ARCHIVE
            ? {
                ...pageInterface,
                is: OperationCentersArchiveForm,
              }
            : pageInterface

        this.activeWindow.next(payload)
      }

      return {
        [ARCHIVE]: createViewAction(
          'mdi-archive-outline',
          ARCHIVE,
          Resources.OPERATION_CENTERS,
          openFormAction,
        ),
      }
    },
  },
  created() {
    this.getItemHook().addAttribute('status')
  },
  methods: {
    async openEditForm(): Promise<void> {
      const formConfig: EntityEditIntentInterface = {
        resourceName: Resources.OPERATION_CENTERS,
        title: '',
        entityId: this.getItemHook().getEntityId(),
        itemHook: this.getItemHook(),
        formOptions: {
          fieldErrorRule: FieldErrorRule.ALWAYS,
          requiredFieldLabelTransformer: null,
        },
      }

      const payload = await getEditFormState(this.$appContext, formConfig)

      this.$eventManager.dispatchEvent(LayoutWindowEvent.SIDE_SHEET_ADD_PAGE, {
        is: 'GenericFormPage',
        title: this.$t('common.edit.btn'),
        props: { payload },
      })
    },
    async openPoliciesForm(): Promise<void> {
      const formConfig: EntityCreateIntentInterface = {
        resourceName: Resources.OPERATION_CENTER_ESCALATION_POLICIES,
        onSuccess: () => {
          this.$eventManager.dispatchEvent(EntityIntentTypes.RESOURCE_UPDATED, {
            resource: Resources.OPERATION_CENTERS,
          })
        },
        initialModel: {
          operationCenter: this.getItemHook().getEntityId(),
          //To remove after https://tracktik.atlassian.net/browse/API-3524
          name: this.$t('escalation-policies.form.name-field', {
            name: this.getItemHook().getEntityId(),
          }),
          escalationRules: [
            { operationCentersToEscalateTo: [], timeToEscalate: null },
          ],
        },
      }

      const payload = await getCreateFormState(this.$appContext, formConfig)

      this.$eventManager.dispatchEvent(LayoutWindowEvent.SIDE_SHEET_ADD_PAGE, {
        is: 'GenericFormPage',

        props: {
          payload: {
            ...payload,
            title: this.$t('escalation-policies.form-create-title', {
              name: this.operationCenterName,
            }),
          },
        },
      })
    },
  },
})
</script>
