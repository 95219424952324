import Axios, { AxiosResponse } from 'axios'
import { isEmpty, mapValues, omitBy } from 'lodash'

export type EntityOptions = Record<string, unknown>

const booleanToParam = (val: boolean): string => (val ? '1' : '0')

const optionToParam = (val: unknown): string =>
  typeof val === 'boolean' ? booleanToParam(val) : val.toString()

export const optionsToParams = (
  options: EntityOptions,
): Record<string, string> => {
  const presentOptions = omitBy(options, isEmpty)

  return mapValues(presentOptions, optionToParam)
}

export const fetchDocumentAsBlob = async (
  url: string,
  options: EntityOptions,
  payload: Record<string, string> = {},
  requestHeaders: EntityOptions = {},
): Promise<Blob> => {
  const formData = new FormData()
  formData.append('_options', JSON.stringify(options))

  const headers = {
    'Content-Type': 'application/x-www-form-urlencoded',
    ...requestHeaders,
  }

  const body = Object.keys(payload).length > 0 ? payload : formData

  const { data } = await Axios.post<Blob>(url, body, {
    headers,
    responseType: 'blob',
  })

  return data
}
