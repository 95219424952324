import uniq from 'lodash/uniq'
import {
  CollectionQuery,
  MarkerCollectionModel,
} from '@/tt-widget-components/schemas-types'
import CollectionWidgetHook from '../../base/CollectionWidgetHook'
import {
  Marker,
  MarkerId,
} from '@tracktik/tt-maps/lib/src/MarkerManager/MarkerManager'
import { WidgetHookDependencies } from '@/tt-widget-factory/types'
import { MapManager } from '@tracktik/tt-maps'
import { reactive, watch } from 'vue'
import debounce from 'lodash/debounce'
import { FilterOperatorType } from '@/tt-widget-factory/definitions'
import { EntityIntentTypes } from '@/tt-widget-entity-flow'
import { TTC_API_MAX_LIMIT } from '@/tt-widget-components/constants'

type MapCollectionDeps = {
  mapManager: MapManager
}

// @ts-ignore -- Type 'MarkerCollectionModel' does not satisfy the constraint 'WidgetModels'.
// https://tracktik.atlassian.net/browse/FE-1411
export default class MarkerCollectionWidgetHook extends CollectionWidgetHook<MarkerCollectionModel> {
  private mapManager: MapManager

  constructor(deps: WidgetHookDependencies, { mapManager }: MapCollectionDeps) {
    super(deps)
    this.mapManager = mapManager
  }

  markersState = reactive({
    activeMarkerIds: [] as MarkerId[],
  })

  private debounceUpdate = debounce(() => {
    this.update()
  }, 100)

  async initialize() {
    super.initialize()
    this.unsubscribeFunctions.push(
      this.services.eventManager.subscribeEvent(
        EntityIntentTypes.PREVIEW_CLOSE,
        ({ entityId }) => {
          this.removeActiveMarker(entityId)
        },
      ),
    )
    this.unsubscribeFunctions.push(
      this.services.eventManager.subscribeEvent(
        EntityIntentTypes.PREVIEW,
        ({ entityId }) => {
          this.setActiveMarker(entityId)
        },
      ),
    )
  }

  setRequiredFields(attributes: string[]) {
    // Refactor to be set on collection widget hook see: FE-1565
    const widgetFields =
      this.state.widget.query?.fields?.map((field) => field.attribute) || []

    const uniqueAttributes = [
      ...new Set([...attributes, ...widgetFields, 'id']),
    ]
    this.attributes = [...uniqueAttributes]
    this.isReadyToFetch = true
    this.update()
  }

  setup() {
    // Not ready to fetch until we get the required fields
    this.isReadyToFetch = false

    const boundingBoxWatcher = watch(
      () => this.mapManager.getBoundingBox(),
      () => this.update(),
    )

    this.queryManager.setWhereQL(
      `${this.getLatitudeAttribute()} <> 0 AND ${this.getLongitudeAttribute()} <> 0`,
    )

    this.unsubscribeFunctions.push(boundingBoxWatcher)
  }

  setActiveMarker(id: MarkerId): void {
    this.markersState.activeMarkerIds.push(id)
  }

  removeActiveMarker(id: MarkerId): void {
    const activeMarkerIds = this.markersState.activeMarkerIds.filter(
      (activeMarkerId) => activeMarkerId !== id,
    )
    this.markersState.activeMarkerIds = [...activeMarkerIds]
  }

  getMarkers(): Marker[] {
    const markers = this.entities?.map((entity): Marker => {
      const { id, lng, lat } = entity

      return {
        id,
        position: [lng, lat],
        active: this.markersState.activeMarkerIds.includes(id),
        data: { entity },
      }
    })

    return markers ?? []
  }

  getLongitudeAttribute(): string {
    return this.widget.geoMapOptions.longitudeAttribute
  }

  getLatitudeAttribute(): string {
    return this.widget.geoMapOptions.latitudeAttribute
  }

  private applyCoordinatesFilter(): void {
    const boundingBox = this.mapManager.getBoundingBox()

    if (boundingBox) {
      /**
       * If we know the bounding box, we want to fetch only the items that are within it.
       */
      this.queryManager.setCustomFilter({
        attribute: this.getLongitudeAttribute(),
        operator: FilterOperatorType.BETWEEN,
        value: [boundingBox.southWest[0], boundingBox.northEast[0]],
      })
      this.queryManager.setCustomFilter({
        attribute: this.getLatitudeAttribute(),
        operator: FilterOperatorType.BETWEEN,
        value: [boundingBox.southWest[1], boundingBox.northEast[1]],
      })
    }
  }

  getDefaultAttributes(): string[] {
    return [
      'id',
      `${this.getLatitudeAttribute()}:lat`,
      `${this.getLongitudeAttribute()}:lng`,
    ]
  }

  protected get effectiveQuery(): CollectionQuery {
    const attributes = uniq([
      ...this.getDefaultAttributes(),
      ...this.getAttributes(),
    ])

    this.queryManager.setFieldsAndExtensionsFromAttributes(attributes)

    this.applyCoordinatesFilter()

    // @TODO: catch when count is higher than limit, and display a message to the user
    return { ...this.queryManager.query, limit: TTC_API_MAX_LIMIT }
  }

  protected getRelationAttributeResources(): string[] {
    const attributes = [
      this.getLatitudeAttribute(),
      this.getLongitudeAttribute(),
      ...this.getAttributes(),
    ]

    return super.getRelationAttributeResources(attributes)
  }

  destroy(): void {
    this.debounceUpdate.cancel()
    super.destroy()
  }
}
