<template>
  <TTooltipOnOverflow :text="name" :disable-tooltip="!isOverflowing">
    <OverflowObserver @overflow="onOverflow">
      <div class="d-flex align-baseline">
        <span
          class="workflow-status--dot flex-shrink-0 mr-2"
          :style="{ backgroundColor: backgroundColor }"
        />
        <span
          class="caption text-uppercase text-truncate font-weight-medium flex-shrink-1"
          :style="{ color: backgroundColor }"
          v-text="name"
        />
      </div>
    </OverflowObserver>
  </TTooltipOnOverflow>
</template>

<script lang="ts">
import Vue, { VueConstructor } from 'vue'
import { ItemHookProvider } from '@/tt-widget-entity-flow/types'
import { WorkflowStatusAttribute } from './types'
import TTooltipOnOverflow from '@/tt-ui/components/TTooltipOnOverflow.vue'
import OverflowObserver from '@/tt-widget-components/components/OverflowObserver.vue'

const REQUIRED_ATTRS: WorkflowStatusAttribute[] = [
  'name',
  'formatBackgroundColor',
  'formatTextColor',
]

export default (
  Vue as VueConstructor<Vue & ItemHookProvider & { relationPrefix: string }>
).extend({
  name: 'WorkflowStatusesReference',
  components: { TTooltipOnOverflow, OverflowObserver },
  inject: ['getItemHook', 'relationPrefix'],
  data: () => ({
    isOverflowing: false,
  }),
  computed: {
    name(): string {
      return this.getValue('name') || ''
    },
    backgroundColor(): string {
      return this.getValue('formatBackgroundColor') || 'black'
    },
  },
  created() {
    REQUIRED_ATTRS.map((attr) => this.getPath(attr)).forEach((attr) =>
      this.getItemHook().addAttribute(attr),
    )
  },
  methods: {
    getPath(attr: WorkflowStatusAttribute) {
      const { relationPrefix } = this

      return relationPrefix ? `${relationPrefix}.${attr}` : attr
    },
    getValue(attr: WorkflowStatusAttribute): string {
      const path = this.getPath(attr)

      return this.getItemHook().getRawValue(path)
    },
    onOverflow(isOverflowing: boolean) {
      this.isOverflowing = isOverflowing
    },
  },
})
</script>

<style scoped>
.workflow-status--dot {
  display: block;
  border-radius: 50%;
  height: 10px;
  width: 10px;
}
</style>
