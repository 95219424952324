<template>
  <EntityCollection
    dense
    :query="query"
    :is-sorted="isSorted"
    @loading="loading = $event"
    @itemsFetched="updateFetchedItems"
  >
    <template v-if="selectAll" #selectAll>
      <v-tooltip dense top :disabled="isSearching">
        <template #activator="{ on }">
          <v-list-item class="sticky-select-all" v-on="on">
            <v-list-item-action>
              <v-simple-checkbox
                :value="areAllSelected()"
                :disabled="!isSearching"
                color="ttPrimary"
                hide-details
                small
                class="pt-0 mt-0"
                @click="toggleSelectFetched"
              />
            </v-list-item-action>
            <v-list-item-content>
              <span
                class="text-uppercase text-caption font-weight-bold"
                :class="{ faded: !isSearching }"
                :style="isSearching ? 'cursor: pointer' : 'cursor: default'"
              >
                {{ $t('components.entity.collection.select_all_fetched') }}
              </span>
            </v-list-item-content>
          </v-list-item>
        </template>
        <span>
          {{
            $t(
              'components.entity.collection.select_all_fetched.disabled.message',
            )
          }}
        </span>
      </v-tooltip>
    </template>
    <template #default="{ itemHook }">
      <v-list-item @click="emitInput(itemHook)">
        <v-list-item-action>
          <v-simple-checkbox
            :value="isSelected(itemHook)"
            color="ttPrimary"
            @click="emitInput(itemHook)"
          />
        </v-list-item-action>
        <v-list-item-content>
          <tt-preset-view
            type="relationReference"
            class="d-inline-block text-truncate"
          />
        </v-list-item-content>
      </v-list-item>
    </template>
  </EntityCollection>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue'

import { CollectionQuery } from '@/tt-widget-components'

import { EntityItemHook } from '../EntityItemHook'
import EntityCollection from './EntityCollection.vue'

const castToString = (itemHook: EntityItemHook): string =>
  String(itemHook.entityReference.entityId || '')

import { EmittedObjectType } from '../types'

export default Vue.extend({
  name: 'EntityCollectionListWrapper',
  components: { EntityCollection },
  props: {
    value: {
      type: Array as PropType<string[]>,
    },
    query: {
      type: Object as PropType<CollectionQuery>,
      required: true,
    },
    isSorted: {
      type: Boolean,
      default: false,
    },
    selectAll: {
      type: Boolean,
      default: false,
    },
    isSearching: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loading: false,
      fetchedEntityIds: [] as string[],
    }
  },
  methods: {
    isSelected(itemHook: EntityItemHook) {
      const value = this.value ?? []

      return value.includes(castToString(itemHook))
    },
    areAllSelected(): boolean {
      return (
        this.fetchedEntityIds.length > 0 &&
        this.fetchedEntityIds.every((id) => (this.value ?? []).includes(id))
      )
    },
    emitInput(itemHook: EntityItemHook) {
      this.$emit('input', castToString(itemHook))
    },
    updateFetchedItems(entityIds: string[]) {
      this.fetchedEntityIds = entityIds
    },
    toggleSelectFetched() {
      //if all are selected then press is to deselect all
      const toBeEmittedObject: EmittedObjectType = {
        fetchedEntityIds: [...this.fetchedEntityIds],
        deselectAll: this.areAllSelected(),
      }
      this.$emit('input:bulkSelect', toBeEmittedObject)
    },
  },
})
</script>
<style scoped>
.sticky-select-all {
  position: sticky;
  top: 0;
  background: white;
  z-index: 10;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
</style>
