import { ColumnInputDefinition } from '@/tt-widget-components/widgets/DataTable/DataTableWidgetHook'
import {
  EmployeeCustomComponenets,
  LeaveRequestStatusColors,
} from '../../types'

const baseTranslation = 'tt-entity-design.leave-management.employee'

const LeaveHistoryCustomColumns: ColumnInputDefinition[] = [
  {
    attributeName: 'id',
    headerText: `${baseTranslation}.leave-history.request-id`,
    style: {
      width: 125,
    },
  },
  {
    attributeName: 'createdOn',
    headerText: 'res.leave-requests.attr.createdOn.label',
    format: 'asDate',
  },
  {
    attributeName: 'leaveType',
    headerText: 'res.leave-requests.attr.leaveType.label',
  },
  {
    attributeName: 'startDateTime',
    headerText: 'res.leave-requests.attr.startDateTime.label',
    format: 'asDate',
    component: {
      is: 'LeaveRequestsDateTimeIconColumn',
      props: {
        dateName: 'startDateTime',
        tooltipText:
          'tt-entity-design.leave-management.leave-requests.start-time',
      },
    },
  },
  {
    attributeName: 'endDateTime',
    headerText: 'res.leave-requests.attr.endDateTime.label',
    format: 'asDate',
  },
  {
    attributeName: 'requestedDays',
    headerText: `${baseTranslation}.leave-history.amount`,
    component: {
      is: EmployeeCustomComponenets.LeaveHistoryAmountColumn,
    },
    style: {
      width: 125,
    },
  },
  {
    attributeName: 'reviewer',
    headerText: `${baseTranslation}.leave-history.reviewed-by`,
  },
  {
    attributeName: 'status',
    headerText: 'res.leave-requests.attr.status.label',
    component: {
      is: 'StatusChip',
      props: { statusColorOptions: LeaveRequestStatusColors },
    },
  },
]

export default LeaveHistoryCustomColumns
