import { DocumentManager } from '@/tt-entity-documents/DocumentManager'
import { AppContext } from '@/tt-app-context'

import DocumentServiceProvider from './providers/DocumentServiceProvider'
import { ExternalURLDocumentProvider } from './providers/ExternalURLDocumentProvider'
import HtmlDocumentServiceProvider from './providers/HtmlDocumentServiceProvider'
import TTCloudDocumentServiceProvider from './providers/TTCloudDocumentServiceProvider'
import TTCloudApiProvider from '@/tt-entity-documents/providers/TTCloudApiProvider'

const documentManager = new DocumentManager()

export default documentManager

export const installDocumentService = (appContext: AppContext) => {
  const documentServiceProvider = new DocumentServiceProvider(appContext)
  const externalUrlServiceProvider = new ExternalURLDocumentProvider()
  const htmlDocumentServiceProvider = new HtmlDocumentServiceProvider()
  const ttCloudDocumentServiceProvider = new TTCloudDocumentServiceProvider(
    appContext,
  )
  const ttCloudApiProvider = new TTCloudApiProvider(appContext)
  documentManager.registerProvider(documentServiceProvider)
  documentManager.registerProvider(externalUrlServiceProvider)
  documentManager.registerProvider(htmlDocumentServiceProvider)
  documentManager.registerProvider(ttCloudDocumentServiceProvider)
  documentManager.registerProvider(ttCloudApiProvider)
}
