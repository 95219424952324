import { PermissionView, PortalType } from './types'

export const PORTAL_TYPE_ICON_MAP: Record<PortalType, PermissionView> = {
  admin: {
    icon: 'mdi-checkbox-multiple-outline',
    color: 'success',
  },
  staff: {
    icon: 'mdi-earth',
    color: 'ttPrimary',
  },
  client: {
    icon: 'mdi-office-building',
    color: 'orange',
  },
  me: {
    icon: 'mdi-account',
    color: 'info',
  },
}

export const PORTAL_TYPES = ['admin', 'staff', 'client', 'me'] as const
