import { DefinitionOption } from '@tracktik/tt-json-schema-form'

import {
  ActionFormTypes,
  modularManager,
  PresetLevels,
  PresetTypes,
} from '@/tt-app-modular'
import {
  addPreviewTab,
  registerResourceForm,
} from '@/tt-widget-entity-flow/helper'
import { ListWidgetModel } from '@/tt-widget-components'

import SiteTaskSchedulesAddExceptionTypes from './SiteTaskSchedulesAddExceptionTypes.vue'
import SiteTaskSchedulesJsonForm from './SiteTaskSchedulesJsonForm.vue'
import SiteTaskScheduleType from './SiteTaskScheduleType.vue'
import { Resources } from '../../types'
import { siteTaskSchedulesSchedulerEventHandler } from './SiteTaskSchedulesSchedulerEvent'
import SiteTaskSchedulesTimeAdjustmentsTab from './SiteTaskSchedulesTimeAdjustmentsTab.vue'
import SiteTaskSchedulesPeriodicScheduleTab from './SiteTaskSchedulesPeriodicScheduleTab.vue'

export default {
  install(Vue) {
    Vue.component(
      'SiteTaskSchedulesAddExceptionTypes',
      SiteTaskSchedulesAddExceptionTypes,
    )
    Vue.component('SiteTaskSchedulesJsonForm', SiteTaskSchedulesJsonForm)
    Vue.component('SiteTaskScheduleType', SiteTaskScheduleType)

    Vue.component(
      'SiteTaskSchedulesTimeAdjustmentsTab',
      SiteTaskSchedulesTimeAdjustmentsTab,
    )
    Vue.component(
      'SiteTaskSchedulesPeriodicSchedulesTab',

      SiteTaskSchedulesPeriodicScheduleTab,
    )

    // Remove skip priceTier when product requests it (SEU-431)
    const defaultDefinition: DefinitionOption = {
      skip: ['priceTier'],
    }
    registerResourceForm(
      Resources.SITE_TASK_SCHEDULES,
      'SiteTaskSchedulesJsonForm',
      ActionFormTypes.FULL_ENTITY,
      { defaultDefinition },
    )

    registerResourceForm(
      Resources.SITE_TASK_SCHEDULES,
      'SiteTaskSchedulesAddExceptionTypes',
      'add-exception-types',
    )

    modularManager.addResourcePreset(
      Resources.SITE_TASK_SCHEDULES,
      PresetTypes.CALENDAR_EVENT,
      {
        title: 'Default',
        data: siteTaskSchedulesSchedulerEventHandler,
        level: PresetLevels.DEFAULTS,
      },
    )
    addPreviewTab(Resources.SITE_TASK_SCHEDULES, {
      title: 'tt-entity-design.site-task-schedules.preview.tab-exceptions',
      is: 'SiteTaskSchedulesTimeAdjustmentsTab',
      /**
       * If the user can perform actions on exception types, the Details preset
       * includes the `exceptionTypes` field in the request.
       * If the field has value (empty or not), show tab. If it's nil, hide it.
       */
      condition: ({ itemHook }) => itemHook.get('exceptionTypes') != null,
    })

    addPreviewTab(Resources.SITE_TASK_SCHEDULES, {
      title: 'tt-entity-design.site-task-schedules.preview.tab-periodic',
      is: 'SiteTaskSchedulesPeriodicSchedulesTab',
      condition: ({ itemHook }) => !!itemHook.get('periodicSchedule.id'),
    })
  },
}
