<template>
  <div class="fill-height">
    <json-field
      name="leaveType"
      resource="leave-types"
      v-bind="$attrs"
      :class="
        !leaveTypeError.length && showBalances ? 'hide-error-tag' : 'pb-2'
      "
      :query-options="leaveType.queryOptions"
      :label="$tc('res.leave-requests.attr.leaveType.label')"
    />
    <div v-if="showBalances" class="days-information-container">
      <div class="days-information-message">
        <span>{{ availableLocale }}:</span>
        <span :class="balancesTextColor">
          {{ balancesLocale }}
        </span>
      </div>
    </div>
    <DateRangeField
      v-model="selectedDates"
      :label="dateRangeLabel"
      :invalid-time-error="invalidTimeError"
      :leave-conflict-error="leaveConflictError"
      :available-balance="availableBalance"
      :scheduled-shifts-dates="scheduledShiftsDates"
      :leave-requests-dates="leaveRequestsDates"
      :form-dates="formDates"
      :min-date="minDateTobeAvailableForDatePicker"
      @input="changeDate($event)"
      @displayed-year-month="fetchShiftsAndRequests"
    >
      <v-divider vertical class="mr-1" />
      <TimePicker
        v-model="time"
        :is24h-preference="is24hPreference"
        class="time-picker"
        @invalid-time="setInvalidTimeError"
      />
      <template #legend>
        <div class="d-flex px-4 py-2">
          <div class="legend px-1 d-flex align-center">
            <div class="dot scheduled-shift" />
            <span class="caption pl-1 pb-0" v-text="$t(scheduledShiftLabel)" />
          </div>
          <div class="legend px-1 d-flex align-center">
            <div class="dot leave-request" />
            <span class="caption pl-1 pb-0" v-text="$t(leaveRequestLabel)" />
          </div>
        </div>
        <v-divider />
      </template>
      <template #conflicts>
        <v-divider
          v-if="conflictingLeaves.length || conflictingShifts.length"
        />
        <ConflictButtons
          :conflicting-shifts="conflictingShifts"
          :conflicting-leaves="conflictingLeaves"
          :within-date-picker="true"
        />
      </template>
    </DateRangeField>
    <json-field
      name="reason"
      outlined
      :label="$tc('res.leave-requests.attr.reason.label')"
    />
    <ConflictButtons
      :conflicting-shifts="conflictingShifts"
      :conflicting-leaves="conflictingLeaves"
    />
    <div
      :class="{ 'mt-4': conflictingLeaves.length || conflictingShifts.length }"
      :v-if="alerts.length"
    >
      <TAlert
        v-for="(alert, key) in alerts"
        :key="alert"
        :text="alert"
        :class="{ 'mt-4': key === 1 }"
      />
    </div>
  </div>
</template>

<script lang="ts">
import Vue, { VueConstructor } from 'vue'

import { EntityCollectionResponse } from 'tracktik-sdk/lib/common/entity-collection'

import DateRangeField from './DateRangeField.vue'
import TimePicker from '@/tt-entity-forms/components/TimePicker.vue'

import { Filter, FormHookProvider } from '@/tt-widget-components'
import { FilterOperatorType } from '@/tt-widget-factory'

// @todo: generic modules shouldn't depend on app modules
import {
  EntitlementUnit,
  EntitlementUnitEnum,
  LeavePolicyItem,
} from '@/tt-widget-views/leave-management/types'
import ConflictButtons from './ConflictButtons.vue'

import { Resources } from '../../types'
import { LeaveRequestsDatesManager } from './LeaveRequestsDatesManager'
import { formatDate } from '@/helpers/dates/formatDate'
import { isDateInFuture } from '@/helpers/dates/isDateInFuture'
import { enumerateDaysBetweenDates } from '@/helpers/dates/enumerateDates'
import uniqBy from 'lodash/uniqBy'
import { DateRangeInput } from '@/helpers/formats/dates/types'
import { ErrorObject } from 'ajv'
import { updateDOM } from '@/helpers/dom'
import { CalendarPills, LeaveRequest, Shift } from './types'
import { LeaveRequestsStatus } from '@/tt-widget-views/leave-management/types'
import {
  DSTDateBetweenDates,
  getDSTMonth,
} from '@/tt-widget-views/leave-management/utils'
import {
  LeavePolicyApiResponse,
  LeavePolicyEmployeeInfo,
} from '../leave-policies/types'
import round from 'lodash/round'

const leaveTypeString = 'leaveType'
const invalidTimeString = 'invalidTime'
const leaveConflictString = 'leaveConflict'
const startDateTimeString = 'startDateTime'
const endDateTimeString = 'endDateTime'
const PHP24HoursFormat = 'H:i'
const SCHEDULED_SHIFTS_COLOR = 'ttPrimary'
const LEAVE_REQUESTS_COLOR = 'error'

export default (Vue as VueConstructor<Vue & FormHookProvider>).extend({
  name: 'LeaveRequestsLogForm',
  components: {
    DateRangeField,
    TimePicker,
    ConflictButtons,
  },
  inject: ['formHook'],
  props: {
    employee: {
      type: [String, Number],
      required: true,
    },
  },
  data() {
    return {
      availableBalance: 0,
      showBalances: false,
      policyItemEntitlementUnit: EntitlementUnitEnum.DAYS as EntitlementUnit,
      leaveType: {
        queryOptions: {
          filters: [
            {
              attribute: 'isRequestableForEmployee',
              operator: FilterOperatorType.EQUAL,
              value: this.employee,
            },
          ],
        },
      },
      selectedDates: [],
      time: '',
      scheduledShifts: [] as Shift[],
      leaveRequests: [] as LeaveRequest[],
      currentLeavePolicy: {} as LeavePolicyApiResponse,
    }
  },
  computed: {
    is24hPreference(): boolean {
      return (
        this.$appContext.authModule.getUserPreferences().timeFormat ===
        PHP24HoursFormat
      )
    },
    dateRangeLabel(): string {
      return (
        this.$tc('res.leave-requests.attr.startDateTime.label') +
        ' / ' +
        this.$tc('res.leave-requests.attr.endDateTime.label')
      )
    },
    minDateTobeAvailableForDatePicker(): string {
      if (this.currentLeavePolicy.isCarryoverApplied) {
        return this.currentLeavePolicy.startPeriodDate
      }

      return ''
    },
    startDateTime(): Date {
      return this.formHook().getPathValue(startDateTimeString)
    },
    endDateTime(): Date {
      return this.formHook().getPathValue(endDateTimeString)
    },
    leaveTypeFieldValue(): number {
      return this.formHook().getPathValue(leaveTypeString)
    },
    leaveTypeError(): ErrorObject[] {
      return this.formHook().errors.leaveType || []
    },
    leaveConflictError(): ErrorObject[] {
      return this.formHook().errors.leaveConflict || []
    },
    invalidTimeError(): ErrorObject[] {
      return this.formHook().errors.invalidTime || []
    },
    isDebouncing(): boolean {
      return this.formHook().state.isDebouncing
    },
    availableLocale(): string {
      return this.$tc('tt-entity-design.leave-requests.available')
    },
    futurePolicyWarning(): string {
      return this.$tc('tt-entity-design.leave-requests.future-period-warning')
    },
    showFuturePolicyWarning(): boolean {
      const { startDate } = this.formDates

      return this.isRequestInFuturePolicy && !!startDate
    },
    availableBalanceRounded(): number {
      return round(this.availableBalance, 2)
    },
    balancesTextColor(): string {
      if (this.showFuturePolicyWarning) {
        return ''
      }

      return this.availableBalance >= 1
        ? 'available-days-positive'
        : 'available-days-negative'
    },
    balancesLocale(): string {
      if (this.showFuturePolicyWarning) {
        return '--'
      }

      if (this.policyItemEntitlementUnit === EntitlementUnitEnum.HOURS) {
        return this.$tc(
          'tt-entity-design.leave-requests.hours-amount',
          this.availableBalanceRounded,
        )
      } else {
        return this.$tc(
          'tt-entity-design.leave-requests.days-amount',
          this.availableBalanceRounded,
        )
      }
    },
    isRequestInFuturePolicy(): boolean {
      const { endPeriodDate } = this.currentLeavePolicy
      const { startDate } = this.formDates

      return isDateInFuture(startDate, endPeriodDate, 'day')
    },
    scheduledShiftsDates(): CalendarPills {
      const datePills = {}

      this.scheduledShifts.forEach((shift) => {
        const startDate = formatDate(shift.startDateTime)
        const endDate = formatDate(shift.endDateTime)

        datePills[startDate] = SCHEDULED_SHIFTS_COLOR

        if (!(endDate in datePills)) {
          datePills[endDate] = SCHEDULED_SHIFTS_COLOR
        }
      })

      return datePills
    },
    leaveRequestsDates(): CalendarPills {
      const datePills = {}

      this.leaveRequests.forEach((request) => {
        const startDate = formatDate(request.startDateTime)
        const endDate = formatDate(request.endDateTime)
        const datesInbetween = enumerateDaysBetweenDates(startDate, endDate)

        const leaveDates = [startDate, ...datesInbetween, endDate]

        leaveDates.forEach((leave) => {
          datePills[leave] = LEAVE_REQUESTS_COLOR
        })
      })

      return datePills
    },
    scheduledShiftLabel(): string {
      return 'tt-entity-design.leave-requests.scheduled-shift'
    },
    leaveRequestLabel(): string {
      return 'tt-entity-design.leave-management.employee.transaction-activity.attr-values.name.leave-request'
    },
    conflictingShifts(): Shift[] {
      if (!this.startDateTime || this.invalidTimeError.length) return []

      return this.scheduledShifts.filter((shift: Shift) => {
        const isShiftStartDateBeforeLeaveRequest =
          LeaveRequestsDatesManager.isSameOrBefore(
            shift.startDateTime,
            this.endDateTime,
          )

        const isShiftEndDateAfterLeaveRequest =
          LeaveRequestsDatesManager.isSameOrAfter(
            shift.endDateTime,
            this.startDateTime,
          )

        return (
          isShiftStartDateBeforeLeaveRequest && isShiftEndDateAfterLeaveRequest
        )
      })
    },
    conflictingLeaves(): LeaveRequest[] {
      if (!this.startDateTime || this.invalidTimeError.length) return []

      return this.leaveRequests.filter((leaveRequest: LeaveRequest) => {
        const isLeaveConflictStartDateBeforeLeaveRequest =
          LeaveRequestsDatesManager.isSameOrBefore(
            leaveRequest.startDateTime,
            this.endDateTime,
          )
        const isLeaveConflictEndDateAfterLeaveRequest =
          LeaveRequestsDatesManager.isSameOrAfter(
            leaveRequest.endDateTime,
            this.startDateTime,
          )

        return (
          isLeaveConflictStartDateBeforeLeaveRequest &&
          isLeaveConflictEndDateAfterLeaveRequest
        )
      })
    },
    conflictBeforeTimeOffset(): Shift[] {
      if (!this.startDateTime) return []

      return this.scheduledShifts.filter((shift: Shift) => {
        const isShiftStartDateBeforeLeaveRequest =
          LeaveRequestsDatesManager.isSameOrBefore(
            formatDate(shift.startDateTime),
            formatDate(this.endDateTime),
          )

        const isShiftEndDateAfterLeaveRequest =
          LeaveRequestsDatesManager.isSameOrAfter(
            formatDate(shift.endDateTime),
            formatDate(this.startDateTime),
          )

        return (
          isShiftStartDateBeforeLeaveRequest && isShiftEndDateAfterLeaveRequest
        )
      })
    },
    formDates(): DateRangeInput {
      return {
        startDate: this.formHook().getPathValue('startDateTime'),
        endDate: this.formHook().getPathValue('endDateTime'),
      }
    },
    createInvalidTimeError(): ErrorObject[] {
      return [
        {
          dataPath: `.${invalidTimeString}`,
          keyword: 'required',
          message: this.$t(
            'tt-entity-design.leave-management.leave-requests.invalid-time-error',
          ),
          params: Object,
          schemaPath: '#/required',
        },
      ]
    },
    createLeaveConflictError(): ErrorObject[] {
      return [
        {
          dataPath: `.${leaveConflictString}`,
          keyword: 'required',
          message: this.$t(
            'tt-entity-design.leave-management.leave-requests.leave-conflict-error',
          ),
          params: Object,
          schemaPath: '#/required',
        },
      ]
    },
    daylightSavingsWarningStarts(): string {
      return this.$t(
        'tt-entity-design.leave-requests.daylight-savings-time-starts-warning',
      )
    },
    daylightSavingsWarningEnds(): string {
      return this.$t(
        'tt-entity-design.leave-requests.daylight-savings-time-ends-warning',
      )
    },
    daylightSavingsTimeWarning(): string | null {
      const DSTDate = DSTDateBetweenDates(
        formatDate(this.formDates.startDate),
        formatDate(this.formDates.endDate),
        this.$appContext.authModule.getUserPreferences(),
      )

      const formattedDSTDate = getDSTMonth(DSTDate)

      if (formattedDSTDate === 2) {
        return this.daylightSavingsWarningStarts
      } else if (formattedDSTDate === 9 || formattedDSTDate === 10) {
        return this.daylightSavingsWarningEnds
      } else {
        return null
      }
    },
    alerts(): string[] {
      const messages = []
      if (this.daylightSavingsTimeWarning) {
        messages.push(this.daylightSavingsTimeWarning)
      }

      if (this.showFuturePolicyWarning) {
        messages.push(this.futurePolicyWarning)
      }

      return messages
    },
  },
  watch: {
    async leaveTypeFieldValue(value: string): Promise<void> {
      const policyItemsEmployeeFilter: Filter = {
        attribute: 'employee',
        value: this.employee,
        operator: FilterOperatorType.EQUAL,
      }
      const policyItemsLeaveTypeFilter: Filter = {
        attribute: leaveTypeString,
        value: value,
        operator: FilterOperatorType.EQUAL,
      }
      const policyItemsBalanceExtension = 'balance'

      if (!value) {
        this.showBalances = false
      }

      if (this.isReadyToValidate(value)) {
        return this.$appContext.widgetServices.resourceDataManager
          .getCollection({
            resource: Resources.LEAVE_POLICY_ITEMS,
            filters: [policyItemsEmployeeFilter, policyItemsLeaveTypeFilter],
            extension: [policyItemsBalanceExtension],
          })
          .then((data: EntityCollectionResponse<LeavePolicyItem>) => {
            const { items } = data
            const availableBalance = items[0].balance.available
            const entitlementUnit = items[0].entitlementUnit
            this.availableBalance = availableBalance
            this.policyItemEntitlementUnit = entitlementUnit
          })
          .finally(() => {
            this.showBalances = true
          })
      }
    },
    async time(selectedTime: string) {
      const [startDate, endDate] = this.selectedDates

      if (!startDate) return

      const [formattedStartDateTime, formattedEndDateTime] =
        LeaveRequestsDatesManager.formatDateRangeForApi(
          startDate,
          endDate,
          selectedTime,
        )

      this.setDates(formattedStartDateTime, formattedEndDateTime)

      await updateDOM()
    },
    formDates() {
      if (this.conflictingLeaves.length) {
        this.formHook().setCustomError(
          leaveConflictString,
          this.createLeaveConflictError,
        )
      } else {
        this.clearLeaveConflictError()
      }
    },
  },
  created() {
    this.fetchEmployeeCurrentPolicy(this.employee)
  },
  mounted() {
    this.formHook().state.errors = {}
  },
  methods: {
    setInvalidTimeError(value: boolean) {
      if (value) {
        this.formHook().setCustomError(
          invalidTimeString,
          this.createInvalidTimeError,
        )
      } else {
        this.clearInvalidTimeError()
      }
    },
    clearInvalidTimeError(): void {
      this.formHook().setCustomError(invalidTimeString, null)
    },
    clearLeaveConflictError(): void {
      this.formHook().setCustomError(leaveConflictString, null)
    },
    resetTime(): void {
      this.time = LeaveRequestsDatesManager.defaultStartTime
    },
    setDates(startDate, endDate) {
      this.formHook().setObjectValue(startDateTimeString, startDate)
      this.formHook().setObjectValue(endDateTimeString, endDate)
    },
    changeDate([startDate, endDate]) {
      const [formattedStartDateTime, formattedEndDateTime] =
        LeaveRequestsDatesManager.formatDateRangeForApi(
          startDate,
          endDate,
          this.time,
        )

      this.setDates(formattedStartDateTime, formattedEndDateTime)

      if (!this.conflictBeforeTimeOffset.length) {
        this.$nextTick(() => {
          this.resetTime()
          this.clearInvalidTimeError()
        })
      }
    },
    fetchShiftsAndRequests(currentYearMonth: string) {
      this.fetchLeaveRequests(currentYearMonth)
      this.fetchScheduledShifts(currentYearMonth)
    },
    fetchEmployeeCurrentPolicy(employee) {
      return this.$appContext.entityServices.persister.api
        .get(Resources.EMPLOYEES, employee, {
          include: ['currentLeavePolicy.isCarryoverApplied'],
        })
        .then(({ currentLeavePolicy }: LeavePolicyEmployeeInfo) => {
          this.currentLeavePolicy = currentLeavePolicy
        })
        .catch((err) => this.$crash.captureException(err))
    },
    fetchLeaveRequests(currentYearMonth: string) {
      const startOfTheMonth =
        LeaveRequestsDatesManager.getStartOfTheMonth(currentYearMonth)

      const endOfTheMonth =
        LeaveRequestsDatesManager.getEndOfTheMonth(currentYearMonth)

      this.getLeaveRequests([startOfTheMonth, endOfTheMonth])
        .then(({ items }) => {
          this.leaveRequests = uniqBy([...this.leaveRequests, ...items], 'id')
        })
        .catch((err) => this.$crash.captureException(err))
    },
    getLeaveRequests([startedOn, endedOn]) {
      return this.$appContext.widgetServices.resourceDataManager.getCollection({
        resource: Resources.LEAVE_REQUESTS,
        filters: [
          {
            attribute: startDateTimeString,
            operator: FilterOperatorType.BEFORE,
            value: endedOn,
          },
          {
            attribute: endDateTimeString,
            operator: FilterOperatorType.AFTER,
            value: startedOn,
          },
          {
            attribute: 'employee',
            operator: FilterOperatorType.EQUAL,
            value: this.employee,
          },
          {
            attribute: 'status',
            operator: FilterOperatorType.IN,
            value: [
              LeaveRequestsStatus.PENDING,
              LeaveRequestsStatus.PENDING_CANCELLATION,
              LeaveRequestsStatus.APPROVED,
            ],
          },
        ],
      })
    },
    fetchScheduledShifts(currentYearMonth: string) {
      const startOfTheMonth =
        LeaveRequestsDatesManager.getStartOfTheMonth(currentYearMonth)

      const endOfTheMonth =
        LeaveRequestsDatesManager.getEndOfTheMonth(currentYearMonth)

      this.getAffectedShifts([startOfTheMonth, endOfTheMonth])
        .then(({ items }) => {
          this.scheduledShifts = uniqBy(
            [...this.scheduledShifts, ...items],
            'id',
          )
        })
        .catch((err) => this.$crash.captureException(err))
    },
    getAffectedShifts([startDateTime, endDateTime]: string[]) {
      return this.$appContext.widgetServices.resourceDataManager.getCollection({
        resource: Resources.SHIFTS,
        filters: [
          {
            attribute: 'startDateTime',
            operator: FilterOperatorType.BEFORE,
            value: endDateTime,
          },
          {
            attribute: 'endDateTime',
            operator: FilterOperatorType.AFTER,
            value: startDateTime,
          },
          {
            attribute: 'employee',
            operator: FilterOperatorType.EQUAL,
            value: this.employee,
          },
          {
            attribute: 'paidTimeOff',
            operator: FilterOperatorType.EQUAL,
            value: 0,
          },
        ],
        include: ['employee,position,position.account'],
      })
    },
    isReadyToValidate(val): boolean {
      return !this.isDebouncing && !!val
    },
  },
})
</script>
<style scoped>
.legend > .dot {
  border-radius: 50%;
  display: inline-block;
  height: 8px;
  margin: 0 1px;
  width: 8px;
}

.legend .scheduled-shift {
  background-color: var(--v-ttPrimary-base);
}

.legend .leave-request {
  background-color: var(--v-error-base);
}

.hide-error-tag >>> .v-text-field__details {
  display: none;
}

.days-information-container {
  padding: 0px 12px;
  min-height: 14px;
  margin-bottom: 16px;
  line-height: 16px;
  font-size: 12px;
  position: relative;
}

.days-information-message {
  position: absolute;
  animation: slide-in 0.2s;
}

.days-information-message > span:last-of-type {
  font-weight: bold;
}

.available-days-positive {
  color: var(--v-ttPrimary-base);
}

.available-days-negative {
  color: var(--v-error-darken2);
}

@keyframes slide-in {
  from {
    top: -5px;
  }
  to {
    top: 0;
  }
}

.time-picker {
  min-width: 250px;
}
</style>
