<template>
  <v-card flat>
    <div class="toolbar3 pa-4 d-flex align-center">
      <FilterOperatorSelection
        v-model="operator"
        :operators="operatorsList"
        style="width: 30%"
      />

      <TSearchInput
        v-if="showInput"
        v-model="search"
        class="ml-2"
        style="width: 70%"
        :debounce-time="100"
      />
    </div>
  </v-card>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue'
import {
  FilterOperatorType,
  Filter,
} from 'tracktik-sdk/lib/common/entity-filters'
import TSearchInput from '@/tt-ui/components/TSearchInput.vue'
import FilterOperatorSelection from './FilterOperatorSelection.vue'
import { FilterOperator } from '@/tt-widget-components/schemas-types'

const STRING_OPERATORS = [
  FilterOperatorType.CONTAINS,
  FilterOperatorType.STARTSWITH,
  FilterOperatorType.ENDSWITH,
  FilterOperatorType.EQUAL,
  FilterOperatorType.ISNOTNULL,
  FilterOperatorType.ISNULL,
]

export default Vue.extend({
  name: 'FilterString',
  components: { TSearchInput, FilterOperatorSelection },
  props: {
    attributeName: {
      type: String,
      required: true,
    },
    value: {
      type: Object as PropType<Filter>,
      default: () => ({} as Filter),
    },
  },
  computed: {
    operatorsList(): FilterOperator[] {
      return STRING_OPERATORS
    },
    showInput(): boolean {
      return ![
        FilterOperatorType.ISNOTNULL,
        FilterOperatorType.ISNULL,
        // @ts-ignore
      ].includes(this.operator)
    },
    search: {
      get(): string {
        // @ts-ignore
        return this.value?.value || ''
      },
      set(value: string) {
        const attribute = this.attributeName
        const operator = this.operator

        this.emitInput({ attribute, operator, value })
      },
    },
    operator: {
      get(): Filter['operator'] {
        return this.value?.operator || FilterOperatorType.CONTAINS
      },
      set(operator: Filter['operator']) {
        const attribute = this.attributeName
        const value = this.search

        const newFilter =
          operator === FilterOperatorType.ISNULL || operator === FilterOperatorType.ISNOTNULL
            ? { attribute, operator }
            : { attribute, operator, value }

        this.emitInput(newFilter)
      },
    },
  },
  methods: {
    emitInput(newFilter: Filter) {
      this.$emit('input', { ...newFilter })
    },
  },
})
</script>
