<template>
  <v-card flat>
    <v-card-text class="d-flex align-center">
      <v-avatar class="mr-4" color="success" size="56">
        <v-img :src="avatar" />
      </v-avatar>
      <div class="flex-grow-1 d-flex flex-column">
        <span class="font-weight-medium">{{ createdOn }}</span>
        <span class="grey--text">{{ name }}</span>
      </div>
      <v-btn color="ttPrimary" icon @click="openLink">
        <v-icon small v-text="'mdi-eye'" />
        {{ $t('tt-entity-design.view-document-button') }}
      </v-btn>
    </v-card-text>
  </v-card>
</template>

<script lang="ts">
import Vue, { VueConstructor } from 'vue'
import { ItemHookProvider } from '@/tt-widget-entity-flow/types'
import { LayoutWindowEvent } from '@/tt-app-layout'
import { DocumentProviders } from '@/tt-entity-documents/types'
import { formatDate } from '@/helpers/dates/formatDate'

export default (Vue as VueConstructor<Vue & ItemHookProvider>).extend({
  name: 'MobileScheduleOccurrencesAuditLogItem',
  components: {},
  inject: ['getItemHook'],
  computed: {
    file(): string {
      return this.getItemHook().getRawValue('file.url')
    },
    name(): string {
      return this.getItemHook().getRawValue('createdBy.name')
    },
    avatar(): string {
      return this.getItemHook().getRawValue('createdBy.avatar')
    },
    createdOn(): string {
      return this.getItemHook().get('createdOn')
    },
  },
  created() {
    this.getItemHook().addAttribute('file.url')
    this.getItemHook().addAttribute('createdBy.name')
    this.getItemHook().addAttribute('createdBy.avatar')
    this.getItemHook().addAttribute('createdOn')
  },
  methods: {
    openLink() {
      this.$eventManager.dispatchEvent(LayoutWindowEvent.DIALOG, {
        is: 'DocumentViewer',
        title: this.$t('tt-entity-design.view-document'),
        width: Math.min(window.innerWidth - 20, 1500),
        height: window.innerHeight * 0.9,
        props: {
          documentInput: {
            provider: DocumentProviders.TTCLOUD_API,
            data: {
              url: this.file,
            },
          },
        },
      })
    },
  },
})
</script>
