<template>
  <div>
    <v-tabs v-model="tabIndex" left slider-color="orange">
      <v-tab v-text="browseLabel" />
      <v-tab :disabled="selectedCount === 0" v-text="selectedFieldsLabel" />
    </v-tabs>

    <template v-if="tabIndex === 0">
      <div class="toolbar3 pa-2 flex--row">
        <TSearchInput v-model="search" @blur="$emit('blur')" />

        <div class="d-flex align-center pl-1">
          <v-tooltip top>
            <template #activator="{ on }">
              <v-btn
                :class="showInactive && 'show-inactive--on'"
                class="button--icon"
                icon
                v-on="on"
                @click="showInactive = !showInactive"
              >
                <v-icon small v-text="`mdi-archive`" />
              </v-btn>
            </template>

            <span
              v-text="$t('tt-entity-forms.relation-fields.show-archived')"
            />
          </v-tooltip>
        </div>
      </div>

      <EntityCollectionListWrapper
        :query="searchQuery"
        is-sorted
        :value="model"
        :select-all="!showSelected"
        :is-searching="isSearching"
        class="entity--collection"
        @input="toggle($event)"
        @input:bulkSelect="toggleBulk($event)"
      />
    </template>

    <EntityCollectionListWrapper
      v-if="showSelected"
      :query="showSelectedQuery"
      is-sorted
      :value="model"
      class="entity--collection"
      @input="toggle($event)"
    />
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue'

import { FilterOperatorType } from 'tracktik-sdk/lib/common/entity-filters'

import EntityCollectionListWrapper from '@/tt-widget-entity-flow/components/EntityCollectionListWrapper.vue'
import { CollectionQuery } from '@/tt-widget-components/schemas-types'
import TSearchInput from '@/tt-ui/components/TSearchInput.vue'
import { EmittedObjectType } from '@/tt-widget-entity-flow/types'

export type EntityMultipleSelectorFieldQuery = Omit<
  CollectionQuery,
  'resource' | 'search' | 'sort'
>

export default Vue.extend({
  name: 'EntityMultipleSelectorField',
  components: { EntityCollectionListWrapper, TSearchInput },
  props: {
    query: {
      type: Object as PropType<EntityMultipleSelectorFieldQuery>,
      default: () => ({} as EntityMultipleSelectorFieldQuery),
    },
    resource: { type: String, required: true },
    value: {
      type: Array as PropType<string[]>,
      default: () => [],
    },
  },
  data() {
    return {
      search: null,
      tabIndex: 0,
      showInactive: false,
    }
  },
  computed: {
    model: {
      get(): string[] {
        return this.value || []
      },
      set(newValue: string[]) {
        this.$emit('input', newValue)
      },
    },
    selectedCount(): number {
      return this.model.length
    },
    selectedFieldsLabel(): string {
      return this.$t('tt-entity-forms.relation-fields.filter-selected', {
        selection: this.selectedCount,
      })
    },
    showSelected(): boolean {
      return this.tabIndex === 1 && this.selectedCount > 0
    },
    searchQuery(): CollectionQuery {
      const { resource, search } = this

      return {
        ...this.query,
        resource,
        search,
        includeInactive: this.showInactive,
      }
    },
    showSelectedQuery(): CollectionQuery {
      const { resource, model } = this

      return {
        resource,
        filters: [
          {
            attribute: 'id',
            operator: FilterOperatorType.IN,
            value: model.join(','),
          },
        ],
      }
    },
    browseLabel(): string {
      return this.$t('tt-entity-forms.relation-fields.filter-list')
    },
    isSearching(): boolean {
      return !!this.search
    },
  },
  methods: {
    toggle(val) {
      if (!val) {
        return
      }
      if (this.model.includes(val)) {
        this.model = this.model.filter((item) => item != val)
      } else {
        this.model = [...this.model, val]
      }
    },
    toggleBulk(object: EmittedObjectType) {
      if (!object) {
        return
      }
      if (object.fetchedEntityIds.length !== 0) {
        let clone = [...this.model]
        object.fetchedEntityIds.map((item) => {
          if (object.deselectAll) {
            clone = clone.filter((i) => i != item)
          } else {
            if (!clone.includes(item)) {
              clone = [...clone, item]
            }
          }
        })
        this.model = [...clone]
      }
    },
  },
})
</script>

<style scoped>
.entity--collection {
  overflow-y: scroll;
  height: 200px;
}

.show-inactive--on {
  color: var(--v-ttPrimary-base) !important;
  background-color: var(--v-ttPrimary-lighten5) !important;
}

.button--icon {
  width: 40px !important;
  height: 40px !important;
}
</style>
