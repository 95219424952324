<template>
  <v-menu offset-y>
    <template #activator="{ on }">
      <v-btn
        class="custom-toolbar-btn-activator mr-2"
        :class="{ 'has-value': !!model }"
        :height="30"
        :max-width="200"
        outlined
        small
        text
        v-on="on"
      >
        <span>{{ buttonTxt }}</span>
        <v-icon
          v-if="!!model"
          style="opacity: 0.5"
          class="ml-2"
          small
          @click.stop="clear"
          v-text="`close`"
        />
        <v-icon
          v-else
          style="opacity: 0.5"
          class="ml-1"
          small
          v-text="`mdi-chevron-down`"
        />
      </v-btn>
    </template>
    <v-list class="pb-0">
      <component :is="component" v-bind="handler" v-model="model" />
      <v-toolbar dense short flat class="level1">
        <v-btn text color="grey" @click="clear">
          <span v-text="$t('common.clear.btn')" />
        </v-btn>
        <v-spacer />
        <v-btn color="green" text @click="apply">
          <span v-text="$t('common.apply.btn')" />
        </v-btn>
      </v-toolbar>
    </v-list>
  </v-menu>
</template>
<script lang="ts">
import Vue, { PropType } from 'vue'
import EnumRadioFilterHandler from './EnumRadioFilterHandler.vue'
import { FilterHandler } from '@/tt-widget-components'

export default Vue.extend({
  name: 'FilterHandlerButton',
  components: {
    EnumRadioFilterHandler,
  },
  props: {
    handler: {
      type: Object as PropType<FilterHandler>,
      required: true,
    },
    resource: {
      type: String,
      required: true,
    },
    value: {
      type: Object as PropType<any>,
      default: null,
    },
  },
  data: () => ({
    model: null,
  }),
  computed: {
    component(): string {
      return this.handler.component
    },
    buttonTxt(): string {
      if (this.model) {
        return this.$t(this.model.label) as string
      }

      const text = this.$appContext.widgetServices.resourceMetaManager
        .getAttributePathLabelKeys(this.resource, this.handler.attribute)
        .map((key: string) => {
          return this.$tc(key)
        })
        .join(' · ')

      return text
    },
  },
  watch: {
    value: {
      handler(newValue) {
        if (newValue == null) {
          this.clear()
        } else if (this.handler.values) {
          const handlerValue = this.handler.values.find(
            (v) => v.value === newValue.value,
          )
          if (handlerValue) {
            this.model = handlerValue
          } else {
            this.clear()
          }
        }
      },
      deep: true,
    },
  },
  methods: {
    clear() {
      this.model = null
      this.apply()
    },
    apply() {
      this.$emit('input', this.handler.handleFilterChange(this.model))
    },
  },
})
</script>

<style scoped lang="scss">
.custom-toolbar-btn-activator {
  background-color: var(--v-level0-base);
  font-size: 0.75em;
  font-weight: 400;
  text-transform: capitalize;

  &.has-value {
    color: var(--v-ttPrimary-base);
    font-weight: bold;
  }
}
</style>
