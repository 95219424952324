<template>
  <TMapMarker :marker="marker" :icon="icon" :color="color" v-on="$listeners">
    <template #menu>
      <slot name="menu" />
    </template>
  </TMapMarker>
</template>

<script lang="ts">
import Vue, { PropType, VueConstructor } from 'vue'

import TMapMarker from '@/tt-ui/components/TMapMarker.vue'
import { EntityMarker } from '@/tt-widget-components/widgets/MapMultiMarkers/types'
import { ItemHookProvider } from '@/tt-widget-entity-flow/types'
import {
  EmployeeClockStatus,
  EmployeeCurrentActivity,
} from '@/tt-entity-design/src/components/employees/types'
import {
  employeeIconColors,
  employeeIcon,
} from '@/tt-entity-design/src/components/employees/constants'
import { Resources } from '@/tt-entity-design/src/types'
import { AttributeEnum } from '@/tt-entity-design/src/schema-types'

type VueWithInjections = VueConstructor<Vue & ItemHookProvider>

export default (Vue as VueWithInjections).extend({
  name: 'MobileDevicesMapMarker',
  components: { TMapMarker },
  inject: ['getItemHook'],
  props: {
    marker: {
      type: Object as PropType<EntityMarker>,
      required: true,
    },
  },
  computed: {
    activities(): boolean[] {
      return [
        this.hasDispatchTask,
        this.hasRunsheetTask,
        this.isStaticGuard,
        this.isLoneWorker,
      ]
    },
    color(): string {
      if (this.currentStatus === 'CLOCKED_IN') {
        if (this.isMobileGuard) {
          if (this.currentActivities.includes('DISPATCH')) {
            return employeeIconColors.DISPATCH
          } else {
            return employeeIconColors.MOBILE
          }
        } else {
          return employeeIconColors.ON_SITE
        }
      } else if (this.currentStatus === 'ON_BREAK') {
        return employeeIconColors.ON_BREAK
      } else {
        return employeeIconColors.CLOCKED_OUT
      }
    },
    currentActivities(): EmployeeCurrentActivity[] {
      return this.getItemHook().getRawValue('user.currentActivities') ?? []
    },
    currentStatus(): EmployeeClockStatus {
      return this.getItemHook().getRawValue('user.clockStatus') ?? 'CLOCKED_OUT'
    },
    hasDispatchTask(): boolean {
      return this.currentActivities.includes('DISPATCH')
    },
    hasRunsheetTask(): boolean {
      return this.currentActivities.includes('RUNSHEET')
    },
    hasMultipleActivities(): boolean {
      return this.activities.filter(Boolean).length > 1
    },
    icon(): string {
      if (this.hasMultipleActivities) return employeeIcon.MULTI_ACTIVITIES
      if (this.hasDispatchTask) return employeeIcon.DISPATCH
      if (this.hasRunsheetTask) return employeeIcon.RUNSHEET
      if (this.isStaticGuard) return employeeIcon.STATIC_GUARD
      if (this.isLoneWorker) return employeeIcon.LONE_WORKER

      return employeeIcon.NO_ACTIVITIES
    },
    accountType(): AttributeEnum<typeof Resources.ACCOUNTS, 'type'> {
      return this.getItemHook().getRawValue('account.type')
    },
    isMobileGuard(): boolean {
      return this.accountType === 'ZONE'
    },
    loneWorkerCheckIns(): unknown[] {
      return (
        this.getItemHook().getRawValue(
          'extensions.workStatus.shift.loneWorkerCheckIns',
        ) ?? []
      )
    },
    isLoneWorker(): boolean {
      return this.loneWorkerCheckIns.length > 0
    },
    isStaticGuard(): boolean {
      return !this.isMobileGuard && !this.isLoneWorker
    },
  },
  created() {
    this.getItemHook().addAttribute('account.type')
    this.getItemHook().addAttribute('user.currentActivities')
    this.getItemHook().addAttribute('user.clockStatus')
    this.getItemHook().addAttribute('extensions.workStatus')
  },
})
</script>
