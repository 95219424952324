<template>
  <TLoadingBar v-if="loading" />
  <div v-else :class="{ 'archive-action-form--wrapper': showAlertBar }">
    <v-alert
      v-if="showAlertBar"
      type="error"
      color="warning"
      icon="mdi-alert"
      class="mb-0"
      text
    >
      <div v-if="hasOperators || hasRegions">
        <p
          class="my-0 font-weight-medium warning--text text--darken-3"
          v-text="paragraphOneLabel"
        />
        <br />
      </div>
      <div v-if="data.itemCount > 0">
        <p
          class="my-0 font-weight-medium warning--text text--darken-3"
          v-text="paragraphTwoLabel"
        />

        <v-divider />
        <div
          v-for="(item, index) in data.items"
          :key="index"
          class="d-flex"
          style="background: white"
        >
          <TChip v-if="!item.ticketTypes" color="green" class="ma-2">
            <v-icon> {{ 'mdi-check' }} </v-icon>
            {{ $t('escalation-policies.default-policies-chip') }}
          </TChip>
          <TChip
            v-else-if="item.ticketTypes.length > 0"
            class="ma-2"
            color="ttPrimary"
          >
            {{
              $tc(
                'escalation-policies.ticket-type-policies-chip',
                item.ticketTypes.length,
                { count: item.ticketTypes.length },
              )
            }}
          </TChip>
          <p
            class="my-0 py-2 text-body-2 warning--text text--darken-3 text-capitalize"
          >
            {{ item.operationCenter.name.toLowerCase() }}
          </p>
        </div>
        <v-divider />
        <p
          class="my-0 font-weight-medium warning--text text--darken-3"
          v-text="paragraphThreeLabel"
        />
      </div>
    </v-alert>
    <GenericFormPage
      class="pt-4 pl-2"
      :payload="updatedPayload"
      @back="$emit('back')"
      @close="$emit('close')"
    />
  </div>
</template>
<script lang="ts">
import Vue, { PropType } from 'vue'
import { DialogFormInterface } from '@/tt-app-layout'
import { VueWithInjections } from '../../conflicts/types'
import { EntityItemHook } from '@/tt-widget-entity-flow/EntityItemHook'
import { Resources } from '@/tt-entity-design/src/types'
import { ResponseItemsType } from '../types'
import { EntityCollectionResponse } from 'tracktik-sdk/lib/common/entity-collection'

export default (Vue as VueWithInjections).extend({
  name: 'OperationCentersArchiveForm',
  inject: {
    activeWindow: { default: undefined },
  },
  props: {
    payload: {
      type: Object as PropType<DialogFormInterface>,
      required: true,
    },
  },
  data() {
    return {
      loading: true,
      data: {} as EntityCollectionResponse<ResponseItemsType>,
    }
  },
  computed: {
    itemHook(): EntityItemHook {
      return this.payload.userContext.itemHook
    },
    hasOperators(): boolean {
      return (
        this.itemHook.getRawValue('operators') !== null &&
        this.itemHook.getRawValue('operators') > 0
      )
    },
    hasRegions(): boolean {
      return (
        this.itemHook.getRawValue('regions') !== null &&
        this.itemHook.getRawValue('regions') > 0
      )
    },
    paragraphOneLabel(): string {
      return this.getTranslation('paragraph-one')
    },
    paragraphTwoLabel(): string {
      return this.getTranslation('paragraph-two')
    },
    paragraphThreeLabel(): string {
      return this.getTranslation('paragraph-three')
    },
    showAlertBar(): boolean {
      return this.hasOperators || this.hasRegions || this.data?.itemCount > 0
    },
    updatedPayload(): Record<string, any> {
      return {
        ...this.payload,
        hideTitleDivider: this.showAlertBar,
      }
    },
  },
  async created() {
    this.itemHook.addAttribute('operators')
    this.itemHook.addAttribute('regions')
    await this.fetchPoliciesByOperationCenter()
    this.loading = false
  },
  methods: {
    getTranslation(paragraph: string) {
      return this.$t(
        `tt-entity-design.operation-centers.archive-form.alert.${paragraph}`,
      )
    },
    async fetchPoliciesByOperationCenter(): Promise<void> {
      this.data =
        await this.$appContext.widgetServices.resourceDataManager.getCollection(
          {
            resource: Resources.OPERATION_CENTER_ESCALATION_POLICIES,
            fields: [
              { attribute: 'id' },
              { attribute: 'ticketTypes' },
              { attribute: 'name' },
              { attribute: 'operationCenter.id' },
              { attribute: 'operationCenter.name' },
            ],
            filters: [
              {
                attribute: 'byEscalationOperationCenter',
                // will need to address the empty operator as is required
                // @ts-ignore
                operator: '',
                value: this.itemHook.getEntityId(),
              },
            ],
          },
        )
    },
  },
})
</script>
<style scoped>
.archive-action-form--wrapper >>> .container {
  padding: 0 !important;
}

.archive-action-form--wrapper >>> .v-alert__icon {
  align-self: center !important;
}
</style>
