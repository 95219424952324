<template>
  <div class="flex--column">
    <ResourceAllowedOperations
      v-slot="{ allowsActions: appAllowsActions }"
      :resource-name="operationCenterResource"
      class="flex--column"
    >
      <div class="flex--column" style="position: relative">
        <TLoadingBar v-if="isLoading" />

        <TreeviewSelector
          v-else
          v-model="draftRegionIds"
          :items="lazyLoadedTreeItems"
          :on-opening-children="fetchChildrenRegionOperationCenters"
          class="pa-3 flex--column overflow-auto"
          hide-batch-selection
        >
          <template #checkbox="{ item, onItemClick, isSelected }">
            <v-simple-checkbox
              v-if="canBeSelected(item)"
              dense
              hide-details
              :value="isSelected(item)"
              color="ttPrimary"
              class="d-flex"
              @input="onItemClick(item)"
            />
            <v-tooltip v-else top>
              <template #activator="{ on }">
                <v-icon
                  class="faded"
                  v-on="on"
                  v-text="`mdi-checkbox-blank-off-outline`"
                />
              </template>
              {{ $t('tt-entity-design.operation-centers.already-assigned') }}
              {{ getAssignedOperationCenterName(item) }}
            </v-tooltip>
          </template>

          <template #name="{ item, onItemClick, isSelected }">
            <span
              :class="{
                faded: !canBeSelected(item),
                'ttPrimary--text': isSelected(item),
              }"
              :style="
                canBeSelected(item) ? 'cursor: pointer' : 'cursor: default'
              "
              @click="() => canBeSelected(item) && onItemClick(item)"
            >
              {{ item.name }}
              <span v-if="getAssignedOperationCenterName(item)">
                - {{ getAssignedOperationCenterName(item) }}
              </span>
            </span>
          </template>
        </TreeviewSelector>
      </div>

      <v-expand-transition v-if="hasChanged" mode="in-out">
        <div class="flex--row pa-3 elevation-6">
          <v-btn color="" small elevation="0" class="mr-6" @click="cancel">
            {{ $t('common.exit.btn') }}
          </v-btn>

          <v-spacer />

          <!-- save button active shown only when user has access to action-->
          <v-btn
            v-if="appAllowsActions && canUpdateRegions"
            :loading="isLoading"
            color="success"
            small
            elevation="0"
            @click="save"
          >
            {{ $t('common.save_changes.btn') }}
          </v-btn>
          <v-tooltip v-else top>
            <template #activator="{ on }">
              <div v-on="on">
                <v-fab-transition>
                  <v-icon v-on="on" v-text="`mdi-lock`" />
                </v-fab-transition>
              </div>
            </template>

            <span
              v-text="
                $t(
                  `tt-entity-design.operation-centers.cannot-modify-assigned-regions`,
                )
              "
            />
          </v-tooltip>
        </div>
      </v-expand-transition>
    </ResourceAllowedOperations>
  </div>
</template>

<script lang="ts">
import Vue, { VueConstructor } from 'vue'
import { ItemHookProvider } from '@/tt-widget-entity-flow/types'
import { updateDOM } from '@/helpers/dom/updateDOM'
import { TTC_API_MAX_LIMIT } from '@/tt-widget-components/constants'
import { getAttr, operationCenterResourceUpdated } from './utils'
import TreeviewSelector from '@/tt-widget-components/components/treeview/TreeviewSelector.vue'
import {
  convertRegionsToTreeItems,
  findItem,
  UserRegion,
} from '@/tt-widget-components/components/treeview/utils'
import { TreeviewItem } from '@/tt-widget-components/components/treeview/types'
import { LayoutWindowEvent } from '@/tt-app-layout/types'
import { cloneData } from '@/helpers/cloneData'
import {
  SyncRegionsProperty,
  OtherOperationCenter,
  RegionOperationCenterMap,
} from './types'
import { replaceChildrenByEmptyArray } from './utils'
import { Resources } from '@/tt-entity-design/src/types'
import { REPLACE_REGIONS } from './constants'

export default (Vue as VueConstructor<Vue & ItemHookProvider>).extend({
  name: 'OperationCentersRegions',
  components: { TreeviewSelector },
  inject: ['getItemHook'],
  data() {
    return {
      isLoading: true,
      /**
       * The region ids that are currently selected in the API.
       * Should not be changed by the UI.
       */
      apiRegionIds: [] as string[],
      /**
       * The region ids that are currently selected in the UI.
       * This is the draft state that will be saved to the API.
       */
      draftRegionIds: [] as string[],
      /**
       * The region ids that are currently not assigned to operation center.
       */
      unassignedRegions: [] as string[],
      /**
       * Used to force the switch to update when the edit mode is toggled and cancelled.
       */
      switchKey: 0,
      /**
       * Map of REGION -> OPERATION_CENTER assignment.
       * Used in edit mode to show which regions can be assigned to the current operation center.
       */
      regionOperationCenterMap: {} as RegionOperationCenterMap,
      /**
       * Edit mode treeItems. Each node will have a `lazyLoadedChildren` property with the children of the node.
       * This is used to load the children of the node when the node is expanded.
       */
      lazyLoadedTreeItems: [] as TreeviewItem[],
    }
  },
  computed: {
    hasChanged(): boolean {
      return (
        this.apiRegionIds.some(
          (apiID) => !this.draftRegionIds.includes(apiID),
        ) ||
        this.draftRegionIds.some(
          (draftID) => !this.apiRegionIds.includes(draftID),
        )
      )
    },
    regions(): UserRegion[] {
      return this.$appContext.regionManager.getAllUserActiveRegions()
    },
    operationCenterResource(): string {
      return Resources.OPERATION_CENTERS
    },
    canUpdateRegions(): boolean {
      return this.getItemHook().isActionAvailable(REPLACE_REGIONS)
    },
    operationCenterId(): number {
      return this.getItemHook().getEntityId()
    },
    treeItems(): TreeviewItem[] {
      return convertRegionsToTreeItems(
        this.$appContext.regionManager,
        this.regions,
      )
    },
  },
  created() {
    this.isLoading = true
    this.fetchSelectedRegionIds()
    this.initRegionOperationCenter()
    this.fetchedUnassignedRegions()
    this.isLoading = false
  },
  methods: {
    replaceChildrenByEmptyArray,
    getAssignedOperationCenterName(region: TreeviewItem): string {
      if (
        this.regionOperationCenterMap[region.id]?.id !==
          this.operationCenterId &&
        this.regionOperationCenterMap[region.id]?.name
      ) {
        return `( ${this.regionOperationCenterMap[region.id]?.name} )`
      } else return ''
    },

    canBeSelected(item: TreeviewItem): boolean {
      if (this.regionOperationCenterMap[item.id]?.id === this.operationCenterId)
        return true
      if (this.regionOperationCenterMap[item.id] === null)
        return this.unassignedRegions.includes(item.id)

      return this.unassignedRegions.includes(item.id)
    },
    async fetchChildrenRegionOperationCenters(
      item: TreeviewItem,
    ): Promise<void> {
      const directChildrenIds = this.$appContext.regionManager
        .getAllUserRegions()
        .filter((region) => region.parentId === item.id)
        .map(({ id }) => id)

      if (!directChildrenIds.length) {
        return
      }

      /**
       * We intentionally mutate the children property of the item
       * https://v2.vuetifyjs.com/en/components/treeview/#load-children
       */
      item.children = [...findItem(this.treeItems, item.id).children].map(
        this.replaceChildrenByEmptyArray,
      )

      await this.fetchRegionOperationCenter(directChildrenIds)
    },
    async fetchRegionOperationCenter(regionIds: string[]): Promise<void> {
      const response = await this.$auth
        .getApi()
        .getAll<{
          region: number
          operationCenter: OtherOperationCenter
        }>(Resources.OPERATION_CENTER_REGIONS, {
          fields: [
            { attribute: 'region' },
            { attribute: 'operationCenter.id' },
            { attribute: 'operationCenter.name' },
          ],
          filters: [
            {
              attribute: 'region',
              operator: 'IN',
              value: regionIds,
            },
          ],
          returnCount: false,
          limit: TTC_API_MAX_LIMIT,
        })
        .then((response) => {
          if (response.total > TTC_API_MAX_LIMIT) {
            console.error(
              'The number of regions to fetch exceeds the API limit:',
              response.total,
            )
          }

          return response.items
        })

      const responseMap = new Map(
        response.map(({ region, operationCenter }) => [
          String(region),
          operationCenter
            ? { id: operationCenter.id, name: operationCenter.name }
            : null,
        ]),
      )

      this.regionOperationCenterMap = regionIds.reduce(
        (acc, regionId) => ({
          ...acc,
          [regionId]: responseMap.get(regionId) || null,
        }),
        this.regionOperationCenterMap,
      )
    },
    async fetchSelectedRegionIds(): Promise<void> {
      const selectedRegionIds = await this.$auth
        .getApi()
        .getAll<{ id: number }>(
          Resources.OPERATION_CENTERS +
            '/' +
            this.operationCenterId +
            '/' +
            getAttr('regions'),
          {
            fields: [{ attribute: 'id' }],
            returnCount: false,
            limit: TTC_API_MAX_LIMIT,
          },
        )
        .then(({ items }) => items.map(({ id }) => String(id)))

      this.apiRegionIds = [...selectedRegionIds]
      this.draftRegionIds = [...selectedRegionIds]
    },
    async initRegionOperationCenter(): Promise<void> {
      await updateDOM()

      this.lazyLoadedTreeItems = cloneData(this.treeItems).map(
        replaceChildrenByEmptyArray,
      )

      const topParentIds = this.lazyLoadedTreeItems.map((item) => item.id)

      await this.fetchRegionOperationCenter(topParentIds)
    },
    async fetchedUnassignedRegions(): Promise<void> {
      const unassignedRegions = await this.$auth
        .getApi()
        .getAll<{ id: number }>(Resources.REGIONS, {
          fields: [{ attribute: 'id' }],
          scope: ['WITHOUT_OPERATION_CENTER'],
          returnCount: false,
          limit: TTC_API_MAX_LIMIT,
        })
        .then(({ items }) => items.map(({ id }) => String(id)))

      this.unassignedRegions = [...unassignedRegions]
    },
    editMapObject() {
      const clonedMap = cloneData(this.regionOperationCenterMap)

      const newLinks = this.draftRegionIds.filter(
        (id) => !this.apiRegionIds.includes(id),
      )
      const removedLinks = this.apiRegionIds.filter(
        (id) => !this.draftRegionIds.includes(id),
      )

      newLinks.forEach((regionId) => {
        clonedMap[regionId] = { id: Number(this.operationCenterId) }
      })

      removedLinks.forEach((regionId) => {
        clonedMap[regionId] = null
      })

      this.regionOperationCenterMap = cloneData(clonedMap)
    },
    async save(): Promise<void> {
      this.isLoading = true

      await updateDOM()

      const payload: Record<SyncRegionsProperty, number[]> = {
        regions: this.draftRegionIds.map((id) => Number(id)),
      }

      await this.$auth
        .getApi()
        .doAction(
          Resources.OPERATION_CENTERS,
          this.operationCenterId,
          REPLACE_REGIONS,
          payload,
        )
        .then(async () => {
          this.editMapObject()

          await this.fetchSelectedRegionIds()
          await this.fetchedUnassignedRegions()
          operationCenterResourceUpdated(this.$eventManager)
        })
        .catch((error) => {
          this.$eventManager.dispatchEvent(LayoutWindowEvent.SNACK_ERROR, {
            message: error.message,
          })
        })
      this.isLoading = false
    },
    cancel(): void {
      const closeEditMode = () => {
        this.draftRegionIds = [...this.apiRegionIds]
      }

      if (this.hasChanged) {
        this.$eventManager.dispatchEvent(LayoutWindowEvent.CONFIRM, {
          message: this.$t('widgets.discard_changes_question') as string,
          accept: closeEditMode,
          acceptColor: 'error',
          cancel: () => {
            // we force re-render the switch to update the state
            this.switchKey += 1
          },
          acceptText: this.$t('widgets.discard_changes') as string,
        })
      } else {
        closeEditMode()
      }
    },
  },
})
</script>
