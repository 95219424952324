import { Attribute } from '@/tt-entity-design/src/schema-types'
import { OPERATION_CENTERS } from './constants'
import { EntityIntentTypes } from '@/tt-widget-entity-flow'
import { Resources } from '@/tt-entity-design/src/types'
import { AppContextEventManager, AppContext } from '@/tt-app-context/types'
import { RegionId } from '@/tt-region-manager/types'
import { fetchRegionListFromOC } from '@/tt-operation-centers/helpers'
import { TreeviewItem } from '@/tt-widget-components/components/treeview/types'
import { StatusEnum, PresenceEnum } from './types'

export const getAttr = (attr: Attribute<typeof OPERATION_CENTERS>) => attr

export const operationCenterResourceUpdated = (
  eventManager: AppContextEventManager,
): void => {
  eventManager.dispatchEvent(EntityIntentTypes.RESOURCE_UPDATED, {
    resource: Resources.OPERATION_CENTERS,
  })
}

/**
 * Method used to fetch all regions that are in operation centers and updates the region filters
 */
export const updateRegionFilter = async (
  appContext: AppContext,
): Promise<void> => {
  const regionIds: RegionId[] = await fetchRegionListFromOC(appContext)
  appContext.contextManager.setRegionContextFilter(regionIds)
}

/**
 * Vuetify Treeview will lazy load children when the `children` property is an empty array.
 */
export const replaceChildrenByEmptyArray = (
  item: TreeviewItem,
): TreeviewItem => ({
  ...item,
  children: item.children?.length ? [] : undefined,
})

const DEFAULT_STATUS_COLOR = 'grey'

const STATUS_COLOR_MAP: Record<StatusEnum, string> = {
  ACTIVE: 'green',
  ARCHIVED: 'orange',
}

const PRESENT_COLOR_MAP: Record<PresenceEnum, string> = {
  PRESENT: 'green',
  ABSENT: 'red',
}

export const getStatusColor = (status: StatusEnum) =>
  STATUS_COLOR_MAP[status] || DEFAULT_STATUS_COLOR

export const getPresenceColor = (presence?: PresenceEnum) =>
  PRESENT_COLOR_MAP[presence] || DEFAULT_STATUS_COLOR
