import VueI18n from 'vue-i18n'

import {
  FormLabelTypes,
  FormTranslateFunctionInterface,
} from '@tracktik/tt-json-schema-form'

import defaultResourceMetaManager, {
  ResourceMetaManagerInterface,
} from '@/tt-widget-factory/services/resource-meta'
import _i18n from '@/plugins/i18n'

interface ResourceTranslatorDependencies {
  i18n?: VueI18n
  resourceMetaManager?: ResourceMetaManagerInterface
}

const translate = (
  key: string,
  fallback = '',
  { i18n = _i18n }: ResourceTranslatorDependencies = {},
): string => {
  const translated = i18n.t(key)

  return `${translated}` === `${key}` ? fallback : translated
}

export const createResourceActionKey = (
  resource: string,
  action: string,
  type: FormLabelTypes = FormLabelTypes.LABEL,
) => `res.${resource}.actions.${action}.labels.${type}`

export class ResourceTranslator {
  // Translate a resource attribute label, placeholder or enum value
  static translateAttribute(
    resource: string,
    attributeName: string,
    type: FormLabelTypes,
    enumValue?: string | null,
    {
      i18n = _i18n,
      resourceMetaManager = defaultResourceMetaManager,
    }: ResourceTranslatorDependencies = {},
  ): string | null {
    const key = resourceMetaManager.getAttributeLabelKey(
      resource,
      attributeName,
      type,
      enumValue,
    )
    if (!key) {
      return null
    }

    return translate(key, undefined, { i18n })
  }

  // Translate a resource action attribute label, placeholder or enum value
  static translateActionAttribute(
    resource: string,
    actionName: string,
    attributeName: string,
    type: FormLabelTypes,
    enumValue?: string | null,
    { i18n }: ResourceTranslatorDependencies = {},
  ): string {
    const scope = `res.${resource}.actions.${actionName}.attr.${attributeName}`
    let key: string

    // @todo: extensions have a more complex schema for locale
    // cause they can have deep object properties and they wrap into a "labels" object (.attr.prop1.attr.prop2.labels.label)
    // we might want to split the name (absoluteName) like : name.split('.').join('.attr.')
    if (enumValue) {
      key = `${scope}.list.${enumValue}.${type}`
      // we might want to unify enum with boolean with the "list" object (instead of a string currently)
      // or check if ".list.VALUE" is a string, or is an object, or we return empty string ""
    } else {
      key = `${scope}.${type}`
    }

    return translate(key, undefined, { i18n })
  }

  static translateActionLabel(
    resource: string,
    actionName: string,
    type = FormLabelTypes.LABEL,
    { i18n }: ResourceTranslatorDependencies = {},
  ): string {
    return translate(
      createResourceActionKey(resource, actionName, type),
      undefined,
      { i18n },
    )
  }

  static translateActionLabels(
    resource: string,
    actionName: string,
    dependencies: ResourceTranslatorDependencies = {},
  ): { label: string; description: string } {
    const translateType = (type: FormLabelTypes) =>
      ResourceTranslator.translateActionLabel(
        resource,
        actionName,
        type,
        dependencies,
      )

    return {
      label: translateType(FormLabelTypes.LABEL),
      description: translateType(FormLabelTypes.DESCRIPTION),
    }
  }

  // Return the JSON-FORM translator callback
  static getFormCallback(
    resource: string,
    actionName?: string,
    dependencies: ResourceTranslatorDependencies = {},
  ): FormTranslateFunctionInterface {
    return (
      text: string,
      name: string,
      type: FormLabelTypes,
      rootName: string,
      parentDefinitionName?: string,
      enumValue?: string,
    ) => {
      const translated = actionName
        ? ResourceTranslator.translateActionAttribute(
            resource,
            actionName,
            name,
            type,
            enumValue,
            dependencies,
          )
        : ResourceTranslator.translateAttribute(
            resource,
            name,
            type,
            enumValue,
            dependencies,
          )

      return translated || text
    }
  }
}

export const createI18nPluralResourceKey = (
  resource: string,
  { i18n = _i18n }: ResourceTranslatorDependencies = {},
) => {
  const isLabelPluralExists = i18n.te(`res.${resource}.labelPlural`)
  const label = `res.${resource}.label`
  const labelPlural = `res.${resource}.labelPlural`

  if (isLabelPluralExists) {
    return labelPlural
  } else {
    console.warn(`Pluralized ${resource} does not exist in locales`)

    return label
  }
}

export const createI18nResourceKey = (resource: string, attr: string) => {
  return `res.${resource}.attr.${attr}.label`
}

export const translateScope = (
  resource: string,
  scope: string,
  { i18n = _i18n }: ResourceTranslatorDependencies = {},
) => {
  return i18n.t(`res.${resource}.scopes.${scope}.label`)
}

export const isValidKey = (
  key: string,
  { i18n = _i18n }: ResourceTranslatorDependencies = {},
) => {
  const label = i18n.t(key)

  return typeof label === 'string' && label !== key
}

export const createI18nEnumKey = (
  resource: string,
  attr: string,
  value: string,
  dependencies: ResourceTranslatorDependencies = {},
) => {
  // @todo: remove support for old boolean labels when API is deployed (TTCLOUD-4145, FE-503)
  // new locales for "list" are now objects instead of simple strings
  const deprecatedKey = `res.${resource}.attr.${attr}.list.${value}`
  const newKey = `res.${resource}.attr.${attr}.list.${value}.label`

  return isValidKey(newKey, dependencies) ? newKey : deprecatedKey
}

export const createI18nEnumKeyDescription = (
  resource: string,
  attr: string,
  value: string,
  dependencies: ResourceTranslatorDependencies = {},
) => {
  const description = `res.${resource}.attr.${attr}.list.${value}.description`

  return isValidKey(description, dependencies) ? description : ''
}

export const translateBooleanAttributeValue = (
  resource: string,
  attr: string,
  value: boolean,
  { i18n = _i18n }: ResourceTranslatorDependencies = {},
) => {
  const key = createI18nEnumKey(resource, attr, String(value), { i18n })

  return i18n.t(key)
}

export const createResourceKey = (resourceName: string): string =>
  `res.${resourceName}.label`
