<template>
  <div>
    <tt-attr
      prepend-icon="mdi-calendar"
      name="leaveRequest.startDateTime"
      format="asDate"
    />
    <tt-attr
      v-show="!isSameDay"
      name="leaveRequest.endDateTime"
      format="asDate"
      :prefix="`${nonBreakingSpace}-${nonBreakingSpace}`"
    />
  </div>
</template>
<script lang="ts">
import Vue, { VueConstructor } from 'vue'
import { ItemHookProvider } from '@/tt-widget-entity-flow/types'
import { isSameDate } from '@/helpers/dates/isSameDate'

export default (Vue as VueConstructor<Vue & ItemHookProvider>).extend({
  name: 'LeaveHistoryDatesColumn',
  inject: ['getItemHook'],
  computed: {
    isSameDay(): boolean {
      const startDateTime = this.getItemHook().get('leaveRequest.startDateTime')
      const endDateTime = this.getItemHook().get('leaveRequest.endDateTime')

      return startDateTime && endDateTime && isSameDate(startDateTime, endDateTime)
    },
    nonBreakingSpace(): string {
      // hexcode for &nbsp;
      return '\xa0'
    },
  },
})
</script>
