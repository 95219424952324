<template>
  <span>
    <v-subheader
      :class="{ 'collapsible-header--disabled': disabled }"
      dense
      class="pl-0"
      :style="{
        cursor: 'pointer',
        height: `${headerHeight}px`,
      }"
      @click.stop="headerClick"
    >
      <v-icon v-text="closed ? 'mdi-menu-right' : 'mdi-menu-down'" />
      <slot name="title">
        <span class="pl-4" v-text="$t(title)" />
      </slot>

      <v-spacer />
      <slot name="right"> </slot>
    </v-subheader>
    <span v-show="!closed">
      <slot :opened="!closed" />
    </span>
  </span>
</template>
<script lang="ts">
import Vue from 'vue'

export default Vue.extend({
  name: 'CollapsibleHeader',
  props: {
    title: {
      type: String,
      default: '',
    },
    opened: {
      type: Boolean,
      default: false,
    },
    headerHeight: {
      type: Number,
      default: 48, // Vuetify's size, same as it is nowdays
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      closed: true,
    }
  },
  created() {
    this.closed = !this.opened
  },
  methods: {
    headerClick() {
      if (this.disabled) return

      this.closed = !this.closed
    },
  },
})
</script>

<style scoped>
.collapsible-header--disabled {
  cursor: default !important;
  opacity: 0.5;
}
</style>
