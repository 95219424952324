<template>
  <v-tooltip top>
    <template #activator="{ on }">
      <v-icon
        color="warning"
        class="mx-2"
        small
        v-on="on"
        @mouseenter="updateNow()"
        v-text="`mdi-database-alert`"
      />
    </template>

    <span v-text="label" />
  </v-tooltip>
</template>

<script lang="ts">
import { getSecondsHumanized } from '@/helpers/formats/dates/utils'
import Vue from 'vue'

export default Vue.extend({
  name: 'WidgetCachedResponseIcon',
  props: {
    cacheTimestampSeconds: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      nowTimestampSeconds: 0,
    }
  },
  computed: {
    label(): string {
      const elapsedSeconds =
        this.cacheTimestampSeconds - this.nowTimestampSeconds

      const text = this.$t('components.widget_context_toolbar.cache_info')

      return `${text} ${getSecondsHumanized(elapsedSeconds, {
        suffix: true,
      })}`
    },
  },
  created() {
    this.updateNow()
  },
  methods: {
    updateNow() {
      this.nowTimestampSeconds = Math.floor(Date.now() / 1000)
    },
  },
})
</script>
