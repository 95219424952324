// export const MobileScheduleGroupsPlugin: PluginFunction<void> = (Vue) => {
// }

import { PluginFunction } from 'vue'

const ScheduleGroupCreateWizard = () =>
  import(
    /* webpackChunkName: "ScheduleGroupCreateWizard" */
    './components/ScheduleGroupCreateWizard.vue'
  )

const ScheduleGroupsView = () =>
  import(
    /* webpackChunkName: "ScheduleGroupsView" */
    './views/ScheduleGroupsView.vue'
  )

const PeriodicListView = () => import('./views/PeriodicListView.vue')

const PeriodicList = () => import('./components/PeriodicList.vue')

const ScheduleGroupList = () =>
  import(
    /* webpackChunkName: "ScheduleGroupsList" */
    './components/ScheduleGroupList.vue'
  )

const ScheduleOccurrenceDayAttr = () =>
  import('./components/ScheduleOccurrenceDayAttr.vue')

const ScheduleOccurrenceTimeRangeAttr = () =>
  import('./components/ScheduleOccurrenceTimeRangeAttr.vue')

const PeriodicRemainingField = () =>
  import('./components/PeriodicRemainingField.vue')

const PeriodicListScheduleComputedName = () =>
  import('./components/PeriodicListScheduleComputedName.vue')

const PeriodicListSiteTaskComputedName = () =>
  import('./components/PeriodicListSiteTaskComputedName.vue')

export { ScheduleGroupsView }

export const MobileScheduleGroupsPlugin: PluginFunction<void> = (Vue) => {
  Vue.component('ScheduleGroupCreateWizard', ScheduleGroupCreateWizard)
  Vue.component('ScheduleGroupsView', ScheduleGroupsView)
  Vue.component('ScheduleGroupList', ScheduleGroupList)
  Vue.component('PeriodicList', PeriodicList)
  Vue.component('ScheduleOccurrenceDayAttr', ScheduleOccurrenceDayAttr)
  Vue.component(
    'ScheduleOccurrenceTimeRangeAttr',
    ScheduleOccurrenceTimeRangeAttr,
  )
  Vue.component('PeriodicListView', PeriodicListView)
  Vue.component('PeriodicRemainingField', PeriodicRemainingField)
  Vue.component(
    'PeriodicListScheduleComputedName',
    PeriodicListScheduleComputedName,
  )
  Vue.component(
    'PeriodicListSiteTaskComputedName',
    PeriodicListSiteTaskComputedName,
  )
}
