/**
 * Data set response
 */
import { DataSetRow, DataSetValue } from '@/tt-widget-components/types'

export interface DataSetResponseMeta {
  count?: number

  [k: string]: any
}

export default class DataSetResponse {
  error: string = null
  meta: DataSetResponseMeta
  rows: DataSetRow[] = []
  cacheTimestamp?: number = 0

  addRow(row: DataSetRow) {
    this.rows.push(row)
  }

  /**
   *
   * @param key
   * @param asc
   */
  asSortedSeries(key: string, asc = true): DataSetRow[] {
    return this.asSeries.sort((itemA, itemB) => {
      const reverser = asc ? 1 : -1

      return itemA[key] + 0 >= itemB[key] + 0 ? -1 * reverser : 1 * reverser
    })
  }

  get asSeries(): DataSetRow[] {
    return this.rows.map((item) => {
      // delete item.___query
      return item
    })
  }

  /**
   * Get the value from
   * @param rowIndex
   * @param key
   */
  getValue(rowIndex: number, key: string): DataSetValue {
    if (this.rows[rowIndex] && this.rows[rowIndex][key]) {
      return this.rows[rowIndex][key]
    }

    return null
  }
}
