<template>
  <div class="d-flex flex-wrap align-center ma-4">
    <TChip v-if="hasDefaultPolicies" color="green" class="ma-1">
      <v-icon color="green" v-text="'mdi-check-all'" />
      {{
        $t(
          'tt-entity-design.operation-center-escalation-policies.chip.default-policies-label',
        )
      }}
    </TChip>
    <template v-for="(type, index) in ticketTypes">
      <div :key="index" class="ma-1">
        <TicketTypesChip
          :ticket-type="type"
          :resource="resource"
          attributes-name="ticketTypes"
        />
      </div>
    </template>
  </div>
</template>

<script lang="ts">
import Vue, { VueConstructor } from 'vue'

import { ItemHookProvider } from '@/tt-widget-entity-flow/types'
import TicketTypesChip from '@/tt-entity-design/src/components/system-exception-tickets/cta-types/TicketTypesChip.vue'

export default (Vue as VueConstructor<Vue & ItemHookProvider>).extend({
  name: 'OperationCenterEscalationPoliciesTile',
  components: {
    TicketTypesChip,
  },
  inject: ['getItemHook'],
  computed: {
    ticketTypes(): string[] {
      return this.getItemHook().getRawValue('ticketTypes')
    },
    hasDefaultPolicies(): boolean {
      return this.ticketTypes === null
    },
    resource(): string {
      return this.getItemHook().getResourceName()
    },
  },
  created() {
    this.getItemHook().addAttribute('ticketTypes')
  },
})
</script>
