import i18n from '@/plugins/i18n'
import {
  SchedulerEventHandler,
  wrapSchedulerEventTemplate,
} from '@/tt-widget-components/widgets/Scheduler/scheduler-event-handler'

import dateFormatter from '@/helpers/formats/dates'
import { createColorManager } from '@/tt-widget-components/widgets/Scheduler/helper'
import { LeaveRequestsStatus } from './types'

const requiredFields: SchedulerEventHandler['requiredFields'] = () => {
  return [
    'leaveType.name',
    'startDateTime',
    'endDateTime',
    'status',
    'employee.id',
    'employee.name',
    'employee.avatar',
    'employee.jobTitle',
    'extensions.affectedShifts.total',
  ]
}

const colors = [
  '#f17300',
  '#0E882A',
  '#e98799',
  '#7e84ff',
  '#0053e0',
  '#cb2891',
  '#486aa2',
  '#393F51',
  '#72c7ed',
  '#2c988e',
  '#7d4ed3',
]
const colorManager = createColorManager('leaveType.name', colors)

const transform: SchedulerEventHandler['transform'] = ({
  itemHook,
  userPreferences,
}) => {
  const eventColor = colorManager.getEventColor(itemHook.entityReference.entity)
  const hasAffectedShifts = !!itemHook.get('extensions.affectedShifts.total')

  const getTime = (): string => {
    const startTime = dateFormatter.asTime(
      itemHook.getRawValue('startDateTime'),
      userPreferences,
    )
    const endTime = dateFormatter.asTime(
      itemHook.getRawValue('endDateTime'),
      userPreferences,
    )

    const text = `${startTime} - ${endTime}`

    return text
  }

  const LeaveRequestsStatusIconOptions: Record<LeaveRequestsStatus, string> = {
    [LeaveRequestsStatus.APPROVED]: 'mdi-check-circle-outline',
    [LeaveRequestsStatus.PENDING]: 'mdi-clock-outline',
    [LeaveRequestsStatus.PENDING_CANCELLATION]: 'mdi-account-cancel-outline',
    [LeaveRequestsStatus.CANCELLED]: 'mdi-cancel',
    [LeaveRequestsStatus.DECLINED]: 'mdi-close-circle-outline',
  }

  const rawStatus = itemHook.getRawValue('status')

  const getStatus = (): { label: string; icon: string } => ({
    label: i18n.t(`res.leave-requests.attr.status.list.${rawStatus}.label`),
    icon: LeaveRequestsStatusIconOptions[rawStatus],
  })

  return {
    id: itemHook.getEntityId(),
    time: getTime(),
    groupResourceId: itemHook.get('employee.id'),
    groupResourceTitle: itemHook.get('employee.name'),
    groupResourceImageUrl: itemHook.get('employee.avatar'),
    groupResourceDescription: itemHook.get('employee.jobTitle'),
    groupResourceAttribute: 'employee',
    startDateTime: itemHook.getRawValue('startDateTime'),
    endDateTime: itemHook.getRawValue('endDateTime'),
    subject: itemHook.get('leaveType.name'),
    affectedShifts: {
      display: hasAffectedShifts ? 'block' : 'none',
      text: i18n.t('res.leave-requests.extensions.affectedShifts.labels.label'),
    },
    status: getStatus(),
    eventColor: eventColor,
  }
}

const template: SchedulerEventHandler['template'] =
  wrapSchedulerEventTemplate(/* html */ `
    <div style="background-color: \${eventColor}; padding: 0 4px" >
      <div class="d-flex justify-start">
        <div style="font-size: 13px; font-weight: 500; text-overflow: ellipsis">
          \${subject}
        </div>
        <i class="v-icon mdi \${status.icon}" style="font-size: 15px; color: white; margin-left: 3px" title="\${status.label}"></i>
      </div>
      <div class="d-flex justify-space-between">
        <div class="e-time" style="font-weight: 500">\${time}</div>
        <div style="display: \${affectedShifts.display}">
          <i class="v-icon mdi mdi-alert-outline" style="font-size: 15px; color: white; line-height: 0" title="\${affectedShifts.text}"></i>
        </div>
      </div>
    </div>
    `)

export const leaveRequestsSchedulerEventHandler: SchedulerEventHandler = {
  requiredFields,
  template,
  transform,
}
