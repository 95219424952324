<template>
  <div class="flex--column policies-relation-list--wrapper">
    <TLoadingBar v-if="loading" />
    <div style="cursor: pointer" @click="openDefaultPoliciesForm">
      <TChip
        v-if="!hasDefaultPolicies && !isLoading"
        class="ma-5"
        :color="'red'"
      >
        {{ $tc('escalation-policies.no-default-policies-chip') }}
      </TChip>
    </div>

    <v-divider class="mx-6" />

    <WidgetFactory :widget="widget" @update:hook="hook = $event" />
  </div>
</template>

<script lang="ts">
import Vue, { VueConstructor } from 'vue'

import { updateDOM } from '@/helpers/dom'
import { ItemHookProvider } from '@/tt-widget-entity-flow/types'
import { ListWidgetModel } from '@/tt-widget-components'
import { WidgetName } from '@/tt-widget-components/lib/names'
import { Resources } from '../../types'
import { getAttr } from '@/tt-entity-design/src/components/operation-center-escalation-policies/utils'
import { SortDirectionType } from 'tracktik-sdk/lib/common/entity-collection'
import { FilterOperatorType } from '@/tt-widget-factory'
import { EscalationPolicies } from '@/tt-entity-design/src/components/operation-center-escalation-policies/types'
import ListWidgetHook from '@/tt-widget-components/widgets/List/ListWidgetHook'
import {
  EntityCreateIntentInterface,
  EntityIntentTypes,
} from '@/tt-widget-entity-flow'
import { getCreateFormState } from '@/tt-entity-design/src/tools/helpers/form-state-generator'
import { LayoutWindowEvent } from '@/tt-app-layout'

export default (Vue as VueConstructor<Vue & ItemHookProvider>).extend({
  name: 'PoliciesRelationListPreviewWrapper',
  inject: ['getItemHook'],
  data() {
    return {
      hook: null as ListWidgetHook | null,
      loading: false,
    }
  },
  computed: {
    entityId(): number {
      return this.getItemHook().getEntityId()
    },
    defaultPolicies(): EscalationPolicies {
      return (
        this.hook?.entities.find((policy) => policy.ticketTypes === null) ||
        ({} as EscalationPolicies)
      )
    },
    hasDefaultPolicies(): boolean {
      return Object.keys(this.defaultPolicies).length > 0
    },
    isLoading(): boolean {
      return this.hook?.loading
    },
    operationCenterName(): string {
      return this.getItemHook().getRawValue('name')
    },
    widget(): ListWidgetModel {
      return {
        title: '',
        is: WidgetName.LIST_WIDGET,
        query: {
          resource: Resources.OPERATION_CENTER_ESCALATION_POLICIES,
          sort: [
            {
              attribute: getAttr('ticketTypes'),
              direction: SortDirectionType.ASC,
            },
            {
              attribute: getAttr('createdOn'),
              direction: SortDirectionType.DESC,
            },
          ],
          filters: [
            {
              attribute: getAttr('operationCenter'),
              operator: FilterOperatorType.EQUAL,
              value: this.entityId,
            },
          ],
        },
        toolbar: {
          show: false,
        },
      }
    },
  },
  methods: {
    async openDefaultPoliciesForm(): Promise<void> {
      this.loading = true

      await updateDOM()

      const formConfig: EntityCreateIntentInterface = {
        resourceName: Resources.OPERATION_CENTER_ESCALATION_POLICIES,
        onSuccess: () => {
          this.$eventManager.dispatchEvent(EntityIntentTypes.RESOURCE_UPDATED, {
            resource: Resources.OPERATION_CENTERS,
          })
        },
        initialModel: {
          operationCenter: this.entityId,
          //To remove after https://tracktik.atlassian.net/browse/API-3524
          name: this.$t('escalation-policies.form.name-field', {
            name: this.operationCenterName,
          }),
          escalationRules: [
            { operationCentersToEscalateTo: [], timeToEscalate: null },
          ],
          hasToDisplayTaskTypeField: false,
        },
      }

      const payload = await getCreateFormState(this.$appContext, formConfig)

      this.$eventManager.dispatchEvent(LayoutWindowEvent.SIDE_SHEET_ADD_PAGE, {
        is: 'GenericFormPage',

        props: {
          payload: {
            ...payload,
            title: this.$t('escalation-policies.form-create-default-title', {
              name: this.operationCenterName,
            }),
          },
        },
      })

      this.loading = false
    },
  },
})
</script>

<style scoped>
.policies-relation-list--wrapper {
  position: relative;
}
.policies-relation-list--wrapper >>> .widget-no-data {
  opacity: 0;
}
</style>
