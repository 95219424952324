<template>
  <json-field
    v-if="hasToDisplayTaskTypeField"
    name="ticketTypes"
    :disabled-values="disabledTicketTypesValues"
    as="TicketTypesField"
  />
</template>

<script lang="ts">
import Vue, { VueConstructor } from 'vue'
import { FormHookProvider } from '@/tt-widget-components'
import { Resources } from '@/tt-entity-design/src/types'
import { FilterOperatorType } from '@/tt-widget-factory'
import { EntityCollectionResponse } from 'tracktik-sdk/lib/common/entity-collection'
import { TicketType, EscalationPolicies } from '../types'

type VueWithInjections = VueConstructor<Vue & FormHookProvider>

export default (Vue as VueWithInjections).extend({
  name: 'TicketTypesSection',
  inject: ['formHook'],
  props: {
    hasToDisplayTaskTypeField: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      loading: false,
      disabledTicketTypesValues: [] as TicketType[],
    }
  },
  computed: {
    ticketTypes(): TicketType[] | null {
      return this.formHook().getPathValue('ticketTypes')
    },
  },
  async created() {
    if (this.hasToDisplayTaskTypeField) {
      await this.fetchEscalationPoliciesByOperationCenter()
    }
  },
  methods: {
    async fetchEscalationPoliciesByOperationCenter(): Promise<void> {
      this.loading = true
      await this.$appContext.widgetServices.resourceDataManager
        .getCollection({
          resource: Resources.OPERATION_CENTER_ESCALATION_POLICIES,
          filters: [
            {
              attribute: 'operationCenter',
              operator: FilterOperatorType.IN,
              value: this.formHook().getPathValue('operationCenter'),
            },
          ],
        })
        .then((response: EntityCollectionResponse<EscalationPolicies>) => {
          const ticketTypes: Record<TicketType, EscalationPolicies[]>[] =
            response.items.reduce((acc, item) => {
              if (item.ticketTypes && Array.isArray(item.ticketTypes)) {
                item.ticketTypes.forEach((ticketType) => {
                  acc[ticketType] = item
                })
              }

              return acc
            }, [] as Record<TicketType, EscalationPolicies[]>[])

          this.disabledTicketTypesValues = Object.keys(ticketTypes).filter(
            (ticketType) =>
              !this.ticketTypes?.includes(ticketType as TicketType),
          ) as TicketType[]
        })
        .finally(() => {
          this.loading = false
        })
    },
  },
})
</script>
