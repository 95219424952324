import { ActionProperty } from '@/tt-entity-design/src/schema-types'
import { DISPATCH_TASKS, ADD_NOTE } from './constants'

export type AddNoteProperty = ActionProperty<
  typeof DISPATCH_TASKS,
  typeof ADD_NOTE
>

export enum SentFromEnum {
  OPERATOR = 'OPERATOR',
}
