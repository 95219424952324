import { Attribute } from '@/tt-widget-factory/services/resource-meta/types'
import { AttributeFilterOptions, Filter } from '@/tt-widget-components'
import { Component } from 'vue'

export interface AttributeFilterItem {
  attributeName: string
}

/**
 * The filter settings used for the filter elements
 */

export type Entity<
  Props extends Record<string, any> = Record<string, unknown>,
> = { id: number } & Props

export interface FilterSetting {
  component?: string
  icon?: string
  attributeName: string
  attributeMeta: Attribute
  filter?: Filter
  options?: AttributeFilterOptions
  label?: string
}

export interface FilterValues {
  whereQL?: string
  includeInactive?: boolean
  scope?: string[]
  search?: string | null
  filters?: { [k: string]: Filter }
}

export enum EntityFilterEvent {
  TOOLBAR_MENU_ACTIVE = 'filter.toolbar.menu-active',
}

export type EntityFilterEventPayload = {
  [EntityFilterEvent.TOOLBAR_MENU_ACTIVE]: boolean
}

export enum FilterDateTimeTabs {
  PRESETS = 'presets',
  RANGE = 'range',
}

export type FilterDateTimeTab = {
  key: FilterDateTimeTabs
  label: string
}

export interface CustomFilterConfig {
  attributeName: string
  is: {
    component: Component
  }
}

export type CustomFilterMapType = Record<string, CustomFilterConfig>
