<template>
  <div>
    <CollapsibleHeader :opened="opened">
      <template #title>
        <v-tooltip top :disabled="!isLabelOverflowing">
          <span v-text="label" />
          <template #activator="{ on }">
            <span class="pl-4 hide-overflow" v-on="on">
              <span
                ref="label"
                :class="{ 'has-value': hasValue }"
                v-text="label"
              />
              <v-badge v-if="!!value" color="green" dot inline />
            </span>
          </template>
        </v-tooltip>
      </template>

      <template #right>
        <slot name="right">
          <v-btn v-if="!!value" icon x-small @click.stop="clear()">
            <v-icon small v-text="`close`" />
          </v-btn>
        </slot>
      </template>

      <v-card flat min-height="90" min-width="250" class="level0 pa-0 ma-0">
        <component
          :is="component"
          v-bind="handler"
          v-model="model"
          @input="apply"
        />
      </v-card>
    </CollapsibleHeader>
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue'
import EnumRadioFilterHandler from './EnumRadioFilterHandler.vue'
import CollapsibleHeader from '@/tt-widget-entity-flow/components/CollapsibleHeader.vue'
import { isElementOverflowing } from '@/helpers/dom'
import { isEmpty } from '@/helpers/isEmpty'
import { FilterHandler } from '@/tt-widget-components'

export default Vue.extend({
  name: 'FilterHandlerCollapsibleSection',
  components: {
    EnumRadioFilterHandler,
    CollapsibleHeader,
  },
  props: {
    handler: {
      type: Object as PropType<FilterHandler>,
      required: true,
    },
    resource: {
      type: String,
      required: true,
    },
    value: {
      type: Object as PropType<any>,
      default: null,
    },
    opened: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    isLabelOverflowing: false,
    model: null,
  }),
  computed: {
    component(): string {
      return this.handler.component
    },
    label(): string {
      if (this.model) {
        return this.$t(this.model.label) as string
      }

      const text = this.$appContext.widgetServices.resourceMetaManager
        .getAttributePathLabelKeys(this.resource, this.handler.attribute)
        .map((key: string) => {
          return this.$tc(key)
        })
        .join(' · ')

      return text
    },
    hasValue(): boolean {
      return !isEmpty(this.value)
    },
  },
  watch: {
    value: {
      handler(newValue) {
        if (newValue == null) {
          this.clear()
        } else if (this.handler.values) {
          const handlerValue = this.handler.values.find(
            (v) => v.value === newValue.value,
          )
          if (handlerValue) {
            this.model = handlerValue
          } else {
            this.clear()
          }
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.checkLabelOverflow()
  },
  updated() {
    this.checkLabelOverflow()
  },
  methods: {
    async checkLabelOverflow() {
      await Vue.nextTick()
      const labelElement = this.$refs['label'] as Element
      this.isLabelOverflowing = isElementOverflowing(labelElement)
    },
    clear() {
      this.model = null
    },
    apply() {
      this.$emit('input', this.handler.handleFilterChange(this.model))
    },
  },
})
</script>
<style scoped>
.hide-overflow {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.has-value {
  color: var(--v-ttPrimary-base);
  font-weight: bold;
}
</style>
