<template>
  <v-autocomplete
    v-model="selectedTaskType"
    dense
    outlined
    item-text="name"
    item-value="name"
    :value="value"
    :label="label"
    :items="structuredTypes"
  >
    <template #selection="{ item }">
      <div class="ma-1">
        <TicketTypesChip
          :ticket-type="item.name"
          :resource="resource"
          attributes-name="type"
        />
      </div>
    </template>
    <template #item="{ item, on }">
      <v-list-item link :disabled="item.disabled" @change="on.click">
        <v-list-item-content>
          <div class="d-flex">
            <TicketTypesChip
              :ticket-type="item.name"
              :resource="resource"
              attributes-name="type"
            />
          </div>
        </v-list-item-content>
      </v-list-item>
    </template>
  </v-autocomplete>
</template>

<script lang="ts">
import Vue, { PropType, VueConstructor } from 'vue'

import { FormHook } from '@tracktik/tt-json-schema-form'
import { Resources } from '@/tt-entity-design/src/types'
import { createI18nEnumKey } from '@/tt-widget-entity-flow/ResourceTranslator'
import {
  groupCtaTypes,
  CTAType,
  getGroupTypeView,
  CtaGroupsEnum,
  GroupTypeView,
} from '@/tt-entity-design/src/components/system-exception-tickets/cta-types/index'
import TicketTypesChip from '@/tt-entity-design/src/components/system-exception-tickets/cta-types/TicketTypesChip.vue'

export default (
  Vue as VueConstructor<Vue & { formHook: () => FormHook }>
).extend({
  name: 'SystemExceptionTicketInstructionsTicketTypeField',
  components: {
    TicketTypesChip,
  },
  inject: ['formHook'],
  props: {
    label: { type: String, required: true },
    value: {
      type: String as PropType<CTAType>,
      default: null,
    },
    items: {
      type: Array as PropType<{ text: string; value: CTAType }[]>,
      required: true,
    },
  },
  computed: {
    selectedTaskType: {
      get(): CTAType | null {
        return this.value
      },
      set(newTaskType: string) {
        this.$emit('input', newTaskType)
      },
    },
    resource(): string {
      return Resources.SYSTEM_EXCEPTION_TICKETS
    },
    structuredTypes(): Array<{
      header?: string
      name?: string
      group?: string
      divider?: boolean
    }> {
      const type = this.items.map((item) => item.value)
      const groupedTypes = groupCtaTypes(type)

      return Object.entries(groupedTypes).reduce(
        (acc, [group, types], index, array) =>
          acc.concat(
            [
              {
                header: this.$t(
                  getGroupTypeView(group as CtaGroupsEnum).i18nKey,
                ) as string,
              },
            ],
            types.map((type) => ({
              name: type,
              group,
            })),
            index < array.length - 1 ? [{ divider: true }] : [],
          ),
        [] as Array<{
          header?: string
          name?: string
          group?: string
          divider?: boolean
        }>,
      )
    },
  },
  methods: {
    getGroupView(group: CtaGroupsEnum): GroupTypeView {
      return getGroupTypeView(group)
    },
    localizeType(type: CTAType) {
      return this.$t(
        createI18nEnumKey(Resources.SYSTEM_EXCEPTION_TICKETS, 'type', type),
      )
    },
  },
})
</script>
