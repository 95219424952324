<template>
  <v-tooltip top :disabled="!hasDescription" :open-delay="tooltipOpenDelay">
    <template #activator="{ on }">
      <div class="text-truncate" v-on="on">
        <TChip
          :color="view.color"
          :close="close"
          @click:close="$emit('click:close')"
          @click="$emit('click')"
        >
          <v-icon v-if="showIcon" :color="view.color" v-text="view.icon" />
          <span class="text-truncate pl-1" v-text="localizeType" />
        </TChip>
      </div>
    </template>

    <span v-text="localizeDescription" />
  </v-tooltip>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue'
import { getTypeView } from './index'
import { GroupTypeView, CTAType } from './types'
import {
  createI18nEnumKey,
  createI18nEnumKeyDescription,
} from '@/tt-widget-entity-flow/ResourceTranslator'
import { Resource } from '@/tt-entity-design/src/schema-types'
import { TranslateResult } from 'vue-i18n/types'
import { TOOLTIP_OPEN_DELAY } from '@/helpers/constants'

export default Vue.extend({
  name: 'TicketTypesChip',
  props: {
    ticketType: {
      type: String as PropType<CTAType>,
      required: true,
    },
    resource: {
      type: String as PropType<Resource>,
      required: true,
    },
    attributesName: {
      type: String,
      required: true,
    },
    showIcon: {
      type: Boolean,
      default: true,
    },
    close: {
      type: Boolean,
      default: false,
    },
    tooltipOpenDelay: {
      type: Number,
      default: TOOLTIP_OPEN_DELAY,
    },
  },
  computed: {
    view(): GroupTypeView {
      return getTypeView(this.ticketType)
    },
    localizeType(): TranslateResult {
      return this.$t(
        createI18nEnumKey(this.resource, this.attributesName, this.ticketType),
      )
    },
    localizeDescription(): TranslateResult {
      return this.$t(
        createI18nEnumKeyDescription(
          this.resource,
          this.attributesName,
          this.ticketType,
        ),
      )
    },
    hasDescription(): boolean {
      return !!this.localizeDescription
    },
  },
})
</script>
