import { WidgetSchema } from '@/tt-widget-factory'

const schedulerTimelineWidgetSchema: WidgetSchema = {
  type: 'object',
  title: 'SchedulerTimeline',
  required: [
    'title',
    'is',
    'query',
    'attributeMap',
    'groupResourceAttributeMap',
  ],
  properties: {
    is: {
      enum: ['SchedulerTimelineWidget'],
    },
    title: {
      title: 'Title',
      type: 'string',
    },
    uid: {
      title: 'UID',
      type: 'string',
    },
    description: {
      title: 'Description',
      type: 'string',
    },
    query: {
      $ref: '#/definitions/CollectionQuery',
    },
    attributeMap: {
      $ref: '#/definitions/SchedulerAttributeMap',
    },
    groupResourceAttributeMap: {
      $ref: '#/definitions/SchedulerTimelineGroupResourceAttributeMap',
    },
    toolbar: {
      $ref: '#/definitions/DefaultToolbar',
    },
    allowActions: {
      title: 'Allow Actions',
      type: 'boolean',
    },
    allowCreation: {
      title: 'Allow Creation',
      type: 'boolean',
    },
    contextFilters: {
      $ref: '#/definitions/WidgetContextFilters',
    },
  },
  definitions: {
    SchedulerAttributeMap: {
      title: 'Attribute Mapping',
      type: 'object',
      required: ['startAttribute', 'endAttribute'],
      properties: {
        titleAttribute: {
          title: 'Title Attribute',
          $ref: '#/definitions/ResourceAttributeName',
        },
        startAttribute: {
          title: 'Start Time Attribute',
          $ref: '#/definitions/ResourceAttributeName',
        },
        endAttribute: {
          title: 'End Time Attribute',
          $ref: '#/definitions/ResourceAttributeName',
        },
      },
    },
    SchedulerTimelineGroupResourceAttributeMap: {
      title: 'Group Resource Attribute Mapping',
      type: 'object',
      required: ['resourceNameAttribute', 'idAttribute', 'titleAttribute'],
      properties: {
        resourceNameAttribute: {
          title: 'Resource Name Attribute',
          $ref: '#/definitions/ResourceAttributeName',
        },
        idAttribute: {
          title: 'ID Attribute',
          $ref: '#/definitions/ResourceAttributeName',
        },
        titleAttribute: {
          title: 'Title Attribute',
          $ref: '#/definitions/ResourceAttributeName',
        },
        descriptionAttribute: {
          title: 'Description Attribute',
          $ref: '#/definitions/ResourceAttributeName',
        },
        imageUrlAttribute: {
          title: 'Image URL Attribute',
          $ref: '#/definitions/ResourceAttributeName',
        },
      },
    },
  },
}

export default schedulerTimelineWidgetSchema
