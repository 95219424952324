import { ValueOf } from '@/helpers/types/ValueOf'

/**
 * string in the format 'YYYY-MM-DD'
 */
export type DateString = string

export const SchedulerTimelineView = {
  TIMELINE_DAY: 'TimelineDay',
  TIMELINE_WEEK: 'TimelineWeek',
  TIMELINE_MONTH: 'TimelineMonth',
} as const

export type SchedulerTimelineView = ValueOf<typeof SchedulerTimelineView>

export type SchedulerTimelineWidgetState = {
  currentView: SchedulerTimelineView
  selectedDate: DateString
}
