<template>
  <div class="fill-height d-flex flex-column">
    <widget-container class="d-flex flex-column">
      <MetadataLoadingGuard>
        <WidgetFactory :widget="hcmLeaveHistoryWidgetModel" />
      </MetadataLoadingGuard>
    </widget-container>
  </div>
</template>

<script lang="ts">
import Vue from 'vue'
import {
  AttributeFilter,
  DataTableWidgetModel,
  Filter,
  Sort,
} from '@/tt-widget-components'
import MetadataLoadingGuard from '../../../../components/MetadataLoadingGuard.vue'
import HumanCapitalManagementEmployeeLeaveHistoryColumns from './HumanCapitalManagementEmployeeLeaveHistoryColumns'
import { Resources } from '@/tt-entity-design/src/types'

export default Vue.extend({
  name: 'HumanCapitalManagementEmployeeLeaveHistory',
  components: {
    MetadataLoadingGuard,
  },
  props: {
    employeeId: {
      type: Number,
      required: true,
    },
  },
  computed: {
    hcmLeaveHistoryWidgetModel(): DataTableWidgetModel {
      const employeeFilter: Filter = {
        attribute: 'employee',
        operator: 'EQUAL',
        value: this.employeeId,
      }
      const sortByMostRecent: Sort = {
        attribute: 'createdOn',
        direction: 'DESC',
      }

      const toolbarStartDateTimeFilter: AttributeFilter = {
        attributeName: 'startDateTime',
      }
      const toolbarStatusFilter: AttributeFilter = {
        attributeName: 'status',
      }

      return {
        is: 'DataTableWidget',
        title: '',
        query: {
          resource: Resources.LEAVE_REQUESTS,
          filters: [employeeFilter],
          sort: [sortByMostRecent],
        },
        toolbar: {
          displayCounts: true,
          filterOptions: {
            filters: [toolbarStartDateTimeFilter, toolbarStatusFilter],
            allowSearch: true,
          },
        },
        component: {
          columns: HumanCapitalManagementEmployeeLeaveHistoryColumns,
        },
      }
    },
  },
})
</script>
