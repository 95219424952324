<template>
  <div class="role--tile pa-3">
    <div class=" ">
      <tt-attr
        prepend-icon="mdi-shield-account"
        prepend-icon-color="purple"
        name="role.name"
      />

      <tt-if-attr name="role.region" class="pl-3" />

      <div class="flex--row py-2">
        <TChip color="ttPrimary">
          <tt-attr name="role.portal" />
        </TChip>

        <tt-if-attr name="role.status" class="pl-3" value="ARCHIVED">
          <TChip color="muted">
            <tt-attr name="role.status" />
          </TChip>
        </tt-if-attr>
      </div>
    </div>

    <div class="delete--button w-100 h-100">
      <div class="flex--column align-end justify-center pr-6">
        <v-tooltip left :disabled="canDelete()">
          <template #activator="{ on }">
            <!-- to make the tooltip work on disabled button -->
            <div v-on="on">
              <v-fab-transition>
                <v-btn
                  v-show="showDeleteButton()"
                  color="error"
                  icon
                  :loading="isLoading"
                  :disabled="!canDelete()"
                  @click.stop="runDelete"
                >
                  <v-icon v-text="'mdi-delete-circle'" />
                </v-btn>
              </v-fab-transition>
            </div>
          </template>

          <span v-text="$t('common.remove.btn')" />
        </v-tooltip>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { ItemHookProvider } from '@/tt-widget-entity-flow/types'
import Vue, { VueConstructor, PropType } from 'vue'

type DeleteCallback = (id: number) => void

export default (Vue as VueConstructor<Vue & ItemHookProvider>).extend({
  name: 'ManagedPermissionPoliciesRoleRelationListTile',
  inject: ['getItemHook'],
  props: {
    showDeleteButton: {
      type: Function as PropType<() => boolean>,
      default: () => false,
    },
    canDelete: {
      type: Function as PropType<() => boolean>,
      default: () => false,
    },
    onDelete: {
      type: Function as PropType<DeleteCallback>,
      default: () =>
        console.warn(
          'No "delete" callback provided.',
        ) as unknown as DeleteCallback,
    },
  },
  data: () => ({
    isLoading: false,
  }),
  computed: {
    roleId(): number {
      return this.getItemHook().getRawValue('role.id')
    },
  },
  created() {
    this.getItemHook().addAttribute('role.id')
  },
  methods: {
    async runDelete() {
      this.isLoading = true
      await this.onDelete(this.roleId)
      this.isLoading = false
    },
  },
})
</script>

<style scoped>
.delete--button {
  position: absolute;
  top: 0;
  left: 0;
}

.role--tile {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
}
</style>
