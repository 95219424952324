import { TQLQueryOptions } from '@/tt-widget-components/schemas-types'
import { MimeType } from '@tracktik/tt-helpers/lib/types'
import { TQLExportFormat } from './constants'

export type ApiTqlExportParams = {
  export?: keyof typeof MimeType

  /**
   * /!\ Named `excludeHeaders` in the TQL Wdiget schema /!\
   */
  excludeHeader?: boolean
  
  encloseAll?: boolean
  
  enclosure?: string
  
  delimiter?: string
  
  includeCoverPage?: boolean
  
  exportTitle?: string
}

export const getTqlExportParams = (
  format: keyof typeof MimeType,
  options?: TQLQueryOptions['exportOptions'],
): ApiTqlExportParams | undefined => {
  const typeHeadersHandlerMap = {
    [TQLExportFormat.CSV]: () => {
      const { excludeHeaders } = options ?? {}
      const { encloseAll, enclosure, delimiter } = options.csvOptions ?? {}

      return {
        // /!\ The API expects the key to be `excludeHeader` instead of `excludeHeaders` /!\
        ...(excludeHeaders && { excludeHeader: excludeHeaders }),
        ...(encloseAll && { encloseAll }),
        ...(enclosure && { enclosure }),
        ...(delimiter && { delimiter }),
      }
    },
    [TQLExportFormat.PDF]: () => {
      const { includeCoverPage, exportTitle } = options?.pdfOptions ?? {}

      return {
        ...(includeCoverPage && { includeCoverPage }),
        ...(exportTitle && { exportTitle }),
      }
    },
  }

  return typeHeadersHandlerMap[format]?.()
}

/**
 * Converts a date format from the formats used in the API to a format that can be used in TQL queries
 * @param apiFormat - The date formatting string as used in the API (i.e. 'Y-m-d H:i:s')
 * @returns A TQL compatible date formatting expression (i.e. '%Y-%m-%d %H:%i:%s')
 */
export const convertDateFormatToTql = (apiFormat: string | null): string => {
  if (!apiFormat) {
    return ''
  }

  const formatMapping = {
    d: '%d', // day with leading zero
    m: '%m', // month with leading zero
    Y: '%Y', // 4-digit year
    y: '%y', // 2-digit year
    H: '%H', // 24-hour format with leading zero
    h: '%I', // 12-hour format with leading zero
    i: '%i', // minutes with leading zero
    s: '%S', // seconds with leading zero
    a: '%p', // AM or PM
    A: '%p', // AM or PM (uppercase, but %p in strftime will use uppercase depending on locale)
  }

  // Replace each element with its TQL equivalent
  return apiFormat.replace(/d|m|Y|y|H|h|i|s|a|A/g, (match) => {
    return formatMapping[match] || match
  })
}
