<template>
  <ActionBarPreset :actions="actionList" />
</template>
<script lang="ts">
import Vue, { PropType, VueConstructor } from 'vue'
import ActionBarPreset from '@/tt-widget-entity-flow/components/action-bar/ActionBarPreset.vue'
import { ViewAction } from '@/tt-widget-entity-flow/components/action-bar/ActionBarPreset'
import {
  EntityActionIntentInterface,
  EntityEditIntentInterface,
  EntityIntentTypes,
} from '@/tt-widget-entity-flow'
import { Resources } from '@/tt-entity-design/src/types'
import { getEditFormState } from '@/tt-entity-design/src/tools/helpers/form-state-generator'
import { ActiveWindowProvider, LayoutWindowEvent } from '@/tt-app-layout'
import { OPERATION_CENTER_ESCALATION_POLICIES } from './constants'
import { createViewAction } from '@/tt-entity-design/src/tools/actions-generator'
import { Action } from '@/tt-widget-factory/services/resource-action/types'
import { ItemHookProvider } from '@/tt-widget-entity-flow/types'
import { ARCHIVE } from './constants'

type VueWithInjections = VueConstructor<
  Vue & ItemHookProvider & ActiveWindowProvider
>

export default (Vue as VueWithInjections).extend({
  name: 'OperationCenterEscalationPoliciesActionBar',
  components: {
    ActionBarPreset,
  },
  inject: ['activeWindow', 'getItemHook'],
  props: {
    actions: {
      type: Array as PropType<Action[]>,
      required: true,
    },
  },
  computed: {
    actionList(): ViewAction[] {
      const availableActions: ViewAction[] = this.actions
        .map(({ actionName }) => {
          return this.actionsDictionnary[actionName] || (null as ViewAction)
        })
        .filter(Boolean)

      const editAction: ViewAction = {
        icon: 'mdi-pencil-outline',
        title: this.$t('common.edit.btn'),
        onClick: async () => this.openTasksForm(),
      }

      return [editAction, ...availableActions]
    },
    actionsDictionnary(): Record<string, ViewAction> {
      const openFormAction = async (actionName: string) => {
        const options: Partial<EntityActionIntentInterface> = {
          formOptions: {
            requiredFieldLabelTransformer: null,
          },
          onSuccess: () => {
            this.$eventManager.dispatchEvent(
              EntityIntentTypes.RESOURCE_UPDATED,
              {
                resource: Resources.OPERATION_CENTERS,
              },
            )
            this.activeWindow.back()
          },
        }
        const pageInterface = await this.getItemHook().getActionFormAsPage(
          actionName,
          options,
        )
        this.activeWindow.next(pageInterface)
      }

      return {
        [ARCHIVE]: createViewAction(
          'mdi-archive-outline',
          ARCHIVE,
          OPERATION_CENTER_ESCALATION_POLICIES,
          openFormAction,
        ),
      }
    },
  },
  methods: {
    async openTasksForm(): Promise<void> {
      const formConfig: EntityEditIntentInterface = {
        resourceName: Resources.OPERATION_CENTER_ESCALATION_POLICIES,
        entityId: this.getItemHook().getEntityId(),
        itemHook: this.getItemHook(),
        onSuccess: () => {
          this.$eventManager.dispatchEvent(EntityIntentTypes.RESOURCE_UPDATED, {
            resource: Resources.OPERATION_CENTERS,
          })
        },
        formOptions: {
          requiredFieldLabelTransformer: null,
        },
      }

      const payload = await getEditFormState(this.$appContext, formConfig)

      this.$eventManager.dispatchEvent(LayoutWindowEvent.SIDE_SHEET_ADD_PAGE, {
        is: 'GenericFormPage',
        props: {
          payload: {
            ...payload,
            title: this.$t(
              'tt-entity-design.operation-center-escalation-policies.form-edit-title',
            ),
          },
        },
      })
    },
  },
})
</script>
