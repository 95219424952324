<template>
  <div class="role--tile pa-3" @click="handleClick">
    <tt-attr
      prepend-icon="mdi-shield-account"
      prepend-icon-color="purple"
      name="name"
    />

    <div class="flex--row py-2">
      <TChip color="ttPrimary">
        <tt-attr name="portal" />
      </TChip>

      <tt-if-attr name="status" class="pl-3" value="ARCHIVED">
        <TChip color="muted">
          <tt-attr name="status" />
        </TChip>
      </tt-if-attr>
    </div>

    <div class="delete--button w-100 h-100">
      <div class="flex--column align-end justify-center pr-6">
        <!-- to make the tooltip work on disabled button -->
        <v-fab-transition>
          <v-btn
            v-show="showDeleteButton()"
            small
            class="error error--text lighten-5"
            :loading="isLoading"
            outlined
            :disabled="!canDelete()"
            @click.stop="runDelete"
          >
            {{ $t('common.remove.btn') }}
            <v-icon small class="ml-2" v-text="'mdi-delete'" />
          </v-btn>
        </v-fab-transition>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { ItemHookProvider } from '@/tt-widget-entity-flow/types'
import Vue, { VueConstructor, PropType } from 'vue'

type Callback = (id: number) => void

export default (Vue as VueConstructor<Vue & ItemHookProvider>).extend({
  name: 'ManagedPermissionPoliciesTile',
  inject: ['getItemHook'],
  props: {
    showDeleteButton: {
      type: Function as PropType<() => boolean>,
      default: () => false,
    },
    canDelete: {
      type: Function as PropType<() => boolean>,
      default: () => false,
    },
    onDelete: {
      type: Function as PropType<Callback>,
      default: () =>
        console.warn('No "delete" callback provided.') as unknown as Callback,
    },
    onClick: {
      type: Function as PropType<Callback>,
      default: () =>
        console.log('No "click" callback provided.') as unknown as Callback,
    },
  },
  data: () => ({
    isLoading: false,
  }),
  computed: {
    entityId(): number {
      return this.getItemHook().getRawValue('id')
    },
  },
  created() {
    this.getItemHook().addAttribute('id')
  },
  methods: {
    async runDelete() {
      this.isLoading = true
      await this.onDelete(this.entityId)
      this.isLoading = false
    },
    handleClick() {
      this.onClick(this.entityId)
    },
  },
})
</script>

<style scoped>
.delete--button {
  position: absolute;
  top: 0;
  left: 0;
}

.role--tile {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
}
</style>
