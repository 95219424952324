import Vue from 'vue'
import Vuex, { Store } from 'vuex'
import { VueRouter } from 'vue-router/types/router'
import Router, { RouteConfig } from 'vue-router'
import Widgets, { PluginConfig, i18n, taskManager, vuetify } from '@/main'
import { AppContext, TaskManagerItems } from '@/tt-app-context'
import { AppConfig } from './types'
import { LayoutWindowEvent } from '@/tt-app-layout'
import { LayoutExtensionSlots, modularManager } from '@/tt-app-modular'
import QueryCacheManager from '@/tt-app-layout/components/QueryCacheManager.vue'
import { getLocaleFromLocaleStorage } from '@/locales'
import { omitPushErrors } from '@/plugins/router'
import { Route404, RouteOAuth, RouteSSO } from './core-routes'
import { SupportedLocale } from '../locales/language-options'
import { TTCloudLocale } from '@tracktik/tt-authentication'

const AppLayoutRouter = () =>
  import('@/tt-app-layout/components/AppLayoutRouter.vue')
const VuetifyRootWrapper = () => import('./views/VuetifyRootWrapper.vue')

export default class AppFactory {
  /**
   * The application configuration
   */
  private appConfig: AppConfig

  /**
   * Router
   */
  private router: VueRouter

  /**
   * Store
   */
  private store: Store<any>

  /**
   * The app state
   */
  public state: { app: any }

  private unsubscribers: Array<Function> = []

  /**
   * Receive the application configuration
   * @param appConfig
   */
  constructor(appConfig: AppConfig) {
    Vue.use(Vuex)
    Vue.use(Router)

    this.state = Vue.observable({ app: null })
    this.appConfig = appConfig
    this.router = new Router({
      routes: this.getRoutes(),
      scrollBehavior(to, from, savedPosition) {
        return { x: 0, y: 0 }
      },
    })
    this.store = new Vuex.Store({
      state: {
        appConfig,
      },
    })
  }

  /**
   * Get the router
   */
  private getRouter(): Router {
    return this.router
  }

  /**
   * Install the
   */
  public install() {
    const config: PluginConfig = {
      installOptions: this.appConfig.installOptions,
      setup: async (...args) => this.appConfig.onSetup(...args),
      o11n: this.appConfig.o11n,
      auth: this.appConfig.auth,
      locale: getLocaleFromLocaleStorage() as SupportedLocale | TTCloudLocale, //@todo: add option in the AppFactory to explicitly say that we want to use localStorage
      disableCssTransitions: this.appConfig.layout.disableCssTransitions,
    }

    Vue.use(Widgets, config, this.getServices())

    // Set the state
    this.state.app = new Vue({
      vuetify,
      router: this.router,
      i18n,
      store: this.store,
      //@ts-ignore
      wait: taskManager,
      render: (h) => h('router-view'),
    }).$mount(this.appConfig.el || '#app')

    // Register necessary events
    this.registerEvents(this.state.app.$appContext)

    // Start fetching metas
    const { FETCHING_METADATAS } = TaskManagerItems
    taskManager.start(FETCHING_METADATAS)

    // Add the query cache manager
    Vue.component('QueryCacheManager', QueryCacheManager)
    modularManager.registerExtension({
      name: 'QueryCache',
      component: { is: QueryCacheManager },
      enabled: true,
      slots: [LayoutExtensionSlots.BODY],
    })
  }

  /**
   * Bundle the services
   */
  private getServices() {
    return {
      router: this.getRouter(),
      store: this.getStore(),
    }
  }

  /**
   * Get the store
   */
  private getStore() {
    return this.store
  }

  /**
   * Get the routes and include the basic default routes
   */
  private getRoutes(): RouteConfig[] {
    return [
      RouteSSO,
      RouteOAuth,
      {
        path: '/',
        alias: ['/support'],
        component: AppLayoutRouter,
        props: {
          layoutConfiguration: this.appConfig.layout,
          dataAnalytics: this.appConfig.dataAnalytics,
        },
        children: this.appConfig.routes,
      },
      ...this.appConfig.customRoutes,
      {
        path: '/',
        alias: ['/support'],
        component: VuetifyRootWrapper,
        children: [Route404],
      },
    ]
  }

  private registerEvents(appContext: AppContext): void {
    this.unsubscribers.push(
      appContext.eventManager.subscribeEvent(
        LayoutWindowEvent.GO_TO,
        (payload) =>
          this.router
            .push(payload)
            .catch(omitPushErrors(['NavigationDuplicated'])),
      ),
    )
  }
}
