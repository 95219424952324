<template>
  <v-card flat class="toolbar3 pa-4 d-flex align-center">
    <!-- BETWEEN or NULL -->
    <div v-if="isBetweenOperator || isNullOperator" class="w-100">
      <FilterOperatorSelection
        v-model="operator"
        :operators="operatorList"
        class="pl-2"
      />

      <!-- BETWEEN ONLY -->
      <div v-if="isBetweenOperator" class="flex--row pt-2 align-center">
        <TSearchInput
          :value="firstInput"
          type="number"
          :debounce-time="100"
          @input="setBetweenFirstValue"
        />

        <span class="px-2" v-text="'-'" />

        <TSearchInput
          type="number"
          :debounce-time="100"
          :value="secondInput"
          @input="setBetweenSecondValue"
        />
      </div>
    </div>

    <!-- OTHERS -->
    <div v-else class="flex--row align-center">
      <FilterOperatorSelection
        v-model="operator"
        :operators="operatorList"
        style="width: 30%"
      />

      <TSearchInput
        :debounce-time="100"
        :value="filterValue"
        type="number"
        clas="ml-2"
        style="width: 70%"
        @input="setValueDebounce"
      />
    </div>
  </v-card>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue'
import {
  FilterOperatorType,
  Filter,
} from 'tracktik-sdk/lib/common/entity-filters'
import {
  BETWEEN_SEPARATOR_NUMBER,
  isNullOperatorType,
} from '@/tt-entity-filter/util'
import { debounce, DebouncedFunc } from 'lodash'
import TSearchInput from '@/tt-ui/components/TSearchInput.vue'
import FilterOperatorSelection from './FilterOperatorSelection.vue'
import { FilterOperator } from '@/tt-widget-components/schemas-types'

const NUMBER_OPERATORS = [
  FilterOperatorType.EQUAL,
  FilterOperatorType.BETWEEN,
  FilterOperatorType.GT,
  FilterOperatorType.GTE,
  FilterOperatorType.LT,
  FilterOperatorType.LTE,
  FilterOperatorType.ISNULL,
  FilterOperatorType.ISNOTNULL,
]

export default Vue.extend({
  components: { TSearchInput, FilterOperatorSelection },
  props: {
    attributeName: {
      type: String,
      required: true,
    },
    value: {
      type: Object as PropType<Filter>,
      default: null,
    },
  },
  computed: {
    operatorList(): FilterOperator[] {
      return NUMBER_OPERATORS
    },
    operator: {
      get(): Filter['operator'] {
        return this.value?.operator || FilterOperatorType.EQUAL
      },
      set(operator: Filter['operator']) {
        this.setOperator(operator)
      },
    },
    filterValue(): string {
      // @ts-ignore
      return this.value?.value || ''
    },
    setValueDebounce(): DebouncedFunc<(value: Filter['value']) => void> {
      return debounce((value: Filter['value']) => {
        const attribute = this.attributeName
        const operator = this.operator

        this.emitUpdate({ attribute, operator, value })
      }, 500)
    },
    firstInput(): string {
      return this.filterValue.split(BETWEEN_SEPARATOR_NUMBER)[0] ?? ''
    },
    secondInput(): string {
      return this.filterValue.split(BETWEEN_SEPARATOR_NUMBER)[1] ?? ''
    },
    isBetweenOperator(): boolean {
      return this.operator === FilterOperatorType.BETWEEN
    },
    isNullOperator(): boolean {
      return isNullOperatorType(this.operator)
    },
  },
  methods: {
    setOperator(newOperator: Filter['operator']) {
      const attribute = this.attributeName
      const previousOperator = this.operator
      const value =
        previousOperator === FilterOperatorType.BETWEEN
          ? null
          : this.filterValue

      this.emitUpdate({ attribute, operator: newOperator, value })
    },
    setBetweenFirstValue(firstValue) {
      const value = `${firstValue}${BETWEEN_SEPARATOR_NUMBER}${this.secondInput}`

      this.setValueDebounce(value)
    },
    setBetweenSecondValue(secondValue) {
      const value = `${this.firstInput}${BETWEEN_SEPARATOR_NUMBER}${secondValue}`

      this.setValueDebounce(value)
    },
    emitUpdate(newFilter: Filter) {
      this.$emit('input', { ...newFilter })
    },
  },
})
</script>
