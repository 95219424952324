<template>
  <v-select
    :menu-props="{
      offsetY: true,
      contentClass: 'overline level0',
    }"
    :items="operatorList"
    :value="value"
    dense
    hide-details
    append-icon=""
    class="faded overline mt-0"
    @input="emitInput"
  />
</template>

<script lang="ts">
import Vue, { PropType } from 'vue'
import { FilterOperator } from '@/tt-widget-components/schemas-types'

type OperatorViewItem = {
  value: FilterOperator
  text: string
}

export default Vue.extend({
  name: 'FilterOperatorSelection',
  props: {
    operators: {
      type: Array as PropType<FilterOperator[]>,
      required: true,
    },
    value: {
      type: String as PropType<FilterOperator>,
      default: null,
    },
  },
  computed: {
    operatorList(): OperatorViewItem[] {
      return this.operators.map((operator) => ({
        value: operator,
        text: this.$t(`filter.operator.${operator}`),
      }))
    },
  },
  methods: {
    emitInput(newOperator: FilterOperator) {
      this.$emit('input', newOperator)
    },
  },
})
</script>

<style scoped>
.v-select >>> .v-input__slot::after {
  content: none;
}

.v-select:hover >>> .v-input__slot::after {
  content: '';
  border-width: 1px;
  border-color: var(--v-bg-grey-base);
}
</style>
