import { registerResourceForm } from '@/tt-widget-entity-flow/helper'
import SystemExceptionTicketInstructionsCreateForm from './form/SystemExceptionTicketInstructionsCreateForm.vue'
import SystemExceptionTicketInstructionsEditForm from './form/SystemExceptionTicketInstructionsEditForm.vue'
import SystemExceptionTicketInstructionsTicketTypeField from './form/SystemExceptionTicketInstructionsTicketTypeField.vue'
import SystemExceptionTicketInstructionsActionBar from './SystemExceptionTicketInstructionsActionBar.vue'
import { Resources } from '../../types'
import { ActionFormTypes, modularManager } from '@/tt-app-modular'

export default {
  install(Vue) {
    Vue.component(
      'SystemExceptionTicketInstructionsCreateForm',
      SystemExceptionTicketInstructionsCreateForm,
    )
    Vue.component(
      'SystemExceptionTicketInstructionsEditForm',
      SystemExceptionTicketInstructionsEditForm,
    )

    Vue.component(
      'SystemExceptionTicketInstructionsTicketTypeField',
      SystemExceptionTicketInstructionsTicketTypeField,
    )

    registerResourceForm(
      Resources.SYSTEM_EXCEPTION_TICKET_INSTRUCTIONS,
      'SystemExceptionTicketInstructionsCreateForm',
      ActionFormTypes.CREATE,
    )

    registerResourceForm(
      Resources.SYSTEM_EXCEPTION_TICKET_INSTRUCTIONS,
      'SystemExceptionTicketInstructionsEditForm',
      ActionFormTypes.EDIT,
    )

    modularManager.registerActionBar(
      Resources.SYSTEM_EXCEPTION_TICKET_INSTRUCTIONS,
      {
        is: SystemExceptionTicketInstructionsActionBar,
      },
    )
  },
}
