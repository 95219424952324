<template>
  <EntityRelationListEditor
    :resource="mainResource"
    :relation-list-attribute="relationListAttribute"
    :can-add="canAdd"
    :can-delete="canDelete"
    :run-add="runAdd"
    :run-delete="runDelete"
    :tile-component="tileComponent"
    :add-query-options="addQueryOptions"
  />
</template>

<script lang="ts">
import Vue, { Component, VueConstructor } from 'vue'
import { CollectionQuery } from '@/tt-widget-components/types'
import { ItemHookProvider } from '@/tt-widget-entity-flow/types'
import {
  ActionProperty,
  Action,
  Attribute,
  CustomFilter,
} from '@/tt-entity-design/src/schema-types'

import { Resources } from '@/tt-entity-design/src/types'
import { updateDOM } from '@/helpers/dom/updateDOM'
import ListWidgetHook from '@/tt-widget-components/widgets/List/ListWidgetHook'
import { FilterOperatorType } from 'tracktik-sdk/lib/common/entity-filters'
import { LayoutWindowEvent } from '@/tt-app-layout'
import ManagedPermissionPoliciesRoleRelationListTile from './ManagedPermissionPoliciesRoleRelationListTile.vue'
import { EntityIntentTypes } from '@/tt-widget-entity-flow'
import EntityRelationListEditor from '@/tt-widget-entity-flow/components/EntityRelationListEditor.vue'

const RESOURCE = Resources.MANAGED_PERMISSION_POLICIES
const RELATION_LIST_ATTR: Attribute<typeof RESOURCE> = 'roleAssignments'
const ADD_ACTION: Action<typeof RESOURCE> = 'add-to-role'
const ROLE_FILTER: CustomFilter<'roles'> =
  'excludeAlreadyAssignedManagedPermissionPolicy'
const ROLE_PORTAL_ATTR: Attribute<'roles'> = 'portal'
const MANAGED_PERMISSION_POLICIES_PORTAL_ATTR: Attribute<typeof RESOURCE> =
  'portal'

const REMOVE_ACTION: Action<typeof RESOURCE> = 'remove-from-role'

type AddActionProperty = ActionProperty<typeof RESOURCE, typeof ADD_ACTION>

type DeleteProperty = ActionProperty<typeof RESOURCE, typeof REMOVE_ACTION>

/**
 * needs to be able to pass a different resource for the "add query"...
 * we need "roles" but by default the component is using the relation list resource, but it does nt work for multi-to-multi relations.
 */
export default (Vue as VueConstructor<Vue & ItemHookProvider>).extend({
  name: 'ManagedPermissionPoliciesRolesRelationList',
  components: { EntityRelationListEditor },
  inject: ['getItemHook'],
  data: () => ({
    hook: null as ListWidgetHook | null,
    isLoading: false,
    entityIdToAdd: null,
  }),
  computed: {
    mainResource(): string {
      return RESOURCE
    },
    canAdd(): boolean {
      return this.getItemHook().isActionAvailable(ADD_ACTION)
    },
    canDelete(): boolean {
      return this.getItemHook().isActionAvailable(REMOVE_ACTION)
    },
    tileComponent(): Component {
      return ManagedPermissionPoliciesRoleRelationListTile
    },
    relationListAttribute(): string {
      return RELATION_LIST_ATTR
    },
    addQueryOptions(): Partial<CollectionQuery> {
      return {
        returnCount: false,
        filters: [
          {
            attribute: ROLE_PORTAL_ATTR,
            operator: FilterOperatorType.EQUAL,
            value: this.getItemHook().getRawValue(
              MANAGED_PERMISSION_POLICIES_PORTAL_ATTR,
            ),
          },
        ],
        customFilters: [
          {
            filterName: ROLE_FILTER,
            value: this.mainEntityId,
          },
        ],
      }
    },
    mainEntityId(): number {
      return this.getItemHook().getEntityId()
    },
  },
  created() {
    this.getItemHook().addAttribute(MANAGED_PERMISSION_POLICIES_PORTAL_ATTR)
  },
  methods: {
    async runAdd() {
      this.isLoading = true
      await updateDOM()

      const payload: Record<AddActionProperty, number> = {
        role: this.entityIdToAdd,
      }

      await this.$auth
        .getApi()
        .doAction(RESOURCE, this.mainEntityId, ADD_ACTION, payload)
        .then(async () => {
          await this.afterUpdate()

          this.entityIdToAdd = null
        })
        .catch((error) => {
          this.$eventManager.dispatchEvent(LayoutWindowEvent.SNACK_ERROR, {
            message: error.message,
          })

          this.isLoading = false
        })
    },
    async runDelete(entityIdToRemove: number) {
      await updateDOM()

      const payload: Record<DeleteProperty, number> = {
        role: entityIdToRemove,
      }

      await this.$auth
        .getApi()
        .doAction(RESOURCE, this.mainEntityId, REMOVE_ACTION, payload)
        .then(async () => {
          await this.afterUpdate()
        })
        .catch((error) => {
          this.$eventManager.dispatchEvent(LayoutWindowEvent.SNACK_ERROR, {
            message: error.message,
          })
        })
    },
    async afterUpdate() {
      const updateList = () => this.hook?.update({ disableCache: true })

      this.$eventManager.dispatchEvent(EntityIntentTypes.RESOURCE_UPDATED, {
        resource: RESOURCE,
      })

      await updateList()

      this.isLoading = false
    },
  },
})
</script>
