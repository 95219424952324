<template>
  <div>
    <EntityViewAttributeCollector
      v-if="resourceName"
      :resource-name="resourceName"
      @input="setFields($event)"
    >
      <template #default="{ itemHook }">
        <slot :item-hook="itemHook" />
      </template>
    </EntityViewAttributeCollector>

    <div v-if="isLoading">
      <v-skeleton-loader v-for="index in 4" :key="index" type="list-item" />
    </div>

    <TNoData v-else-if="isEmpty" />

    <div v-else>
      <slot name="selectAll" />
      <EntityLooper
        v-bind="$attrs"
        :container="container"
        :entities="data"
        :resource-name="resourceName"
      >
        <template #default="{ itemHook }">
          <slot :item-hook="itemHook" />
        </template>
      </EntityLooper>
      <div v-if="hasToDisplayObserver" v-intersect.quiet="handleInfinityScroll">
        <v-skeleton-loader v-for="index in 2" :key="index" type="list-item" />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import debounce from 'lodash/debounce'
import isEqual from 'lodash/isEqual'
import { DebouncedFunc } from 'lodash'
import Vue, { VueConstructor, PropType } from 'vue'

import QueryManager from '@/tt-widget-components/base/QueryManager'
import { CollectionQuery } from '@/tt-widget-components'
import { ContextManagerInterface, WidgetServices } from '@/tt-widget-factory'
import { onFetchCollectionError } from '@/tt-widget-components/base/CollectionWidgetHook'

import { EventLifeCycleManager } from '../EventLifeCycleManager'
import { EntityIntentTypes, ResourceUpdatedInterface } from '../intents'

import TNoData from '@/tt-ui/components/TNoData.vue'
import { mapSortedAttributes } from '../helper'
import { DevConsole } from '@/plugins/DevConsole'
import EntityLooper from '@/tt-widget-entity-flow/components/EntityLooper.vue'

type VueWithContextManager = VueConstructor<
  Vue & { contextManager: ContextManagerInterface }
>

const QUERY_LIMIT = 20

const REQUEST_CANCEL_MSG = 'Request canceled'

export default (Vue as VueWithContextManager).extend({
  name: 'EntityCollection',
  components: { TNoData, EntityLooper },
  inject: {
    contextManager: { default: null },
  },
  inheritAttrs: false,
  props: {
    query: {
      type: Object as PropType<CollectionQuery>,
      required: true,
    },
    container: {
      type: String,
      default: 'v-list',
    },
    isSorted: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loading: false,
      totalItems: null as null | number,
      data: [] as any[],
      requiredFields: [] as string[],
      eventLifeCycle: null as EventLifeCycleManager | null,
      queryManager: null as QueryManager | null,
      cancelPreviousRequest: null as (() => void) | null,
    }
  },

  computed: {
    isEmpty(): boolean {
      return this.totalItems === 0
    },
    isLoading(): boolean {
      return !this.data.length && this.loading
    },
    hasLoadedAllData(): boolean {
      return this.totalItems === this.data.length
    },
    hasToDisplayObserver(): boolean {
      return this.totalItems === null || !this.hasLoadedAllData
    },
    resourceName(): string {
      return this.query.resource
    },
    setUpdateDebounce(): DebouncedFunc<() => void> {
      return debounce(() => {
        this.update()
      }, 250)
    },
  },
  watch: {
    loading(val: boolean) {
      this.$emit('loading', val)
    },
    query: {
      deep: true,
      handler(val, oldVal) {
        if (isEqual(val, oldVal)) {
          return
        }
        if (!val.resource) {
          return
        }

        const resourceModel =
          this.$appContext.widgetServices.resourceMetaManager.getResource(
            this.query.resource,
          )
        if (!resourceModel) {
          return
        }
        this.queryManager.updateQuery(this.query)
        this.queryManager.setCustomFilters(this.query.filters)
        this.resetAndUpdate()
      },
    },
  },
  created() {
    const contextManager =
      this.contextManager || this.$appContext.widgetServices.contextManager

    this.queryManager = new QueryManager(this.query, contextManager, {
      services: this.$appContext.widgetServices,
    })

    this.eventLifeCycle = new EventLifeCycleManager(
      this.$appContext.contextManager,
    )

    this.eventLifeCycle.subscribeTo(EntityIntentTypes.CONTEXT_CHANGE, () => {
      this.resetAndUpdate()
    })

    this.eventLifeCycle.subscribeTo(
      EntityIntentTypes.RESOURCE_UPDATED,
      (payload: ResourceUpdatedInterface) => {
        if (payload.resource === this.query.resource) {
          this.resetAndUpdate()
        }
      },
    )
  },
  beforeDestroy() {
    this.eventLifeCycle?.destroy()
    this.cancelPreviousRequest?.()
  },
  methods: {
    debounceUpdate(): void {
      this.setUpdateDebounce.cancel()
      this.setUpdateDebounce()
    },
    setFields(val: string[]): void {
      if (val.length) {
        this.requiredFields = val
        this.resetAndUpdate()
      }
    },
    resetPagination(): void {
      this.queryManager.setOffset(0)
      this.totalItems = null
      this.data = []
    },
    resetAndUpdate(): void {
      this.resetPagination()
      this.debounceUpdate()
    },
    handleInfinityScroll(): void {
      if (
        (this.totalItems !== null && this.data.length >= this.totalItems) ||
        this.loading
      ) {
        return
      }
      this.debounceUpdate()
    },
    async update(): Promise<void> {
      if (this.cancelPreviousRequest) {
        this.cancelPreviousRequest()
        this.cancelPreviousRequest = null
      }

      if (!this.query.resource) {
        return
      }
      this.loading = true

      const services: WidgetServices = this.$appContext.widgetServices
      const currentOffset = this.queryManager.query.offset ?? 0
      const nextOffset = currentOffset + QUERY_LIMIT

      if (this.isSorted) {
        this.queryManager.setSort(mapSortedAttributes(this.requiredFields))
      }

      this.queryManager.setFieldsAndExtensionsFromAttributes([
        ...this.requiredFields,
        'id',
      ])
      this.queryManager.setLimit(QUERY_LIMIT)

      const { run, cancel } =
        services.resourceDataManager.cancelableGetCollection(
          this.queryManager.query,
        )

      this.cancelPreviousRequest = () => cancel(REQUEST_CANCEL_MSG)

      await run()
        .then(({ total, items }) => {
          this.queryManager.setOffset(nextOffset)

          this.totalItems = total
          this.data = [...this.data, ...items]
          // Emit the fetched items
          const entityIds: string[] = this.data.map((item) => String(item.id))
          this.$emit('itemsFetched', entityIds)
          this.loading = false
        })
        .catch((error) => {
          if (error.message === REQUEST_CANCEL_MSG) {
            DevConsole.log('Request canceled')

            return
          }

          this.loading = false

          this.$crash.captureException(error)

          return onFetchCollectionError(this.$eventManager)(error)
        })
    },
  },
})
</script>
