<template>
  <TicketTypesChip
    :ticket-type="ticketType"
    :resource="resource"
    attributes-name="type"
  />
</template>

<script lang="ts">
import Vue, { VueConstructor } from 'vue'

import TicketTypesChip from './TicketTypesChip.vue'
import { ItemHookProvider } from '@/tt-widget-entity-flow/types'
import { CTAType } from './types'

export default (Vue as VueConstructor<Vue & ItemHookProvider>).extend({
  name: 'SystemExceptionTicketsTypeAttr',
  components: {
    TicketTypesChip,
  },
  inject: ['getItemHook'],
  computed: {
    ticketType(): CTAType {
      return this.getItemHook().getRawValue('type')
    },
    resource(): string {
      return this.getItemHook().getResourceName()
    },
  },
  created() {
    this.getItemHook().addAttribute('type')
  },
})
</script>
