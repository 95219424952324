import moment from 'moment-timezone'
import Vue, { Component } from 'vue'

import { parseResourceDates } from '@/tt-widget-factory/helpers/parse-resource-attributes'
import {
  FieldTypes,
  FilterOperatorType,
  WidgetHookDependencies,
  WidgetState,
} from '@/tt-widget-factory'

import CollectionWidgetHook, {
  CollectionWidgetState,
} from '../../base/CollectionWidgetHook'
import {
  CalendarWidgetModel,
  DefaultToolbar,
  SchedulerWidgetModel,
} from '../../schemas-types'
import { SchedulerView, ViewEvent } from './types'
import { TTC_API_MAX_LIMIT } from '@/tt-widget-components/constants'
import {
  createDateTimeFromIso,
  createDateTimeString,
} from '@/helpers/dates/createDateTimeString'
import { Timezone } from '@/helpers/dates/timezones'
import {
  isLocaleDateTimeFieldType,
  isTemporalFieldType,
} from '@/tt-entity-filter/temporal-filters/field-types-validator'
import get from 'lodash/get'
import { WidgetName } from '@/tt-widget-components/lib/names'
import { createTemporalFilterManager } from '@/tt-entity-filter/temporal-filters/TemporalFilterManager'
import { DateString, SchedulerWidgetState } from './types'
import { IS_DEV } from '@tracktik/tt-pusher/lib/src/constants'
import { COLORS, DEFAULT_TOOLBAR } from './constants'
import {
  createColorManager,
  isViewValid,
  schedulerViewMapping,
} from '@/tt-widget-components/widgets/Scheduler/helper'
import { isResourceWhitelisted } from '@/tt-widget-factory/services/metadata-provider/resource-blacklist'
import { isValidDate } from '@/helpers/dates/datetimeFormatValidator'

export default class SchedulerWidgetHook extends CollectionWidgetHook<
  SchedulerWidgetModel | CalendarWidgetModel
> {
  state: WidgetState & CollectionWidgetState & SchedulerWidgetState

  constructor(deps: WidgetHookDependencies) {
    super(deps)

    // keep in separate state
    const schedulerState: SchedulerWidgetState = {
      currentView: SchedulerView.WEEK,
      selectedDate: this.getUserNowDate(),
    }

    this.state = Vue.observable({
      ...schedulerState,
      ...this.state,
    })
  }

  get currentView(): SchedulerView {
    return this.state.currentView
  }

  get selectedDate(): DateString {
    return this.state.selectedDate
  }

  // Calendar controls are always in the toolbar, so we always want to show it
  // Overrides the default ColllectionWidgetHook getter
  get showToolbar(): boolean {
    return true
  }

  get viewOptions(): SchedulerView[] {
    return Object.values(SchedulerView)
  }

  getEventComponent(): Component | null {
    return (
      (this.widget.is === WidgetName.CALENDAR_WIDGET &&
        // @ts-ignore
        this.widget.eventSettings?.component) ||
      null
    )
  }

  /**
   * Returns the start date of the current view, in the user's timezone (`YYYY-MM-DD` format)
   */
  getStartDate(): DateString {
    const timeFrame = schedulerViewMapping[this.currentView] || 'day'
    const date = moment(this.selectedDate).startOf(timeFrame)

    return this.isDateTimeAttribute(this.getStartAttribute())
      ? date.subtract(1, 'day').format(`YYYY-MM-DD`)
      : date.format(`YYYY-MM-DD`)
  }

  /**
   * Returns the end date of the current view, in the user's timezone (`YYYY-MM-DD` format)
   */
  getEndDate(): DateString {
    const timeFrame = schedulerViewMapping[this.currentView] || 'day'
    const date = moment(this.selectedDate).endOf(timeFrame)

    return this.isDateTimeAttribute(this.getEndAttribute())
      ? date.add(1, 'day').format(`YYYY-MM-DD`)
      : date.format(`YYYY-MM-DD`)
  }

  /**
   * Sets the current view of the Scheduler, and optionally fetches the data for the new view.
   */
  async setCurrentView(view: SchedulerView, update = true): Promise<void> {
    if (!isViewValid(view)) return
    this.state.currentView = view
    if (update) await this.updateDateRangeFilter()
  }

  /**
   * Sets the required fields, and optionally fetches the data for the new view.
   */
  setRequiredFields(extraAttributes: string[] = []): Promise<void> {
    const resourceHasAttribute = (attributeName: string): boolean =>
      !!this.services.resourceMetaManager.getAttribute(
        this.resource,
        attributeName,
      )

    /**
     * On whitelisted resources, we remove invalid fields before the request.
     * On regular, invalid fields are kept, causing an API error by design so
     * we can capture the error earlier.
     */
    const isntWhitelisted = (field: string) =>
      !isResourceWhitelisted(this.resource) || resourceHasAttribute(field)

    const attributes = [
      'id',
      this.getStartAttribute(),
      this.getEndAttribute(),
      this.getTitleAttribute(),
      this.getColorAttribute(),
      ...extraAttributes,
    ]
      .filter(Boolean)
      .filter(isntWhitelisted)

    this.attributes = [...new Set(attributes)]

    this.isReadyToFetch = true

    return this.updateDateRangeFilter()
  }

  /**
   * Change the selected date, and optionally fetches the data for the new view.
   */
  async setSelectedDate(date: DateString, update = true): Promise<void> {
    if (!isValidDate(date))
      console.warn('New selected date is not a valid date:', date)
    this.state.selectedDate = date
    if (update) await this.updateDateRangeFilter()
  }

  setup(): void {
    // Not ready to fetch until we get the required fields
    this.isReadyToFetch = false

    this.queryManager.limit = TTC_API_MAX_LIMIT

    if (IS_DEV) this.validateConfiguration()

    if (!this.widget.toolbar) this.setWidgetToolbar(DEFAULT_TOOLBAR)

    this.updateDateRangeFilter()

    this.queryManager.setResetCallback(() => {
      this.setCurrentView(SchedulerView.WEEK, false)
      this.setSelectedDate(this.getUserNowDate(), false)
      this.updateDateRangeFilter(false)
    })
  }

  getColorAttribute(): string | null {
    const colorAttribute =
      this.widget.is === WidgetName.CALENDAR_WIDGET &&
      this.widget.eventSettings?.colorByAttribute

    return colorAttribute || null
  }

  /**
   * Check if the total number of entities is over the limit.
   * Used to display a message in the view component.
   */
  isOverLimit(): boolean {
    return this.totalEntities > this.queryManager.limit
  }

  /**
   * Return a list of events ready to be displayed in the view component.
   */
  getViewEvents(): ViewEvent[] {
    const colorManager = createColorManager(this.getColorAttribute(), COLORS)

    const isStartDateOnly =
      this.getFieldType(this.getStartAttribute()) === FieldTypes.Date
    const isEndDateOnly =
      this.getFieldType(this.getEndAttribute()) === FieldTypes.Date

    const timezone = this.getUserTimezone()

    return this.entities.map((entity): ViewEvent => {
      const name = get(entity, this.getTitleAttribute())
      const color = colorManager.getEventColor(entity)

      const datetimeStart = get(entity, this.getStartAttribute())
      const start = isStartDateOnly
        ? datetimeStart
        : createDateTimeFromIso(datetimeStart, timezone)

      const datetimeEnd = get(entity, this.getEndAttribute())
      const end = isEndDateOnly
        ? datetimeEnd
        : createDateTimeFromIso(datetimeEnd, timezone)

      return {
        id: entity.id,
        name,
        start,
        end,
        color,
      }
    })
  }

  /**
   * Returns the current date in the user's timezone.
   */
  getUserNowDate(): string {
    return moment.tz(moment(), this.getUserTimezone()).format('YYYY-MM-DD')
  }

  /**
   * Returns the percentage of the day that has passed. Used to highlight the current time in the Scheduler.
   *
   * eg:
   * - if it is locally 00:00 AM, the percentage will be 0%
   * - if it is locally 12:00 PM, the percentage will be 50%
   */
  getPercentageDayCompletion(): number {
    const userLocalNow = moment.tz(moment(), this.getUserTimezone())

    const userDayStart = userLocalNow.clone().startOf('day')

    const dayDurationMinutes = moment
      .duration(userLocalNow.diff(userDayStart))
      .asMinutes()

    return Math.round((dayDurationMinutes / (24 * 60)) * 100)
  }

  getUserTimezone(): Timezone {
    return this.services.authModule.getUserPreferences().timeZone as Timezone
  }

  protected parseEntity(item: Record<string, any>): Record<string, any> {
    /**
     * The API provides the values of Date attributes with a DateTime format
     * the moment: API-1715.
     * We must strip their time and timezone information out to prevent
     * timezone issues when the SyncFusion Schedule component parses the date
     * so it can alocate each event in their appropriate date slots.
     */
    return parseResourceDates(
      this.services.resourceMetaManager,
      this.resource,
      item,
    )
  }

  protected setWidgetToolbar(toolbar: DefaultToolbar): void {
    this.state.widget = { ...this.state.widget, toolbar }
  }

  protected updateDateRangeFilter(updateHook = true): Promise<void> {
    if (!this.isInitialized || !this.currentView || !this.selectedDate) return

    const start = createDateTimeString(this.getStartDate(), '00:00:00')
    const end = createDateTimeString(this.getEndDate(), '23:59:59')

    if (this.getStartAttribute() === this.getEndAttribute()) {
      const temporalFilterManager = createTemporalFilterManager({
        attribute: this.getStartAttribute(),
        operator: FilterOperatorType.BETWEEN,
        value: [start, end],
      })

      temporalFilterManager.setTimezone(this.getUserTimezone())

      const temporalFilter = temporalFilterManager.getFilter()

      this.queryManager.setCustomFilter(temporalFilter)
    } else {
      const startFilter = createTemporalFilterManager({
        attribute: this.getStartAttribute(),
        operator: FilterOperatorType.BEFORE,
        value: end,
      })

      const endFilter = createTemporalFilterManager({
        attribute: this.getEndAttribute(),
        operator: FilterOperatorType.AFTER,
        value: start,
      })

      startFilter.setTimezone(this.getUserTimezone())
      endFilter.setTimezone(this.getUserTimezone())

      this.queryManager.setCustomFilter(startFilter.getFilter())
      this.queryManager.setCustomFilter(endFilter.getFilter())
    }

    // Dont overwrite the Widget's Query Sort if there is any!
    const sort = this.widget.query.sort
    const hasSort = (Array.isArray(sort) && sort.length) || sort
    if (!hasSort) {
      this.queryManager.setSort([
        { attribute: this.getStartAttribute(), direction: 'ASC' },
      ])
    }

    if (updateHook) {
      return this.update()
    }
  }

  private getStartAttribute(): string {
    return this.widget.attributeMap.startAttribute
  }

  private getEndAttribute(): string {
    return this.widget.attributeMap.endAttribute
  }

  private getFieldType(attribute: string): FieldTypes | null {
    return (
      this.services.resourceMetaManager.getAttribute(this.resource, attribute)
        ?.type ?? null
    )
  }

  private getTitleAttribute(): string {
    return this.widget.attributeMap.titleAttribute
  }

  /**
   * Logs messages when configuration is not correct for the Calendar Widget. Useful for debugging.
   */
  private validateConfiguration(): void {
    if (this.widget.is === WidgetName.SCHEDULER_WIDGET) return

    const isTemporalField = (attr: string) =>
      isTemporalFieldType(this.getFieldType(attr))
    const isLocaleDatetime = (attr: string) =>
      isLocaleDateTimeFieldType(this.getFieldType(attr))

    if (!isTemporalField(this.getStartAttribute()))
      console.error(
        'The start attribute is not a temporal field:',
        this.getStartAttribute(),
      )

    if (!isTemporalField(this.getEndAttribute()))
      console.error(
        'The end attribute is not a temporal field:',
        this.getEndAttribute(),
      )

    if (!isLocaleDatetime(this.getStartAttribute()))
      console.warn(
        'The start attribute is not an ISO datetime field, filtering on it may not work as expected.',
        this.getStartAttribute(),
      )

    if (!isLocaleDatetime(this.getEndAttribute()))
      console.warn(
        'The end attribute is not an ISO datetime field, filtering on it may not work as expected.',
        this.getEndAttribute(),
      )
  }

  private isDateTimeAttribute(attribute: string): boolean {
    return this.getFieldType(attribute) === FieldTypes.DateTime
  }
}
