<template>
  <div class="d-flex align-center">
    <tt-attr name="status">
      <TChipLabel v-if="status" v-bind="chipProps" />
    </tt-attr>
  </div>
</template>

<script lang="ts">
import Vue, { VueConstructor } from 'vue'
import { ItemHookProvider } from '@/tt-widget-entity-flow/types'
import { StatusColorType, TChipLabelType } from '@/tt-ui/types'

import { AssetPersonColorsOptions } from './constants'

type VueWithInjections = VueConstructor<Vue & ItemHookProvider>

export default (Vue as VueWithInjections).extend({
  name: 'AssetPersonsStatusAttr',
  inject: ['getItemHook'],
  computed: {
    status(): string {
      return this.getItemHook().get('status')
    },
    rawStatus(): string {
      return this.getItemHook().getRawValue('status')
    },
    statusColor(): StatusColorType {
      return AssetPersonColorsOptions[this.rawStatus]
    },
    chipProps(): Record<string, any> {
      return {
        textColor: this.statusColor.text,
        backgroundColor: this.statusColor.background,
        modifiers: {
          style: {
            fontWeight: 'bold',
            textTransform: 'none',
            opacity: 1,
          },
        },
        textKey: this.status,
        type: TChipLabelType.Pill,
      }
    },
  },
})
</script>
