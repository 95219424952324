<template>
  <div class="flex--column">
    <ResourceAllowedOperations
      v-slot="{ allowsActions: appAllowsActions }"
      :resource-name="resource"
      class="flex--column"
    >
      <div
        v-if="appAllowsActions"
        class="flex--row align-center px-3"
        style="height: 62px"
      >
        <div>
          <v-tooltip left>
            <template #activator="{ on }">
              <!-- to make the tooltip work on disabled button -->
              <div class="flex--row align-center" v-on="on">
                <v-switch
                  v-model="isEditMode"
                  inset
                  class="my-0"
                  hide-details
                  dense
                  color="success"
                />

                <v-icon
                  small
                  class="mr-2"
                  :color="isEditMode ? 'success' : 'primary'"
                  v-text="'mdi-pencil'"
                />
              </div>
            </template>

            <span v-text="$t('Edit')" />
          </v-tooltip>
        </div>

        <div v-if="isEditMode" class="w-100">
          <div v-if="!canAdd">
            <v-icon small class="red--text mr-2" v-text="'mdi-alert-circle'" />
            <span
              class="caption red--text"
              v-text="`You don't have permission to manage.`"
            />
          </div>
          <div v-else class="flex--row align-center" style="height: 38px">
            <EntitySelectorField
              v-model="entityIdToAdd"
              :resource="relationListResource"
              :query-options="addQueryOptions"
              :model-context="contextFilters"
              :disabled="!canAdd"
              :label="browseLabel"
              no-border
              filled
              rounded
              hide-details
              class="w-100"
            />

            <v-btn
              large
              icon
              color="success"
              class="add--button mx-1"
              elevation="0"
              :disabled="!entityIdToAdd || !canAdd"
              :loading="isAdding"
              @click="clickAdd"
            >
              <v-icon v-text="'mdi-plus-circle'" />
            </v-btn>
          </div>
        </div>
      </div>

      <v-divider />

      <WidgetFactory
        ref="widget-factory"
        class="h-100"
        :widget="widget"
        skip-validation
        :allow-reorder="allowReorder && isEditMode"
        @update:hook="hook = $event"
      />
    </ResourceAllowedOperations>
  </div>
</template>

<script lang="ts">
import Vue, { Component, PropType, VueConstructor } from 'vue'
import { CollectionQuery, ListWidgetModel } from '@/tt-widget-components/types'
import { ItemHookProvider } from '@/tt-widget-entity-flow/types'

import ListWidgetHook from '@/tt-widget-components/widgets/List/ListWidgetHook'
import { EntityIntentTypes } from '@/tt-widget-entity-flow/intents/types'
import { modularManager, PresetTypes } from '@/tt-app-modular'
import EntityRelationListEditorTile from './EntityRelationListEditorTile.vue'
import { updateDOM } from '@tracktik/tt-helpers/lib/browser/updateDOM'

const getPresetTile = (resource: string) =>
  modularManager.getResourcePreset(resource, PresetTypes.LIST_ITEM)

const handleError = (error: Error) => {
  console.error(error)
}

type Callback = (id: number) => Promise<void>

export default (Vue as VueConstructor<Vue & ItemHookProvider>).extend({
  name: 'EntityRelationListEditor',
  inject: ['getItemHook'],
  props: {
    resource: {
      type: String,
      required: true,
    },
    relationListAttribute: {
      type: String,
      required: true,
    },
    canAdd: {
      type: Boolean,
      required: true,
    },
    canDelete: {
      type: Boolean,
      required: true,
    },
    runAdd: {
      type: Function as PropType<Callback>,
      required: true,
    },
    runDelete: {
      type: Function as PropType<Callback>,
      required: true,
    },
    addQueryOptions: {
      type: Object as PropType<Partial<CollectionQuery>>,
      default: () => ({}),
    },
    tileComponent: {
      type: [Object, Function, String] as PropType<Component>,
      default: () => null,
    },
    allowReorder: {
      type: Boolean,
      default: false,
    },
    contextFilters: {
      type: Object as PropType<Record<string, boolean>>,
      default: () => ({
        accountAttribute: false,
        regionAttribute: false,
        dateRangeAttribute: false,
      }),
    },
  },
  data: () => ({
    hook: null as ListWidgetHook | null,
    entityIdToAdd: null,
    isAdding: false,
    editMode: false,
  }),
  computed: {
    isEditMode: {
      get(): boolean {
        return this.editMode
      },
      async set(value: boolean) {
        await updateDOM()
        this.editMode = value
      },
    },
    mainEntityId(): number {
      return this.getItemHook().getEntityId()
    },
    browseLabel(): string {
      return this.canAdd
        ? (this.$t('common.browse') as string)
        : (this.$t('tt-entity-forms.relation-fields.filter-list') as string)
    },
    listItemComponent(): Component {
      return (
        this.tileComponent ?? getPresetTile(this.relationListResource).data.is
      )
    },
    relationListResource(): string {
      return this.$appContext.widgetServices.resourceMetaManager.getAttribute(
        this.resource,
        this.relationListAttribute,
      ).relation.resource
    },
    widget(): ListWidgetModel {
      return {
        is: 'ListWidget',
        title: '',
        allowActions: true,
        disableResourcePreviewOnClick: true,
        listItem: {
          // @ts-ignore
          is: EntityRelationListEditorTile,
          props: {
            // we pass function instead of boolean to avoid rerendering the whole list when it changes
            // @ts-ignore
            showDeleteButton: () => this.isEditMode,
            // @ts-ignore
            canDelete: () => this.canDelete,
            // @ts-ignore
            runDelete: (id) => this.clickDelete(id),
            // @ts-ignore
            listItemComponent: this.listItemComponent,
          },
        },
        query: {
          resource: this.relationListResource,
          relationListResource: {
            resource: this.resource,
            id: this.mainEntityId,
            attribute: this.relationListAttribute,
          },
          contextFilters: this.contextFilters,
        },
        toolbar: {
          show: false,
        },
      }
    },
  },
  created() {
    this.getItemHook().needActions()
  },
  methods: {
    async clickAdd() {
      this.isAdding = true

      await this.runAdd(this.entityIdToAdd)
        .then(() => (this.entityIdToAdd = null))
        .catch(handleError)

      this.isAdding = false

      await this.afterUpdate()
    },
    async clickDelete(id: number) {
      await this.runDelete(id).catch(handleError)

      await this.afterUpdate()
    },
    async afterUpdate() {
      this.$eventManager.dispatchEvent(EntityIntentTypes.RESOURCE_UPDATED, {
        resource: this.resource,
      })

      await this.hook?.update({ disableCache: true })
    },
  },
})
</script>
