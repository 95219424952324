<template>
  <v-card
    v-if="jsonSchema"
    flat
    class="fill-height d-flex flex-column flex-nowrap dispatch-task-form--hidden-overflow"
  >
    <v-divider />

    <FormUpdatedBanner class="mt-2" v-bind="{ resourceName, entityId }" />

    <json-form
      v-model="model"
      class="dispatch-task-form--hidden-overflow fill-height"
      :schema="jsonSchema"
      :form-options="effectiveFormOptions"
      :name="rootName"
      :user-context="userContext"
      @errors="mainFormErrors = $event"
      @valid="mainFormErrors = {}"
      @debouncing="debouncing = $event"
    >
      <DispatchTasksFormFieldSetter class="fill-height">
        <StepsWizard :tabs="tabs">
          <template #[tabSlots.MAIN]="{ selectTab }">
            <DispatchTasksFormMainFields
              class="fill-height pa-4 dispatch-task-form--fields-wrapper"
              @back="back"
            />
            <DispatchTasksFormButton
              :disabled="isEditForm ? isSubmitDisabled : hasFormErrors"
              :debouncing="debouncing"
              :loading="loading"
              :errors="fromErrors"
              :tab-key="tabKeys.MAIN"
              @click:next="selectTab(tabKeys.REPORT)"
              @click:back="back"
              @click:submit="submitDispatchTaskForm"
            />
          </template>
          <template #[tabSlots.REPORT]>
            <DispatchTasksFormReportField
              class="fill-height pa-4 dispatch-task-form--fields-wrapper"
              @errors="hasReportErrors = $event"
            />
            <DispatchTasksFormButton
              :disabled="isSubmitDisabled"
              :debouncing="debouncing"
              :loading="loading"
              :errors="fromErrors"
              :tab-key="tabKeys.REPORT"
              @click:back="back"
              @click:submit="submitDispatchTaskForm"
            />
          </template>
        </StepsWizard>
      </DispatchTasksFormFieldSetter>
    </json-form>
  </v-card>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue'
import { ErrorObject } from 'ajv'
import {
  EmptyValueRule,
  FormOptions,
  JSONSchema7,
} from '@tracktik/tt-json-schema-form'
import { filterErrors } from '@/helpers/form-errors-manager'
import { LayoutWindowEvent } from '@/tt-app-layout'
import DispatchTasksFormMainFields from './DispatchTasksFormMainFields.vue'
import DispatchTasksFormReportField from './DispatchTasksFormReportField.vue'
import DispatchTasksFormFieldSetter from './DispatchTasksFormFieldSetter.vue'
import CallToActionSnackBarContent from '../lone-worker/CallToActionSnackBarContent.vue'
import FormUpdatedBanner from '@/tt-app-layout/components/FormUpdatedBanner.vue'
import DispatchTasksFormButton from './DispatchTasksFormButton.vue'
import StepsWizard from '@/tt-widget-views/components/StepsWizard.vue'
import { Resources } from '../../../types'
import { EntityIntentTypes } from '@/tt-widget-entity-flow'
import { DispatchTasksForm } from '../types'
import { dispatchTaskFormService } from './dispatch-task-form-service'
import {
  OverrideFrenquencyServiceType,
  overrideFrenquencyService,
} from '../lone-worker/override-lone-worker-frequency-service'
import {
  DispatchTaskFormServiceProvider,
  DispatchTaskFormServiceType,
} from './types'
import { StepsWizardTab } from '@/tt-widget-views/types'
import { TAB_KEYS } from './constants'

export default Vue.extend({
  name: 'DispatchTasksForm',
  components: {
    DispatchTasksFormMainFields,
    DispatchTasksFormReportField,
    DispatchTasksFormFieldSetter,
    FormUpdatedBanner,
    StepsWizard,
    DispatchTasksFormButton,
  },
  provide(): DispatchTaskFormServiceProvider {
    return {
      dispatchFormService: this.dispatchFormService,
    }
  },
  inject: {
    activeWindow: { default: null },
  },
  props: {
    /**
     * tt-json-schema-form's form options
     */
    formOptions: { type: Object as PropType<FormOptions>, default: () => ({}) },
    /**
     * Form's initial value
     */
    initialModel: {
      type: Object as PropType<Record<string, any>>,
      default: () => ({} as Record<string, any>),
    },
    /**
     * tt-json-schema-form's JSON Schema
     */
    jsonSchema: { type: Object as PropType<JSONSchema7>, required: true },
    /**
     * tt-json-schema-form's root field name
     */
    rootName: { type: String, default: undefined },
    /**
     * tt-json-schema-form's user context
     */
    userContext: {
      type: Object as PropType<Record<string, any>>,
      default: undefined,
    },
    /**
     * Submit handler
     */
    submit: {
      type: Function as PropType<(data: Record<string, any>) => Promise<any>>,
      required: true,
    },
  },
  data() {
    return {
      debouncing: false,
      loading: false,
      mainFormErrors: null as { [key: string]: ErrorObject[] } | null,
      model: {} as Record<string, any>,
      hasReportErrors: false,
    }
  },
  computed: {
    dispatchFormService(): DispatchTaskFormServiceType {
      return dispatchTaskFormService(this.$appContext.authModule)
    },
    effectiveFormOptions(): FormOptions {
      return {
        emptyValues: EmptyValueRule.KEEP,
        locale: this.$i18n.locale,
        ...this.formOptions,
      }
    },
    fromErrors(): Record<string, ErrorObject[]> {
      return filterErrors(this.dispatchFormService.getErrorsListWhiteListed(), {
        ...this.mainFormErrors,
      })
    },
    hasFormErrors(): boolean {
      return !!Object.keys(this.fromErrors || {}).length
    },
    isEditForm(): boolean {
      // We cannot edit the location and the report. This boolean allows us to hide these fields.
      return this.dispatchFormService.getIsEditForm()
    },
    entityId(): string {
      const entityId = this.userContext?.itemHook?.getEntityId()

      return entityId ? `${entityId}` : ''
    },
    resourceName(): string {
      return Resources.DISPATCH_TASKS
    },
    tabs(): StepsWizardTab[] {
      const mainTab = [
        {
          key: TAB_KEYS.MAIN,
          label: this.$t('tt-entity-design.dispatch-tasks.form-tabs.main'),
        },
      ]
      const reportTab = [
        {
          key: TAB_KEYS.REPORT,
          label: this.$t('tt-entity-design.dispatch-tasks.form-tabs.report'),
          disabled: () => this.hasFormErrors,
        },
      ]

      return this.isEditForm ? mainTab : [...mainTab, ...reportTab]
    },
    tabKeys(): typeof TAB_KEYS {
      return TAB_KEYS
    },
    tabSlots(): Record<keyof typeof TAB_KEYS, string> {
      const toSlotEntry = ([key, value]) => [key, `tab-${value}`]
      const entries = Object.entries(TAB_KEYS).map(toSlotEntry)

      return Object.fromEntries(entries)
    },
    isSubmitDisabled(): boolean {
      return (
        this.hasFormErrors ||
        this.debouncing ||
        this.loading ||
        this.hasReportErrors ||
        !this.dispatchFormService.getForceSave()
      )
    },
    overrideFrequencyService(): OverrideFrenquencyServiceType {
      return overrideFrenquencyService(this.$appContext)
    },
  },
  async created() {
    this.dispatchFormService.setIsEditForm(this.rootName as DispatchTasksForm)
    this.model = this.initializeFormModel()

    if (this.isEditForm && this.model.location) {
      await this.dispatchFormService.fetchDispatchCustomLocations(
        this.model.location,
      )
    }
  },
  beforeDestroy() {
    this.dispatchFormService.destroy()
  },
  methods: {
    initializeFormModel(): Record<string, any> {
      return this.dispatchFormService.initializeForm(this.initialModel)
    },
    back(): void {
      this.$emit('back')
    },
    onSubmitError(error): void {
      this.$eventManager.dispatchEvent(LayoutWindowEvent.SNACK_ERROR, {
        message: error,
      })
    },
    async onSubmitSuccess(
      dispatchTaskId: number,
      assignedUser: number,
    ): Promise<void> {
      const overrideFrequencyAvalaible =
        await this.overrideFrequencyService.isAvailableAction(assignedUser)

      const snackBarMessage = this.isEditForm
        ? {
            message: this.$t('common.operation_successful'),
          }
        : {
            message: this.$t('common.entity_created_successful'),
          }

      const snackBarPayload = overrideFrequencyAvalaible
        ? {
            ...snackBarMessage,
            is: CallToActionSnackBarContent,
            props: {
              employeeId: assignedUser,
              dispatchTaskId,
            },
          }
        : {
            ...snackBarMessage,
          }

      this.$eventManager.dispatchEvent(LayoutWindowEvent.SNACK_SUCCESS, {
        ...snackBarPayload,
      })
      this.$eventManager.dispatchEvent(EntityIntentTypes.RESOURCE_UPDATED, {
        resource: Resources.DISPATCH_TASKS,
      })
      this.back()
    },
    async submitDispatchTaskForm(): Promise<void> {
      if (this.fromErrors) {
        return
      }
      this.loading = true

      this.submit(this.dispatchFormService.sanitizeFormModel(this.model))
        .then(async ({ id, assignedUser }) => {
          await this.onSubmitSuccess(id, assignedUser)
        })
        .catch((error) => {
          this.$crash.captureException(error)
          this.onSubmitError(error)
        })
        .finally(() => {
          this.loading = false
        })
    },
  },
})
</script>
<style scoped>
.dispatch-task-form--hidden-overflow {
  overflow: hidden;
}
.dispatch-task-form--fields-wrapper {
  flex-grow: 1;
  height: calc(100% - 64px);
  overflow-y: auto;
  overflow-x: hidden;
}
</style>
