// ******** This file is autogenerated DO NOT MODIFY **********

import { modularManager, PresetTypes, PresetLevels } from '@/tt-app-modular'
import { Resources } from '../../types'
import custom from './custom'
import RoleManagedPermissionPoliciesTile from './RoleManagedPermissionPoliciesTile.vue'
import RoleManagedPermissionPoliciesColumns from './RoleManagedPermissionPoliciesColumns'
import RoleManagedPermissionPoliciesDetail from './RoleManagedPermissionPoliciesDetail.vue'
import RoleManagedPermissionPoliciesMetaCard from './RoleManagedPermissionPoliciesMetaCard.vue'
import RoleManagedPermissionPoliciesReference from './RoleManagedPermissionPoliciesReference.vue'

export default {
  install(Vue) {
    Vue.use(custom)
    Vue.component(
      'RoleManagedPermissionPoliciesTile',
      RoleManagedPermissionPoliciesTile,
    )
    Vue.component(
      'RoleManagedPermissionPoliciesDetail',
      RoleManagedPermissionPoliciesDetail,
    )
    Vue.component(
      'RoleManagedPermissionPoliciesMetaCard',
      RoleManagedPermissionPoliciesMetaCard,
    )
    Vue.component(
      'RoleManagedPermissionPoliciesReference',
      RoleManagedPermissionPoliciesReference,
    )

    modularManager.addResourcePreset(
      Resources.ROLE_MANAGED_PERMISSION_POLICIES,
      PresetTypes.LIST_ITEM,
      {
        title: 'Default',
        data: { is: 'RoleManagedPermissionPoliciesTile' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.ROLE_MANAGED_PERMISSION_POLICIES,
      PresetTypes.COLUMNS,
      {
        title: 'Default',
        data: RoleManagedPermissionPoliciesColumns,
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.ROLE_MANAGED_PERMISSION_POLICIES,
      PresetTypes.DETAILS_CARD,
      {
        title: 'Default',
        data: { is: 'RoleManagedPermissionPoliciesDetail' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.ROLE_MANAGED_PERMISSION_POLICIES,
      PresetTypes.META_CARD,
      {
        title: 'Default',
        data: { is: 'RoleManagedPermissionPoliciesMetaCard' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.ROLE_MANAGED_PERMISSION_POLICIES,
      PresetTypes.RELATION_REFERENCE,
      {
        title: 'Default',
        data: { is: 'RoleManagedPermissionPoliciesReference' },
        level: PresetLevels.DEFAULTS,
      },
    )
  },
}
