<template>
  <v-app id="injected-leave-management-view">
    <v-main>
      <ProvideLayoutManager :layout-manager="manager">
        <BoundingBox v-slot="{ top }" inherit-parent-height>
          <app-window :closable="false">
            <template #before>
              <v-icon small> arrow_back </v-icon>
            </template>
            <template #title="{ title }">
              <v-toolbar-title class="full-page-title pl-2">
                {{ title }}
              </v-toolbar-title>
            </template>
            <LeaveRequestsView
              :allow-actions="allowActions"
              :initial-tab="initialTab"
              :is-premium="isPremium"
              :display-absent-tab="displayAbsentTab"
              :start-date-time="startDateTime"
              :end-date-time="endDateTime"
              :origin="origin"
            />
          </app-window>
          <AppLayoutUtils :top="top" />
        </BoundingBox>
      </ProvideLayoutManager>
    </v-main>
  </v-app>
</template>
<script lang="ts">
import LayoutManager from '@/tt-app-layout/LayoutManager'
import ProvideLayoutManager from '@/tt-app-layout/components/ProvideLayoutManager.vue'
import LeaveRequestsView from './leave-requests/LeaveRequestsView.vue'
import Vue, { PropType } from 'vue'
import { LayoutConfiguration } from '@/tt-app-layout'
import { LeaveRequestInjectedOrigin, LeaveRequestProvider } from './types'
import BoundingBox from '@/tt-widget-views/components/BoundingBox.vue'
import { AllowEntityOperationsProvider } from '@/types'
import { Resources } from '@/tt-entity-design/src/types'

export default Vue.extend({
  name: 'LeaveRequestsInjected',
  components: {
    ProvideLayoutManager,
    LeaveRequestsView,
    BoundingBox,
  },
  provide(): LeaveRequestProvider | AllowEntityOperationsProvider {
    return {
      isLeaveRequestPremium: this.isPremium,
      manageLRinPayroll: this.manageLRinPayroll,
      allowEntityActions: [Resources.LEAVE_REQUESTS],
    }
  },
  props: {
    allowActions: {
      type: Boolean,
      default: true,
    },
    initialTab: {
      type: String,
      default: '',
    },
    manageLRinPayroll: {
      type: Boolean,
      default: false,
    },
    displayAbsentTab: {
      type: Boolean,
      default: false,
    },
    layoutConfiguration: {
      type: Object as PropType<LayoutConfiguration>,
      default: () => ({}),
    },
    isPremium: {
      type: Boolean,
      default: false,
    },
    startDateTime: {
      type: String,
      default: () => '',
    },
    endDateTime: {
      type: String,
      default: () => '',
    },
    /* Product Decision to keep track of the origin of the Leave Request
     * Based on the place this view is used we are going to see different filters:
     * - Payroll -> Filter by Period (currently it has no support for presets)
     * - Dashboard -> Filter by Start Date (keep current production behavior, with support for presets)
     *
     * More info: https://tracktik.atlassian.net/browse/BOSS-4090?focusedCommentId=381434
     */
    origin: {
      type: String as PropType<LeaveRequestInjectedOrigin>,
      default: '',
    },
  },
  data() {
    return {
      manager: new LayoutManager(this.$eventManager, this.layoutConfiguration),
    }
  },
  beforeDestroy() {
    if (this.manager) {
      this.manager.destroy()
    }
  },
})
</script>
