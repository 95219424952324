<template>
  <div>
    <json-object v-if="!onlyExceptions" name="days">
      <SchedulingGroupDaySetupDayRow
        day-name="WEEKDAYS"
        :children="weekDays"
        :should-allow-enter-value="true"
        :should-allow-enter-value-section="
          shouldAllowEnterValueSection('WEEKDAYS')
        "
        parent-name="days"
      />
      <SchedulingGroupDaySetupDayRow
        day-name="WEEKENDS"
        :children="weekEnds"
        :should-allow-enter-value="true"
        :should-allow-enter-value-section="
          shouldAllowEnterValueSection('WEEKENDS')
        "
        parent-name="days"
      />
    </json-object>
    <json-object v-if="showExceptions" name="holidays">
      <SchedulingGroupDaySetupDayRow
        v-for="exception in effectiveHolidays"
        :key="holidayKey(exception.id)"
        :day-name="holidayKey(exception.id)"
        :children="childrenNames(exception.children)"
        :should-allow-enter-value="true"
        :should-allow-enter-value-section="
          shouldAllowEnterValueSection(holidayKey(exception.id))
        "
        :validation-message="getValidationMessage(holidayKey(exception.id))"
        :enable-perform-on-exception-day="enableHolidayExceptionOnly"
        parent-name="holidays"
      />
    </json-object>
    <json-object v-if="shouldDisplayOthers" name="others">
      <SchedulingGroupDaySetupDayRow
        v-for="exception in others"
        :key="othersKey(exception.id)"
        :day-name="othersKey(exception.id)"
        :children="othersChildrenNames(exception.children)"
        :should-allow-enter-value="
          shouldAllowEnterValue(othersKey(exception.id))
        "
        :should-allow-enter-value-section="
          shouldAllowEnterValueSection(othersKey(exception.id))
        "
        :validation-message="getValidationMessage(othersKey(exception.id))"
        :enable-perform-on-exception-day="enableHolidayExceptionOnly"
        is-others
        parent-name="others"
      />
    </json-object>
    <json-object v-if="shouldDisplayFlagDays" name="flagDays">
      <SchedulingGroupDaySetupDayRow
        v-for="exception in flagDays"
        :key="flagDayKey(exception.id)"
        :day-name="flagDayKey(exception.id)"
        :children="flagDaysChildrenNames(exception.children)"
        :should-allow-enter-value="true"
        :should-allow-enter-value-section="
          shouldAllowEnterValueSection(flagDayKey(exception.id))
        "
        is-flag-day
        parent-name="flagDays"
      />
    </json-object>
    <json-object v-if="showClosed" name="closed">
      <SchedulingGroupDaySetupDayRow
        day-name="closed"
        :enable-perform-on-exception-day="enableHolidayExceptionOnly"
        parent-name="closed"
      />
    </json-object>
  </div>
</template>
<script lang="ts">
import isEmpty from 'lodash/isEmpty'
import Vue, { PropType, VueConstructor } from 'vue'

import { Resources } from '@/tt-entity-design/src/types'

import {
  ExceptionType,
  PerformType,
  SchedulingEntityResource,
  weekDays,
  weekEnds,
} from '../types'
import SchemaHelper from '../helpers/schema-helper'

import { FormHookProvider } from '@/tt-widget-components'

import SchedulingGroupDaySetupDayRow from './SchedulingGroupDaySetupDayRow.vue'
import { rootExceptionTypeId } from '@/tt-entity-design/src/components/exception-types/exception-types-parent'
import {
  getHolidayKey,
  getOthersKey,
  parseExceptionKey,
} from '../helpers/schema-helper'

type VueWithInjections = Vue & FormHookProvider

export default (Vue as VueConstructor<VueWithInjections>).extend({
  name: 'SchedulingGroupDaySetupForm',
  components: {
    SchedulingGroupDaySetupDayRow,
  },
  inject: ['formHook', 'namespace'],
  props: {
    holidays: { type: Array as PropType<ExceptionType[]>, default: () => [] },
    others: {
      type: Array as PropType<ExceptionType[]>,
      default: () => [],
    },
    flagDays: {
      type: Array as PropType<ExceptionType[]>,
      default: () => [],
    },
    exceptionEnabled: {
      type: Boolean,
      default: false,
    },
    showClosed: {
      type: Boolean,
      default: false,
    },
    onlyExceptions: {
      type: Boolean,
      default: false,
    },
    othersTypeForRunsheetEventsEnabled: {
      type: Boolean,
      default: false,
    },
    othersTypeForRunsheetsEnabled: {
      type: Boolean,
      default: false,
    },
    othersTypeForSiteTasksEnabled: {
      type: Boolean,
      default: false,
    },
    resourceName: {
      type: String as PropType<SchedulingEntityResource>,
      required: true,
    },
    taskType: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      isFlagDayJobType: false,
    }
  },
  computed: {
    enableHolidayExceptionOnly(): boolean {
      if (this.resourceName === Resources.SITE_TASK_SCHEDULE_TIMINGS) {
        return false
      }

      return true
    },

    // Filter the closed days for exceptions only, as only the parent option is shown
    effectiveHolidays(): ExceptionType[] {
      if (this.resourceName !== Resources.SITE_TASK_SCHEDULE_TIMINGS) {
        return this.holidays
      }

      return this.holidays.filter(
        (holiday) => holiday.id !== rootExceptionTypeId.Closed,
      )
    },
    hasExceptions(): boolean {
      return !isEmpty(this.holidays)
    },
    schemaHelper(): SchemaHelper {
      return new SchemaHelper(this.resourceName)
    },
    showExceptions(): boolean {
      return (
        this.hasExceptions &&
        (this.exceptionEnabled ||
          this.resourceName === Resources.MOBILE_RUNSHEETS)
      )
    },
    weekDays(): string[] {
      return [...weekDays]
    },
    weekEnds(): string[] {
      return [...weekEnds]
    },
    flagDayId(): number {
      return rootExceptionTypeId.FlagDay
    },
    hasAnyHoliday(): boolean {
      const existsHolidays = !isEmpty(this.holidays)

      return existsHolidays
    },
    shouldDisplayOthers(): boolean {
      if (!this.hasAnyHoliday) {
        return false
      }

      if (this.resourceName === Resources.MOBILE_RUNSHEETS) {
        return this.othersTypeForRunsheetsEnabled
      }

      if (this.resourceName === Resources.MOBILE_SCHEDULES) {
        return this.othersTypeForRunsheetEventsEnabled
      }

      if (this.resourceName === Resources.SITE_TASK_SCHEDULE_TIMINGS) {
        return this.othersTypeForSiteTasksEnabled
      }

      return false
    },
    shouldDisplayFlagDays(): boolean {
      //TODO: Add Logic for Site Task,RunsheetEvent etc
      return this.isFlagDayJobType && this.flagDays.length > 0
    },
    holidaysFormValues(): ExceptionType {
      return this.formHook().getPathValue('holidays') || {}
    },
    othersFormValues(): ExceptionType {
      return this.formHook().getPathValue('others') || {}
    },
    daysFormValues(): ExceptionType {
      return this.formHook().getPathValue('days') || {}
    },
    flagDaysFormValues(): ExceptionType {
      return this.formHook().getPathValue('flagDays') || {}
    },
    flagDayChildren(): number[] {
      return this.flagDays[0]?.children?.map((child) => child.id) || []
    },
    isAnyFlagDaySelected(): boolean {
      const isFlagDaySelected =
        !!this.flagDaysFormValues[this.flagDayKey(rootExceptionTypeId.FlagDay)]
      const isFlagDayChildSelected = this.flagDayChildren.some(
        (childId) => !!this.flagDaysFormValues[this.flagDayKey(childId)],
      )

      return isFlagDaySelected || isFlagDayChildSelected
    },
    isAnyOtherHolidaySelected(): boolean {
      return this.holidays.some((holiday) => {
        if (holiday.id === rootExceptionTypeId.FlagDay) return false
        if (this.holidaysFormValues[getHolidayKey(holiday.id)]) return true

        return holiday.children?.some(
          (child) => this.holidaysFormValues[getHolidayKey(child.id)],
        )
      })
    },
    isAnyOtherSelected(): boolean {
      const others = this.others?.find((other) => other.id === 0)
      const othersChildren = others?.children?.map((child) => child.id) || []

      return (
        othersChildren.some(
          (childId) => this.othersFormValues[getOthersKey(childId)],
        ) || !!this.othersFormValues[getOthersKey(others?.id)]
      )
    },
    isDaySelected(): boolean {
      return !isEmpty(this.daysFormValues)
    },
  },
  async created() {
    if (!this.taskType) {
      this.isFlagDayJobType = false

      return
    }
    const taskTypeDetails: { isFlagDay: boolean } = await this.$auth
      .getApi()
      .get(Resources.TASK_TYPES, this.taskType)
    this.isFlagDayJobType = taskTypeDetails.isFlagDay
  },

  methods: {
    holidayKey(id: number): string {
      return this.schemaHelper.getHolidayKey(id)
    },
    flagDayKey(id: number): string {
      return this.schemaHelper.getFlagDaysKey(id)
    },

    othersKey(id: number): string {
      return this.schemaHelper.getOthersKey(id)
    },
    childrenNames(children: ExceptionType[]): string[] {
      return children?.map((child) => this.holidayKey(child.id)) || []
    },
    flagDaysChildrenNames(children: ExceptionType[]): string[] {
      return children?.map((child) => this.flagDayKey(child.id)) || []
    },
    othersChildrenNames(children: ExceptionType[]): string[] {
      return children?.map((child) => this.othersKey(child.id)) || []
    },
    shouldAllowEnterValue(dayName: string): boolean {
      if (dayName === 'WEEKDAYS' || dayName === 'WEEKENDS') {
        return true
      }

      if (dayName.startsWith('holiday-')) {
        return true
      }

      const parsedOthers = parseExceptionKey(dayName)

      if (parsedOthers === rootExceptionTypeId.WeekdayBeforeEve) {
        return this.isHolidayTypeSet(rootExceptionTypeId.HolidayEve)
      } else if (parsedOthers === rootExceptionTypeId.WeekdayBeforeHoliday) {
        return this.isHolidayTypeSet(rootExceptionTypeId.Holiday)
      } else if (parsedOthers === rootExceptionTypeId.HolidayBeforeWeekDay) {
        return this.isHolidayTypeSet(rootExceptionTypeId.Holiday)
      } else if (parsedOthers === 0) {
        const hasBeenSetAnyHoliday = this.isHolidayTypeSet(
          rootExceptionTypeId.Holiday,
        )
        const hasBeenSetAnyHolidayEve = this.isHolidayTypeSet(
          rootExceptionTypeId.HolidayEve,
        )

        return hasBeenSetAnyHoliday || hasBeenSetAnyHolidayEve
      } else {
        return true
      }
    },

    isHolidayTypeSet(id: number): boolean {
      const formValues = this.holidaysFormValues
      const parent = formValues[getHolidayKey(id)]

      if (parent?.performType === PerformType.DONT_PERFORM_TASK) {
        return true
      }
      if (parent && parent.rangeStartTime && parent.rangeEndTime) {
        return true
      }

      const parentData = this.holidays.find((h) => h.id === id)
      if (!parentData || !parentData.children) {
        return false
      }

      return parentData.children.some((child) => {
        const childData = formValues[getHolidayKey(child.id)]
        if (childData?.performType === PerformType.DONT_PERFORM_TASK) {
          return true
        }

        return childData && childData.rangeStartTime && childData.rangeEndTime
      })
    },

    shouldAllowEnterValueSection(dayName: string): boolean {
      if (dayName === 'WEEKDAYS' || dayName === 'WEEKENDS') {
        if (this.isAnyFlagDaySelected) {
          return false
        }
      }

      const isFlagDay = dayName.startsWith('flagDay-')

      if (this.isAnyFlagDaySelected) {
        return isFlagDay
      }

      if (
        this.isAnyOtherHolidaySelected ||
        this.isAnyOtherSelected ||
        this.isDaySelected
      ) {
        return !isFlagDay
      }

      return true
    },

    getValidationMessage(dayName: string): string {
      const parsedOthers = parseExceptionKey(dayName)

      if (parsedOthers === rootExceptionTypeId.WeekdayBeforeEve) {
        return this.$t(
          'scheduling_group.others.weekday_before_eve.validation.label',
        ) as string
      } else if (parsedOthers === rootExceptionTypeId.WeekdayBeforeHoliday) {
        return this.$t(
          'scheduling_group.others.weekday_before_holiday.validation.label',
        ) as string
      } else if (parsedOthers === rootExceptionTypeId.HolidayBeforeWeekDay) {
        return this.$t(
          'scheduling_group.others.holiday_before_weekday.validation.label',
        ) as string
      } else {
        return this.$t('scheduling_group.others.validation.label') as string
      }
    },
  },
})
</script>
