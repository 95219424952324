<template>
  <div v-if="loading">
    <v-skeleton-loader type="table-cell" />
  </div>
  <div v-else>
    <v-select
      v-if="canShowAssociatedFeature"
      v-model="selectedFeatures"
      v-bind="{
        ...vuetifyDefaults,
      }"
      multiple
      :items="items"
      :placeholder="label"
      :label="label"
    />
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue'
import { VueWithFormHookInjection } from '@/helpers/components/types'
import { vuetifyDefaults } from '@tracktik/tt-json-schema-form'
import { ReportTemplateFeatures, ReportTemplateType } from './types'
import {
  getFeatureAssociationsForAccount,
  getFeatureAssociationsForReportTemplate,
} from '@/tt-entity-design/src/components/report-templates/helpers'
import { Attribute } from '@/tt-entity-design/src/schema-types'

export interface FeatureSelectItem {
  text: string
  value: Feature
}

export interface Feature {
  feature: string
}

export default (Vue as VueWithFormHookInjection).extend({
  name: 'ReportTemplateFeatureAssociationField',
  inject: ['formHook'],
  props: {
    name: {
      type: String,
      required: true,
    },
    value: {
      type: Array as PropType<Feature[]>,
      default: null,
    },
    allowFeatureDisassociation: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      vuetifyDefaults,
      loading: true,
      disabledFeatures: [],
      features: {} as Record<ReportTemplateFeatures, string>,
    }
  },
  computed: {
    label(): string {
      return this.$t(
        'tt-entity-design.report-templates.form.feature-association.placeholder',
      )
    },
    isEditing(): boolean {
      return Boolean(this.formHook().getPathValue('id'))
    },
    reportTemplateId(): number {
      return this.formHook().getPathValue('id')
    },
    accountId(): number | null {
      return this.formHook().getPathValue('assignedSite')
    },
    reportType(): string {
      return this.formHook().getPathValue('type')
    },
    associatedFeaturesName(): Attribute<'report-templates'> {
      return 'associatedFeatures'
    },
    canShowAssociatedFeature(): boolean {
      return (
        this.accountId &&
        this.reportType !== ReportTemplateType.DISPATCHABLE_TASK
      )
    },
    selectedFeatures: {
      get(): Feature[] {
        return this.value
      },
      set(features: Feature[]) {
        this.formHook().setObjectValue(this.name, features)
      },
    },
    items(): FeatureSelectItem[] {
      return Object.entries(this.features).map(([feature, text]) => {
        return {
          text,
          value: { feature },
          disabled: this.isFeatureDisabled(feature),
        }
      }) as FeatureSelectItem[]
    },
  },
  watch: {
    reportType: {
      immediate: true,
      handler(type: string) {
        if (type === ReportTemplateType.DISPATCHABLE_TASK) {
          this.formHook().setObjectValue(this.associatedFeaturesName, [])
        }
      },
    },
  },
  async created() {
    await this.loadFeatureAssociation()
    if (this.isEditing) {
      await this.loadSelectedFeatureAssociation()
    } else {
      this.loadingDone()
    }
  },
  methods: {
    async loadFeatureAssociation() {
      try {
        const response = await getFeatureAssociationsForAccount(
          this.$auth,
          this.accountId as number,
        )
        this.features = response.reportTemplateFeatures.features
      } catch (err) {
        this.$crash.captureException(err)
      }
    },
    async loadSelectedFeatureAssociation() {
      try {
        const response = await getFeatureAssociationsForReportTemplate(
          this.$auth,
          this.reportTemplateId,
        )
        const features = response.associatedFeatures.map(
          (associatedFeature) => {
            this.disabledFeatures = [
              ...this.disabledFeatures,
              associatedFeature.feature,
            ]

            return { feature: associatedFeature.feature }
          },
        )
        this.selectedFeatures = features
      } catch (err) {
        this.$crash.captureException(err)
      } finally {
        this.loadingDone()
      }
    },
    isFeatureDisabled(feature: string): boolean {
      return (
        !this.allowFeatureDisassociation &&
        this.disabledFeatures.includes(feature)
      )
    },
    loadingDone() {
      this.loading = false
    },
  },
})
</script>
<style>
/* Making the disabled options a bit transparent to distinct between enabled and disabled options */
.v-list-item--disabled {
  opacity: 0.5;
}
</style>
