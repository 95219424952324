import { ColumnInputDefinition } from '@/tt-widget-components/widgets/DataTable/DataTableWidgetHook'
import {
  EmployeeCustomComponenets,
  LeaveRequestStatusColors,
} from '../../../types'

const baseTranslation = 'tt-entity-design.leave-management.employee'

export const HumanCapitalManagementEmployeeLeaveHistoryColumns: ColumnInputDefinition[] =
  [
    {
      attributeName: 'createdOn',
      headerText: 'res.leave-requests.attr.createdOn.label',
      format: 'asDate',
    },
    {
      attributeName: 'leaveType',
      headerText: 'res.leave-requests.attr.leaveType.label',
    },
    {
      attributeName: 'startDateTime',
      headerText: 'res.leave-requests.attr.startDateTime.label',
      format: 'asDate',
      component: {
        is: 'LeaveRequestsDateTimeIconColumn',
        props: {
          dateName: 'startDateTime',
          tooltipText:
            'tt-entity-design.leave-management.leave-requests.start-time',
        },
      },
    },
    {
      attributeName: 'endDateTime',
      headerText: 'res.leave-requests.attr.endDateTime.label',
      format: 'asDate',
    },
    {
      attributeName: 'requestedDays',
      headerText: `${baseTranslation}.leave-history.amount`,
      component: {
        is: EmployeeCustomComponenets.LeaveHistoryAmountColumn,
      },
      style: {
        width: 125,
      },
    },
    {
      headerText: 'res.leave-requests.extensions.affectedShifts.labels.label',
      component: {
        is: 'LeaveRequestsAffectedShiftsCounter',
      },
    },
    {
      attributeName: 'status',
      headerText: 'res.leave-requests.attr.status.label',
      component: {
        is: 'StatusChip',
        props: { statusColorOptions: LeaveRequestStatusColors },
      },
    },
  ]

export default HumanCapitalManagementEmployeeLeaveHistoryColumns
