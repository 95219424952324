import {
  Action,
  Attribute,
  Resource,
} from '@/tt-entity-design/src/schema-types'
import { Resources } from '@/tt-entity-design/src/types'

export const DISPATCH_TASKS: Resource = Resources.DISPATCH_TASKS
export const COMMENTS: Resource = Resources.COMMENTS

export const NOTES: Attribute<typeof DISPATCH_TASKS> = 'notes'
export const ADD_NOTE: Action<typeof DISPATCH_TASKS> = 'add-note'

export const CREATED_ON: Attribute<typeof DISPATCH_TASKS> = 'createdOn'
