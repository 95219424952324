<template>
  <div class="fill-height">
    <WidgetFactory v-if="isReady" :widget="cancellationsLeaveRequestsWidget" />
  </div>
</template>
<script lang="ts">
import { Resources } from '@/tt-entity-design/src/types'
import {
  AttributeFilter,
  CustomFilter,
  DataTableWidgetModel,
  Filter,
} from '@/tt-widget-components/types'
import { LeaveRequestsCancellationsColumns } from './LeaveRequestsCancellationsColumns'
import { FilterOperatorType } from '@/tt-widget-factory'
import Vue, { PropType } from 'vue'
import { LeaveRequestInjectedOrigin, LeaveRequestsStatus } from '../../types'
import { ColumnInputDefinition } from '@/tt-widget-components/widgets/DataTable/DataTableWidgetHook'
import { insertColumnsIntoArray } from '../../utils'
import {
  createLeaveRequestFilterByPeriod,
  isInjectedInPayroll,
} from '../helpers'

export default Vue.extend({
  name: 'LeaveRequestsCancellationsTab',
  props: {
    allowActions: {
      type: Boolean,
      default: true,
    },
    isPremium: {
      type: Boolean,
      required: true,
    },
    startDateTime: {
      type: String,
      default: () => '',
    },
    endDateTime: {
      type: String,
      default: () => '',
    },
    /* Product Decision to keep track of the origin of the Leave Request
     * Based on the place this view is used we are going to see different filters:
     * - Payroll -> Filter by Period (currently it has no support for presets)
     * - Dashboard -> Filter by Start Date (keep current production behavior, with support for presets)
     *
     * More info: https://tracktik.atlassian.net/browse/BOSS-4090?focusedCommentId=381434
     */
    origin: {
      type: String as PropType<LeaveRequestInjectedOrigin>,
      default: '',
    },
  },
  computed: {
    byPeriodFilter(): string | CustomFilter {
      if (this.startDateTime && this.endDateTime) {
        return createLeaveRequestFilterByPeriod(this.startDateTime, this.endDateTime)
      }

      return 'byPeriod'
    },
    isInPayroll(): boolean {
      return isInjectedInPayroll(this.origin)
    },
    startDateTimeFilter(): AttributeFilter {
      return { attributeName: 'startDateTime' }
    },
    isReady(): boolean {
      return this.$appContext.isReady()
    },
    cancellationsLeaveRequestsWidget(): DataTableWidgetModel {
      const statusFilter: Filter = {
        attribute: 'status',
        operator: FilterOperatorType.EQUAL,
        value: LeaveRequestsStatus.PENDING_CANCELLATION,
      }

      return {
        is: 'DataTableWidget',
        title: '',
        allowActions: this.allowActions,
        query: {
          resource: Resources.LEAVE_REQUESTS,
          filters: [statusFilter],
        },
        component: {
          columns: this.isPremium
            ? insertColumnsIntoArray(
                LeaveRequestsCancellationsColumns,
                LeaveRequestsCancellationsColumns.length,
                this.serviceLevelImpactColumn,
              )
            : LeaveRequestsCancellationsColumns,
        },
        toolbar: {
          displayCounts: true,
          filterOptions: {
            filters: !this.isInPayroll ? [this.startDateTimeFilter] : [],
            customFilters: this.isInPayroll ? [this.byPeriodFilter] : [],
            allowSearch: true,
          },
        },
      }
    },
    serviceLevelImpactColumn(): ColumnInputDefinition[] {
      return [
        {
          headerText: 'tt-entity-design.leave-management.leave-requests.sli',
          attributeName: 'serviceLevelImpact',
          component: {
            is: 'ServiceLevelImpactPercentage',
          },
        },
      ]
    },
  },
})
</script>
