import {
  BackOfficeReportsTab,
  ReportingAndAnalyticsViews,
  PositionPremiumTypes,
} from './types'
import { Resources } from '@/tt-entity-design/src/types'
import {
  EmployeeAccountAssignmentPayRateColumns,
  EmployeePayRateColumns,
  EmployeePremiumAssignmentColumns,
  PositionsBillRateColumns,
  PositionsEarningsPayPremiumColumns,
  PositionsPayRateColumns,
  SchedulingReportColumns,
  PriceTiersBillSettingsColumns,
  EmployeeAccountAssignmentPayRateDownloadableColumns,
  SchedulingReportDownloadableColumns,
  PositionsEarningsPayPremiumDownloadableColumns,
  EmployeePremiumAssignmentDownloadableColumns,
} from './columns'
import { FilterOperatorType } from 'tracktik-sdk/lib/common/entity-filters'
import { DatePresetValue } from '@/tt-entity-filter/temporal-filters/date-presets'
import { SortDirectionType } from 'tracktik-sdk/lib/common/entity-collection'
import {
  HumanCapitalManagementConflictsColumns,
  HumanCapitalManagementConflictsDownloadableColumns,
} from './columns/human-capital-management/HumanCapitalManagementConflictsColumns'
import {
  HumanCapitalManagementCertificationsColumns,
  HumanCapitalManagementCertificationsDownloadableColumns,
} from './columns/human-capital-management/HumanCapitalManagementCertificationsColumns'
import { LeaveRequestsStatus } from '../leave-management/types'
import {
  CompletedRequestsColumns,
  CompletedRequestsDownloadableColumns,
} from './columns/leave-requests/CompletedRequestsColumns'

export const EFFECTIVE_DATE_TABS: BackOfficeReportsTab[] = [
  {
    query: {
      resource: Resources.EMPLOYEE_ACCOUNT_ASSIGNMENT_PAY_SETTINGS,
    },
    key: ReportingAndAnalyticsViews.EMPLOYEE_ACCOUNT_ASSIGNMENT_PAY_RATE,
    title: 'back-office-reports.employee-account-assignment-pay-rate',
    columns: EmployeeAccountAssignmentPayRateColumns,
    downloadColumns: EmployeeAccountAssignmentPayRateDownloadableColumns,
    filters: [
      {
        attributeName: 'effectiveDate',
      },
    ],
  },
  {
    query: { resource: Resources.POSITION_PAY_SETTINGS },
    key: ReportingAndAnalyticsViews.POSITIONS_PAY_RATE,
    title: 'back-office-reports.positions-pay-rate',
    columns: PositionsPayRateColumns,
    filters: [
      {
        attributeName: 'effectiveDate',
      },
    ],
  },
  {
    query: { resource: Resources.POSITION_BILL_SETTINGS },
    key: ReportingAndAnalyticsViews.POSITIONS_BILL_RATE,
    title: 'back-office-reports.positions-bill-rate',
    columns: PositionsBillRateColumns,
    filters: [
      {
        attributeName: 'effectiveDate',
      },
    ],
  },
  {
    query: { resource: Resources.EMPLOYEE_PAY_SETTINGS },
    key: ReportingAndAnalyticsViews.EMPLOYEE_PAY_RATE,
    title: 'back-office-reports.employee-pay-rate',
    columns: EmployeePayRateColumns,
    filters: [
      {
        attributeName: 'effectiveDate',
      },
      {
        attributeName: 'hourlyRateType',
      },
    ],
  },
  {
    query: { resource: Resources.TASK_PRICE_TIER_BILL_SETTINGS },
    key: ReportingAndAnalyticsViews.PRICE_TIERS_BILL_SETTINGS,
    title: 'back-office-reports.effective-date.price-tiers.title',
    columns: PriceTiersBillSettingsColumns,
    filters: [
      {
        attributeName: 'effectiveDate',
      },
      { attributeName: 'taskPriceTier.serviceModel' },
      { attributeName: 'chargeType' },
    ],
  },
]

export const SCHEDULING_TABS: BackOfficeReportsTab[] = [
  {
    query: { resource: Resources.SHIFTS },
    key: ReportingAndAnalyticsViews.SCHEDULING_REPORT,
    title: 'back-office-reports.scheduling.scheduling-report.title',
    columns: SchedulingReportColumns,
    downloadColumns: SchedulingReportDownloadableColumns,
    filters: [
      {
        attributeName: 'startsOn',
        defaultValue: {
          operator: FilterOperatorType.EQUAL,
          value: DatePresetValue.Today,
        },
      },
      { attributeName: 'position.account.region' },
      { attributeName: 'position.account' },
      { attributeName: 'position' },
      { attributeName: 'employee' },
      {
        attributeName: 'attendanceStatus',
      },
      {
        attributeName: 'vacant',
      },
      {
        attributeName: 'onShiftBoard',
      },
      {
        attributeName: 'locked',
      },
    ],
    propsData: {
      onRecordClick: null,
    },
  },
  // --------------------------------------------------------
  // TODO: Uncomment when the BOSS-3186 is done.
  // --------------------------------------------------------
  // {
  //   query: { resource: Resources.AUDIT_LOGS },
  //   key: ReportingAndAnalyticsViews.SCHEDULE_CHANGES,
  //   title: 'back-office-reports.scheduling.schedule-changes.title',
  //   columns: ScheduleChangesColumns,
  //   downloadColumns: backOfficeScheduleChangesColumnsColumns,
  //
  // },
]

export const EARNINGS_PREMIUM_TABS: BackOfficeReportsTab[] = [
  {
    query: {
      resource: Resources.POSITION_PREMIUM_ASSIGNMENTS,
      filters: [
        {
          attribute: 'premium.type',
          operator: FilterOperatorType.IN,
          value: [
            PositionPremiumTypes.BILL_ON_TOP,
            PositionPremiumTypes.PAY_ON_TOP,
          ],
        },
      ],
    },
    key: ReportingAndAnalyticsViews.POSITIONS_EARNINGS_PREMIUM,
    title: 'back-office-reports.earnings-premium.positions.title',
    columns: PositionsEarningsPayPremiumColumns,
    downloadColumns: PositionsEarningsPayPremiumDownloadableColumns,
    filters: [
      { attributeName: 'premium.createdOn' },
      {
        attributeName: 'premium.type',
        options: {
          exclude: [
            PositionPremiumTypes.BILL_HOURLY,
            PositionPremiumTypes.PAY_HOURLY,
            PositionPremiumTypes.PAY_ADHOC,
          ],
        },
      },
    ],
  },
  {
    query: {
      resource: Resources.EMPLOYEE_PREMIUM_ASSIGNMENTS,
      filters: [
        {
          attribute: 'premium.type',
          operator: FilterOperatorType.IN,
          value: [
            PositionPremiumTypes.BILL_ON_TOP,
            PositionPremiumTypes.PAY_ON_TOP,
          ],
        },
      ],
    },
    key: ReportingAndAnalyticsViews.EMPLOYEE_PROFILE_EARNINGS_PREMIUM,
    title: 'back-office-reports.earnings-premium.employee.title',
    columns: EmployeePremiumAssignmentColumns,
    downloadColumns: EmployeePremiumAssignmentDownloadableColumns,
    filters: [
      { attributeName: 'premium.createdOn' },
      {
        attributeName: 'premium.type',
        options: {
          exclude: [
            PositionPremiumTypes.BILL_HOURLY,
            PositionPremiumTypes.PAY_HOURLY,
            PositionPremiumTypes.PAY_ADHOC,
          ],
        },
      },
    ],
  },
]

export const HUMAN_CAPITAL_MANAGEMENT_TABS: BackOfficeReportsTab[] = [
  {
    query: {
      resource: Resources.LEAVE_REQUESTS,
      filters: [
        {
          attribute: 'status',
          operator: FilterOperatorType.IN,
          value: [LeaveRequestsStatus.APPROVED, LeaveRequestsStatus.DECLINED],
        },
      ],
    },
    key: ReportingAndAnalyticsViews.VIEW_HCM_COMPLETED_REQUESTS,
    title: 'back-office-reports.hcm-activity.completedRequests',
    columns: CompletedRequestsColumns,
    downloadColumns: CompletedRequestsDownloadableColumns,
    filters: [
      {
        attributeName: 'startDateTime',
      },
      {
        attributeName: 'status',
      },
    ],
  },
  {
    query: {
      resource: Resources.EMPLOYEE_SKILLS,
      sort: [
        {
          attribute: 'createdOn',
          direction: SortDirectionType.DESC,
        },
      ],
      filters: [
        {
          attribute: 'skill.externalIdObject.paycorCertificationId',
          operator: FilterOperatorType.ISNOTNULL,
        },
      ],
    },
    key: ReportingAndAnalyticsViews.VIEW_HCM_CERTIFICATIONS,
    title: 'back-office-reports.hcm-activity.certifications',
    columns: HumanCapitalManagementCertificationsColumns,
    downloadColumns: HumanCapitalManagementCertificationsDownloadableColumns,
    filters: [
      {
        attributeName: 'status',
      },
    ],
  },
  {
    query: {
      resource: Resources.CONFLICTS,
      sort: [
        {
          attribute: 'createdOn',
          direction: SortDirectionType.DESC,
        },
      ],
    },
    key: ReportingAndAnalyticsViews.VIEW_HCM_CONFLICTS,
    title: 'back-office-reports.hcm-activity.conflicts',
    columns: HumanCapitalManagementConflictsColumns,
    downloadColumns: HumanCapitalManagementConflictsDownloadableColumns,
    filters: [
      {
        attributeName: 'status',
      },
      {
        attributeName: 'conflictType',
      },
      {
        attributeName: 'account',
      },
    ],
  },
]

export const LEAVE_MANAGEMENT_TABS: BackOfficeReportsTab[] = [
  {
    query: {
      resource: Resources.LEAVE_REQUESTS,
      filters: [
        {
          attribute: 'status',
          operator: FilterOperatorType.IN,
          value: [
            LeaveRequestsStatus.APPROVED,
            LeaveRequestsStatus.DECLINED,
            LeaveRequestsStatus.CANCELLED,
          ],
        },
      ],
    },
    title: 'back-office-reports.hcm-activity.completedRequests',
    key: ReportingAndAnalyticsViews.VIEW_LEAVE_MANAGEMENT_COMPLETED_REQUESTS,
    columns: CompletedRequestsColumns,
    downloadColumns: CompletedRequestsDownloadableColumns,
    filters: [
      {
        attributeName: 'startDateTime',
      },
      {
        attributeName: 'status',
      },
    ],
  },
]
