import isEmpty from 'lodash/isEmpty'
import merge from 'lodash/merge'
import {
  Sort,
  SortDirectionType,
} from 'tracktik-sdk/lib/common/entity-collection'

import {
  DefinitionOption,
  DefinitionOptionMap,
} from '@tracktik/tt-json-schema-form'

import {
  ActionFormTypes,
  DocumentPresetInterface,
  modularManager,
  PresetItem,
  PresetLevels,
  PresetTypes,
} from '@/tt-app-modular'
import { AllowEntityActionsOption, AllowEntityOption } from '@/types'
import { DialogFormInterface, LayoutComponents } from '@/tt-app-layout'
import {AppContext, AppContextEventPayload} from '@/tt-app-context'
import {
  ComponentReference,
  FullColumnDefinition,
} from '@/tt-widget-components'
import {
  getExtensionPathWithoutPrefix,
  hasExtensionPrefix,
} from '@/tt-widget-factory/services/resource-meta/Extensions'
import { isResourceWhitelisted } from '@/tt-widget-factory/services/metadata-provider/resource-blacklist'
import { ResourceMetaManagerInterface } from '@/tt-widget-factory'

import { PreviewTabInterface } from './types'
import { Action, Resource } from '../tt-entity-design/src/schema-types'
import { resolve } from '@/tt-widget-views/helpers'
import { Resources } from '@/tt-entity-design/src/types'
import {LayoutWindowEvent} from "@/tt-app-layout";
import {EventManagerInterface} from "@tracktik/tt-event-manager";
import I18n from "@/plugins/i18n";
import { Item } from  "./types";
import { MOBILE_RUNSHEET_SCHEDULE_OCCURRENCES } from '@/tt-entity-design/src/components/mobile-runsheet-occurrences/constants'

export enum ResourceFormType {
  STANDALONE_COMPONENT = 'STANDALONE_COMPONENT',
  JSON_FIELD_COMPONENT = 'JSON_FIELD_COMPONENT',
}

/**
 * Add a column view
 * @param resource
 * @param title
 * @param componentName
 * @param props
 */
export function addColumnPreset(
  resource: string,
  title: string,
  column: FullColumnDefinition,
) {
  modularManager.addResourcePreset(resource, PresetTypes.COLUMN, {
    title,
    tag: resource,
    level: PresetLevels.DEFAULTS,
    data: column,
  })
}

export function allowsEntityOperation<R extends Resource>(
  allowEntityOption: AllowEntityOption,
  data: {
    resourceName: R
    entity?: object
  },
  appContext?: AppContext,
): boolean {
  const operation = resolve(allowEntityOption, false, [data, appContext])

  return Array.isArray(operation)
    ? operation.includes(data.resourceName)
    : operation ?? false
}

export function isGeneralAllowEntityOption(
  option: AllowEntityOption | AllowEntityActionsOption,
): option is AllowEntityOption {
  return option == null || typeof option === 'boolean' || Array.isArray(option)
}

/**
 * Checks if the FE application is allowing the resource action.
 */
export function allowsEntityAction<R extends Resource>(
  allowEntityOption: AllowEntityActionsOption,
  resourceName: R,
  actionName?: Action<R>,
): boolean {
  if (isGeneralAllowEntityOption(allowEntityOption)) {
    return allowsEntityOperation(allowEntityOption, { resourceName })
  }

  if (!actionName && Object.keys(allowEntityOption).includes(resourceName)) {
    return true
  }

  const resourceActionOption = allowEntityOption[resourceName]

  return Array.isArray(resourceActionOption)
    ? resourceActionOption.includes(actionName)
    : resourceActionOption ?? false
}

type ResourceFormOptions = {
  /**
   * Force opening the form into a specific layout component. Default behaviour is to use the "active window".
   */
  openIn?: LayoutComponents.dialog // can add more
  defaultDefinition?: DefinitionOption
}

/**
 * Register a custom standalone component to be used in a dialog.
 */
export function registerResourceFormStandaloneComponent(
  resourceName: string,
  componentName: string,
  actionFormType: ActionFormTypes = ActionFormTypes.FULL_ENTITY,
  baseDialogForm: Partial<DialogFormInterface>,
) {
  const presetItem: PresetItem = {
    title: 'Form',
    tag: actionFormType,
    data: {
      is: componentName,
      dialog: baseDialogForm,
      type: ResourceFormType.STANDALONE_COMPONENT,
    },
    level: PresetLevels.DEFAULTS,
  }
  modularManager.addResourcePreset(resourceName, PresetTypes.FORM, presetItem)
}

/**
 * Register a component with some fields definitions `<json-field>` to be used inside AppLayoutDialogForm.
 * Will be wrapper with a `<JsonForm>` (tt-json-schema-form)
 * If you need to register a custom "standalone" component, use `registerResourceFormStandaloneComponent()`
 */
export function registerResourceForm(
  resourceName: Resources,
  componentName: string | null,
  actionFormType: string = ActionFormTypes.FULL_ENTITY,
  options?: ResourceFormOptions,
  level: PresetLevels = PresetLevels.DEFAULTS,
) {
  const presetItem: PresetItem = {
    title: 'Form',
    tag: actionFormType,
    data: {
      ...(componentName ? { is: componentName } : {}),
      options,
      type: ResourceFormType.JSON_FIELD_COMPONENT,
    },
    level: level,
  }
  modularManager.addResourcePreset(resourceName, PresetTypes.FORM, presetItem)
}

/**
 * Returns the registered resource action form as a json-schema-form definition, if exists.
 */
export const getResourceFormDefinition = (
  definitionName: string,
  resource: string,
  actionFormType: string = ActionFormTypes.FULL_ENTITY,
): DefinitionOptionMap => {
  const presetItem = modularManager.getResourcePreset(
    resource,
    PresetTypes.FORM,
    actionFormType,
  )

  const definition = merge(
    {},
    presetItem?.data?.is ? { view: { is: presetItem.data.is } } : {},
    presetItem?.data?.options?.defaultDefinition,
  )

  return isEmpty(definition) ? {} : { [definitionName]: definition }
}

export const getResourceFormOptions = (
  resource: string,
  actionFormType: string = ActionFormTypes.FULL_ENTITY,
): ResourceFormOptions => {
  const presetItem = modularManager.getResourcePreset(
    resource,
    PresetTypes.FORM,
    actionFormType,
  )

  return presetItem?.data?.options ?? {}
}

/**
 * Returns the registered resource action form as a json-schema-form definition, if exists.
 */
export const getResourceStandaloneForm = (
  definitionName: string,
  resource: string,
  actionFormType: string = ActionFormTypes.FULL_ENTITY,
): string => {
  const presetItem = modularManager.getResourcePreset(
    resource,
    PresetTypes.FORM,
    actionFormType,
  )

  return presetItem?.data?.is
}

/**
 * Returns the registered resource action form as a json-schema-form definition, if exists.
 */
export const isResourceFormStandaloneComponent = (
  definitionName: string,
  resource: string,
  actionFormType: string = ActionFormTypes.FULL_ENTITY,
): boolean => {
  const presetItem = modularManager.getResourcePreset(
    resource,
    PresetTypes.FORM,
    actionFormType,
  )

  return presetItem?.data?.type === ResourceFormType.STANDALONE_COMPONENT
}

export const getResourceFormWidth = (
  resource: string,
  actionFormType: string = ActionFormTypes.FULL_ENTITY,
): number => {
  const presetItem = modularManager.getResourcePreset(
    resource,
    PresetTypes.FORM,
    actionFormType,
  )

  return presetItem?.data?.dialog?.width ?? 600
}

export const getResourceFormDialogOptions = (
  resource: string,
  actionFormType: string = ActionFormTypes.FULL_ENTITY,
): DialogFormInterface | null => {
  const presetItem = modularManager.getResourcePreset(
    resource,
    PresetTypes.FORM,
    actionFormType,
  )

  return presetItem?.data?.dialog ?? null
}

export function createDocumentColumn(
  documentPreset: DocumentPresetInterface,
): FullColumnDefinition {
  return {
    name: 'Download PDF',
    style: {
      maxWidth: 50,
      width: 50,
    },
    component: {
      is: 'EntityDocumentButton',
      props: {
        document: {
          name: documentPreset.name,
        },
      },
    },
  }
}

export function addResourceDocument(
  resource: string,
  documentDefinition: DocumentPresetInterface,
) {
  modularManager.addResourcePreset(resource, PresetTypes.DOCUMENT, {
    title: documentDefinition.name,
    tag: resource,
    level: PresetLevels.DEFAULTS,
    data: documentDefinition,
  })
  addColumnPreset(
    resource,
    documentDefinition.name,
    createDocumentColumn(documentDefinition),
  )
}

export function getResourceDocument(
  resource: string,
  name: string,
): DocumentPresetInterface | undefined {
  return modularManager
    .getResourcePresets(resource, PresetTypes.DOCUMENT, resource)
    .find((preset) => preset.title === name)?.data
}

/**
 * Add a entity tab to a resource
 * @param resource
 * @param tab
 */
export function addPreviewTab(resource: string, tab: PreviewTabInterface) {
  modularManager.addItem(`${resource}.preview.tabs`, tab)
}

/**
 * Set attribute view
 *
 * @param resource
 * @param attributeName
 * @param component
 */
export function setAttributeView(
  resource: string,
  attributeName: string,
  component: ComponentReference,
) {
  const presetKey = modularManager.getResourceAttributePresetKey(
    resource,
    PresetTypes.ATTRIBUTE_VIEW,
    attributeName,
  )
  modularManager.addPreset(presetKey, {
    data: component,
    title: `${resource} / ${attributeName} / View`,
  })
}

/**
 * Set an icon and color default for the resource
 *
 * @param resource
 * @param icon
 * @param color
 */
export function setIconAndColorResourcePreset(
  resource: string,
  icon: string,
  color: string,
) {
  modularManager.addResourcePreset(resource, PresetTypes.LOGO_COLOR, {
    data: {
      icon,
      color,
    },
    title: `${resource} Icon Color`,
  })
}

export function isAttributeDefined(
  objectToCheck: object,
  pathName: string,
): boolean {
  if (!pathName) {
    return false
  }
  const absoluteNamePath = pathName ? pathName.split('.') : []

  let currentTest = objectToCheck

  for (let index = 0; index < absoluteNamePath.length; index++) {
    const element = absoluteNamePath[index]
    if (!currentTest[element]) {
      return false
    }
    currentTest = currentTest[element]
  }

  return true
}

export const isWhitelistedResourceInvalidField = ({
                                                    attributeName,
                                                    relationPrefix,
                                                    resourceMetaManager,
                                                    resourceName,
                                                  }: {
  attributeName: string
  relationPrefix?: string
  resourceMetaManager: ResourceMetaManagerInterface
  resourceName: string
}): boolean => {
  const getAbsoluteName = () => {
    if (relationPrefix) return `${relationPrefix}.${attributeName}`
    if (hasExtensionPrefix(attributeName)) {
      return getExtensionPathWithoutPrefix(attributeName)
    }

    return attributeName
  }

  return (
    isResourceWhitelisted(resourceName) &&
    !resourceMetaManager.getAttribute(resourceName, getAbsoluteName())
  )
}

export const mapSortedAttributes = (attributes: string[]): Sort[] => {
  return attributes
    .filter((attribute) => attribute !== 'id')
    .map((attribute) => ({
      attribute,
      direction: SortDirectionType.ASC,
    }))
}

export async function downloadDocumentHelper(
  appContext: AppContext,
  eventManager: EventManagerInterface<AppContextEventPayload>,
  i18n: typeof I18n,
  selectedItems: Item[],
  translationKey: string
) {
  const documentPreset = getResourceDocument(
    MOBILE_RUNSHEET_SCHEDULE_OCCURRENCES,
    translationKey
  );

  if (!documentPreset) {
    console.error(
      `No document preset registered for resource "${
        MOBILE_RUNSHEET_SCHEDULE_OCCURRENCES
      }" and document name ${i18n.t(translationKey)}`
    );
    return;
  }

  const selectedItemIds = selectedItems.map((item) => item.id).join(",");
  const reportInput = await documentPreset.toDocument(
    appContext,
    {
      resourceName: selectedItems[0].resourceName,
      // @ts-ignore
      entityId: selectedItemIds,
    },
    i18n.locale
  );

  eventManager.dispatchEvent(LayoutWindowEvent.VIEW_DOCUMENT, reportInput);
}

export function getPreviewTabs(resource: string): PreviewTabInterface[] {
  return modularManager.getItems(
    `${resource}.preview.tabs`,
  ) as PreviewTabInterface[]
}
