// Enter your custom installation here

import {
  addPreviewTab,
  registerResourceForm,
} from '@/tt-widget-entity-flow/helper'
import { Resources } from '@/tt-entity-design/src/types'

import EmployeeNotesTab from './EmployeeNotesTab.vue'
import EmployeesSetLoneWorkerFrequencyActionForm from './EmployeesSetLoneWorkerFrequencyActionForm.vue'
import { createI18nResourceKey } from '@/tt-widget-entity-flow/ResourceTranslator'
import { Attribute } from '../../schema-types'

const getAttr = (attr: Attribute<typeof Resources.EMPLOYEES>): string => attr

export default {
  install(Vue) {
    Vue.component('EmployeeNotesTab', EmployeeNotesTab)
    Vue.component(
      'EmployeesSetLoneWorkerFrequencyActionForm',
      EmployeesSetLoneWorkerFrequencyActionForm,
    )

    registerResourceForm(
      Resources.EMPLOYEES,
      'EmployeesSetLoneWorkerFrequencyActionForm',
      'set-loneworker-frequency',
    )

    // Add the schedule preview in the tabs
    addPreviewTab(Resources.EMPLOYEES, {
      title: 'tt-entity-design.schedule',
      is: 'SchedulePreview',
    })

    addPreviewTab(Resources.EMPLOYEES, {
      title: 'tt-entity-design.employee-hub.employee.notes-on-employee',
      is: 'EmployeeNotesTab',
    })

    addPreviewTab(Resources.EMPLOYEES, {
      title: createI18nResourceKey(
        Resources.EMPLOYEES,
        getAttr('checkedOutAssets'),
      ),
      is: 'EntityRelationListCustomPreviewWrapper',
      props: {
        attributeName: getAttr('checkedOutAssets'),
        showToolbar: false,
      },
    })
  },
}
