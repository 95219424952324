import {
  AggregationQuery,
  AggregationQueryResponse,
} from '@/tt-widget-factory/definitions'
import { ContextManagerInterface } from '@/tt-widget-factory'
import { ResourceDataManagerInterface } from '@/tt-widget-factory/services/resource-data/types'

import { createField } from '@/tt-widget-factory/services/resource-meta/Fields'
import {
  getExtensionName,
  isExtension,
  isNotExtension,
} from '@/tt-widget-factory/services/resource-meta/Extensions'

import BaseQueryManager, { BaseQueryManagerOptions } from './BaseQueryManager'
import DataSetResponse from './DataSetWidgetResponse'
import {
  DataSetDefinition,
  DataSetRow,
  DataSetDimension,
  DataSetMeasure,
  removeNullKeys,
  CollectionQuery,
} from '../types'
import { dataSetToAggregationQuery } from './DataSetWidgetConverters'

import ResourceDefaultColumns from '../../../public/resource-columns.json'
import { TTC_API_MAX_LIMIT } from '@/tt-widget-components/constants'

/**
 * Data set
 */
export default class DataSetQueryManager extends BaseQueryManager<AggregationQuery> {
  private dataManager: ResourceDataManagerInterface
  readonly dataSet: DataSetDefinition

  data?: DataSetResponse
  havingQL?

  constructor(
    dataSet: DataSetDefinition,
    contextManager: ContextManagerInterface,
    { contextAttributeMap, services, ...options }: BaseQueryManagerOptions,
  ) {
    const query = dataSetToAggregationQuery(dataSet)
    if (dataSet) {
      super(query, contextManager, {
        contextAttributeMap,
        services,
        ...options,
      })
      this.dataManager = services.resourceDataManager
    } else {
      super(query, contextManager, { services, ...options })
    }
  }

  get resource(): string {
    return this.initialQuery.resource
  }

  /**
   * Output query
   */
  get query(): AggregationQuery {
    return removeNullKeys({
      ...this.initialQuery,
      filters: this.filters,
      whereQL: this.whereQL,
      havingQL: this.havingQL,
      includeInactive: this.includeInactive,
      search: this.search,
    } as AggregationQuery)
  }

  /**
   * Is valid
   */
  isValid(): boolean {
    return !!this.query?.resource
  }

  /**
   * Fetch
   * @param query
   */
  async fetch(
    query?: AggregationQuery,
    search?: string,
    filterfunction: (
      response: AggregationQueryResponse,
    ) => AggregationQueryResponse = (resp) => resp,
  ): Promise<DataSetResponse> {
    const out = new DataSetResponse()

    this.isRunning = true

    let aggrQuery = query || this.query
    if (search) {
      aggrQuery = {
        ...aggrQuery,
        search,
      }
    }

    await this.dataManager
      .getAggregation(aggrQuery)
      .then(filterfunction)
      .then((response: AggregationQueryResponse) => {
        // @ts-ignore
        out.meta = response.items

        response.items.forEach((row: DataSetRow) => {
          const rowOut: { [k: string]: any } = {
            ...row.data,
            ___query: row.query,
          }
          out.addRow(rowOut)
        })

        out.cacheTimestamp = response.cacheTimestamp
      })
      .catch((err) => {
        out.error = err
      })

    this.data = out
    this.isRunning = false

    return out
  }

  public get dimensions(): DataSetDimension[] {
    const { dimensions } = this.query
    if (!dimensions) {
      return []
    }

    return dimensions.map(({ alias, attribute, modifier }) => {
      const key = alias || (modifier && `${attribute}|${modifier}`) || attribute
      const name = alias || attribute

      return {
        key,
        name,
        alias,
        attribute,
        modifier,
      }
    })
  }

  public get firstDimension(): DataSetDimension {
    const { dimensions } = this

    return dimensions && dimensions[0]
  }

  public get hasMultipleDimensions(): boolean {
    return this.dimensions && this.dimensions.length > 1
  }

  public get measures(): DataSetMeasure[] {
    const { measures } = this.query
    if (!measures) {
      return []
    }

    return measures.map(({ alias, attribute, operation }) => {
      const key =
        alias || (operation && `${attribute}|${operation}`) || attribute
      const name = alias || attribute

      return {
        key,
        name,
        alias,
        attribute,
        operation,
      }
    })
  }

  public get firstMeasure(): DataSetMeasure {
    const { measures } = this

    return measures && measures[0]
  }

  /**
   * Converts the aggregation query to a collection query with the same filters.
   */
  getCsvQuery(): CollectionQuery {
    const columns = ResourceDefaultColumns[this.resource] as string[]

    const fields = columns.filter(isNotExtension).map(createField)
    const extension = columns.filter(isExtension).map(getExtensionName)

    const { dimensions, measures, havingQL, ...restQuery } = this.query

    return {
      ...restQuery,
      fields,
      extension,
      limit: TTC_API_MAX_LIMIT,
      offset: 0,
    }
  }
}
