<template>
  <v-card flat>
    <WidgetFactory :widget="widget" />
  </v-card>
</template>

<script lang="ts">
import Vue, { VueConstructor } from 'vue'
import { ListWidgetModel } from '@/tt-widget-components'
import { WidgetName } from '@/tt-widget-components/lib/names'
import { MOBILE_RUNSHEET_OCCURRENCES } from '../mobile-runsheet-occurrences/constants'
import { ItemHookProvider } from '@/tt-widget-entity-flow/types'

export default (Vue as VueConstructor<Vue & ItemHookProvider>).extend({
  name: 'MobileRunsheetOccurrencesAuditLog',
  inject: ['getItemHook'],
  computed: {
    mobileRunsheetOccurrenceId(): number {
      return this.getItemHook().getEntityId()
    },
    widget(): ListWidgetModel {
      return {
        is: WidgetName.LIST_WIDGET,
        title: '',
        disableResourcePreviewOnClick: true,
        listItem: {
          is: 'MobileRunsheetOccurrencesAuditLogItem',
        },
        query: {
          resource: 'printed-runsheet-occurrences',
          relationListResource: {
            resource: MOBILE_RUNSHEET_OCCURRENCES,
            id: this.mobileRunsheetOccurrenceId,
            attribute: 'printedRunsheetOccurrences',
          },
        },
        toolbar: {
          show: false,
        },
      }
    },
  },
})
</script>
