import {
  ActionProperty,
  ResourceSchema,
} from '@/tt-entity-design/src/schema-types'
import { ItemId } from '@/tt-region-manager/types'
import {
  ADD_OPERATOR,
  OPERATION_CENTERS,
  REMOVE_OPERATOR,
  REPLACE_REGIONS,
} from './constants'
import { Resources } from '@/tt-entity-design/src/types'

export type AddActionProperty = ActionProperty<
  typeof OPERATION_CENTERS,
  typeof ADD_OPERATOR
>

export type DeleteProperty = ActionProperty<
  typeof OPERATION_CENTERS,
  typeof REMOVE_OPERATOR
>

export type SyncRegionsProperty = ActionProperty<
  typeof OPERATION_CENTERS,
  typeof REPLACE_REGIONS
>

export type OtherOperationCenter = {
  id: number
  name: string
}

export type RegionOperationCenterMap = Record<ItemId, OtherOperationCenter>

export type ResponseItemsType = {
  id: number
  operationCenter: OtherOperationCenter
  ticketTypes: string[] | null
  name: string
}

export enum StatusEnum {
  ACTIVE = 'ACTIVE',
  ARCHIVED = 'ARCHIVED',
}

export enum PresenceEnum {
  PRESENT = 'PRESENT',
  ABSENT = 'ABSENT',
}
