import { EmployeeIconColorState, EmployeeIconState } from './types'

export const employeeIconColors: Record<EmployeeIconColorState, string> = {
  DISPATCH: 'blue',
  MOBILE: 'orange',
  ON_BREAK: 'grey',
  ON_SITE: 'green',
  CLOCKED_OUT: 'black',
}

export const employeeIcon: Record<EmployeeIconState, string> = {
  DISPATCH: 'mdi-clipboard',
  LONE_WORKER: 'mdi-sign-caution',
  MULTI_ACTIVITIES: 'mdi-star',
  NO_ACTIVITIES: 'mdi-close-octagon',
  RUNSHEET: 'mdi-check-all',
  STATIC_GUARD: 'mdi-map-marker-down',
  TOUR: 'mdi-repeat-variant',
}
