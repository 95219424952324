import { AppContext } from '@/tt-app-context'
import { EntityCreateIntentInterface } from '@/tt-widget-entity-flow'
import { ValueOf } from '@/helpers/types/ValueOf'
import { ColumnDefinition } from '@/tt-widget-components'
import { Resources } from '@/tt-entity-design/src/types'
import { Attribute, AttributeEnum } from '@/tt-entity-design/src/schema-types'

export type TypeAssets = typeof Resources.ASSETS
export type TypeAccounts = typeof Resources.ACCOUNTS
export type TypeAssetTypes = typeof Resources.ASSET_TYPES
export type TypeAssetCategories = typeof Resources.ASSET_CATEGORIES
export type TypeSiteLocations = typeof Resources.SITE_LOCATIONS
export type EnumAccountTypes = AttributeEnum<TypeAccounts, 'type'>
export type EnumAccountSubTypes = AttributeEnum<TypeAccounts, 'subType'>

/**
 * Create Menu Items
 */
export type AssetCreateMenuItem = {
  resource?: string
  route?: string
  canShow?: boolean
}

/**
 * Asset Events
 */
export enum AssetEditorEvents {
  PERSON_CREATE = 'assets.person.editor.create',
}

export type AssetEditorEventPayload = {
  [AssetEditorEvents.PERSON_CREATE]: EntityCreateIntentInterface
}

/**
 * Asset Serial Search
 * It'll be used to look for an asset searching it's serial number.
 * The search shall look up among all the sites of that said client but the
 * results will be filtered by the backend for safety.
 *
 * The account.id can also be null if the user doesn't have access to that site
 * The (asset) id can also be null if the asset is inactive
 */
export type AssetSerialSearchIdType = number | null
export type AssetSerialSearchResultAccount = {
  id: AssetSerialSearchIdType
  name: string
  type: AssetSerialSearchIdType
  typeName: string | null
}
export type AssetSerialSearchResultType = {
  id: AssetSerialSearchIdType
  icon: string
  name: string
  color: string
}

export type AssetSerialSearchResult = {
  name: string | null
  id: AssetSerialSearchIdType
  type: AssetSerialSearchResultType
  status: AssetStatusCode
  serialNumber: string
  message: string
  account: AssetSerialSearchResultAccount
}

/**
 * Asset Status Color Schemas
 */
export enum assetStatusEnums {
  ACTIVE = 'ACTIVE',
  PENDING = 'PENDING',
  INACTIVE = 'INACTIVE',
}

export type AssetStatusCode = ValueOf<typeof assetStatusEnums>

export type AssetStatusColor = {
  backgroundColor: string
  textColor: string
}

/**
 * Account types enums
 */
export enum accountTypeEnums {
  CLIENT = 'site',
  ZONE = 'zone',
}

/**
 * Asset Types and Categories columns and filters blacklist
 */
export type FiltersBlackList = {
  [key: string]: string[]
}

export type ColumnBlackList = {
  [key: string]: ColumnDefinition[]
}

/**
 * Asset Types and Categories import wizard
 */
export type AssetCategoryImportItem = {
  id: string
  name: string
  icon?: string | null
  color?: string | null
  description?: string | null
}

export type AssetTypeImportItem = {
  id: string
  name: string
  icon?: string | null
  color?: string | null
  category: AssetCategoryImportItem
}

/**
 * Asset Tracking Settings
 */
export interface FetchAssetTrackingSettingsParams {
  accountId?: number | undefined
  appContext?: AppContext
}

export enum AssetTrackingSettingsActions {
  CREATE = 'canCreate',
  LIST = 'canList',
  CAN_IMPORT = 'canImportModelTypes',
}

export type AssetTrackingSettingsCanExecuteAction = {
  resourceName: string
  actionName: keyof AssetSettingsResourceSettings
  settings: AssetTrackingSettings
}

export type AssetSettingsResourceSettings = {
  [AssetTrackingSettingsActions.CREATE]?: boolean
  [AssetTrackingSettingsActions.LIST]?: boolean
  [key: string]: boolean | undefined
}

export type AssetTrackingSettings = {
  resources?: {
    [key: string]: AssetSettingsResourceSettings
  }
  [AssetTrackingSettingsActions.CAN_IMPORT]?: boolean
  [key: string]: any
}

export type AssetTrackingSettingsProvider = {
  assetTrackingSettings: AssetTrackingSettings
}

/**
 * AccountContext component and related types
 */
export interface AssetTrackingContextAccount {
  id: number
  name: string
  type: EnumAccountTypes
  subType: EnumAccountSubTypes
  timeZone: string
  address: Attribute<typeof Resources.ADDRESSES>
  // backwards compatibility
  value?: number
  text?: string
}

/**
 * Used to define the context account information and some assertions
 * like wether the account is a site, region, zone, multi-site, etc.
 */
export type ContextAccountInfo = {
  accountInfo: AssetTrackingContextAccount | null
  isSite: boolean
  isRegion: boolean
  isZone: boolean
  isMultiSite: boolean
}
