// ******** This file is autogenerated DO NOT MODIFY **********

import { modularManager, PresetTypes, PresetLevels } from '@/tt-app-modular'
import { Resources } from '../../types'
import custom from './custom'
import PositionTeamsTile from './PositionTeamsTile.vue'
import PositionTeamsColumns from './PositionTeamsColumns'
import PositionTeamsDetail from './PositionTeamsDetail.vue'
import PositionTeamsMetaCard from './PositionTeamsMetaCard.vue'
import PositionTeamsReference from './PositionTeamsReference.vue'

export default {
  install(Vue) {
    Vue.use(custom)
    Vue.component('PositionTeamsTile', PositionTeamsTile)
    Vue.component('PositionTeamsDetail', PositionTeamsDetail)
    Vue.component('PositionTeamsMetaCard', PositionTeamsMetaCard)
    Vue.component('PositionTeamsReference', PositionTeamsReference)

    modularManager.addResourcePreset(
      Resources.POSITION_TEAMS,
      PresetTypes.LIST_ITEM,
      {
        title: 'Default',
        data: { is: 'PositionTeamsTile' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.POSITION_TEAMS,
      PresetTypes.COLUMNS,
      {
        title: 'Default',
        data: PositionTeamsColumns,
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.POSITION_TEAMS,
      PresetTypes.DETAILS_CARD,
      {
        title: 'Default',
        data: { is: 'PositionTeamsDetail' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.POSITION_TEAMS,
      PresetTypes.META_CARD,
      {
        title: 'Default',
        data: { is: 'PositionTeamsMetaCard' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.POSITION_TEAMS,
      PresetTypes.RELATION_REFERENCE,
      {
        title: 'Default',
        data: { is: 'PositionTeamsReference' },
        level: PresetLevels.DEFAULTS,
      },
    )
  },
}
