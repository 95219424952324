<template>
  <div>
    <RelationField
      v-model="model"
      :resource="resource"
      :additional-query-options="additionalQueryOptions"
      :label="label"
      @blur="onBlur"
    />
  </div>
</template>

<script lang="ts">
import Vue from 'vue'
import { VueWithFormHookInjection } from '@/helpers/components/types'
import { Resources } from '../../types'
import { EntityCollectionRequestOptions } from 'tracktik-sdk/lib/common/entity-collection'
import { AllowEntityOperationsProvider } from '@/types'
import { ReportTemplateLabels } from '@/tt-entity-design/src/components/report-templates/types'

export default (Vue as VueWithFormHookInjection).extend({
  name: 'ReportTemplateStandardizedIdField',
  inject: ['formHook'],
  provide(): AllowEntityOperationsProvider {
    return {
      allowEntityActions: false,
      allowEntityCreation: false,
      allowEntityDelete: false,
      allowEntityEdit: false,
    }
  },
  props: {
    name: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      model: 0 as number | null,
    }
  },
  computed: {
    label(): string {
      return this.$t('report_template_form.standardized-id.label')
    },
    reportTemplateId(): number {
      return this.formHook().getPathValue('id')
    },
    isEditing(): boolean {
      return Boolean(this.reportTemplateId)
    },
    resource(): Resources {
      return Resources.ENTITY_LABELS
    },
    additionalQueryOptions(): EntityCollectionRequestOptions {
      return {
        scope: 'REPORT_TEMPLATE',
      }
    },
  },
  async created() {
    if (this.isEditing) {
      await this.loadStandardizedId()
    }
  },
  methods: {
    async loadStandardizedId() {
      try {
        this.$auth
          .getApi()
          .get(Resources.REPORT_TEMPLATES, this.reportTemplateId, {
            include: ['labels'],
          })
          .then((item: { labels: ReportTemplateLabels[] }) => {
            const label = item.labels.pop()
            this.model = label.id
            this.setFieldValue([this.model])
          })
      } catch (err) {
        this.$crash.captureException(err)
      }
    },
    onBlur() {
      if (this.model) {
        this.setFieldValue([this.model])
      } else {
        this.setFieldValue([])
      }
    },
    setFieldValue(value: number[]) {
      this.formHook().setObjectValue(this.name, value)
    },
  },
})
</script>
