<template>
  <v-timeline class="w-100 pr-2 pt-0" :dense="!showSentFrom">
    <v-timeline-item fill-dot class="w-100" :color="iconColor" right>
      <template #icon>
        <v-tooltip :open-delay="0" top>
          <template #activator="{ on }">
            <v-icon color="level0" v-on="on" v-text="icon" />
          </template>

          <span class="pr-1" v-text="typeLabel" />
        </v-tooltip>
      </template>

      <template #opposite>
        <v-tooltip top>
          <template #activator="{ on }">
            <v-icon
              class="pl-2"
              :color="sentFrom.color"
              v-on="on"
              v-text="sentFrom.icon"
            />
          </template>

          <span class="pr-1" v-text="sentFrom.label" />
        </v-tooltip>
      </template>

      <template #default>
        <v-card class="elevation-2">
          <v-card-text>
            <div class="w-100 pb-3">
              <tt-attr name="createdBy" />
            </div>

            <ResizeObserver class="resize--observer" @resize="checkOverflow">
              <div
                ref="comment--hidden"
                class="comment--container is-expanded"
                v-text="comment"
              />
            </ResizeObserver>

            <div
              class="comment--container pb-3"
              :class="{
                'is-expanded': isExpanded,
                'is-overflowing': isOverflowing,
              }"
            >
              <div v-text="comment" />
            </div>

            <div class="flex--column align-center opacity-faded">
              <v-btn
                v-if="showExpand"
                text
                x-small
                :ripple="false"
                @click="expand(true)"
              >
                {{ $t('tt-entity-design.comments.show-more') }}
              </v-btn>

              <v-btn
                v-if="showCollapse"
                text
                x-small
                :ripple="false"
                @click="expand(false)"
              >
                {{ $t('tt-entity-design.comments.show-less') }}
              </v-btn>
            </div>

            <div class="comment--datetime">
              {{ createdOnRelativeDate }}
            </div>
          </v-card-text>
        </v-card>
      </template>
    </v-timeline-item>
  </v-timeline>
</template>

<script lang="ts">
import Vue, { VueConstructor } from 'vue'
import { getDurationHumanized } from '@/helpers/formats/dates/utils'
import { ItemHookProvider } from '@/tt-widget-entity-flow/types'
import ResizeObserver from '@/tt-widget-components/components/ResizeObserver/ResizeObserver.vue'
import { SentFromView, TypeEnum, TypeView } from './types'
import {
  TYPE,
  CREATED_ON,
  COMMENT,
  SENT_FROM,
  TYPE_VIEW_MAP,
  TYPE_SENT_FROM,
} from './constants'
import { createI18nEnumKey } from '@/tt-widget-entity-flow/ResourceTranslator'
import { Resources } from '@/tt-entity-design/src/types'

export default (Vue as VueConstructor<Vue & ItemHookProvider>).extend({
  name: 'CommentsNotesTile',
  components: { ResizeObserver },
  inject: ['getItemHook'],
  props: {
    showSentFrom: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    now: new Date().toISOString(),
    stopTimer: null as null | (() => void),
    isOverflowing: false,
    isExpanded: false,
  }),
  computed: {
    showExpand(): boolean {
      return this.isOverflowing && !this.isExpanded
    },
    showCollapse(): boolean {
      return this.isExpanded
    },
    type(): TypeEnum {
      return this.getItemHook().getRawValue(TYPE)
    },
    createdOn(): string {
      return this.getItemHook().getRawValue(CREATED_ON)
    },
    comment(): string {
      return this.getItemHook().getRawValue(COMMENT)
    },
    createdOnRelativeDate(): string {
      return getDurationHumanized(this.now, this.createdOn)
    },
    typeView(): TypeView {
      return TYPE_VIEW_MAP[this.type]
    },
    typeLabel(): string {
      return this.getItemHook().get(TYPE)
    },
    iconColor(): string {
      return this.typeView?.color
    },
    icon(): string {
      return this.typeView?.icon
    },
    sentFrom(): SentFromView {
      const sentFromKey = this.getItemHook().getRawValue(SENT_FROM)
      const sentFromType = TYPE_SENT_FROM[sentFromKey]
      const label = this.getItemHook().get(SENT_FROM)

      return {
        icon: sentFromType?.icon,
        color: sentFromType?.color,
        label,
      }
    },
  },
  created() {
    this.getItemHook().addAttribute(TYPE)
    this.getItemHook().addAttribute(CREATED_ON)
    this.getItemHook().addAttribute(COMMENT)
    this.getItemHook().addAttribute(SENT_FROM)

    const timerId = setInterval(() => {
      this.now = new Date().toISOString()
    }, 60000)

    this.stopTimer = () => clearInterval(timerId)
  },
  beforeDestroy() {
    this.stopTimer?.()
  },
  methods: {
    async expand(value: boolean) {
      this.isExpanded = value
    },
    checkOverflow() {
      const el = this.$refs['comment--hidden'] as Element

      const parentWidth = el.parentElement?.getBoundingClientRect().width
      const elementWidth = el.getBoundingClientRect().width

      const parentHeight = el.parentElement?.getBoundingClientRect().height
      const elementHeight = el.getBoundingClientRect().height

      this.isOverflowing =
        !!parentWidth &&
        (elementWidth > parentWidth || elementHeight > parentHeight)
    },
  },
})
</script>

<style scoped>
* {
  --text-max-height: 50px;
  --timeline-divider-width: 96px;
  --timeline-opposite-width: 40px;
}

.v-timeline {
  display: flex;
  flex-direction: column;
  align-items: center;

  min-width: 0;
  width: 60px;
}

.comment--container {
  position: relative;
  overflow: hidden;
  height: auto;
  transition: max-height 0.2s ease;
  white-space: pre-wrap;
}

.comment--container:not(.is-expanded) {
  max-height: var(--text-max-height);
}

.comment--container.is-expanded {
  max-height: auto;
}

/** Add fade-out overlay at the bottom if text is overflowing */
.comment--container.is-overflowing:not(.is-expanded)::after {
  content: '';
  background: linear-gradient(
    to top,
    var(--v-level0-base),
    transparent 2em,
    transparent
  );
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
}

.resize--observer {
  position: absolute;
  top: 0;
  left: 0;
  height: var(--text-max-height);
  visibility: hidden;
}

.comment--datetime {
  width: 100;
  min-width: 0;
  text-align: right;
  font-size: 0.8em;
  opacity: 0.7;
  font-style: italic;
}

.v-timeline-item {
  justify-content: left; /* Align timeline items content to the left */
}
/* Position the timeline middle divider correctly */
.v-application--is-ltr .v-timeline:not(.v-timeline--dense):before {
  left: calc(
    var(--timeline-opposite-width) + calc(var(--timeline-divider-width) / 2)
  );
}
/* Set fixed width for opposite content */
::v-deep(.v-timeline-item__opposite) {
  flex: none;
  width: var(--timeline-opposite-width);
}
/* Ensure the divider width is explicitly set to maintain alignment */
::v-deep(.v-timeline-item__divider) {
  width: var(--timeline-divider-width);
}
/* Ensure the timeline body uses full remaining width */
::v-deep(.v-timeline-item__body) {
  max-width: 100% !important;
}
</style>
