import { CollectionQuery } from '@/tt-widget-components'

import {
  AggregationQuery,
  AggregationQueryResponse,
  CollectionQueryResponse,
} from '../../definitions'
import {
  CancelableResponse,
  CollectionQueryOptions,
  ResourceDataManagerInterface,
  ResourceDataProviderInterface,
} from './types'

export default class ResourceDataManager
  implements ResourceDataManagerInterface
{
  constructor(resourceDataProvider: ResourceDataProviderInterface[] = []) {
    resourceDataProvider.map((provider) => this.registerProvider(provider))
  }

  async getAggregation(
    query: AggregationQuery,
    disableCache?: boolean,
  ): Promise<AggregationQueryResponse> {
    const provider = this.resolveProvider(query)

    return provider.getAggregation(query, disableCache)
  }

  async getCollection(
    query: CollectionQuery,
    options?: CollectionQueryOptions,
  ): Promise<CollectionQueryResponse> {
    const provider = this.resolveProvider(query)

    return provider.getCollection(query, options)
  }

  cancelableGetCollection(
    query: CollectionQuery,
    options?: CollectionQueryOptions,
  ): CancelableResponse<CollectionQueryResponse> {
    const provider = this.resolveProvider(query)

    return provider.cancelableGetCollection(query, options)
  }

  registerProvider(provider: ResourceDataProviderInterface): void {
    this.providers.push(provider)
  }

  getProviders() {
    return this.providers
  }

  //Private
  private providers: ResourceDataProviderInterface[] = []

  private resolveProvider(
    query: AggregationQuery | CollectionQuery,
  ): ResourceDataProviderInterface {
    const provider = this.providers.find((provider) => provider.canQuery(query))
    if (!provider) {
      throw new Error(
        `Not resource data provider found for resource [${query.resource}], Did you forget to register it?`,
      )
    }

    return provider
  }
}
