<template>
  <div class="pt-3">
    <v-textarea
      :value="rawValue"
      :label="computedLabel"
      outlined
      dense
      hide-details
      no-resize
      disabled
      readonly
    />
    <TLoadingBar v-if="isLoading" class="loader" />
  </div>
</template>

<script lang="ts">
import Vue, { VueConstructor } from 'vue'
import { ItemHookProvider } from '@/tt-widget-entity-flow/types'
import { ResourceTranslator } from '@/tt-widget-entity-flow/ResourceTranslator'
import { FormLabelTypes } from '@tracktik/tt-json-schema-form'

export default (Vue as VueConstructor<Vue & ItemHookProvider>).extend({
  name: 'SystemExceptionTicketsInstructionSnapshotAttr',
  inject: ['getItemHook'],
  computed: {
    resource(): string | null {
      return this.getItemHook().getResourceName()
    },
    computedLabel(): string | null {
      return ResourceTranslator.translateAttribute(
        this.resource,
        'instructions',
        FormLabelTypes.LABEL,
      )
    },
    isLoading(): boolean {
      return this.getItemHook().loading
    },
    instructionSnapshot(): string | null {
      return (
        this.getItemHook().getRawValue('instructionSnapshot.description') ||
        null
      )
    },
    instructions(): string | null {
      return this.getItemHook().getRawValue('instructions') || null
    },
    rawValue(): string {
      const rawValue = this.instructionSnapshot
        ? this.instructionSnapshot
        : this.instructions

      return rawValue || (!this.isLoading && this.noInstructionsLabel) || '...'
    },
    noInstructionsLabel(): string {
      return this.$t(this.translationKey('no-instructions'))
    },
  },
  created() {
    this.getItemHook().addAttribute('instructions')
    this.getItemHook().addAttribute('instructionSnapshot')
  },
  methods: {
    translationKey(key: string): string {
      return `tt-entity-design.system-exception-tickets.instructions.${key}`
    },
  },
})
</script>

<style scoped>
.loader {
  position: relative;
  height: 1px;
}

.v-textarea >>> textarea {
  font-size: smaller;
}
</style>
