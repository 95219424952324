<template>
  <component :is="menuType" v-model="isOpen" v-bind="menuProps">
    <template #activator="{ on }">
      <div
        :data-analytics="`core:entity-custom-filter:button|resource:${resource}|custom-filter:${filterName}`"
      >
        <FilterButtonActivator
          :has-value="hasValue"
          :label="label"
          @clear="clear"
          v-on="on"
        />
      </div>
    </template>

    <v-card
      v-if="isOpen"
      min-height="90"
      max-width="350"
      class="level0 pa-0 ma-0"
      :data-analytics="`core:entity-custom-filter:controls|resource:${resource}|custom-filter:${filterName}`"
    >
      <v-toolbar v-if="menuType === 'v-dialog'" dense short flat class="level1">
        <v-icon class="mr-2" v-text="`mdi-filter-variant`" />
        <span v-text="label" />
      </v-toolbar>

      <CustomFilterField
        v-model="model"
        :resource="resource"
        :filter-name="filterName"
        @delete="$emit('delete')"
      />

      <v-toolbar dense short flat class="level1">
        <v-btn text color="grey" @click="clear">
          <span v-text="$t('common.clear.btn')" />
        </v-btn>
        <v-spacer />
        <v-btn color="green" text @click="apply">
          <span v-text="$t('common.apply.btn')" />
        </v-btn>
      </v-toolbar>
    </v-card>
  </component>
</template>

<script lang="ts">
import cloneDeep from 'lodash/cloneDeep'
import Vue from 'vue'
import {} from 'vuetify'

import CustomFilterField from '@/tt-widget-components/components/filters/CustomFilterField.vue'
import TChipLabel from '@/tt-ui/components/TChipLabel.vue'
import {
  CustomFilterMeta,
  CustomFilterType,
} from '@/tt-widget-factory/services/resource-meta/types'

import FilterButtonActivator from './FilterButtonActivator.vue'

const SuffixDictionnary: Record<CustomFilterType, (value?: any) => string> = {
  [CustomFilterType.STRING]: () => '',
  [CustomFilterType.FLOAT]: () => '',
  [CustomFilterType.RELATION]: (value) => (value ? `(1)` : ``),
  [CustomFilterType.RELATION_LIST]: (value) =>
    value?.length ? `(${value.length})` : ``,
  [CustomFilterType.DATE_TIME]: () => '',
}

export default Vue.extend({
  name: 'EntityToolbarCustomFilterButton',
  components: { CustomFilterField, FilterButtonActivator, TChipLabel },
  inheritAttrs: false,
  props: {
    resource: { type: String, required: true },
    filterName: { type: String, required: true },
    value: { default: null },
  },
  data() {
    return {
      isOpen: false,
      model: cloneDeep(this.value),
    }
  },
  computed: {
    customFilterMeta(): CustomFilterMeta {
      return this.$appContext.widgetServices.resourceMetaManager.getCustomFilter(
        this.resource,
        this.filterName,
      )
    },
    suffix(): string {
      const getSuffix = SuffixDictionnary[this.customFilterMeta.type]

      return getSuffix(this.value)
    },
    label(): string {
      // @todo: bind to locales schema when API-1029 is done
      return this.customFilterMeta.labels.label
    },
    hasValue(): boolean {
      const isValueArray =
        this.customFilterMeta.type === CustomFilterType.RELATION_LIST

      return Boolean(isValueArray ? (this.value as any[])?.length : this.value)
    },
    menuType(): 'v-menu' | 'v-dialog' {
      return this.$vuetify.breakpoint.mdAndUp ? 'v-menu' : 'v-dialog'
    },
    menuProps(): Record<string, unknown> {
      if (this.$vuetify.breakpoint.mdAndUp) {
        return {
          nudgeBottom: 30,
          bottom: true,
          closeOnContentClick: false,
          left: true,
          width: 350,
        }
      } else {
        return {
          width: 350,
        }
      }
    },
  },
  watch: {
    isOpen() {
      if (!this.isOpen) this.model = cloneDeep(this.value)
    },
    value: {
      handler(newValue) {
        this.model = cloneDeep(newValue)
      },
      deep: true,
    },
  },
  methods: {
    clear() {
      this.model = null
      this.apply()
    },
    apply() {
      this.$emit('apply', this.model)
      this.close()
    },
    close() {
      this.isOpen = false
    },
  },
})
</script>
