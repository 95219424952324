<template>
  <tt-attr-row-card v-bind="$props">
    <tt-attr-row v-if="showLabels" name="labels" value-css-class="color-pill" />
    <tt-attr-row name="customId" value-css-class="color-pill" />
    <tt-attr-row name="availableToAllRegions" />
    <tt-attr-row name="details" />
    <tt-attr-row name="tag" />
    <tt-attr-row name="adminOnly" />
    <tt-attr-row name="account" />
    <tt-attr-row name="parentTemplate" />
    <tt-attr-row name="extendable" />
    <tt-attr-row name="type" />
    <tt-attr-row name="category" />
    <tt-attr-row name="assignedSite" />
    <tt-attr-row name="approveAutomatically" />
    <tt-attr-row name="notifyAlertQueue" />
    <tt-attr-row name="reportShouldBeLinkedToASiteNotAZone" />
    <tt-attr-row name="excludeFromShiftReport" />
    <tt-attr-row name="defaultLanguage" />
    <tt-attr-row name="translatable" />
    <tt-attr-row v-if="showAssociatedFeatures" name="associatedFeatures" />
  </tt-attr-row-card>
</template>

<script lang="ts">
import Vue, { VueConstructor } from 'vue'
import { ItemHookProvider } from '@/tt-widget-entity-flow/types'

export default (Vue as VueConstructor<Vue & ItemHookProvider>).extend({
  name: 'ReportTemplatesDetail',
  inject: ['getItemHook'],
  computed: {
    showLabels(): boolean {
      return !!this.getItemHook().hasAttribute('labels')
    },
    showAssociatedFeatures(): boolean {
      return !!this.getItemHook().hasAttribute('associatedFeatures')
    },
  },
})
</script>
