import { Resources } from '@/tt-entity-design/src/types'
import { createI18nPluralResourceKey } from '@/tt-widget-entity-flow/ResourceTranslator'
import { addPreviewTab } from '@/tt-widget-entity-flow/helper'
import { Attribute } from '@/tt-entity-design/src/schema-types'
import ManagedPermissionPoliciesRolesRelationList from './ManagedPermissionPoliciesRolesRelationList.vue'
import { modularManager } from '@/tt-app-modular'
import ManagedPermissionPoliciesRolesActionBar from './ManagedPermissionPoliciesActionBar.vue'
const getAttr = (attr: Attribute<'managed-permission-policies'>) => attr

export default {
  install(Vue) {
    addPreviewTab(Resources.MANAGED_PERMISSION_POLICIES, {
      title: createI18nPluralResourceKey(Resources.ROLES),
      is: ManagedPermissionPoliciesRolesRelationList,
    })

    modularManager.registerActionBar(Resources.MANAGED_PERMISSION_POLICIES, {
      is: ManagedPermissionPoliciesRolesActionBar,
    })
  },
}
