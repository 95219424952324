<template>
  <div class="fill-height">
    <WidgetFactory v-if="isReady" :widget="absentLeaveRequestsWidget" />
  </div>
</template>
<script lang="ts">
import { Resources } from '@/tt-entity-design/src/types'
import {
  CustomFilter,
  DataTableWidgetModel,
  Filter,
} from '@/tt-widget-components/types'
import { LeaveRequestsAbsentColumns } from './LeaveRequestsAbsentColumns'
import { FilterOperatorType } from '@/tt-widget-factory'
import Vue from 'vue'
import moment from 'moment-timezone'
import { LeaveRequestsStatus } from '@/tt-entity-design/src/components/leave-requests/types'
import { ColumnInputDefinition } from '@/tt-widget-components/widgets/DataTable/DataTableWidgetHook'
import { insertColumnsIntoArray } from '../../utils'
import { LeaveRequestsDatesManager } from '@/tt-entity-design/src/components/leave-requests/LeaveRequestsDatesManager'

export default Vue.extend({
  name: 'LeaveRequestsAbsentTab',
  props: {
    allowActions: {
      type: Boolean,
      default: true,
    },
    isPremium: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    isReady(): boolean {
      return this.$appContext.isReady()
    },
    currentDate(): string {
      return moment().format('YYYY-MM-DD')
    },
    affectedAccountsFilter(): CustomFilter {
      return {
        filterName: 'byAffectedAccounts',
      }
    },
    absentLeaveRequestsWidget(): DataTableWidgetModel {
      const startDateFilter: Filter = {
        attribute: 'startDateTime',
        operator: FilterOperatorType.EQUAL,
        value: this.currentDate,
      }
      const endDateFilter: Filter = {
        attribute: 'endDateTime',
        operator: FilterOperatorType.EQUAL,
        value: LeaveRequestsDatesManager.formatEndDate(this.currentDate),
      }
      const statusFilter: Filter = {
        attribute: 'status',
        operator: FilterOperatorType.EQUAL,
        value: LeaveRequestsStatus.PENDING,
      }

      return {
        is: 'DataTableWidget',
        title: '',
        allowActions: this.allowActions,
        query: {
          resource: Resources.LEAVE_REQUESTS,
          filters: [startDateFilter, endDateFilter, statusFilter],
        },
        component: {
          columns: this.isPremium
            ? insertColumnsIntoArray(
                LeaveRequestsAbsentColumns,
                LeaveRequestsAbsentColumns.length,
                this.serviceLevelImpactColumn,
              )
            : LeaveRequestsAbsentColumns,
        },
        toolbar: {
          filterOptions: {
            allowSearch: true,
            customFilters: [this.affectedAccountsFilter],
          },
        },
      }
    },
    serviceLevelImpactColumn(): ColumnInputDefinition[] {
      return [
        {
          headerText: 'tt-entity-design.leave-management.leave-requests.sli',
          attributeName: 'serviceLevelImpact',
          component: {
            is: 'ServiceLevelImpactPercentage',
          },
        },
      ]
    },
  },
})
</script>
