import { CommentAttribute, TypeEnum, SentFromType, TypeView } from './types'

export const TYPE = 'type' satisfies CommentAttribute
export const CREATED_ON = 'createdOn' satisfies CommentAttribute
export const COMMENT = 'comment' satisfies CommentAttribute

export const SENT_FROM = 'sentFrom' satisfies CommentAttribute
export const TYPE_VIEW_MAP: Record<TypeEnum, TypeView> = {
  AUTO_APPROVED: { icon: 'mdi-check', color: 'success' },
  BILLING_NOTE: { icon: 'mdi-currency-usd', color: 'warning' },
  COMMENT: { icon: 'mdi-comment', color: 'ttPrimary' },
  LOG: { icon: 'mdi-database', color: 'info' },
}

export const TYPE_SENT_FROM: Record<SentFromType, TypeView> = {
  FIELD_USER: { icon: 'mdi-shield-account-variant', color: 'grey' },
  OPERATOR: { icon: 'mdi-headset', color: 'grey' },
}
