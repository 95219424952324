import { StatusColorType } from '@/tt-ui/types'
import { AssetPersonsStatus } from './types'

/**
 * Color map for asset persons status
 */
export const AssetPersonColorsOptions: Record<
  AssetPersonsStatus,
  StatusColorType
> = {
  ACTIVE: {
    background: 'bg-green1',
    text: 'bg-text-green1',
  },
  ARCHIVED: {
    background: 'bg-red1',
    text: 'bg-text-red1',
  },
}
