import moment from 'moment-timezone'
import Vue from 'vue'

import { parseResourceDates } from '@/tt-widget-factory/helpers/parse-resource-attributes'
import {
  FieldTypes,
  FilterOperatorType,
  WidgetHookDependencies,
  WidgetState,
} from '@/tt-widget-factory'

import CollectionWidgetHook, {
  CollectionWidgetState,
} from '../../base/CollectionWidgetHook'
import {
  DefaultToolbar,
  SchedulerTimelineWidgetModel,
} from '../../schemas-types'
import { SchedulerTimelineView } from '../SchedulerTimeline/types'
import { TTC_API_MAX_LIMIT } from '@/tt-widget-components/constants'
import { createDateTimeString } from '@/helpers/dates/createDateTimeString'
import { Timezone } from '@/helpers/dates/timezones'
import { createTemporalFilterManager } from '@/tt-entity-filter/temporal-filters/TemporalFilterManager'
import { DateString, SchedulerTimelineWidgetState } from './types'
import { DEFAULT_TOOLBAR } from '../Scheduler/constants'
import { isViewValid } from './helper'
import { isResourceWhitelisted } from '@/tt-widget-factory/services/metadata-provider/resource-blacklist'
import { isValidDate } from '@/helpers/dates/datetimeFormatValidator'
import { schedulerTimelineViewMapping } from './helper'
import { SortDirectionType } from 'tracktik-sdk/lib/common/entity-collection'

export default class SchedulerTimelineWidgetHook extends CollectionWidgetHook<SchedulerTimelineWidgetModel> {
  state: WidgetState & CollectionWidgetState & SchedulerTimelineWidgetState

  constructor(deps: WidgetHookDependencies) {
    super(deps)

    // keep in separate state
    const schedulerState: SchedulerTimelineWidgetState = {
      currentView: SchedulerTimelineView.TIMELINE_WEEK,
      selectedDate: this.getUserNowDate(),
    }

    this.state = Vue.observable({
      ...schedulerState,
      ...this.state,
    })
  }

  get currentView(): SchedulerTimelineView {
    return this.state.currentView
  }

  get selectedDate(): DateString {
    return this.state.selectedDate
  }

  // Calendar controls are always in the toolbar, so we always want to show it
  // Overrides the default ColllectionWidgetHook getter
  get showToolbar(): boolean {
    return true
  }

  get viewOptions(): SchedulerTimelineView[] {
    return Object.values(SchedulerTimelineView)
  }

  /**
   * Returns the start date of the current view, in the user's timezone (`YYYY-MM-DD` format)
   */
  getStartDate(): DateString {
    const timeFrame = schedulerTimelineViewMapping[this.currentView] || 'day'

    return moment(this.selectedDate).startOf(timeFrame).format(`YYYY-MM-DD`)
  }

  /**
   * Returns the end date of the current view, in the user's timezone (`YYYY-MM-DD` format)
   */
  getEndDate(): DateString {
    const timeFrame = schedulerTimelineViewMapping[this.currentView] || 'day'

    return moment(this.selectedDate).endOf(timeFrame).format(`YYYY-MM-DD`)
  }

  /**
   * Sets the current view of the Scheduler, and optionally fetches the data for the new view.
   */
  async setCurrentView(
    view: SchedulerTimelineView,
    update = true,
  ): Promise<void> {
    if (!isViewValid(view)) return
    this.state.currentView = view
    if (update) await this.updateDateRangeFilter()
  }

  /**
   * Sets the required fields, and optionally fetches the data for the new view.
   */
  setRequiredFields(extraAttributes: string[] = []): Promise<void> {
    const resourceHasAttribute = (attributeName: string): boolean =>
      !!this.services.resourceMetaManager.getAttribute(
        this.resource,
        attributeName,
      )

    const groupResourceAttributes = [
      this.getGroupResourceNameAttribute(),
      this.getGroupResourceAttributeId(),
      this.getGroupResourceAttributeTitle(),
      this.getGroupResourceAttributeDescription(),
      this.getGroupResourceAttributeImageUrl(),
    ]

    /**
     * On whitelisted resources, we remove invalid fields before the request.
     * On regular, invalid fields are kept, causing an API error by design so
     * we can capture the error earlier.
     */
    const isntWhitelisted = (field: string) =>
      !isResourceWhitelisted(this.resource) || resourceHasAttribute(field)

    const attributes = [
      'id',
      this.getStartAttribute(),
      this.getEndAttribute(),
      this.getTitleAttribute(),
      ...groupResourceAttributes,
      ...extraAttributes,
    ]
      .filter(Boolean)
      .filter(isntWhitelisted)

    this.attributes = [...new Set(attributes)]

    this.isReadyToFetch = true

    return this.updateDateRangeFilter()
  }

  /**
   * Change the selected date, and optionally fetches the data for the new view.
   */
  async setSelectedDate(date: DateString, update = true): Promise<void> {
    if (!isValidDate(date)) {
      console.warn('New selected date is not a valid date:', date)
    }

    this.state.selectedDate = date
    if (update) await this.updateDateRangeFilter()
  }

  setup(): void {
    // Not ready to fetch until we get the required fields
    this.isReadyToFetch = false

    this.queryManager.limit = TTC_API_MAX_LIMIT

    if (!this.widget.toolbar) this.setWidgetToolbar(DEFAULT_TOOLBAR)

    this.updateDateRangeFilter()

    this.queryManager.setResetCallback(() => {
      this.setCurrentView(SchedulerTimelineView.TIMELINE_WEEK, false)
      this.setSelectedDate(this.getUserNowDate(), false)
      this.updateDateRangeFilter(false)
    })
  }

  /**
   * Check if the total number of entities is over the limit.
   * Used to display a message in the view component.
   */
  isOverLimit(): boolean {
    return this.totalEntities > this.queryManager.limit
  }

  /**
   * Returns the current date in the user's timezone.
   */
  getUserNowDate(): string {
    return moment.tz(moment(), this.getUserTimezone()).format('YYYY-MM-DD')
  }

  getUserTimezone(): Timezone {
    return this.services.authModule.getUserPreferences().timeZone as Timezone
  }

  protected parseEntity(item: Record<string, any>): Record<string, any> {
    /**
     * The API provides the values of Date attributes with a DateTime format
     * the moment: API-1715.
     * We must strip their time and timezone information out to prevent
     * timezone issues when the SyncFusion Schedule component parses the date
     * so it can alocate each event in their appropriate date slots.
     */
    return parseResourceDates(
      this.services.resourceMetaManager,
      this.resource,
      item,
    )
  }

  protected setWidgetToolbar(toolbar: DefaultToolbar): void {
    this.state.widget = { ...this.state.widget, toolbar }
  }

  protected updateDateRangeFilter(updateHook = true): Promise<void> {
    if (!this.isInitialized || !this.currentView || !this.selectedDate) return

    const start = createDateTimeString(this.getStartDate(), '00:00:00')
    const end = createDateTimeString(this.getEndDate(), '23:59:59')

    if (this.getStartAttribute() === this.getEndAttribute()) {
      const temporalFilterManager = createTemporalFilterManager({
        attribute: this.getStartAttribute(),
        operator: FilterOperatorType.BETWEEN,
        value: [start, end],
      })

      temporalFilterManager.setTimezone(this.getUserTimezone())

      const temporalFilter = temporalFilterManager.getFilter()

      this.queryManager.setCustomFilter(temporalFilter)
    } else {
      const startFilter = createTemporalFilterManager({
        attribute: this.getStartAttribute(),
        operator: FilterOperatorType.BEFORE,
        value: end,
      })

      const endFilter = createTemporalFilterManager({
        attribute: this.getEndAttribute(),
        operator: FilterOperatorType.AFTER,
        value: start,
      })

      startFilter.setTimezone(this.getUserTimezone())
      endFilter.setTimezone(this.getUserTimezone())

      this.queryManager.setCustomFilter(startFilter.getFilter())
      this.queryManager.setCustomFilter(endFilter.getFilter())
    }

    // Dont overwrite the Widget's Query Sort if there is any!
    const sort = this.widget.query.sort
    const hasSort = (Array.isArray(sort) && sort.length) || sort
    if (!hasSort) {
      this.queryManager.setSort([
        {
          attribute: this.getStartAttribute(),
          direction: SortDirectionType.ASC,
        },
      ])
    }

    if (updateHook) {
      return this.update()
    }
  }

  private getStartAttribute(): string {
    return this.widget.attributeMap.startAttribute
  }

  private getEndAttribute(): string {
    return this.widget.attributeMap.endAttribute
  }

  private getFieldType(attribute: string): FieldTypes | null {
    return (
      this.services.resourceMetaManager.getAttribute(this.resource, attribute)
        ?.type ?? null
    )
  }

  private getTitleAttribute(): string {
    return this.widget.attributeMap.titleAttribute
  }

  private getGroupResourceNameAttribute(): string {
    return (this.widget as SchedulerTimelineWidgetModel)
      .groupResourceAttributeMap.resourceNameAttribute
  }

  private getGroupResourceAttributeId(): string {
    return (this.widget as SchedulerTimelineWidgetModel)
      .groupResourceAttributeMap.idAttribute
  }

  private getGroupResourceAttributeTitle(): string {
    return (this.widget as SchedulerTimelineWidgetModel)
      .groupResourceAttributeMap.titleAttribute
  }

  private getGroupResourceAttributeDescription(): string {
    return (this.widget as SchedulerTimelineWidgetModel)
      .groupResourceAttributeMap.descriptionAttribute
  }

  private getGroupResourceAttributeImageUrl(): string {
    return (this.widget as SchedulerTimelineWidgetModel)
      .groupResourceAttributeMap.imageUrlAttribute
  }
}
