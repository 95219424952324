import { ColumnInputDefinition } from '@/tt-widget-components/widgets/DataTable/DataTableWidgetHook'

export const LeaveRequestsAbsentColumns: ColumnInputDefinition[] = [
  { attributeName: 'employee' },
  { attributeName: 'leaveType.name' },
  {
    attributeName: 'startDateTime',
    format: 'asDate',
    component: {
      is: 'LeaveRequestsDateTimeIconColumn',
      props: {
        dateName: 'startDateTime',
        tooltipText:
          'tt-entity-design.leave-management.leave-requests.start-time',
      },
    },
  },
  {
    attributeName: 'balanceDays',
    component: {
      is: 'LeaveRequestsBalanceDays',
    },
  },
  'reason',
  {
    headerText: 'res.leave-requests.extensions.affectedShifts.labels.label',
    component: {
      is: 'LeaveRequestsAffectedShiftsCounter',
    },
  },
]
