<template>
  <div class="pa-3 h-100">
    <v-simple-table fixed-header class="h-100">
      <thead>
        <tr>
          <th class="text-left" style="min-width: 300px" v-text="'Resource'" />
          <th class="text-left">
            <div class="d-flex">
              <v-icon small class="pl-1" v-text="`mdi-plus`" />
              <span
                v-text="$t('tt-entity-design.api-permissions.type.create')"
              />
            </div>
          </th>
          <th class="text-left">
            <div class="d-flex">
              <v-icon small class="pl-1" v-text="`mdi-eye`" />
              <span v-text="$t('tt-entity-design.api-permissions.type.view')" />
            </div>
          </th>
          <th class="text-left">
            <div class="d-flex">
              <v-icon small class="pl-1" v-text="`mdi-pencil`" />
              <span v-text="$t('tt-entity-design.api-permissions.type.edit')" />
            </div>
          </th>
          <th class="text-left">
            <div class="d-flex">
              <v-icon small class="pl-1" v-text="`mdi-delete`" />
              <span
                v-text="$t('tt-entity-design.api-permissions.type.delete')"
              />
            </div>
          </th>
          <th class="text-left">
            <v-icon small class="pl-1" v-text="`mdi-rocket-launch`" />
            <span
              v-text="$t('tt-entity-design.api-permissions.type.actions')"
            />
          </th>
        </tr>
      </thead>

      <tbody>
        <tr
          v-for="(
            { label, canCreate, canView, canEdit, canDelete, actions }, resource
          ) in resources"
          :key="resource"
        >
          <td v-text="label" />
          <td>
            <div class="d-flex align-center justify-center">
              <v-badge
                v-if="canCreate"
                :color="getIconColor(canCreate)"
                :icon="getIcon(canCreate)"
                :content="canCreate"
                overlap
              >
                <v-simple-checkbox disabled :value="true" color="ttPrimary" />
              </v-badge>
              <v-simple-checkbox
                v-else
                disabled
                :value="false"
                color="ttPrimary"
              />
            </div>
          </td>
          <td>
            <div class="d-flex align-center justify-center">
              <v-badge
                v-if="canView"
                :color="getIconColor(canView)"
                :icon="getIcon(canView)"
                :content="canView"
                overlap
              >
                <v-simple-checkbox disabled :value="true" color="ttPrimary" />
              </v-badge>
              <v-simple-checkbox
                v-else
                disabled
                :value="false"
                color="ttPrimary"
              />
            </div>
          </td>
          <td>
            <div>
              <v-badge
                v-if="canEdit"
                :color="getIconColor(canEdit)"
                :icon="getIcon(canEdit)"
                :content="canEdit"
                overlap
              >
                <v-simple-checkbox disabled :value="true" color="ttPrimary" />
              </v-badge>
              <v-simple-checkbox
                v-else
                disabled
                :value="false"
                color="ttPrimary"
              />
            </div>
          </td>
          <td>
            <v-badge
              v-if="canDelete"
              :color="getIconColor(canDelete)"
              :icon="getIcon(canDelete)"
              :content="canDelete"
              overlap
            >
              <v-simple-checkbox disabled :value="true" color="ttPrimary" />
            </v-badge>
            <v-simple-checkbox
              v-else
              disabled
              :value="false"
              color="ttPrimary"
            />
          </td>
          <td>
            <TChip
              v-for="(action, actionName) in actions"
              :key="actionName"
              :color="action.canExecute ? 'success' : 'ttPrimary'"
              class="px-2 ma-1"
            >
              {{ action.label }}

              <v-icon
                v-if="action.canExecute"
                x-small
                class="pl-1"
                v-text="`mdi-check`"
              />
            </TChip>
          </td>
        </tr>
      </tbody>
    </v-simple-table>
  </div>
</template>

<script lang="ts">
import {
  createResourceKey,
  createResourceActionKey,
} from '@/tt-widget-entity-flow/ResourceTranslator'
import Vue, { PropType } from 'vue'
import { Action, ResourceView, PortalType, PermissionView } from './types'
import { PORTAL_TYPES, PORTAL_TYPE_ICON_MAP } from './constants'
import { Resource } from '@/tt-widget-factory/services/resource-meta/types'
import { permissionMatch } from '@tracktik/tt-authentication'
import { DevConsole } from '@/plugins/DevConsole'

export default Vue.extend({
  name: 'ApiPermissionsViewer',
  props: {
    permissions: {
      type: Array as PropType<string[]>,
      required: true,
    },
    /**
     * Will hide resources when no permissions is set
     */
    showOnlyActivePermissionResources: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    resources(): ResourceView[] {
      const resourceMetas =
        this.$appContext.widgetServices.resourceMetaManager.getAllResources()

      const createActions = (
        resource: string,
        actions?: Record<string, Action>,
      ) => {
        const entries = Object.entries(actions ?? {})
          .filter(([name, _]) => name !== 'post' && name !== 'put')
          .map(([name, _]): [string, Action] => [
            name,
            {
              label: this.$t(createResourceActionKey(resource, name)),
              canExecute: this.hasActionPermission(resource, name),
            },
          ])

        const filteredEntries = this.showOnlyActivePermissionResources
          ? entries.filter(([_, { canExecute }]) => canExecute)
          : entries

        return Object.fromEntries(filteredEntries)
      }

      const hasSomeActions = (
        resource: string,
        actions?: Record<string, Action>,
      ) => {
        return (
          actions &&
          Object.keys(actions).some((action) =>
            this.hasActionPermission(resource, action),
          )
        )
      }

      const hasSomePermission = (
        resource: string,
        resourceSchema: Resource,
      ) => {
        return (
          hasSomeActions(resource, resourceSchema.actions) ||
          this.getCreatePermission(resource) ||
          this.getViewPermission(resource) ||
          this.getEditPermission(resource) ||
          this.getDeletePermission(resource)
        )
      }

      const createResourceView = ([resource, resourceSchema]: [
        string,
        Resource,
      ]): ResourceView => ({
        resourceName: resource,
        label: this.$t(createResourceKey(resource)),
        canCreate: this.getCreatePermission(resource),
        canView: this.getViewPermission(resource),
        canEdit: this.getEditPermission(resource),
        canDelete: this.getDeletePermission(resource),
        actions: createActions(resource, resourceSchema.actions),
      })

      return Object.entries(resourceMetas)
        .filter(([resource, resourceSchema]) =>
          this.showOnlyActivePermissionResources
            ? hasSomePermission(resource, resourceSchema)
            : true,
        )
        .map(([resource, resourceSchema]) =>
          createResourceView([resource, resourceSchema]),
        )
    },
  },
  methods: {
    hasPermission(permission: string): boolean {
      return permissionMatch(permission, this.permissions)
    },
    getTypeIcon(type: PortalType): PermissionView | null {
      return PORTAL_TYPE_ICON_MAP[type] ?? null
    },
    getIconColor(type: PortalType): string {
      const icon = this.getTypeIcon(type)

      return icon?.color ?? ''
    },
    getIcon(type: PortalType): string {
      const icon = this.getTypeIcon(type)

      return icon?.icon ?? ''
    },
    getPermissionType(
      resource: string,
      operation: string,
    ): PortalType | undefined {
      const operations = PORTAL_TYPES.filter((type) =>
        this.hasPermission(`${type}:${resource}:${operation}`),
      )

      if (operations.length > 1) {
        DevConsole.log('multiple permissions', resource, operation, operations)
      }

      return PORTAL_TYPES.find((type) =>
        this.hasPermission(`${type}:${resource}:${operation}`),
      )
    },
    getCreatePermission(resource: string): PortalType | undefined {
      return this.getPermissionType(resource, 'create')
    },
    getViewPermission(resource: string): PortalType | undefined {
      return this.getPermissionType(resource, 'view')
    },
    getEditPermission(resource: string): PortalType | undefined {
      return this.getPermissionType(resource, 'edit')
    },
    getDeletePermission(resource: string): PortalType | undefined {
      return this.getPermissionType(resource, 'delete')
    },
    hasActionPermission(resource: string, action: string): boolean {
      return this.hasPermission(`admin:${resource}:${action}:execute`)
    },
  },
})
</script>

<style scoped>
.v-data-table >>> .v-data-table__wrapper {
  height: 100% !important;
}

.v-simple-checkbox >>> .v-input--selection-controls__ripple::before {
  content: none;
}
</style>
