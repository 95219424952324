<template>
  <tt-tile v-bind="$props">
    <tt-attr slot="title" name="name" />
    <div slot="actionRight">
      <OperationCentersStatusAttr />
    </div>
  </tt-tile>
</template>
<script lang="ts">
import Vue from 'vue'
import OperationCentersStatusAttr from './OperationCentersStatusAttr.vue'
export default Vue.extend({
  name: 'OperationCentersTile',
  components: {
    OperationCentersStatusAttr,
  },
})
</script>
