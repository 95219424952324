<template>
  <component
    :is="container"
    v-if="entities && entities.length > 0"
    v-bind="$attrs"
    class="flex--column"
    :class="{ 'cursor--move': allowReorder }"
  >
    <ProvideItemHook
      v-for="(item, index) in items"
      :key="JSON.stringify(item.itemHook.entityReference)"
      :provide="item.itemHook"
    >
      <div class="flex--row align-center">
        <div
          class="drag-icon-container"
          :class="{ 'drag-icon-container--visible': allowReorder }"
        >
          <v-icon class="pa-3" v-text="'mdi-drag'" />
        </div>

        <slot :item-hook="item.itemHook" :index="index" class="w-100" />
      </div>
    </ProvideItemHook>
  </component>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue'
import Sortable from 'sortablejs'

import { EntityItemHook } from '@/tt-widget-entity-flow/EntityItemHook'

import { EntityRecord } from '../types'

export default Vue.extend({
  name: 'EntityLooper',
  inheritAttrs: false,
  props: {
    resourceName: {
      type: String,
      required: true,
    },
    container: {
      type: String,
      default: 'div',
    },
    actions: {
      type: Object as PropType<Record<string, string[]>>,
      default: () => ({}),
    },
    entities: {
      type: Array as PropType<EntityRecord[]>,
      default: () => [],
    },
    allowReorder: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      sortableInstance: null as null | Sortable,
    }
  },
  computed: {
    items(): EntityRecord[] | null {
      if (!this.entities.length) {
        return null
      }

      return this.entities.map((item: EntityRecord) => ({
        ...item,
        itemHook: new EntityItemHook(
          this.$appContext,
          {
            entity: item,
            resourceName: this.resourceName,
            entityId: item.id,
            availableActions: this.actions[item.id],
          },
          {
            fetchEnabled: false,
            hasFetched: true,
          },
        ),
      }))
    },
  },
  watch: {
    allowReorder() {
      this.destroySortable()

      if (this.allowReorder) this.initSortable()
    },
  },
  mounted() {
    if (this.allowReorder) this.initSortable()
  },
  beforeDestroy() {
    this.destroySortable()
  },
  methods: {
    initSortable() {
      const el = this.$el as HTMLElement

      this.sortableInstance = Sortable.create(el, {
        animation: 150,
        ghostClass: 'opacity-faded',
        onEnd: (evt) => {
          this.$emit('reorder', {
            oldIndex: evt.oldIndex,
            newIndex: evt.newIndex,
          })
        },
      })
    },
    destroySortable() {
      this.sortableInstance?.destroy()
      this.sortableInstance = null
    },
  },
})
</script>

<style scoped>
.cursor--move {
  cursor: move;
}

.drag-icon-container {
  width: auto;
  max-width: 0;
  transition: max-width 0.5s;
  overflow: hidden;
}

.drag-icon-container--visible {
  /** something bigger than the actual width for the transition */
  max-width: 200px;
}
</style>
