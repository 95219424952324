<template>
  <v-timeline-item
    fill-dot
    color="ttPrimary"
    class="escalation-rules--timeline-item-wrapper"
  >
    <template #icon>
      <span v-text="index + 1" />
    </template>
    <v-card>
      <v-card-text class="pb-0">
        <OperationCentersToEscalateToField
          :value="operationCentersToEscalateTo"
          :disabled-ids="disabledIds"
          :index="index"
          @input:operationCentersToEscalateTo="handleInput($event)"
        />
        <TimeToEscalateField
          class="mb-4"
          :value="timeToEscalate"
          :index="index"
          @input:timeToEscalate="handleInput($event)"
        />
      </v-card-text>
      <v-card-actions class="justify-end">
        <v-tooltip top>
          <template #activator="{ on }">
            <v-btn
              small
              depressed
              text
              :disabled="!hasRemoveButton"
              v-on="on"
              @click="$emit('remove', index)"
            >
              <v-icon color="red"> mdi-delete </v-icon>
            </v-btn>
          </template>
          <span>{{ $t('common.remove.btn') }}</span>
        </v-tooltip>
      </v-card-actions>
    </v-card>
  </v-timeline-item>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue'

import TimeToEscalateField from './TimeToEscalateField.vue'
import { EscalationRule } from '../types'

export default Vue.extend({
  name: 'EscalationRuleItemField',
  components: {
    TimeToEscalateField,
  },
  props: {
    index: { type: Number, required: true },
    hasRemoveButton: { type: Boolean, default: true },
    value: { type: Object as PropType<EscalationRule>, required: true },
    disabledIds: { type: Array, default: () => [] },
  },
  computed: {
    operationCentersToEscalateTo(): number[] {
      return this.value.operationCentersToEscalateTo || []
    },
    timeToEscalate(): number | null {
      return this.value.timeToEscalate ?? null
    },
  },
  methods: {
    handleInput(value: Partial<EscalationRule>): void {
      const escalationRule = {
        ...this.value,
        ...value,
      }
      this.$emit('input', escalationRule)
    },
  },
})
</script>

<style scoped>
.escalation-rules--timeline-item-wrapper >>> .v-timeline-item__inner-dot {
  color: white;
}
</style>
