import { CacheMaxAge } from './../../tt-widget-components/schemas-types'
import {
  Dimension,
  EntityCollectionResponse,
} from 'tracktik-sdk/lib/common/entity-collection'
import {
  EntityAggregationResponse,
  EntityAggregationResponseObject,
} from 'tracktik-sdk/lib/common/entity-aggregation'
import { FilterOperatorType } from 'tracktik-sdk/lib/common/entity-filters'

import { Filter } from '@/tt-widget-components/types'

export type { Dimension }
export { FilterOperatorType }

export type ResourceName = string
export type ResourceAttributeName = string

export const enum DimensionModifier {
  YEARMONTH = 'YEARMONTH',
  YEAR = 'YEAR',
  MONTHNUMBER = 'MONTHNUMBER',
  MONTH = 'MONTH',
  DATE = 'DATE',
  MONTHDAY = 'MONTHDAY',
  DAYOFWEEK = 'DAYOFWEEK',
  DAYOFWEEKNUMBER = 'DAYOFWEEKNUMBER',
  HOUR = 'HOUR',
}

export const dimensionModifiers: DimensionModifier[] = [
  DimensionModifier.YEARMONTH,
  DimensionModifier.YEAR,
  DimensionModifier.DATE,
  DimensionModifier.MONTHNUMBER,
  DimensionModifier.MONTH,
  DimensionModifier.MONTHDAY,
  DimensionModifier.DAYOFWEEK,
  DimensionModifier.DAYOFWEEKNUMBER,
  DimensionModifier.HOUR,
]
export type DimensionModifierFormValue = {
  value: DimensionModifier
  text: string
}
export const dimensionModifierFormValues: DimensionModifierFormValue[] = [
  {
    value: DimensionModifier.DATE,
    text: 'DATE',
  },
  {
    value: DimensionModifier.YEARMONTH,
    text: 'YEAR AND MONTH',
  },
  {
    value: DimensionModifier.YEAR,
    text: 'YEAR',
  },
  {
    value: DimensionModifier.MONTHNUMBER,
    text: 'MONTH',
  },
  {
    value: DimensionModifier.MONTHDAY,
    text: 'DAY OF MONTH',
  },
  {
    value: DimensionModifier.DAYOFWEEKNUMBER,
    text: 'DAY OF WEEK',
  },
  {
    value: DimensionModifier.HOUR,
    text: 'HOUR',
  },
]

export type MeasureOperation =
  | 'COUNT'
  | 'AVG'
  | 'SUM'
  | 'MIN'
  | 'MAX'
  | 'EXPRESSION'
  | 'DISTINCT'
export type SortDirection = 'ASC' | 'DESC'
export type Scope = string
/**
 * Icon name ex. home
 */
export type Icon = string
export type Color =
  | 'red'
  | 'pink'
  | 'purple'
  | 'deepPurple'
  | 'indigo'
  | 'blue'
  | 'lightBlue'
  | 'cyan'
  | 'teal'
  | 'green'
  | 'lightGreen'
  | 'lime'
  | 'yellow'
  | 'amber'
  | 'orange'
  | 'deepOrange'
  | 'brown'
  | 'grey'
  | 'blueGrey'

export type CustomFilter = {
  filterName: string
  value: string
}

export type ResourceExtension = string
export type FieldModifier = 'TRIM' | 'CAPITALIZE' | 'UPPERCASE' | 'LOWERCASE'
/**
 * Query to fetch aggregated data
 */
export interface AggregationQuery {
  resource: ResourceName
  dimensions?: Dimension[]
  measures: Measure[]
  /**
   * Page Offset
   */
  offset?: number
  /**
   * Page Size
   */
  limit?: number
  /**
   * Search Criteria
   */
  search?: string
  sort?: Sort[]
  filters?: Filter[]
  customFilters?: CustomFilter[]
  context?: Filter[]
  /**
   * Common Filters
   */
  scope?: Scope[]
  /**
   * Include Inactive
   */
  includeInactive?: boolean

  whereQL?: string
  havingQL?: string
  cacheMaxAge?: number
}
export interface Measure {
  operation: MeasureOperation
  attribute: ResourceAttributeName
  alias?: string

  [k: string]: any
}

export interface Sort {
  attribute: ResourceAttributeName
  direction?: SortDirection

  [k: string]: any
}

export interface Field {
  attribute: ResourceAttributeName
  alias?: string
  modifier?: FieldModifier

  [k: string]: any
}

export type AggregationQueryResponse = EntityAggregationResponseObject<any>
export type CollectionQueryResponse = EntityCollectionResponse<any>
