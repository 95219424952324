import { UserActivityStatus } from './types'
import { Resources } from '@/tt-entity-design/src/types'
import { Action } from '@/tt-entity-design/src/schema-types'

export const statusColorDictionary: Record<UserActivityStatus, string> = {
  [UserActivityStatus.ACTIVE]: 'green darken-1',
  [UserActivityStatus.INACTIVE]: 'red darken-1',
  [UserActivityStatus.AWAY]: 'orange darken-1',
}

export const DEFAULT_STATUS_COLOR = 'grey'

export const BROWSER_CHANNEL_PRESENCE_TRACKING = 'presence-tracking'

export const RESET_PRESENCE_TRACKING_TIMER = 'reset-presence-tracking-timer'

/**
 * Default settings
 * inactivityTime: Inactivity duration before switching to AWAY mode (in milliseconds).
 */
export const INACTIVITY_TIME = 300000

/**
 * Default settings
 * countdownValue: Countdown duration before switching to INACTIVE mode (in milliseconds).
 */
export const COUNTDOWN_TIME = 30000

/**
 * Default settings
 * debounceDelay: Delay before resetting the timer after user activity (in milliseconds).
 */
export const DEBOUNCE_DELAY = 500
/**
 * Default settings
 * debounceMaxWait: Maximum delay before resetting the timer after user activity (in milliseconds).
 */
export const DEBOUNCE_MAX_WAIT = 10000

export const { OPERATION_CENTERS } = Resources
//@ts-ignore action introduced in API-3655 to removed after it's merged
export const SET_OPERATOR_PRESENCE: Action<typeof OPERATION_CENTERS> =
  'set-operator-presence'
//@ts-ignore action introduced in API-3655 to removed after it's merged
export const UNSET_OPERATOR_PRESENCE: Action<typeof OPERATION_CENTERS> =
  'unset-operator-presence'
