<template>
  <div>
    <TChip v-if="hasDefaultPolicies" color="green" class="ma-2">
      <v-icon v-text="'mdi-check'" />
      {{ $t('escalation-policies.default-policies-chip') }}
    </TChip>
    <TChip
      v-if="customPoliciesKeysCount || !hasDefaultPolicies"
      class="ma-2"
      :color="customPoliciesKeysCount ? 'ttPrimary' : 'red'"
    >
      {{
        $tc(
          'escalation-policies.ticket-type-policies-chip',
          customPoliciesKeysCount,
          { customPoliciesKeysCount },
        )
      }}
    </TChip>
  </div>
</template>

<script lang="ts">
import Vue, { VueConstructor } from 'vue'
import { ItemHookProvider } from '@/tt-widget-entity-flow/types'
import {
  EscalationPolicies,
  TicketType,
} from '@/tt-entity-design/src/components/operation-center-escalation-policies/types'

export default (Vue as VueConstructor<Vue & ItemHookProvider>).extend({
  name: 'OperationCentersEscalationPoliciesAttr',
  inject: ['getItemHook'],
  computed: {
    policies(): EscalationPolicies[] {
      return this.getItemHook().getRawValue('policies') || []
    },
    defaultPolicies(): EscalationPolicies {
      return (
        this.policies.find((policy) => policy.ticketTypes === null) ||
        ({} as EscalationPolicies)
      )
    },
    hasDefaultPolicies(): boolean {
      return Object.keys(this.defaultPolicies).length > 0
    },
    customPolicies(): Record<TicketType, EscalationPolicies> {
      return this.policies.reduce((acc, item) => {
        if (item.ticketTypes && Array.isArray(item.ticketTypes)) {
          item.ticketTypes.forEach((ticketType) => {
            acc[ticketType] = item
          })
        }

        return acc
      }, {} as Record<TicketType, EscalationPolicies>)
    },
    customPoliciesKeys(): TicketType[] {
      return (Object.keys(this.customPolicies) as TicketType[]) || []
    },
    customPoliciesKeysCount(): number {
      return this.customPoliciesKeys.length
    },
  },
  created() {
    this.getItemHook().addAttribute('policies')
  },
})
</script>
