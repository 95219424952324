<template>
  <span
    v-if="showField"
    :class="{ 'text-clickable': !preventRelationPreview }"
    :title="showTooltip ? displayValue : null"
    @click="(event) => openPreview(event)"
    v-text="displayValue"
  />
</template>

<script lang="ts">
import Vue, { VueConstructor } from 'vue'

import { FormLabelTypes } from '@tracktik/tt-json-schema-form'

import { ActiveWindowProvider, LayoutComponents } from '@/tt-app-layout'

import { EntityIntentTypes } from '../intents/types'
import { EntityItemHook } from '../EntityItemHook'
import { isWhitelistedResourceInvalidField } from '../helper'
import { ItemHookProvider } from '../types'

type VueWithInjections = VueConstructor<
  Vue & ActiveWindowProvider & ItemHookProvider
>

/**
 * Displays a count items in the relation list as a link to the items' list
 */
export default (Vue as VueWithInjections).extend({
  name: 'TtAttrRelationList',
  inject: {
    activeWindow: { default: undefined },
    getItemHook: {},
  },
  props: {
    /**
     * Resource label to be shown beside the count. Default: the translated
     * resource's attribute name
     */
    label: { type: String, default: '' },
    /**
     * The resource's attribute's name
     */
    name: { type: String, required: true },
    /**
     * The resource name
     */
    resource: { type: String, required: true },
    /**
     * The count of items in the relation list
     */
    value: { type: Number, required: true },
    /**
     * Show tooltip
     */
    showTooltip: { type: Boolean, default: false },
    /**
     * Prevent the relation preview from opening on click
     */
    preventRelationPreview: { type: Boolean, default: false },
  },
  computed: {
    entityId(): number {
      return this.getItemHook().getRawValue('id')
    },
    isSideSheetChild(): boolean {
      return this.activeWindow?.name === LayoutComponents.sideSheet
    },
    itemHook(): EntityItemHook {
      return this.getItemHook()
    },
    displayLabel(): string {
      // Currently we only have access to the plural translated value given by the API
      // This part will be reviewed once these tickets are completed API-1405 & LAB-370
      const getTranslationKey = () =>
        this.$appContext.widgetServices.resourceMetaManager.getAttributeLabelKey(
          this.resource,
          this.name,
          FormLabelTypes.LABEL,
        )

      return this.$t(this.label || getTranslationKey() || this.name)
    },
    displayValue(): string {
      return `${this.value} ${this.displayLabel}`
    },
    showField(): boolean {
      return !isWhitelistedResourceInvalidField({
        attributeName: this.name,
        resourceMetaManager:
          this.$appContext.widgetServices.resourceMetaManager,
        resourceName: this.getItemHook().entityReference.resourceName,
      })
    },
  },
  methods: {
    openPreview(event: MouseEvent): void {
      if (this.preventRelationPreview) return

      // prevent event bubbling
      event.stopPropagation()

      // dispatch the preview
      this.$eventManager.dispatchEvent(EntityIntentTypes.PREVIEW, {
        resourceName: this.resource,
        activeWindow: this.activeWindow,
        entityId: this.entityId,
        entity: this.name,
        // on cases that we have an attr that is not required, it is not part of the columns preset
        // passing the existing itemHook does not fetch that attr
        // to be investigated what issues we might have, passing a new itemHook would cause
        // https://tracktik.atlassian.net/browse/FE-1876
        itemHook: this.itemHook,
        hasToAddPages: this.isSideSheetChild,
        is: 'EntityRelationListPreview',
      })
    },
  },
})
</script>

<style scoped>
.text-clickable:hover,
.text-clickable.selected {
  color: #4e69e9;
}
</style>
