<template>
  <ActionBarPreset :actions="actionList" />
</template>

<script lang="ts">
import Vue from 'vue'
import ActionBarPreset from '@/tt-widget-entity-flow/components/action-bar/ActionBarPreset.vue'
import {
  ViewAction,
  VueWithInjections,
} from '@/tt-widget-entity-flow/components/action-bar/ActionBarPreset'
import { LayoutWindowEvent } from '@/tt-app-layout'
import ApiPermissionsViewer from './permissions/ApiPermissionsViewer.vue'
import { Resources } from '@/tt-entity-design/src/types'
import { Attribute } from '@/tt-entity-design/src/schema-types'

export default (Vue as VueWithInjections).extend({
  name: 'ManagedPermissionPoliciesRolesActionBar',
  components: {
    ActionBarPreset,
  },
  inject: ['getItemHook'],
  computed: {
    actionList(): ViewAction[] {
      const viewPermissions: ViewAction = {
        icon: 'mdi-eye',
        title: this.$t('permissions'),
        onClick: async () => this.openPermissions(),
      }

      return [viewPermissions]
    },
  },
  methods: {
    async openPermissions(): Promise<void> {
      const policyPermissionAttr: Attribute<
        typeof Resources.MANAGED_PERMISSION_POLICIES
      > = 'permissions'

      const permissions = await this.$auth
        .getApi()
        .get(
          Resources.MANAGED_PERMISSION_POLICIES,
          this.getItemHook().getEntityId(),
          { fields: [{ attribute: policyPermissionAttr }] },
        )
        .then((entity) => entity[policyPermissionAttr])
        .catch((error) => {
          console.error('Error fetching permissions', error)

          return []
        })

      this.$eventManager.dispatchEvent(LayoutWindowEvent.BOTTOM_SHEET, {
        closeButton: true,
        is: ApiPermissionsViewer,
        props: {
          permissions,
          showOnlyActivePermissionResources: true,
        },
      })
    },
  },
})
</script>
